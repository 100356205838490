import * as React from 'react';
import { generatePath } from 'react-router-dom';
import { useServerSuccessEffect } from '../../../lib/hooks/ServerStateHooks';
import {
    useParkingaboServerFetch,
    useParkingaboServerWrite,
} from '../api/ParkingaboApi';
import { useParams } from 'react-router';
import {
    getBaseUriForPaymentMethod,
    ParkingaboPaymentMethods,
    PaymentMethodType,
} from '../components/forms/PaymentMethodForm';

const PAYMENT_POLLING_TIMEOUT = 1000;
const PAYMENT_POLLING_CANCEL_TIMEOUT = 10000;

export enum PaymentAttemptState {
    ABORTED = 'ABORTED',
    AUTHORIZED = 'AUTHORIZED',
    INITIALIZED = 'INITIALIZED',
    CREATED = 'CREATED',
    REFUNDED = 'REFUNDED',
    SETTLED = 'SETTLED',
}

export function paymentIsPending(paymentState?: PaymentStatus | null) {
    if (!paymentState) {
        return false;
    }

    return (
        paymentState.state === PaymentAttemptState.CREATED ||
        paymentState.state === PaymentAttemptState.INITIALIZED ||
        paymentState.state === PaymentAttemptState.AUTHORIZED
    );
}

export interface PaymentStatus {
    state: PaymentAttemptState;
    redirectUri?: string;
}

export function usePaymentStatus(
    pendingPaymentAttemptId: string,
    paymentMethodType: PaymentMethodType,
) {
    const [timeoutId, setTimeoutId] = React.useState<number | null>(null);
    const [canCancelTimeoutId, setCanCancelTimeoutId] = React.useState<
        number | null
    >(null);
    const [canCancel, setCanCancel] = React.useState(false);
    const { tenantId } = useParams<{ tenantId?: string }>();

    React.useEffect(() => {
        const id = window.setTimeout(() => {
            // after 30 seconds from the first attempt the payment can be canceled
            setCanCancel(true);
        }, PAYMENT_POLLING_CANCEL_TIMEOUT);

        setCanCancelTimeoutId(id);
    }, []);

    const [paymentStatusState, refetchPaymentStatus] = useParkingaboServerFetch<
        PaymentStatus,
        { paymentAttemptId: string },
        null
    >(
        ({ paymentAttemptId }) => ({
            url: `/ui-api/parkingabo/user/self/payment/${paymentAttemptId}/state?baseUri=${encodeURIComponent(
                `${window.location.protocol}//${
                    window.location.host
                }${generatePath(
                    getBaseUriForPaymentMethod(
                        paymentMethodType,
                        ParkingaboPaymentMethods.TWINT,
                    ),
                    { tenantId },
                )}`,
            )}`,
        }),
        { paymentAttemptId: pendingPaymentAttemptId },
    );
    const [paymentCancelState, cancel] = useParkingaboServerWrite<
        { paymentAttemptId: string },
        { success: boolean }
    >(({ paymentAttemptId }) => ({
        url: `/ui-api/parkingabo/user/self/payment/${paymentAttemptId}/cancel`,
    }));

    useServerSuccessEffect(paymentStatusState, status => {
        // polling
        if (paymentIsPending(status)) {
            const id = window.setTimeout(() => {
                setTimeoutId(null);
                refetchPaymentStatus();
            }, PAYMENT_POLLING_TIMEOUT);

            setTimeoutId(id);
        }
    });

    function cancelPayment() {
        if (canCancelTimeoutId) {
            window.clearTimeout(canCancelTimeoutId);
        }
        if (timeoutId) {
            window.clearTimeout(timeoutId);
        }
        cancel({ paymentAttemptId: pendingPaymentAttemptId });
    }

    return {
        paymentStatusState,
        paymentCancelState,
        canCancel,
        refetchPaymentStatus,
        cancelPayment,
    };
}
