import { Box } from '@mui/material';
import * as React from 'react';
import { Control } from 'react-hook-form/dist/types';
import { languageTranslations } from '../../../../../common/components/material-ui/react-hook-form-fields/CommonOptions';
import { Localized } from '../../../../../common/hooks/LanguageProvider';
import { getCustomerDisplayName } from '../../../../../common/models/Users';
import {
    RequestMethod,
    ServerRequestState,
    useNavigateOnSuccess,
    useServerSuccessEffect,
} from '../../../../../lib/hooks/ServerStateHooks';
import { useParkingaboServerWrite } from '../../../api/ParkingaboApi';
import { UserDataForm } from '../../../components/forms/UserDataForm';
import { ParkingaboLayoutWithHeader } from '../../../components/layout/ParkingaboLayoutWithHeader';
import { ParkingaboRoutedModal } from '../../../components/layout/ParkingaboRoutedModal';
import { ParkingaboMenuListItem } from '../../../components/ParkingaboMenuListItem';
import {
    ParkingaboUser,
    sexTranslations,
} from '../../../shared/ParkingaboModels';
import {
    AuthedRouteCompProps,
    useParkingaboAuthedPathGeneration,
} from '../../RouteUtils';
import { ValidationData } from '../../../../../common/utils/FormValidationHelpers';
import { Outlet } from 'react-router-dom';

export function useUserUpdate(refetchUser: () => void) {
    const [state, submit] = useParkingaboServerWrite<
        UserDataForm.OnboardingPayload,
        never
    >(() => ({
        url: '/ui-api/parkingabo/user/self',
        method: RequestMethod.PUT,
    }));

    useServerSuccessEffect(state, refetchUser);
    return { state, submit };
}

export function BaseModal({
    open,
    refetchUser,
    render,
    noCancelSaveButton,
    user,
}: {
    open: boolean;
    user: ParkingaboUser;
    refetchUser: () => void;
    noCancelSaveButton?: boolean;
    render: (
        control: Control,
        requestState: ServerRequestState<never, ValidationData>,
        submit: () => void,
    ) => JSX.Element;
}) {
    const { state, submit } = useUserUpdate(refetchUser);
    const generateAuthedParkingaboPath = useParkingaboAuthedPathGeneration();
    const profileSettingsPath = generateAuthedParkingaboPath('settings/user');
    useNavigateOnSuccess(state, profileSettingsPath);

    return (
        <ParkingaboRoutedModal
            backUrl={profileSettingsPath}
            open={open}
            render={() => (
                <ParkingaboLayoutWithHeader
                    backTo={profileSettingsPath}
                    title={
                        <Localized
                            de="Benutzer"
                            fr="Utilisateur"
                            it="Utente"
                            en="User"
                        />
                    }
                >
                    <UserDataForm.UserBaseForm
                        requestState={state}
                        submit={submit}
                        initialValues={user}
                        render={render}
                        noCancelSaveButton={noCancelSaveButton}
                    />
                </ParkingaboLayoutWithHeader>
            )}
        />
    );
}

export function EditProfileRoute({ user }: AuthedRouteCompProps) {
    const generateAuthedParkingaboPath = useParkingaboAuthedPathGeneration();
    return (
        <ParkingaboLayoutWithHeader
            backTo={generateAuthedParkingaboPath('settings')}
            noGutter
            title={
                <Localized
                    de="Benutzer"
                    fr="Utilisateur"
                    it="Utente"
                    en="User"
                />
            }
        >
            <Outlet />
            <Box>
                <ParkingaboMenuListItem
                    to={generateAuthedParkingaboPath('settings/user/name')}
                    supText={
                        <Localized
                            de="Anrede / Vorname / Nachname"
                            fr="Titre / Prénom / nom"
                            it="Titolo / Nome / cognome"
                            en="Salutation / Firstname / lastname"
                        />
                    }
                    text={
                        <>
                            {user.gender && (
                                <>{sexTranslations[user.gender]} </>
                            )}
                            {getCustomerDisplayName(user)}
                        </>
                    }
                />
                <ParkingaboMenuListItem
                    to={generateAuthedParkingaboPath('settings/user/language')}
                    supText={
                        <Localized
                            de="Korrespondenzsprache"
                            fr="Langue de correspondance"
                            it="Lingua di corrispondenza"
                            en="Correspondence language"
                        />
                    }
                    text={languageTranslations[user.language]}
                />
                <ParkingaboMenuListItem
                    to={generateAuthedParkingaboPath('settings/user/email')}
                    supText={
                        <Localized
                            de="E-Mail"
                            fr="E-mail"
                            it="E-mail"
                            en="Email"
                        />
                    }
                    text={
                        user.requestedEmail !== null ? (
                            <>
                                {user.requestedEmail}
                                <Localized
                                    de=" - nicht bestätigt"
                                    fr=" - pas confirmé"
                                    it=" - non confermato"
                                    en=" - not confirmed"
                                />
                            </>
                        ) : (
                            user.email
                        )
                    }
                />
                <ParkingaboMenuListItem
                    to={generateAuthedParkingaboPath('settings/user/password')}
                    supText={
                        <Localized
                            de="Passwort"
                            fr="Mot de passe"
                            it="Password"
                            en="Password"
                        />
                    }
                    text="********"
                />
            </Box>
        </ParkingaboLayoutWithHeader>
    );
}
