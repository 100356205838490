/*!
 * numeral.js language configuration
 * language : French in Switzerland (fr-ch)
 */
export const language: NumeralJSLocale = {
    delimiters: {
        thousands: "'",
        decimal: '.',
    },
    abbreviations: {
        thousand: 'k',
        million: 'm',
        billion: 'b',
        trillion: 't',
    },
    currency: {
        symbol: 'CHF',
    },
    ordinal: (number: number): string => {
        return number === 1 ? 'er' : 'e';
    },
};
