import * as React from 'react';
import { selectState } from '../../../../lib/Flux';
import {
    JsxMessage,
    Language,
    languageFromString,
    Message,
} from '../../../../lib/Localized';
import * as SettingsState from '../state/SettingsState';

export const Localized = selectState<
    Message | JsxMessage,
    { language: Language }
>(
    store => ({
        language: languageFromString(
            new SettingsState.StateSlice(store).state.language,
        ),
    }),
    props => <React.Fragment>{props[props.language]}</React.Fragment>,
);
