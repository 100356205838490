import * as React from 'react';
import { useProductAddContext } from './ProductsAddOutlet';
import { ProductsConfigurationReservation } from './ProductsConfigurationReservation';
import { ProductsConfigurationSubscription } from './ProductsConfigurationSubscription';
import { AuthedRouteCompProps } from '../../RouteUtils';
import { ParkingaboProductType } from '../../../shared/ParkingaboProductModels';
import { exhaustiveGuard } from '../../../../../lib/ExhaustiveGuard';
import { ProductsConfigurationGuestReservation } from './ProductsConfigurationGuestReservation';

export function ProductsConfigurationOutlet({
    refetchProducts,
    user,
}: AuthedRouteCompProps) {
    const {
        zones,
        productSelectionState,
        disableProductChange,
    } = useProductAddContext();
    if (productSelectionState.product === null) {
        return null;
    }
    if (
        productSelectionState.product?.type ===
        ParkingaboProductType.RESERVATION
    ) {
        return (
            <ProductsConfigurationReservation
                user={user}
                product={productSelectionState.product}
                zones={zones}
                noProductChange={disableProductChange}
                refetchProducts={refetchProducts}
            />
        );
    }

    if (
        productSelectionState.product?.type ===
        ParkingaboProductType.SUBSCRIPTION
    ) {
        return (
            <ProductsConfigurationSubscription
                product={productSelectionState.product}
                zones={zones}
                noProductChange={disableProductChange}
                refetchProducts={refetchProducts}
            />
        );
    }

    if (
        productSelectionState.product?.type ===
        ParkingaboProductType.GUEST_RESERVATION
    ) {
        return (
            <ProductsConfigurationGuestReservation
                user={user}
                product={productSelectionState.product}
                zones={zones}
                noProductChange={disableProductChange}
                refetchProducts={refetchProducts}
            />
        );
    }
    exhaustiveGuard(productSelectionState.product);
}
