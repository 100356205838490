export enum VehicleLicensePlateType {
    CAR = 'CAR',
    MOTORCYCLE = 'MOTORCYCLE',
}

export enum VehicleBadgeType {
    PARKINGPAY_BADGE = 'ParkingpayBadge',
    PARKINGPAY_BADGE_7BYTE = 'ParkingpayBadge7Byte',
    BONCARD = 'Boncard',
    SPITAL_EMMENTAL = 'SpitalEmmental',
    SWISSPASS = 'SwissPass',
    AIRPORT_ZH_SMARTCARD = 'AirportZhSmartCard',
}

interface BaseVehicle {
    customerTenantCarId: string;
    identificationQrCodeId: string;
    description: string;
}

interface VehicleWithLicensePlate {
    licensePlateNr: string;
    type: VehicleLicensePlateType;
    country: string;
}

interface VehicleWithBadge {
    badgeId: number;
    badgeLabelNr: string;
    badgeType: VehicleBadgeType;
}

export type ParkingaboVehicleWithLicensePlate = BaseVehicle &
    VehicleWithLicensePlate &
    Partial<VehicleWithBadge>;
type ParkingaboVehicleWithBadge = BaseVehicle &
    Partial<VehicleWithLicensePlate> &
    VehicleWithBadge;
type ParkingaboVehicleComplete = BaseVehicle &
    VehicleWithLicensePlate &
    VehicleWithBadge;
type ParkingaboVehicleEmpty = BaseVehicle &
    Partial<VehicleWithLicensePlate> &
    Partial<VehicleWithBadge>;

export type ParkingaboVehicle =
    | ParkingaboVehicleEmpty
    | ParkingaboVehicleWithLicensePlate
    | ParkingaboVehicleWithBadge
    | ParkingaboVehicleComplete;

export function isVehicleWithLicensePlate(
    v: ParkingaboVehicle,
): v is ParkingaboVehicleWithLicensePlate {
    return (
        v.country !== undefined &&
        v.country !== null &&
        v.type !== undefined &&
        v.type !== null &&
        v.licensePlateNr !== undefined &&
        v.licensePlateNr !== null
    );
}
export function isVehicleWithBadge(
    v: ParkingaboVehicle,
): v is ParkingaboVehicleWithBadge {
    return (
        v.badgeId !== undefined &&
        v.badgeId !== null &&
        v.badgeLabelNr !== undefined &&
        v.badgeLabelNr !== null &&
        v.badgeType !== undefined &&
        v.badgeType !== null
    );
}

export function isCompleteVehicle(
    v: ParkingaboVehicle,
): v is ParkingaboVehicleComplete {
    return isVehicleWithBadge(v) && isVehicleWithLicensePlate(v);
}
