import * as React from 'react';
import { useState } from 'react';
import {
    ParkingaboProductListItem,
    ParkingaboProductType,
} from '../../../shared/ParkingaboProductModels';
import { ProductDetailBody, ProductOverviewList } from './ProductsDetailOutlet';
import { useParkingaboServerWrite } from '../../../api/ParkingaboApi';
import { ValidationData } from '../../../../../common/utils/FormValidationHelpers';
import {
    RequestMethod,
    RequestStatus,
    useServerSuccessEffect,
} from '../../../../../lib/hooks/ServerStateHooks';
import { BackendRequestErrorMessage } from '../../../components/error/BackendRequestErrorMessage';
import { FeedbackPopup } from '../../../components/FeedbackPopup';
import { Localized } from '../../../../../common/hooks/LanguageProvider';
import { Typography } from '@mui/material';
import { CancelSuccessDialog } from './ProductsDetailOutletReservation';
import { ParkingaboButton } from '../../../components/layout/ParkingaboButton';
import { DateTime } from 'luxon';
import { useNavigate } from 'react-router-dom';

interface GuestReservationCancellationPayload {
    contractId: number;
}

export function ProductDetailGuestReservation({
    product,
    refetchProducts,
}: {
    product: ParkingaboProductListItem;
    refetchProducts: () => void;
}) {
    const navigate = useNavigate();
    const [cancelConfirmDialogOpen, setCancelConfirmDialogOpen] = useState(
        false,
    );
    const [cancelSuccessDialogOpen, setCancelSuccessDialogOpen] = useState(
        false,
    );
    const [
        cancellationState,
        cancelGuestReservation,
    ] = useParkingaboServerWrite<
        GuestReservationCancellationPayload,
        ValidationData
    >(({ contractId }) => ({
        url: `/ui-api/parkingabo/user/self/product/${contractId}`,
        method: RequestMethod.DELETE,
    }));
    useServerSuccessEffect(cancellationState, () => {
        setCancelSuccessDialogOpen(true);
    });
    const validFrom = DateTime.fromISO(product.validFrom);
    const showCancelButton =
        DateTime.now() < validFrom.minus({ minute: 1 }) &&
        product.type === ParkingaboProductType.GUEST_RESERVATION;
    return (
        <ProductDetailBody>
            <BackendRequestErrorMessage requestState={cancellationState} />
            <ProductOverviewList product={product} />
            {showCancelButton && (
                <ParkingaboButton
                    variant="outlined"
                    loading={cancellationState.status === RequestStatus.PENDING}
                    onClick={() => setCancelConfirmDialogOpen(true)}
                >
                    <Localized
                        de="Reservierung löschen"
                        fr="Supprimer la réservation"
                        it="Cancella riservazione"
                        en="Delete reservation"
                    />
                </ParkingaboButton>
            )}
            <CancellationConfirmDialog
                open={cancelConfirmDialogOpen}
                onAbort={() => setCancelConfirmDialogOpen(false)}
                onConfirm={() => {
                    cancelGuestReservation({
                        contractId: product.contractId,
                    });
                    setCancelConfirmDialogOpen(false);
                }}
            />
            <CancelSuccessDialog
                open={cancelSuccessDialogOpen}
                onClick={() => {
                    refetchProducts();
                    setCancelSuccessDialogOpen(false);
                    navigate('..');
                }}
            />
        </ProductDetailBody>
    );
}

function CancellationConfirmDialog({
    open,
    onAbort,
    onConfirm,
}: {
    open: boolean;
    onAbort: () => void;
    onConfirm: () => void;
}) {
    return (
        <FeedbackPopup
            open={open}
            color="warning"
            title={
                <Localized
                    de="Reservierung löschen"
                    fr="Supprimer la réservation"
                    it="Cancella riservazione"
                    en="Delete reservation"
                />
            }
            onAbort={onAbort}
            onConfirm={onConfirm}
            confirmLabel={
                <Localized
                    de="Löschen"
                    fr="Supprimer"
                    it="Elimina"
                    en="Delete"
                />
            }
        >
            <Typography component="p" sx={{ paddingBottom: 1 }}>
                <Localized
                    de="Möchten Sie diese Reservierung wirklich löschen?"
                    fr="Voulez-vous vraiment supprimer cette réservation?"
                    it="Vuole veramente cancellare questa riservazione?"
                    en="Do you really want to delete this reservation?"
                />
            </Typography>
            <Typography>
                <Localized
                    de="Bitte informieren Sie den Gast, dass der QR-Code für die Einfahrt zum Parking nicht mehr gültig ist."
                    fr="Veuillez informer le visiteur que le code QR pour entrer dans le parking n'est plus valide."
                    it="La preghiamo di informare l'ospite che il codice QR per l'ingresso nel parcheggio non è più valido."
                    en="Please inform the guest that the QR code for entering the parking is no longer valid."
                />
            </Typography>
        </FeedbackPopup>
    );
}
