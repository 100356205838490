export function rgba(hex: string, opacity: number): string {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;

    const hhex = hex.replace(
        shorthandRegex,
        (_m, r, g, b) => r + r + g + g + b + b,
    );

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hhex);

    if (!result || result.length !== 4) {
        return `ERROR in rgba(${hex}, ${opacity})`;
    }

    const r = parseInt(result[1], 16);
    const g = parseInt(result[2], 16);
    const b = parseInt(result[3], 16);

    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}
