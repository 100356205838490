import { AuthedRouteCompProps } from '../RouteUtils';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

export function TenantSelectionRoute({ user }: AuthedRouteCompProps) {
    const navigate = useNavigate();

    useEffect(() => navigate(`/${user.tenantId}`), [user.tenantId]);
    return null;
}
