import { DateTime } from 'luxon';
import * as React from 'react';
import { ParkingaboListItem } from '../../../components/ParkingaboMenuListItem';
import { Box, Stack, Typography } from '@mui/material';
import { Localized } from '../../../../../common/hooks/LanguageProvider';
import { Formatter } from '../../../../../lib/Date';
import { currencyCentsToLocalPrice } from '../../../../../lib/NumberFormatter';
import { useParkingaboServerFetch } from '../../../api/ParkingaboApi';
import { ParkingaboAsyncLoadedSection } from '../../../components/layout/ParkingaboAsyncLoadedSection';

interface ProductTransactions {
    productId: number;
    identifier: number;
    zone: string | null;
    vehicle: string | null;
    validFrom: string;
    validTo: string | null;
    priceInRappen: number | null;
}

export function ProductTransactionsList({ productId }: { productId: string }) {
    const [transactions] = useParkingaboServerFetch<
        ProductTransactions[],
        { productId: string | undefined }
    >(
        () => ({
            url: `/ui-api/parkingabo/user/self/product/${productId}/transactions`,
        }),
        { productId: productId },
    );

    return (
        <ParkingaboAsyncLoadedSection
            state={transactions}
            render={transactionData => (
                <TransactionsDisplay transactions={transactionData} />
            )}
        />
    );
}

function TransactionsDisplay({
    transactions,
}: {
    transactions: ProductTransactions[];
}) {
    if (transactions.length === 0) {
        return (
            <ParkingaboListItem>
                <Typography
                    color="text.disabled"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexGrow: 1,
                    }}
                >
                    <Localized
                        de="Keine Parkvorgänge in den letzten 7 Tagen"
                        fr="Pas de stationnement ces 7 derniers jours"
                        it="Nessuna sosta negli ultimi 7 giorni"
                        en="No parking procedures in the last 7 days"
                    />
                </Typography>
            </ParkingaboListItem>
        );
    }

    return (
        <>
            <Box>
                {transactions.map(contract => {
                    const hasPrice =
                        contract.priceInRappen !== null &&
                        contract.priceInRappen !== 0;
                    const validFrom = DateTime.fromISO(contract.validFrom);
                    const validTo = contract.validTo
                        ? DateTime.fromISO(contract.validTo)
                        : null;
                    const duration = validTo ? (
                        differenceInMin(validFrom, validTo)
                    ) : (
                        <Box
                            sx={{ fontStyle: 'italic', color: 'text.disabled' }}
                        >
                            offen
                        </Box>
                    );
                    return (
                        <ParkingaboListItem
                            key={contract.identifier}
                            variableHeight
                        >
                            <Stack
                                direction="column"
                                justifyContent="space-between"
                                alignItems="stretch"
                                spacing={1}
                                p={0}
                                sx={{ width: '100%' }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                        flexShrink: 0,
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                        }}
                                    >
                                        {Formatter.dayMonthYearHourMinute(
                                            validFrom,
                                        )}
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                        }}
                                    >
                                        {!hasPrice && duration}
                                        {hasPrice &&
                                            currencyCentsToLocalPrice(
                                                'de',
                                                contract.priceInRappen!!,
                                            )}
                                    </Box>
                                </Box>
                                <Typography>
                                    {!hasPrice ? (
                                        <Box>{contract.vehicle}</Box>
                                    ) : (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'flex-start',
                                                flexShrink: 0,
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                }}
                                            >
                                                {contract.vehicle}
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                }}
                                            >
                                                {duration}
                                            </Box>
                                        </Box>
                                    )}
                                </Typography>
                                {contract.zone && (
                                    <Typography>
                                        <Box>{contract.zone}</Box>
                                    </Typography>
                                )}
                            </Stack>
                        </ParkingaboListItem>
                    );
                })}
            </Box>
            <Typography
                sx={{
                    textAlign: 'center',
                    color: theme => theme.palette.text.disabled,
                    paddingY: 1,
                }}
            >
                <Localized
                    de="Letzte 7 Tage"
                    fr="Derniers 7 jours"
                    it="Ultimi 7 giorni"
                    en="Last 7 days"
                />
            </Typography>
        </>
    );
}

function differenceInMin(start: DateTime, end: DateTime) {
    const durationObj = end.diff(start, ['minutes']).toObject();
    durationObj.minutes = Math.round(
        durationObj.minutes ? durationObj.minutes : 0,
    );
    return durationObj.minutes + ' min';
}
