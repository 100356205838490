export const isMobile = () => {
    return !!navigator.userAgent.match(
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i,
    );
};

export const isIos = () => {
    return !!navigator.userAgent.match(/iPhone|iPad|iPod/i);
};

export const isAndroid = () => {
    return !!navigator.userAgent.match(/Android/i);
};
