import * as React from 'react';
import { Control, Controller, Path } from 'react-hook-form';
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
    RadioGroup,
    FormControlLabel,
    Radio,
    FormLabel,
    Typography,
} from '@mui/material';

export interface ReactHookFormSelectOption<FieldType> {
    value: FieldType;
    display: React.ReactNode;
}

export interface ReactHookFormSelectProps<FormFields, FieldType> {
    label: React.ReactNode;
    control: Control<FormFields>;
    name: Path<FormFields>;
    options: ReactHookFormSelectOption<FieldType | ''>[];
    style?: React.CSSProperties;
    readonly?: boolean;
}

export function ReactHookFormDropdownSelect<
    FormFields,
    FieldType extends number | string | string[]
>({
    label,
    control,
    name,
    options,
    style,
    readonly,
}: ReactHookFormSelectProps<FormFields, FieldType>) {
    return (
        <FormControl
            style={{
                width: '100%',
                ...style,
            }}
        >
            <InputLabel disableAnimation={readonly} variant="standard">
                {label}
            </InputLabel>
            <Controller
                name={name}
                control={control}
                render={({ field, fieldState: { error, isTouched } }) => (
                    <>
                        <Select
                            name={field.name}
                            value={field.value}
                            error={!!error && !isTouched}
                            disabled={readonly}
                            onChange={e => field.onChange(e.target.value)}
                        >
                            {options.map(o => (
                                <MenuItem
                                    value={o.value}
                                    key={o.value.toString()}
                                >
                                    {o.display}
                                </MenuItem>
                            ))}
                        </Select>
                        {error && !isTouched && (
                            <FormHelperText>{error.message}</FormHelperText>
                        )}
                    </>
                )}
            />
        </FormControl>
    );
}

export function ReactHookFormRadioSelect<
    FormFields,
    FieldType extends number | string | string[]
>({
    label,
    control,
    name,
    options,
    style,
    readonly,
}: ReactHookFormSelectProps<FormFields, FieldType>) {
    return (
        <FormControl
            style={{
                width: '100%',
                ...style,
            }}
        >
            <FormLabel>
                <Typography variant="overline">{label}</Typography>
            </FormLabel>
            <Controller
                name={name}
                control={control}
                render={({ field, fieldState: { error, isTouched } }) => (
                    <>
                        <RadioGroup
                            defaultValue="female"
                            name={field.name}
                            value={field.value}
                            onChange={e => field.onChange(e.target.value)}
                        >
                            {options.map(o => (
                                <FormControlLabel
                                    value={o.value}
                                    control={<Radio />}
                                    label={
                                        <Typography
                                            component="span"
                                            sx={{
                                                fontSize: 14,
                                                fontWeight: 700,
                                            }}
                                        >
                                            {o.display}
                                        </Typography>
                                    }
                                    disabled={readonly}
                                    key={o.value.toString()}
                                />
                            ))}
                        </RadioGroup>
                        {error && !isTouched && (
                            <FormHelperText>{error.message}</FormHelperText>
                        )}
                    </>
                )}
            />
        </FormControl>
    );
}
