import * as React from 'react';
import { useEffect, useState } from 'react';
import { Localized } from '../../../../../common/hooks/LanguageProvider';
import { Box, FormControlLabel, Switch, TextField } from '@mui/material';
import { DateTime } from 'luxon';
import {
    formatZoneName,
    ParkingaboProductTemplateGuestReservation,
} from './ParkingaboProductTemplateModels';
import {
    WizardBody,
    WizardBottomBar,
    WizardFadeInEffect,
    WizardProductHeader,
    WizardStepper,
} from './WizardComponents';
import { useNavigate } from 'react-router-dom';
import { useParkingaboServerWrite } from '../../../api/ParkingaboApi';
import {
    RequestStatus,
    useServerSuccessEffect,
} from '../../../../../lib/hooks/ServerStateHooks';
import { BackendRequestErrorMessage } from '../../../components/error/BackendRequestErrorMessage';

import { FeedbackPopup } from '../../../components/FeedbackPopup';
import { ParkingaboOverlineList } from '../../../components/layout/ParkingaboOverlineList';
import { ParkingaboZoneInfo } from '../../../shared/ParkingaboProductModels';
import { HourSelectionStep, MultiDateSelectionStep } from './SharedSteps';
import { ParkingaboUser } from '../../../shared/ParkingaboModels';

const enum WizardStep {
    DAY,
    START_TIME,
    END_TIME,
    OVERVIEW,
}

interface WizardSelection {
    day: DateTime | null;
    startDateTime: DateTime | null;
    endDateTime: DateTime | null;
    honestyFlag: boolean;
    customerRemark: string | null;
}

export function ProductsConfigurationGuestReservation({
    user,
    product,
    zones,
    noProductChange,
    refetchProducts,
}: {
    user: ParkingaboUser;
    product: ParkingaboProductTemplateGuestReservation;
    zones: ParkingaboZoneInfo[];
    noProductChange: boolean;
    refetchProducts: () => void;
}) {
    const [selection, setSelection] = useState<WizardSelection>({
        day: null,
        startDateTime: null,
        endDateTime: null,
        customerRemark: null,
        honestyFlag: false,
    });

    const [
        purchaseState,
        purchaseProduct,
        resetPurchaseState,
    ] = useParkingaboServerWrite<
        {
            productTemplateId: string;
            start: string;
            end: string;
            remark: string | null;
        },
        never
    >(() => ({
        url: `/ui-api/parkingabo/user/self/product/purchase/guest-reservation`,
    }));

    useServerSuccessEffect(purchaseState, refetchProducts);

    const navigate = useNavigate();

    useEffect(() => {
        if (purchaseState.status === RequestStatus.ERROR) {
            resetPurchaseState();
        }
    }, [selection]);

    function handlePurchase() {
        if (
            !selection.startDateTime ||
            !selection.endDateTime ||
            activeStep !== WizardStep.OVERVIEW
        ) {
            throw new Error(
                'Tried to purchase product without completed selection',
            );
        }
        setShowTestPhaseDialog(false);
        purchaseProduct({
            productTemplateId: product.productTemplateId,
            start: selection.startDateTime.toISO(),
            end: selection.endDateTime.toISO(),
            remark: selection.customerRemark,
        });
    }

    function combineDayAndTime(day: DateTime, time: DateTime) {
        return day.set({
            hour: time.hour,
            minute: 0,
            second: 0,
            millisecond: 0,
        });
    }

    const steps = [
        {
            step: WizardStep.DAY,
            label: <Localized de="Datum" fr="Date" it="Data" en="Date" />,
        },
        {
            step: WizardStep.START_TIME,
            label: <Localized de="Start" fr="Début" it="Inizio" en="Start" />,
        },
        {
            step: WizardStep.END_TIME,
            label: <Localized de="Ende" fr="Fin" it="Fine" en="End" />,
        },
        {
            step: WizardStep.OVERVIEW,
            label: (
                <Localized
                    de="Übersicht"
                    fr="Aperçu"
                    it="Panoramica"
                    en="Overview"
                />
            ),
        },
    ];

    const firstStep = steps[0].step;
    const [activeStep, setActiveStep] = React.useState<WizardStep>(firstStep);

    const nextStepHasClearance = stepHasClearance(
        activeStep,
        product,
        selection,
    );
    const minHour =
        selection.day != null && selection.day > DateTime.now()
            ? 0
            : DateTime.now().hour;

    const [showTestPhaseDialog, setShowTestPhaseDialog] = useState(false);

    return (
        <>
            <BackendRequestErrorMessage requestState={purchaseState} />
            <PurchaseSuccessDialog
                open={purchaseState.status === RequestStatus.SUCCESS}
                onAbort={() => navigate('...')}
            />
            <TestphaseProductSelected
                open={showTestPhaseDialog}
                onAbort={() => setShowTestPhaseDialog(false)}
                onProceed={handlePurchase}
            />
            <WizardProductHeader>
                <Localized {...product.name} />
            </WizardProductHeader>
            <WizardStepper
                steps={steps}
                activeStep={activeStep}
                onStepClick={step => {
                    if (stepHasClearance(step, product, selection)) {
                        setActiveStep(step);
                    }
                }}
                offset={noProductChange ? 0 : 2}
            />
            <WizardBody>
                {activeStep === WizardStep.DAY && (
                    <MultiDateSelectionStep
                        dates={selection.day ? [selection.day] : []}
                        onChange={dates => {
                            setSelection({
                                ...selection,
                                day: dates[0],
                            });
                        }}
                        minValidityDate={
                            product.minValidityStart
                                ? DateTime.max(
                                      DateTime.fromISO(
                                          product.minValidityStart,
                                      ),
                                      DateTime.now(),
                                  )
                                : null
                        }
                        maxSelectableDates={1}
                        maxDaysInFuture={product.maxDaysPurchaseAheadInFuture}
                    />
                )}
                {activeStep === WizardStep.START_TIME && (
                    <HourSelectionStep
                        instruction={{
                            de: 'Bitte wählen Sie eine Startzeit',
                            fr: 'Sélectionnez une heure de début',
                            it: 'Selezionare un orario di inizio',
                            en: 'Select a start time',
                        }}
                        minHour={minHour}
                        maxHour={23}
                        value={selection.startDateTime}
                        onChange={v => {
                            const dateTimeToSet = v
                                ? combineDayAndTime(selection.day!!, v)
                                : null;
                            setSelection(s => ({
                                ...s,
                                startDateTime: dateTimeToSet,
                            }));
                        }}
                    />
                )}
                {activeStep === WizardStep.END_TIME && (
                    <HourSelectionStep
                        instruction={{
                            de: 'Bitte wählen Sie eine Endzeit',
                            fr: 'Sélectionnez une heure de fin',
                            it: 'Selezionare un orario di fine',
                            en: 'Select a end time',
                        }}
                        minHour={(selection.startDateTime?.hour ?? minHour) + 1}
                        maxHour={Math.min(
                            23,
                            (selection.startDateTime?.hour ?? minHour) +
                                product.maxHours,
                        )}
                        value={selection.endDateTime}
                        onChange={v => {
                            const dateTimeToSet = v
                                ? combineDayAndTime(selection.day!!, v)
                                : null;
                            setSelection(s => ({
                                ...s,
                                endDateTime: dateTimeToSet,
                            }));
                        }}
                    />
                )}
                {activeStep === WizardStep.OVERVIEW && (
                    <ProductOverview
                        product={product}
                        zones={zones}
                        from={selection.startDateTime!!}
                        to={selection.endDateTime!!}
                        honestyFlag={{
                            set: v =>
                                setSelection(s => ({ ...s, honestyFlag: v })),
                            value: selection.honestyFlag,
                        }}
                        customerRemark={{
                            set: (v: string) =>
                                setSelection(s => ({
                                    ...s,
                                    customerRemark: v,
                                })),
                            value: selection.customerRemark,
                        }}
                    />
                )}
            </WizardBody>
            <WizardBottomBar
                onNextClick={
                    nextStepHasClearance
                        ? () => {
                              if (activeStep === WizardStep.OVERVIEW) {
                                  if (
                                      user.tenantId === 360 &&
                                      selection.day!! <
                                          DateTime.fromISO('2022-10-01')
                                  ) {
                                      setShowTestPhaseDialog(true);
                                  } else {
                                      handlePurchase();
                                  }
                              } else {
                                  setActiveStep(activeStep + 1);
                              }
                          }
                        : undefined
                }
                nextLabel={
                    activeStep === WizardStep.OVERVIEW ? (
                        <Localized
                            de="Buchen"
                            fr="Réserver"
                            it="Prenota"
                            en="Book"
                        />
                    ) : undefined
                }
                nextAsSubmitRequestState={
                    activeStep === WizardStep.OVERVIEW
                        ? purchaseState.status
                        : undefined
                }
                onPreviousClick={() => {
                    if (activeStep === firstStep) {
                        navigate('..');
                    } else {
                        setActiveStep(activeStep - 1);
                    }
                }}
                previousLabel={
                    activeStep === firstStep ? (
                        <Localized
                            de="Produkt ändern"
                            fr="Changer de produit"
                            it="Cambia prodotto"
                            en="Change product"
                        />
                    ) : undefined
                }
                hidePrevious={noProductChange && activeStep === firstStep}
            />
        </>
    );
}

function stepHasClearance(
    step: WizardStep,
    product: ParkingaboProductTemplateGuestReservation,
    selection: WizardSelection,
): boolean {
    switch (step) {
        case WizardStep.DAY:
            return !!selection.day;
        case WizardStep.START_TIME:
            return !!selection.day && !!selection.startDateTime;
        case WizardStep.END_TIME:
            return (
                !!selection.day &&
                !!selection.startDateTime &&
                !!selection.endDateTime
            );
        case WizardStep.OVERVIEW:
            return (
                !!selection.day &&
                !!selection.startDateTime &&
                !!selection.endDateTime &&
                selection.honestyFlag &&
                (product.customerRemarkFieldText === null ||
                    !!selection.customerRemark)
            );
        default:
            return true;
    }
}

function ProductOverview({
    product,
    zones,
    from,
    to,
    honestyFlag,
    customerRemark,
}: {
    product: ParkingaboProductTemplateGuestReservation;
    zones: ParkingaboZoneInfo[];
    from: DateTime;
    to: DateTime;
    honestyFlag: { value: boolean; set: (v: boolean) => void };
    customerRemark: { value: string | null; set: (v: string) => void };
}) {
    const productZones = zones.filter(zone =>
        product.zoneIds.includes(zone.zoneId),
    );

    return (
        <WizardFadeInEffect>
            <ParkingaboOverlineList.Body>
                <ParkingaboOverlineList.Item
                    label={
                        productZones.length > 1 ? (
                            <Localized
                                de="Parkings"
                                fr="Parkings"
                                it="Parcheggi"
                                en="Parkings"
                            />
                        ) : (
                            <Localized
                                de="Parking"
                                fr="Parking"
                                it="Parcheggio"
                                en="Parking"
                            />
                        )
                    }
                >
                    {productZones.map(zone => (
                        <Box
                            sx={{
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                            }}
                            key={zone.zoneId}
                        >
                            {formatZoneName(zone)}
                        </Box>
                    ))}
                </ParkingaboOverlineList.Item>
                <ParkingaboOverlineList.Item
                    label={
                        <Localized
                            de="Dauer"
                            fr="Durée"
                            it="Durata"
                            en="Duration"
                        />
                    }
                >
                    {from.toFormat('dd.MM.yyyy HH:00')} -{' '}
                    {to.toFormat('dd.MM.yyyy HH:00')}
                </ParkingaboOverlineList.Item>
                {product.info && (
                    <ParkingaboOverlineList.Item
                        label={
                            <Localized
                                de="Info"
                                fr="Info"
                                it="Info"
                                en="Info"
                            />
                        }
                    >
                        <Localized {...product.info} />
                    </ParkingaboOverlineList.Item>
                )}
                {product.customerRemarkFieldText && (
                    <TextField
                        value={customerRemark.value || ''}
                        onChange={e => customerRemark.set(e.target.value)}
                        label={
                            <Localized {...product.customerRemarkFieldText} />
                        }
                        multiline
                        rows={4}
                    />
                )}
                <FormControlLabel
                    control={
                        <Switch
                            checked={honestyFlag.value}
                            onChange={(e, v) => honestyFlag.set(v)}
                        />
                    }
                    label={<Localized {...product.honestyCheckboxText} />}
                />
            </ParkingaboOverlineList.Body>
        </WizardFadeInEffect>
    );
}

function PurchaseSuccessDialog({
    open,
    onAbort,
}: {
    open: boolean;
    onAbort: () => void;
}) {
    return (
        <FeedbackPopup
            open={open}
            color="success"
            title={
                <Localized
                    de="Bestätigung"
                    fr="Confirmation"
                    it="Conferma"
                    en="Confirmation"
                />
            }
            abortLabel={'OK'}
            onAbort={onAbort}
        >
            <Localized
                de="Das Produkt wurde erfolgreich erworben."
                fr="Le produit a été acheté avec succès."
                it="Il prodotto è stata acquistato con successo."
                en="The product was successfully purchased."
            />
        </FeedbackPopup>
    );
}

export function TestphaseProductSelected({
    open,
    onAbort,
    onProceed,
}: {
    open: boolean;
    onAbort: () => void;
    onProceed: () => void;
}) {
    return (
        <FeedbackPopup
            open={open}
            color="warning"
            title={
                <Localized
                    de="Testphase"
                    fr="Phase de test"
                    it="Fase di test"
                    en="Test phase"
                />
            }
            onConfirm={onProceed}
            confirmLabel={
                <Localized
                    de="Trotzdem kaufen"
                    fr="Acheter quand même"
                    it="Acquista comunque"
                    en="Buy anyway"
                />
            }
            abortLabel={
                <Localized
                    de="Abbrechen"
                    fr="Annuler"
                    it="Annulla"
                    en="Cancel"
                />
            }
            onAbort={onAbort}
        >
            <Localized
                de="Das Produkt wurde mit Datum in der Testphase ausgewählt. Produkte während der Testphase sind nur für Testzwecke gedacht."
                fr="Le produit a été sélectionné avec une date dans la phase de test. Les produits en phase de test sont uniquement destinés à des fins de test."
                it="Il prodotto è stato selezionato con una data nella fase di test. I prodotti durante la fase di test sono solo a scopo di test."
                en="The product was selected with a date in the test phase. Products during the test phase are for test purposes only."
            />
        </FeedbackPopup>
    );
}
