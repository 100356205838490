import 'core-js/stable';
import * as ReactDOM from 'react-dom';
import * as React from 'react';
import { AppContainer } from 'react-hot-loader';
import { App } from './App';
import { register } from './ServiceWorkerRegistration';
import { envIsProduction, envIsTest } from '../../lib/Environment';
import * as Sentry from '@sentry/browser';
import { CaptureConsole } from '@sentry/integrations';

const sentryDsn = ((): string | undefined => {
    if (envIsProduction()) {
        return 'https://fd1c9c8235734ac4a5d62e134ac69680@o28142.ingest.sentry.io/5856623';
    } else if (envIsTest()) {
        return 'https://0dba0e9032174065b08c9d8737160701@o28142.ingest.sentry.io/5856621';
    }
})();

if (sentryDsn) {
    Sentry.init({
        dsn: sentryDsn,
        release: PCS_VERSION,
        integrations: [
            new CaptureConsole({
                levels: ['error'],
            }),
        ],
    });
}

const rootEl = document.getElementById('app-container');
const render = (
    Component: React.ComponentClass<any> | React.FunctionComponent<any>,
) => {
    ReactDOM.render(
        <AppContainer>
            <Component />
        </AppContainer>,
        rootEl,
    );
};

render(App);

if ((module as any).hot) {
    (module as any).hot.accept('./App', () => {
        // we need to rerequire component here
        render(require('./App').App);
    });
}

register();
