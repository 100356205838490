import * as React from 'react';
import { Outlet } from 'react-router-dom';
import { AuthedRouteCompProps } from '../routes/RouteUtils';
import { PendingPaymentPoller } from './PendingPaymentPoller';
import { PaymentMethodType } from './forms/PaymentMethodForm';

export function PaymentChecker({
    refetchUser,
    user,
    paymentMethodType,
}: { paymentMethodType: PaymentMethodType } & AuthedRouteCompProps) {
    return (
        <>
            {user.pendingPaymentAttemptId ? (
                <PendingPaymentPoller
                    refetchUser={refetchUser}
                    showConfirm={true}
                    pendingPaymentId={user.pendingPaymentAttemptId.toString()}
                    onPaymentCanceled={refetchUser}
                    paymentMethodType={paymentMethodType}
                />
            ) : (
                <Outlet />
            )}
        </>
    );
}
