import * as React from 'react';
import { useEffect, useState } from 'react';
import { Localized } from '../../../../../common/hooks/LanguageProvider';
import { ParkingaboLayoutWithHeader } from '../../../components/layout/ParkingaboLayoutWithHeader';
import { AuthedRouteCompProps } from '../../RouteUtils';
import { Box } from '@mui/material';
import { ParkingaboAsyncLoadedSection } from '../../../components/layout/ParkingaboAsyncLoadedSection';
import { ParkingaboProductTemplate } from './ParkingaboProductTemplateModels';
import {
    useNavigate,
    useParams,
    Outlet,
    useOutletContext,
} from 'react-router-dom';
import { WizardSelectionState } from './ProductsSelectionOutlet';
import { useParkingaboServerFetch } from '../../../api/ParkingaboApi';
import { ParkingaboRoutedModal } from '../../../components/layout/ParkingaboRoutedModal';
import { ParkingaboZoneInfo } from '../../../shared/ParkingaboProductModels';

interface WizardProductSelection {
    zone: ParkingaboZoneInfo | null;
    product: ParkingaboProductTemplate | null;
}

interface ProductAddOutletContext {
    productTemplates: ParkingaboProductTemplate[];
    zones: ParkingaboZoneInfo[];
    productSelectionState: WizardSelectionState;
    setProductSelectionState: (value: WizardSelectionState) => void;
    productNotFound: boolean;
    disableProductChange: boolean;
}

export function useProductAddContext() {
    return useOutletContext<ProductAddOutletContext>();
}

function ProductAddOutlet({ context }: { context: ProductAddOutletContext }) {
    return <Outlet context={context} />;
}

interface ParkingaboProductSelectionResponse {
    productTemplates: ParkingaboProductTemplate[];
    zones: ParkingaboZoneInfo[];
}

export function ProductsAddOutlet({ user, products }: AuthedRouteCompProps) {
    const { productTemplateId } = useParams<{ productTemplateId?: string }>();
    const navigate = useNavigate();

    const [productsState] = useParkingaboServerFetch<
        ParkingaboProductSelectionResponse,
        {}
    >(
        () => ({
            url: '/ui-api/parkingabo/user/self/product/cardcenter-products',
        }),
        {},
    );

    const [productNotFound, setProductNotFound] = useState(false);
    const [disableProductChange, setDisableProductChange] = useState(false);

    const [productSelectionState, setProductSelectionState] = useState<
        WizardProductSelection
    >({
        zone: null,
        product: null,
    });

    useEffect(() => {
        if (productTemplateId) {
            setDisableProductChange(true);
        }
    }, []);

    useEffect(() => {
        if (productTemplateId && productsState.data) {
            const product = productsState.data.productTemplates.find(
                template => template.productTemplateId === productTemplateId,
            );
            if (product) {
                setProductSelectionState({ ...productSelectionState, product });
            } else {
                setProductNotFound(true);
                navigate('.');
            }
        }
    }, [productTemplateId, productsState.data]);

    useEffect(() => {
        if (productNotFound && productSelectionState.product) {
            setProductNotFound(false);
            setDisableProductChange(false);
        }
    }, [productSelectionState]);

    return (
        <ParkingaboRoutedModal
            open={true}
            backUrl={'..'}
            render={() => (
                <ParkingaboLayoutWithHeader
                    noGutter
                    backTo={'..'}
                    title={
                        <Localized
                            de="Produkt hinzufügen"
                            fr="Ajouter un produit"
                            it="Aggiungi prodotto"
                            en="Add product"
                        />
                    }
                >
                    <ParkingaboAsyncLoadedSection
                        state={productsState}
                        render={products => (
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'stretch',
                                    height: '100%',
                                }}
                            >
                                <ProductAddOutlet
                                    context={{
                                        productTemplates:
                                            products.productTemplates,
                                        zones: products.zones,
                                        productSelectionState: productSelectionState,
                                        setProductSelectionState: setProductSelectionState,
                                        productNotFound: productNotFound,
                                        disableProductChange: disableProductChange,
                                    }}
                                />
                            </Box>
                        )}
                    />
                </ParkingaboLayoutWithHeader>
            )}
        />
    );
}
