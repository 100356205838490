import * as React from 'react';
import { Box } from '@mui/material';
import { Localized } from '../../../../common/hooks/LanguageProvider';
import { ParkingaboMenuListItem } from '../ParkingaboMenuListItem';
import { useParkingaboServerWrite } from '../../api/ParkingaboApi';
import {
    RequestStatus,
    useServerSuccessEffect,
} from '../../../../lib/hooks/ServerStateHooks';
import { BlockingLoadingModal } from '../BlockingLoadingModal';
import { generatePath, useParams } from 'react-router';
import { PaymentIcon } from '../PaymentIcon';

export enum ParkingaboPaymentMethods {
    TWINT = 'TWINT',
    CARDS = 'CARDS',
    POSTFINANCE = 'POSTFINANCE',
}
export enum PaymentMethodType {
    ONBOARDING = 'ONBOARDING',
    UPDATE = 'UPDATE',
    REGISTER = 'REGISTER',
}
interface AliasCreationRequestPayload {
    paymentMethod: ParkingaboPaymentMethods;
    baseUri: string;
}

export function PaymentMethodForm({
    paymentMethodType,
}: {
    paymentMethodType?: PaymentMethodType;
}) {
    const { tenantId } = useParams<{ tenantId?: string }>();
    const [externalUriState, fetchExternalUri] = useParkingaboServerWrite<
        AliasCreationRequestPayload,
        { redirectUri: string }
    >(() => ({
        url: `/ui-api/parkingabo/user/self/payment/start-alias-registration`,
    }));

    function handlePaymentMethodFlow(
        e: React.MouseEvent | undefined,
        paymentMethod: ParkingaboPaymentMethods,
    ) {
        if (e) {
            e.preventDefault();
        }

        const baseUri = generatePath(
            getBaseUriForPaymentMethod(paymentMethodType, paymentMethod),
            { tenantId },
        );
        fetchExternalUri({
            paymentMethod,
            baseUri: `${window.location.protocol}//${window.location.host}${baseUri}`,
        });
    }

    useServerSuccessEffect(externalUriState, data => {
        location.href = data.redirectUri;
    });

    return (
        <Box>
            <ParkingaboMenuListItem
                onClick={e =>
                    handlePaymentMethodFlow(e, ParkingaboPaymentMethods.TWINT)
                }
                to=""
                icons={[<PaymentIcon icon="twi" key="twi" />]}
                text={<Localized de="TWINT" fr="TWINT" it="TWINT" en="TWINT" />}
            />
            <ParkingaboMenuListItem
                onClick={e =>
                    handlePaymentMethodFlow(e, ParkingaboPaymentMethods.CARDS)
                }
                to=""
                icons={[
                    <PaymentIcon icon="vis" key="vis" />,
                    <PaymentIcon icon="eca" key="eca" />,
                    <PaymentIcon icon="amx" key="amx" />,
                ]}
                text={
                    <Localized de="Karten" fr="Cartes" it="Carte" en="Cards" />
                }
            />
            <ParkingaboMenuListItem
                onClick={e =>
                    handlePaymentMethodFlow(
                        e,
                        ParkingaboPaymentMethods.POSTFINANCE,
                    )
                }
                to=""
                text={
                    <Localized
                        de="Postfinance"
                        fr="Postfinance"
                        it="Postfinance"
                        en="Postfinance"
                    />
                }
            />
            <BlockingLoadingModal
                open={
                    externalUriState.status === RequestStatus.PENDING ||
                    externalUriState.status === RequestStatus.SUCCESS
                }
            />
        </Box>
    );
}

export function getBaseUriForPaymentMethod(
    paymentMethodType?: PaymentMethodType,
    paymentMethod?: ParkingaboPaymentMethods,
) {
    if (paymentMethod === ParkingaboPaymentMethods.TWINT) {
        switch (paymentMethodType) {
            case PaymentMethodType.ONBOARDING:
                return '/:tenantId/onboarding-twint';
            case PaymentMethodType.REGISTER:
                return '/:tenantId/register-twint';
            case PaymentMethodType.UPDATE:
                return '/:tenantId/update-twint';
        }
    } else {
        switch (paymentMethodType) {
            case PaymentMethodType.ONBOARDING:
                return '/:tenantId/onboarding-payments';
            case PaymentMethodType.REGISTER:
                return '/:tenantId/register-payments';
            case PaymentMethodType.UPDATE:
                return '/:tenantId/update-payments';
        }
    }
    return '';
}
