import * as React from 'react';
import * as superagent from 'superagent';
import { RequestStatus } from '../../lib/hooks/ServerStateHooks';
import { generateState } from '../../lib/Flux';

export enum PairingState {
    UNKNOWN = 'UNKNOWN',
    CREATED = 'CREATED',
    CONFIRMED_BY_USER = 'CONFIRMED_BY_USER',
    SUCCESS = 'SUCCESS',
    ABORTED = 'ABORTED',
}

export namespace PendingTwintPairing {
    export enum Mode {
        PAYMENT = 'PAYMENT',
        UOF = 'UOF',
    }

    export interface State {
        pairingToken: string | null;
        clientState: ClientState;
        mode: Mode;
    }

    export const { get, set, reset } = generateState<State>(
        'twint-pairing-token',
        { pairingToken: null, clientState: null, mode: Mode.PAYMENT },
    );
}

type TwintPaymentStateUnknown = {
    mode: PendingTwintPairing.Mode.PAYMENT;
    state: PairingState.UNKNOWN;
    requestState: RequestStatus;
    permitId: null;
};
type TwintPaymentStateKnown = {
    mode: PendingTwintPairing.Mode.PAYMENT;
    state: PairingState;
    requestState: RequestStatus;
    token: number;
    amount: string;
    permitId: number | null;
};
export type TwintPaymentState =
    | TwintPaymentStateUnknown
    | TwintPaymentStateKnown;
type TwintUofStateUnknown = {
    mode: PendingTwintPairing.Mode.UOF;
    state: PairingState.UNKNOWN;
    requestState: RequestStatus;
};
type TwintUofStateKnown = {
    mode: PendingTwintPairing.Mode.UOF;
    state: PairingState;
    requestState: RequestStatus;
    token: string;
};
export type TwintUofState = TwintUofStateUnknown | TwintUofStateKnown;
export type TwintPendingState = TwintPaymentState | TwintUofState;
export type ClientState = null | 'SUCCESS' | 'NO_TWINT' | 'CANCELED' | 'ERROR';

export enum UoFState {
    CREATED = 'CREATED',
    REQUESTED = 'REQUESTED',
    PAIRED = 'PAIRED',
    ABORTED = 'ABORTED',
    SUCCESS = 'SUCCESS',
}

export function transactionFromUoFState(state: UoFState) {
    switch (state) {
        case UoFState.ABORTED:
            return PairingState.ABORTED;
        case UoFState.SUCCESS:
            return PairingState.SUCCESS;
        case UoFState.PAIRED:
            return PairingState.CONFIRMED_BY_USER;
        case UoFState.REQUESTED:
        case UoFState.CREATED:
            return PairingState.CREATED;
    }
}

export interface TwintUoFStateResponse {
    state: UoFState;
    aliasId: number;
    token: string;
}

export type TwintPairingState = (
    mode: PendingTwintPairing.Mode,
    requestCounter: number,
    token: string,
) => TwintPendingState;

export function abortTwintTransaction(
    abortRequest: superagent.Request<any, any>,
    onDone?: () => void,
) {
    const onEnd = () => {
        if (onDone) {
            onDone();
        }
    };
    return abortRequest.end(onEnd);
}

export function useTwintState(
    token: string,
    mode: PendingTwintPairing.Mode,
    clientState: ClientState = null,
    fluxLogger: (action: string, payload: any) => any,
    stateState: TwintPairingState,
    abortRequest: superagent.Request<any, any>,
): TwintPendingState {
    const [requestCounter, setRequestCounter] = React.useState(0);
    const [lastState, setLastState] = React.useState<TwintPendingState>(
        mode === PendingTwintPairing.Mode.PAYMENT
            ? {
                  mode: PendingTwintPairing.Mode.PAYMENT,
                  state: PairingState.UNKNOWN,
                  requestState: RequestStatus.NEVER_EXECUTED,
                  permitId: null,
              }
            : {
                  mode: PendingTwintPairing.Mode.UOF,
                  state: PairingState.UNKNOWN,
                  requestState: RequestStatus.NEVER_EXECUTED,
              },
    );
    const pairingState = stateState(mode, requestCounter, token);

    React.useEffect(() => {
        if (
            pairingState.state === PairingState.SUCCESS ||
            pairingState.state === PairingState.ABORTED
        ) {
            return;
        }
        const interval = setInterval(() => {
            if (pairingState.requestState !== RequestStatus.PENDING) {
                setRequestCounter(requestCounter => requestCounter + 1);
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [pairingState]);

    React.useEffect(() => {
        if (clientState === 'ERROR') {
            fluxLogger('twint-abort-client-error', pairingState);
            abortTwintTransaction(abortRequest);
        }
    }, [clientState]);

    React.useEffect(() => {
        if (
            pairingState.state !== PairingState.UNKNOWN &&
            pairingState.state !== lastState.state
        ) {
            setLastState(pairingState);
        }
    }, [pairingState.state]);

    return lastState;
}
