import * as React from 'react';
import { useMemo } from 'react';
import { Localized } from '../../../../../common/hooks/LanguageProvider';
import { Stack, Typography } from '@mui/material';
import { ParkingaboProductTemplate } from './ParkingaboProductTemplateModels';
import {
    WizardBody,
    WizardBottomBar,
    WizardFadeInEffect,
    WizardStepInfo,
    WizardStepper,
} from './WizardComponents';
import { ErrorAlert } from '../../../../../common/components/material-ui/ErrorAlert';
import { SelectableList } from '../../../components/SelectableList';
import { useNavigate } from 'react-router-dom';
import { useProductAddContext } from './ProductsAddOutlet';
import { ParkingaboZoneInfo } from '../../../shared/ParkingaboProductModels';
import { VehicleLicensePlateType } from '../../../../../common/models/Vehicle';
import TwoWheeler from '@mui/icons-material/TwoWheeler';
import DriveEta from '@mui/icons-material/DriveEta';

const enum WizardStep {
    ZONE,
    PRODUCT,
}

export interface WizardSelectionState {
    zone: ParkingaboZoneInfo | null;
    product: ParkingaboProductTemplate | null;
}

export function ProductsSelectionOutlet() {
    const {
        productTemplates,
        zones,
        productSelectionState,
        setProductSelectionState,
        productNotFound,
    } = useProductAddContext();

    const navigate = useNavigate();

    const [activeStep, setActiveStep] = React.useState<WizardStep>(
        productSelectionState.zone ? WizardStep.PRODUCT : WizardStep.ZONE,
    );

    const steps: WizardStepInfo<WizardStep>[] = [
        {
            step: WizardStep.ZONE,
            label: (
                <Localized
                    de="Standort"
                    fr="Lieu"
                    it="Località"
                    en="Location"
                />
            ),
        },
        {
            step: WizardStep.PRODUCT,
            label: (
                <Localized
                    de="Produkt"
                    fr="Produit"
                    it="Prodotto"
                    en="Product"
                />
            ),
        },
    ];

    const nextStepHasClearance = stepHasClearance(
        activeStep + 1,
        productSelectionState,
    );

    function handleNext() {
        switch (activeStep) {
            case WizardStep.ZONE:
                setActiveStep(activeStep + 1);
                break;
            case WizardStep.PRODUCT:
                if (productSelectionState.product) {
                    navigate(productSelectionState.product.productTemplateId);
                }
                break;
        }
    }

    return (
        <>
            {productNotFound && (
                <ErrorAlert>
                    <Localized
                        de="Produkt nicht gefunden. Wählen Sie manuell ein Produkt aus."
                        fr="Produit pas trouvé. Sélectionnez manuellement un produit."
                        it="Prodotto non trovato. Selezioni un prodotto manualmente."
                        en="Product not found. Select a product manually."
                    />
                </ErrorAlert>
            )}
            <WizardStepper
                steps={steps}
                activeStep={activeStep}
                onStepClick={step => {
                    if (stepHasClearance(step, productSelectionState)) {
                        setActiveStep(step);
                    }
                }}
            />
            <WizardBody>
                {activeStep === WizardStep.ZONE && (
                    <ZoneSelection
                        zones={zones}
                        selectedZoneId={productSelectionState.zone?.zoneId}
                        onZoneChange={zone => {
                            setProductSelectionState({
                                ...productSelectionState,
                                zone,
                            });
                            handleNext();
                        }}
                    />
                )}
                {activeStep === WizardStep.PRODUCT && (
                    <ProductSelection
                        products={productTemplates}
                        selectedProductTemplateId={
                            productSelectionState.product?.productTemplateId
                        }
                        selectedZoneId={productSelectionState.zone?.zoneId!!}
                        onProductChange={product => {
                            setProductSelectionState({
                                ...productSelectionState,
                                product,
                            });
                            if (product) {
                                navigate(product.productTemplateId);
                            }
                        }}
                    />
                )}
            </WizardBody>
            <WizardBottomBar
                onNextClick={nextStepHasClearance ? handleNext : undefined}
                onPreviousClick={
                    WizardStep.PRODUCT
                        ? () => setActiveStep(activeStep - 1)
                        : undefined
                }
                hidePrevious={activeStep === WizardStep.ZONE}
            />
        </>
    );
}

function stepHasClearance(step: WizardStep, selection: WizardSelectionState) {
    switch (step) {
        case WizardStep.ZONE:
            return true;
        case WizardStep.PRODUCT:
            return !!selection.zone;
        default:
            return !!selection.zone && !!selection.product;
    }
}

function ZoneSelection({
    zones,
    selectedZoneId,
    onZoneChange,
}: {
    zones: ParkingaboZoneInfo[];
    selectedZoneId: number | undefined;
    onZoneChange: (zone: ParkingaboZoneInfo | null) => void;
}) {
    const selectionItems = useMemo(
        () =>
            zones.map(zone => ({
                id: zone.zoneId,
                title: zone.name,
                content: <Typography>{zone.city}</Typography>,
            })),
        [zones],
    );

    function handleSelectionChange(selectedIds: number[]) {
        const selectedZone =
            zones.find(zone => zone.zoneId === selectedIds[0]) ?? null;
        onZoneChange(selectedZone);
    }

    const initialSelection = selectedZoneId ? [selectedZoneId] : undefined;

    return (
        <WizardFadeInEffect>
            <SelectableList
                selectionItems={selectionItems}
                onChange={handleSelectionChange}
                initialSelection={initialSelection}
                preventDeselect
            />
        </WizardFadeInEffect>
    );
}

function ProductSelection({
    products,
    selectedProductTemplateId,
    selectedZoneId,
    onProductChange,
}: {
    products: ParkingaboProductTemplate[];
    selectedProductTemplateId?: string;
    selectedZoneId: number;
    onProductChange: (zone: ParkingaboProductTemplate | null) => void;
}) {
    const selectionItems = useMemo(() => {
        const availableProductsOfSelectedZone = products.filter(product =>
            product.zoneIds.includes(selectedZoneId),
        );
        const hasMotorcycle = availableProductsOfSelectedZone.some(
            product =>
                product.vehicleType === VehicleLicensePlateType.MOTORCYCLE,
        );
        return availableProductsOfSelectedZone.map(product => ({
            id: product.productTemplateId,
            content: (
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Typography display="inline" fontWeight="bold">
                        <Localized {...product.name} />
                    </Typography>
                    {hasMotorcycle && (
                        <Stack justifyContent="center" alignItems="center">
                            {product.vehicleType ===
                            VehicleLicensePlateType.CAR ? (
                                <DriveEta />
                            ) : (
                                <TwoWheeler />
                            )}
                        </Stack>
                    )}
                </Stack>
            ),
        }));
    }, [products, selectedZoneId]);

    function handleSelectionChange(selectedIds: string[]) {
        const selectedProduct =
            products.find(
                product => product.productTemplateId === selectedIds[0],
            ) ?? null;
        onProductChange(selectedProduct);
    }

    const initialSelection = selectedProductTemplateId
        ? [selectedProductTemplateId]
        : undefined;

    return (
        <WizardFadeInEffect>
            <SelectableList
                selectionItems={selectionItems}
                onChange={handleSelectionChange}
                initialSelection={initialSelection}
                preventDeselect
            />
        </WizardFadeInEffect>
    );
}
