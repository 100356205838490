import * as superagent from 'superagent';

export class StatusCodeClass {
    private statusCodeClass: number | null;
    constructor(statusCode: number | null) {
        this.statusCodeClass = Math.floor((statusCode || 0) / 100) || 0;
    }
    get success(): boolean {
        return this.statusCodeClass === 2;
    }
    get redirect(): boolean {
        return this.statusCodeClass === 3;
    }
    get clientError(): boolean {
        return this.statusCodeClass === 4;
    }
    get serverError(): boolean {
        return this.statusCodeClass === 5;
    }
    get error(): boolean {
        return this.clientError || this.serverError;
    }
}

export class StatusCode {
    statusCode: number | null;

    constructor(statusCode: number | null) {
        this.statusCode = statusCode;
    }
    get cls(): StatusCodeClass {
        return new StatusCodeClass(this.statusCode);
    }
    get ok(): boolean {
        return this.statusCode === 200;
    }
    get created(): boolean {
        return this.statusCode === 201;
    }
    get accepted(): boolean {
        return this.statusCode === 202;
    }
    get nonAuthoritativeInfo(): boolean {
        return this.statusCode === 203;
    }
    get noContent(): boolean {
        return this.statusCode === 204;
    }
    get movedPermanently(): boolean {
        return this.statusCode === 301;
    }
    get found(): boolean {
        return this.statusCode === 302;
    }
    get badRequest(): boolean {
        return this.statusCode === 400;
    }
    get unauthorized(): boolean {
        return this.statusCode === 401;
    }
    get forbidden(): boolean {
        return this.statusCode === 403;
    }
    get notFound(): boolean {
        return this.statusCode === 404;
    }
    get methodNotAllowed(): boolean {
        return this.statusCode === 405;
    }
    get timeout(): boolean {
        return this.statusCode === 408;
    }
    get noResponse(): boolean {
        return this.statusCode === 444;
    }

    get tooManyRequests(): boolean {
        return this.statusCode === 429;
    }

    get internalServerError(): boolean {
        return this.statusCode === 500;
    }
}

export interface Response {
    request: superagent.Request<any, any>;
    pending: boolean;
    statusCode: StatusCode;
    body: any;
}

export function makePendingResponse(
    req: superagent.Request<any, any>,
): Response {
    return {
        request: req,
        pending: true,
        statusCode: new StatusCode(null),
        body: undefined,
    };
}

export function makeTimeoutResponse(
    req: superagent.Request<any, any>,
): Response {
    return {
        request: req,
        pending: false,
        statusCode: new StatusCode(408),
        body: undefined,
    };
}

export class SuperagentResponse implements Response {
    private _res: superagent.Response<any>;
    request: superagent.Request<any, any>;
    constructor(
        req: superagent.Request<any, any>,
        res: superagent.Response<any>,
    ) {
        this.request = req;
        this._res = res;
    }
    get timeout(): boolean {
        return false;
    }
    get pending(): boolean {
        return false;
    }
    get statusCode(): StatusCode {
        if (!this._res) {
            return new StatusCode(444);
        }
        return new StatusCode(this._res.status);
    }
    get body(): any {
        if (!this._res) {
            return undefined;
        }
        return this._res.body || undefined;
    }
}
