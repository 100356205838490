import * as React from 'react';
import { ParkingaboVehicle } from '../../../../../common/models/Vehicle';
import {
    ServerRequestState,
    useServerSuccessEffect,
} from '../../../../../lib/hooks/ServerStateHooks';
import {
    AliasRequiredReason,
    ParkingaboUser,
} from '../../../shared/ParkingaboModels';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export interface StepConfig {
    steps: Array<{
        type: OnboardingStepType;
        completed?: boolean;
        initialValues: { [key: string]: string | number | boolean };
    }>;
}

export enum OnboardingStepType {
    USER_DATA = 'USER_DATA',
    VEHICLE = 'VEHICLE',
    PAYMENT = 'PAYMENT',
}

const emptyStepConfig: StepConfig = {
    steps: [
        {
            type: OnboardingStepType.USER_DATA,
            initialValues: {
                firstName: '',
                lastName: '',
                salutation: 'MR',
            },
        },
        {
            type: OnboardingStepType.VEHICLE,
            initialValues: {
                firstName: '',
                lastName: '',
                salutation: 'MR',
            },
        },
        {
            type: OnboardingStepType.PAYMENT,
            initialValues: {},
        },
    ],
};

const OnboardingContext = React.createContext<{
    config: StepConfig;
    setConfig: React.Dispatch<React.SetStateAction<StepConfig>>;
}>({
    config: emptyStepConfig,
    setConfig: () => {},
});

export function OnboardingProvider({
    children,
    user,
    vehicles,
}: {
    children: React.ReactNode | React.ReactNodeArray;
    user: ParkingaboUser;
    vehicles: ParkingaboVehicle[];
}) {
    const [config, setConfig] = React.useState(emptyStepConfig);

    useEffect(() => {
        if (user.firstName && user.lastName && user.gender) {
            let index = config.steps.findIndex(
                s => s.type === OnboardingStepType.USER_DATA,
            );
            if (index > -1 && !config.steps[index].completed) {
                setOnboardingStepCompletionStatus(setConfig, index, true);
            }
        }

        let paymentIndex = config.steps.findIndex(
            s => s.type === OnboardingStepType.PAYMENT,
        );
        if (paymentIndex > -1) {
            setOnboardingStepCompletionStatus(
                setConfig,
                paymentIndex,
                user.aliasRequiredReason !==
                    AliasRequiredReason.ONBOARDING_REQUIRED,
            );
        }

        if (vehicles.length > 0) {
            let index = config.steps.findIndex(
                s => s.type === OnboardingStepType.VEHICLE,
            );
            if (index > -1 && !config.steps[index].completed) {
                setOnboardingStepCompletionStatus(setConfig, index, true);
            }
        }
    }, [vehicles, user]);

    return (
        <OnboardingContext.Provider
            value={{
                config,
                setConfig,
            }}
        >
            {children}
        </OnboardingContext.Provider>
    );
}

export function useOnboarding() {
    return React.useContext(OnboardingContext);
}

export function useSetOnboardingStepAsCompleteOnSuccess<DT, ET>(
    state: ServerRequestState<DT, ET>,
    currentStep: number,
) {
    const { setConfig } = useOnboarding();

    useServerSuccessEffect(state, () => {
        setOnboardingStepCompletionStatus(setConfig, currentStep, true);
    });
}

export function useNavigateOnStepCompletion(stepToCheck: number, url: string) {
    const { config } = useOnboarding();
    const navigate = useNavigate();
    React.useEffect(() => {
        if (config.steps[stepToCheck].completed) {
            navigate(url);
        }
    }, [config.steps[stepToCheck].completed]);
}

function setOnboardingStepCompletionStatus(
    setOnboardingConfig: React.Dispatch<React.SetStateAction<StepConfig>>,
    currentStep: number,
    completionStatus: boolean,
) {
    setOnboardingConfig(c => ({
        ...c,
        steps: [
            ...c.steps.slice(0, currentStep),
            {
                ...c.steps[currentStep],
                completed: completionStatus,
            },
            ...c.steps.slice(currentStep + 1),
        ],
    }));
}
