import { Link, Typography, useTheme } from '@mui/material';
import * as React from 'react';
import { Localized } from '../../../../../common/hooks/LanguageProvider';
import {
    RequestStatus,
    useNavigateOnSuccess,
    useServerSuccessEffect,
} from '../../../../../lib/hooks/ServerStateHooks';
import {
    useParkingaboServerFetch,
    useParkingaboServerWrite,
} from '../../../api/ParkingaboApi';
import { BlockingLoadingModal } from '../../../components/BlockingLoadingModal';
import { FeedbackPopup } from '../../../components/FeedbackPopup';
import {
    AuthedRouteCompProps,
    useParkingaboAuthedPathGeneration,
} from '../../RouteUtils';
import { useNavigate } from 'react-router-dom';
import { PaymentMethodType } from '../../../components/forms/PaymentMethodForm';
import { ParkingaboUser } from '../../../shared/ParkingaboModels';
import { getTwintCheckingPath } from './TwintPairingRoute';
import { useEffect, useState } from 'react';

export enum AliasState {
    CREATED = 'CREATED',
    REQUESTED = 'REQUESTED',
    PAIRED = 'PAIRED',
    ABORTED = 'ABORTED',
    SUCCESS = 'SUCCESS',
}

interface AliasStatus {
    state: AliasState;
}

function usePolling(
    token: string,
    user: ParkingaboUser,
    paymentMethodType: PaymentMethodType,
) {
    const generateAuthedParkingaboPath = useParkingaboAuthedPathGeneration();
    const [timeoutId, setTimeoutId] = React.useState<number | null>(null);
    const [canCancelTimeoutId, setCanCancelTimeoutId] = React.useState<
        number | null
    >(null);
    const [canCancel, setCanCancel] = React.useState(false);
    const baseUri = `${window.location.protocol}//${
        window.location.host
    }${generateAuthedParkingaboPath(getTwintCheckingPath(paymentMethodType))}`;

    React.useEffect(() => {
        const id = window.setTimeout(() => {
            // after 30 seconds from the first attempt the payment can be canceled
            setCanCancel(true);
        }, 5000);

        setCanCancelTimeoutId(id);
    }, []);

    const [state, refetchState] = useParkingaboServerFetch<
        AliasStatus,
        { twintPairingToken: string }
    >(
        ({ twintPairingToken }) => ({
            url: `/ui-api/parkingabo/user/self/payment/twint-alias/${twintPairingToken}/state?baseUri=${encodeURIComponent(
                baseUri,
            )}`,
        }),
        token ? { twintPairingToken: token } : null,
    );
    const [paymentCancelState, cancel] = useParkingaboServerWrite<
        { twintPairingToken: string },
        { success: boolean }
    >(({ twintPairingToken }) => ({
        url: `/ui-api/parkingabo/user/self/payment/twint-alias/${twintPairingToken}/abort`,
    }));

    useServerSuccessEffect(state, res => {
        if (
            res.state === AliasState.REQUESTED ||
            res.state === AliasState.CREATED
        ) {
            const id = window.setTimeout(refetchState, 1000);

            setTimeoutId(id);
        }
    });

    function cancelPayment() {
        if (canCancelTimeoutId) {
            window.clearTimeout(canCancelTimeoutId);
        }
        if (timeoutId) {
            window.clearTimeout(timeoutId);
        }
        cancel({ twintPairingToken: token });
    }

    return {
        paymentCancelState,
        canCancel,
        cancelPayment,
        state,
    };
}

export function TwintPairingCheckRoute({
    paymentMethodType,
    userStatus,
    user,
    refetchUser,
}: {
    userStatus: RequestStatus;
    paymentMethodType: PaymentMethodType;
} & AuthedRouteCompProps) {
    const navigate = useNavigate();
    const generateAuthedParkingaboPath = useParkingaboAuthedPathGeneration();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('twintToken');
    const theme = useTheme();
    const [refetchLoading, setRefetchLoading] = useState(false);

    const { state, canCancel, cancelPayment, paymentCancelState } = usePolling(
        token!,
        user,
        paymentMethodType,
    );

    useServerSuccessEffect(paymentCancelState, refetchUser);
    useNavigateOnSuccess(
        paymentCancelState,
        generateAuthedParkingaboPath('twint-checking/onboarding-twint'),
    );

    const successBackUrl =
        paymentMethodType === PaymentMethodType.ONBOARDING
            ? generateAuthedParkingaboPath('onboarding/success')
            : generateAuthedParkingaboPath('settings/payment/success');
    const errorBackUrl = ((): string => {
        switch (paymentMethodType) {
            case PaymentMethodType.REGISTER:
                return generateAuthedParkingaboPath('payment/register');
            case PaymentMethodType.UPDATE:
                return generateAuthedParkingaboPath('payment/update');
            case PaymentMethodType.ONBOARDING:
                return generateAuthedParkingaboPath('onboarding/payment');
        }
    })();

    useServerSuccessEffect(state, data => {
        if (data.state === AliasState.SUCCESS) {
            setRefetchLoading(true);
            refetchUser();
        }
    });
    useEffect(() => {
        if (refetchLoading && userStatus === RequestStatus.SUCCESS) {
            navigate(successBackUrl);
        }
    }, [userStatus]);

    if (!token) {
        return null;
    }

    switch (state.data?.state) {
        case undefined: // still pending
        case AliasState.CREATED:
        case AliasState.REQUESTED:
        case AliasState.PAIRED:
        case AliasState.SUCCESS:
            return (
                <BlockingLoadingModal open={true}>
                    <FeedbackPopup
                        open={true}
                        color="warning"
                        title={
                            <Localized
                                de="Verarbeitung läuft..."
                                fr="Traitement en cours..."
                                it="Elaborazione in corso..."
                                en="Processing..."
                            />
                        }
                        abortLabel={
                            <Localized
                                de="Abbrechen"
                                fr="Annuler"
                                it="Annulla"
                                en="Cancel"
                            />
                        }
                        onAbort={canCancel ? () => cancelPayment() : undefined}
                    >
                        <Localized
                            de="Bitte warten Sie, bis die Transaktion verarbeitet ist."
                            fr="Veuillez attendre que la transaction soit traitée."
                            it="Per favore attenda che la transazione venga elaborata."
                            en="Please wait until the transaction is processed."
                        />
                    </FeedbackPopup>
                    <FeedbackPopup
                        open={
                            paymentCancelState.status === RequestStatus.ERROR ||
                            (paymentCancelState.status ===
                                RequestStatus.SUCCESS &&
                                !paymentCancelState.data.success)
                        }
                        color="error"
                        title={
                            <Localized
                                de="Fehler"
                                fr="Erreur"
                                it="Errore"
                                en="Error"
                            />
                        }
                    >
                        <Typography style={{ marginBottom: theme.spacing(2) }}>
                            <Localized
                                de="Es ist ein Fehler beim Abbrechen aufgetreten. Bitte kontaktieren Sie den Support."
                                fr="Il y a eu une erreur avec la demande d'annulation, veuillez contacter le support."
                                it="C'è stato un errore con la richiesta di annullamento, la preghiamo di contattare il supporto."
                                en="There was an error with the cancel request, please contact support."
                            />
                        </Typography>
                        <Link href="">
                            <Localized
                                de="Erneut versuchen"
                                fr="Réessayer"
                                it="Riprova"
                                en="Retry"
                            />
                        </Link>
                    </FeedbackPopup>
                </BlockingLoadingModal>
            );
        case AliasState.ABORTED:
            return (
                <FeedbackPopup
                    open={true}
                    color="error"
                    onAbort={() => {
                        navigate(errorBackUrl);
                    }}
                    abortLabel={'OK'}
                    title={
                        <Localized
                            de="Fehler"
                            fr="Erreur"
                            it="Errore"
                            en="Error"
                        />
                    }
                >
                    <Localized
                        de="Ihre TWINT App konnte nicht hinzugefügt werden."
                        fr="Votre app TWINT n'a pas pu être ajouté."
                        it="Non è stato possibile aggiungere la sua app TWINT."
                        en="Your TWINT app could not be added."
                    />
                </FeedbackPopup>
            );
    }
}
