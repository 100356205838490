import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import {
    Localized,
    useLanguage,
} from '../../../../../common/hooks/LanguageProvider';
import { Box, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import {
    formatZoneName,
    ParkingaboProductTemplateSubscription,
} from './ParkingaboProductTemplateModels';
import {
    WizardBody,
    WizardBottomBar,
    WizardFadeInEffect,
    WizardProductHeader,
    WizardStepInfo,
    WizardStepper,
} from './WizardComponents';
import { useNavigate } from 'react-router-dom';
import { useParkingaboServerWrite } from '../../../api/ParkingaboApi';
import {
    RequestStatus,
    useServerSuccessEffect,
} from '../../../../../lib/hooks/ServerStateHooks';
import { BackendRequestErrorMessage } from '../../../components/error/BackendRequestErrorMessage';
import { Formatter } from '../../../../../lib/Date';
import { SelectableList } from '../../../components/SelectableList';
import { currencyCentsToLocalPrice } from '../../../../../lib/NumberFormatter';
import { FeedbackPopup } from '../../../components/FeedbackPopup';
import { ParkingaboOverlineList } from '../../../components/layout/ParkingaboOverlineList';
import { ParkingaboZoneInfo } from '../../../shared/ParkingaboProductModels';

const enum WizardStep {
    START_DATE,
    OVERVIEW,
}

interface WizardSelection {
    startDate: DateTime | null;
}

export function ProductsConfigurationSubscription({
    product,
    zones,
    noProductChange,
    refetchProducts,
}: {
    product: ParkingaboProductTemplateSubscription;
    zones: ParkingaboZoneInfo[];
    noProductChange: boolean;
    refetchProducts: () => void;
}) {
    const [selection, setSelection] = useState<WizardSelection>({
        startDate: null,
    });

    const [
        purchaseState,
        purchaseProduct,
        resetPurchaseState,
    ] = useParkingaboServerWrite<
        {
            productTemplateId: string;
            startDate: string;
            monthlyRappenAmount: number;
        },
        never
    >(() => ({
        url: `/ui-api/parkingabo/user/self/product/purchase/cardcenter-abo-month`,
    }));

    useServerSuccessEffect(purchaseState, refetchProducts);

    const navigate = useNavigate();

    useEffect(() => {
        if (purchaseState.status === RequestStatus.ERROR) {
            resetPurchaseState();
        }
    }, [selection]);

    function handlePurchase() {
        if (!selection.startDate || activeStep !== WizardStep.OVERVIEW) {
            throw new Error(
                'Tried to purchase product without completed selection',
            );
        }
        purchaseProduct({
            productTemplateId: product.productTemplateId,
            startDate: selection.startDate.toISODate(),
            monthlyRappenAmount:
                product.intervalConfig.intervalSubscriptionAmountRappen,
        });
    }

    const steps: WizardStepInfo<WizardStep>[] = [
        {
            step: WizardStep.START_DATE,
            label: <Localized de="Datum" fr="Date" it="Data" en="Date" />,
        },
        {
            step: WizardStep.OVERVIEW,
            label: (
                <Localized
                    de="Übersicht"
                    fr="Aperçu"
                    it="Panoramica"
                    en="Overview"
                />
            ),
        },
    ];

    const firstStep = steps[0].step;
    const [activeStep, setActiveStep] = React.useState<WizardStep>(firstStep);

    const nextStepHasClearance = stepHasClearance(activeStep + 1, selection);

    return (
        <>
            <BackendRequestErrorMessage requestState={purchaseState} />
            <PurchaseSuccessDialog
                open={purchaseState.status === RequestStatus.SUCCESS}
                onClose={() => navigate('...')}
            />
            <WizardProductHeader>
                <Localized {...product.name} />
            </WizardProductHeader>
            <WizardStepper
                steps={steps}
                activeStep={activeStep}
                onStepClick={step => {
                    if (stepHasClearance(step, selection)) {
                        setActiveStep(step);
                    }
                }}
                offset={noProductChange ? 0 : 2}
            />
            <WizardBody>
                {activeStep === WizardStep.START_DATE && (
                    <StartDateSelection
                        product={product}
                        selectedStartDate={selection.startDate}
                        onDateChange={date => {
                            setSelection({ startDate: date });
                            if (date) {
                                setActiveStep(activeStep + 1);
                            }
                        }}
                    />
                )}
                {activeStep === WizardStep.OVERVIEW && (
                    <ProductOverview
                        product={product}
                        zones={zones}
                        startDate={selection.startDate!!}
                    />
                )}
            </WizardBody>
            <WizardBottomBar
                onNextClick={
                    nextStepHasClearance
                        ? () => {
                              if (activeStep === WizardStep.OVERVIEW) {
                                  handlePurchase();
                              } else {
                                  setActiveStep(activeStep + 1);
                              }
                          }
                        : undefined
                }
                nextLabel={
                    activeStep === WizardStep.OVERVIEW ? (
                        <Localized
                            de="Abonnieren"
                            fr="S'abonner"
                            it="Sottoscrivi"
                            en="Subscribe"
                        />
                    ) : undefined
                }
                nextAsSubmitRequestState={
                    activeStep === WizardStep.OVERVIEW
                        ? purchaseState.status
                        : undefined
                }
                onPreviousClick={() => {
                    if (activeStep === firstStep) {
                        navigate('..');
                    } else {
                        setActiveStep(activeStep - 1);
                    }
                }}
                previousLabel={
                    activeStep === firstStep ? (
                        <Localized
                            de="Produkt ändern"
                            fr="Changer de produit"
                            it="Cambia prodotto"
                            en="Change product"
                        />
                    ) : undefined
                }
                hidePrevious={noProductChange && activeStep === firstStep}
            />
        </>
    );
}

function stepHasClearance(
    step: WizardStep,
    selection: WizardSelection,
): boolean {
    switch (step) {
        case WizardStep.START_DATE:
            return true;
        default:
            return !!selection.startDate;
    }
}

function StartDateSelection({
    product,
    selectedStartDate,
    onDateChange,
}: {
    product: ParkingaboProductTemplateSubscription;
    selectedStartDate: DateTime | null;
    onDateChange: (date: DateTime | null) => void;
}) {
    const { language } = useLanguage();
    const selectionItems = useMemo(() => {
        let startDateOptions: DateTime[] = [];
        const now = DateTime.now();

        for (
            let i = 1;
            i <=
            product.intervalConfig
                .howManyIntervalsYouCanStartSubscriptionInTheFuture;
            i++
        ) {
            startDateOptions.push(now.startOf('month').plus({ month: i }));
        }

        return (
            startDateOptions.map(date => ({
                id: date.toISO(),
                content: (
                    <Typography fontWeight="bold">
                        {Formatter.monthNameYear(
                            date,
                            Formatter.getLocaleFromString(language),
                        )}
                    </Typography>
                ),
                date: date,
            })) ?? []
        );
    }, [product]);

    function handleDurationChange(selectedIds: string[]) {
        const date =
            selectionItems.find(item => item.id === selectedIds[0])?.date ??
            null;
        onDateChange(date);
    }

    return (
        <WizardFadeInEffect>
            <SelectableList
                selectionItems={selectionItems}
                initialSelection={
                    selectedStartDate ? [selectedStartDate.toISO()] : []
                }
                onChange={handleDurationChange}
                preventDeselect
            />
        </WizardFadeInEffect>
    );
}

function ProductOverview({
    product,
    zones,
    startDate,
}: {
    product: ParkingaboProductTemplateSubscription;
    zones: ParkingaboZoneInfo[];
    startDate: DateTime;
}) {
    const productZones = zones.filter(zone =>
        product.zoneIds.includes(zone.zoneId),
    );

    return (
        <WizardFadeInEffect>
            <ParkingaboOverlineList.Body>
                <ParkingaboOverlineList.Item
                    label={
                        productZones.length > 1 ? (
                            <Localized
                                de="Parkings"
                                fr="Parkings"
                                it="Parcheggi"
                                en="Parkings"
                            />
                        ) : (
                            <Localized
                                de="Parking"
                                fr="Parking"
                                it="Parcheggio"
                                en="Parking"
                            />
                        )
                    }
                >
                    {productZones.map(zone => (
                        <Box
                            sx={{
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                            }}
                            key={zone.zoneId}
                        >
                            {formatZoneName(zone)}
                        </Box>
                    ))}
                </ParkingaboOverlineList.Item>
                <ParkingaboOverlineList.Item
                    label={
                        <Localized
                            de="Startdatum"
                            fr="Date de début"
                            it="Data di inizio"
                            en="Start date"
                        />
                    }
                >
                    {Formatter.dayMonthYear(startDate)}
                </ParkingaboOverlineList.Item>
                <ParkingaboOverlineList.Item
                    label={
                        <Localized de="Info" fr="TODO" it="TODO" en="Info" />
                    }
                >
                    <Localized
                        de="bis auf Widerruf"
                        fr="jusqu'à révocation"
                        it="fino a revoca"
                        en="until revocation"
                    />
                </ParkingaboOverlineList.Item>
                <ParkingaboOverlineList.Item
                    label={
                        <Localized
                            de="Preis"
                            fr="Prix"
                            it="Prezzo"
                            en="Price"
                        />
                    }
                >
                    <Typography fontSize={'1.5rem'} fontWeight="bold">
                        {currencyCentsToLocalPrice(
                            'de',
                            product.intervalConfig
                                .intervalSubscriptionAmountRappen,
                        )}
                        <Localized
                            de=" / Monat"
                            fr=" / mois"
                            it=" / mese"
                            en=" / month"
                        />
                    </Typography>
                </ParkingaboOverlineList.Item>
            </ParkingaboOverlineList.Body>
        </WizardFadeInEffect>
    );
}

function PurchaseSuccessDialog({
    open,
    onClose,
}: {
    open: boolean;
    onClose: () => void;
}) {
    return (
        <FeedbackPopup
            open={open}
            color="success"
            title={
                <Localized
                    de="Bestätigung"
                    fr="Confirmation"
                    it="Conferma"
                    en="Confirmation"
                />
            }
            abortLabel={'OK'}
            onAbort={onClose}
        >
            <Localized
                de="Das Produkt wurde erfolgreich abonniert."
                fr="Le produit a été souscrit avec succès."
                it="Il prodotto è stato sottoscritto con successo."
                en="The product was successfully subscribed."
            />
        </FeedbackPopup>
    );
}
