import * as React from 'react';
import { generatePath, useParams } from 'react-router';
import {
    LoadingSpinnerPresets,
    PresetLoadingSpinner,
} from '../../../../common/components/material-ui/PresetLoadingSpinner';
import {
    useNavigateOnSuccess,
    useNavigateOnError,
} from '../../../../lib/hooks/ServerStateHooks';
import { useParkingaboServerWrite } from '../../api/ParkingaboApi';

export function EmailVerificationRoute() {
    const { token } = useParams<{ token: string }>();

    const [submitState, submit] = useParkingaboServerWrite<
        { token: string },
        never
    >(() => ({
        url: '/ui-api/parkingabo/user/confirm',
    }));

    React.useEffect(() => {
        if (token) {
            submit({ token });
        }
    }, [token]);

    useNavigateOnSuccess(submitState, '/login');
    useNavigateOnError(
        submitState,
        generatePath('/link-expired/:token', {
            token,
        }),
    );

    return (
        <PresetLoadingSpinner
            preset={LoadingSpinnerPresets.FillAllSpaceAndCenter}
        />
    );
}
