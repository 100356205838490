import { CircularProgress, useTheme } from '@mui/material';
import * as React from 'react';
import { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { isPWA } from '../../../lib/utils/PwaUtils';
import {
    useParkingaboServerFetch,
    useParkingaboServerWrite,
    useUpdateParkingaboCsrfToken,
} from '../api/ParkingaboApi';
import {
    REFRESH_TOKEN_LOCALSTORAGE_KEY,
    SessionValidity,
    useCheckAndRefreshSession,
    useSession,
} from '../api/ParkingaboLoginApi';
import {
    requestDone,
    RequestStatus,
    useServerErrorEffect,
    useServerSuccessEffect,
} from '../../../lib/hooks/ServerStateHooks';
import { OnboardingProvider } from './authorized/onboarding/OnboardingConfig';
import { ParkingaboUser } from '../shared/ParkingaboModels';
import { ParkingaboVehicle } from '../../../common/models/Vehicle';
import { ParkingaboAsyncLoadedSection } from '../components/layout/ParkingaboAsyncLoadedSection';
import { VehiclesRoute } from './authorized/vehicles/VehiclesRoute';
import { VehiclesDetailRoute } from './authorized/vehicles/VehiclesDetailRoute';
import { AddVehicleRoute } from './authorized/vehicles/AddVehicleRoute';
import { ProductsAddOutlet } from './authorized/products/ProductsAddOutlet';
import { ProductsConfigurationOutlet } from './authorized/products/ProductsConfigurationOutlet';
import { ProductsSelectionOutlet } from './authorized/products/ProductsSelectionOutlet';
import { ProductsRoute } from './authorized/products/ProductsRoute';
import { ProductsDetailOutlet } from './authorized/products/ProductsDetailOutlet';
import { ParkingaboProductListItem } from '../shared/ParkingaboProductModels';
import { AddPaymentMethodRoute } from './authorized/payment/AddPaymentMethodRoute';
import { PaymentRegisterInitialRoute } from './authorized/payment/PaymentRegisterInitialRoute';
import { PaymentMethodType } from '../components/forms/PaymentMethodForm';
import { PaymentUpdateInitialRoute } from './authorized/payment/PaymentUpdateInitialRoute';
import { PaymentAbortedRoute } from './authorized/payment/PaymentAbortedRoute';
import { TwintPairingRoute } from './authorized/payment/TwintPairingRoute';
import { TwintPairingCheckRoute } from './authorized/payment/TwintPairingCheckRoute';
import { PaymentMethodCheckRoute } from './authorized/payment/PaymentMethodCheckRoute';
import { OnboardingPaymentMethodRoute } from './authorized/onboarding/OnboardingPaymentMethodRoute';
import { OnboardingUserDataRoute } from './authorized/onboarding/OnboardingUserDataRoute';
import { OnboardingVehicleRoute } from './authorized/onboarding/OnboardingVehicleRoute';
import { OnboardingSuccessRoute } from './authorized/onboarding/OnboardingSuccessRoute';
import { HomeRoute } from './authorized/HomeRoute';
import { TenantSelectionRoute } from './authorized/TenantSelectionRoute';
import { TenantChecker } from '../components/TenantChecker';
import { PaymentChecker } from '../components/PaymentChecker';
import { RedirectRequiredChecker } from '../components/RedirectRequiredChecker';
import { SettingsRoute } from './authorized/settings/SettingsRoute';
import { PaymentSettingsRoute } from './authorized/settings/PaymentSettingsRoute';
import { EmailResentConfirmationRoute } from './authorized/settings/EmailResentConfirmationRoute';
import { AddPaymentSuccessRoute } from './authorized/settings/AddPaymentSuccessRoute';
import { EditLanguageRoute } from './authorized/settings/EditLanguageRoute';
import { EditNameRoute } from './authorized/settings/EditNameRoute';
import { EditEmailRoute } from './authorized/settings/EditEmailRoute';
import { EditPasswordRoute } from './authorized/settings/EditPasswordRoute';
import { EditProfileRoute } from './authorized/settings/EditProfileRoute';
import { LoginRoute } from './public/LoginRoute';
import { ParkingaboRegistrationRoute } from './public/ParkingaboRegistrationRoute';
import { RegistrationConfirmationRoute } from './public/RegistrationConfirmationRoute';
import { RegistrationLinkExpiredRoute } from './public/RegistrationLinkExpiredRoute';
import { RequestPasswordResetRoute } from './public/RequestPasswordResetRoute';
import { PasswordRequestSentSuccessRoute } from './public/PasswordRequestSentSuccessRoute';
import { ResetPasswordRoute } from './public/ResetPasswordRoute';
import { ResetPasswordSuccessRoute } from './public/ResetPasswordSuccessRoute';
import { ResetPasswordTokenErrorRoute } from './public/ResetPasswordTokenErrorRoute';
import { EmailVerificationRoute } from './public/EmailVerificationRoute';
import {
    AccountStatementItemDetail,
    AccountStatementOutlet,
} from './authorized/account-statement/AccountStatementOutlet';

export interface ParkingaboLogin {
    loggedIn: boolean;
    logout: () => void;
    setLoggedIn: (value: boolean) => void;
    subdomain: string | null;
    appNotReadyToBeShown: boolean;
}

export function useLogin(): ParkingaboLogin {
    const setGlobalCsrfToken = useUpdateParkingaboCsrfToken();
    const [loggedIn, setLoggedIn] = React.useState(false);
    const [appReadyToBeShown, setAppReadyToBeShown] = React.useState(false);
    const [
        hasAttemptedLoginWithRefreshToken,
        setHasAttemptedLoginWithRefreshToken,
    ] = React.useState(false);
    const [session, setSession] = React.useState<SessionValidity>();
    const [checkSessionState] = useCheckAndRefreshSession();
    useServerSuccessEffect(checkSessionState, setSession);
    const [sessionState, getSession] = useSession();
    useServerSuccessEffect(sessionState, setSession);

    const refreshToken = localStorage.getItem(REFRESH_TOKEN_LOCALSTORAGE_KEY);

    function loginWithRefreshToken(nonNullableRefreshToken: string) {
        setHasAttemptedLoginWithRefreshToken(true);
        getSession({ refreshToken: nonNullableRefreshToken });
    }

    const [logoutState, logout] = useParkingaboServerWrite(() => ({
        url: '/ui-api/parkingabo/session/logout',
    }));

    useServerSuccessEffect(logoutState, () => setLoggedIn(false));

    React.useEffect(() => {
        if (!loggedIn && appReadyToBeShown) {
            getSession();
        }
    }, [loggedIn]);

    React.useEffect(() => {
        if (!session || (appReadyToBeShown && loggedIn)) {
            return;
        }
        if (session.loginId && session.refreshToken) {
            localStorage.setItem(
                REFRESH_TOKEN_LOCALSTORAGE_KEY,
                session.refreshToken,
            );
        } else if (hasAttemptedLoginWithRefreshToken && !session.loginId) {
            // login didn't work => token is invalid
            localStorage.removeItem(REFRESH_TOKEN_LOCALSTORAGE_KEY);
        }

        setLoggedIn(!!session.loginId);
        setGlobalCsrfToken(session.csrfToken);
        setAppReadyToBeShown(true);
    }, [session]);

    useServerSuccessEffect(checkSessionState, data => {
        if (!data.loginId) {
            if (refreshToken && isPWA()) {
                // could autologin
                loginWithRefreshToken(refreshToken);
            } else {
                // cannot auto-relogin
                setLoggedIn(false);
                setGlobalCsrfToken(data.csrfToken);
                setAppReadyToBeShown(true);
            }
        }
    });

    useServerErrorEffect(checkSessionState, statusCode => {
        if (statusCode === 401) {
            if (isPWA() && refreshToken) {
                loginWithRefreshToken(refreshToken);
            } else {
                getSession();
            }
        }
    });

    const anyPending = [checkSessionState.status, sessionState.status].some(
        status => status === RequestStatus.PENDING,
    );

    return {
        loggedIn,
        logout: () => logout(), // make sure nothing weird is passed in
        setLoggedIn,
        subdomain: session?.subdomain || null,
        appNotReadyToBeShown:
            !requestDone(checkSessionState.status) ||
            anyPending ||
            !appReadyToBeShown,
    };
}

export function AppRoutes({ login }: { login: ParkingaboLogin }) {
    const theme = useTheme();

    if (login.appNotReadyToBeShown) {
        return (
            <div
                style={{
                    backgroundColor: theme.palette.grey[400],
                    height: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <CircularProgress />
            </div>
        );
    }

    if (!login.loggedIn) {
        return <PublicRoutes login={login} />;
    }

    return <AuthedRoutes login={login} logout={login.logout} />;
}

export function NoMatch({ loggedIn }: { loggedIn: boolean }) {
    const navigate = useNavigate();
    useEffect(() => {
        if (!loggedIn) {
            navigate(`/login`, { replace: true });
        } else {
            navigate(`/`, { replace: true });
        }
    }, [loggedIn]);

    return null;
}

export function PublicRoutes({ login }: { login: ParkingaboLogin }) {
    return (
        <Routes>
            <Route path={'/login'} element={<LoginRoute login={login} />} />
            <Route
                path={'/register/*'}
                element={<ParkingaboRegistrationRoute />}
            >
                <Route
                    path={'email-sent'}
                    element={<RegistrationConfirmationRoute />}
                />
                <Route
                    path={'link-expired/:token'}
                    element={<RegistrationLinkExpiredRoute />}
                />
            </Route>
            <Route
                path={'/request-password-reset/*'}
                element={<RequestPasswordResetRoute />}
            >
                <Route
                    path={'success'}
                    element={<PasswordRequestSentSuccessRoute />}
                />
            </Route>
            <Route
                path={'/reset-password/:token/*'}
                element={<ResetPasswordRoute />}
            >
                <Route
                    path={'error'}
                    element={<ResetPasswordTokenErrorRoute />}
                />
                <Route
                    path={'success'}
                    element={<ResetPasswordSuccessRoute />}
                />
            </Route>
            <Route
                path={'/verify/:token'}
                element={<EmailVerificationRoute />}
            />
            {/*}
            <Routes>
                {publicRoutesConfig.map(route => (
                    <Route
                        path={route.path}
                        element={
                            <route.Component
                                login={isLoginRoute(route.path) ? login : undefined}
                            />
                        }
                    />
                ))}*/}
            <Route path="/*" element={<NoMatch loggedIn={login.loggedIn} />} />
        </Routes>
    );
}

export function AuthedRoutes({
    login,
    logout,
}: {
    login: ParkingaboLogin;
    logout: () => void;
}) {
    const [userState, refetchUser] = useParkingaboServerFetch<
        ParkingaboUser,
        {},
        null
    >(
        () => ({
            url: `/ui-api/parkingabo/user/self`,
        }),
        {},
    );
    const [productState, refetchProducts] = useParkingaboServerFetch<
        ParkingaboProductListItem[],
        {},
        null
    >(
        () => ({
            url: `/ui-api/parkingabo/user/self/product`,
        }),
        {},
    );
    const [vehiclesState, refetchVehicles] = useParkingaboServerFetch<
        ParkingaboVehicle[],
        {},
        null
    >(
        () => ({
            url: `/ui-api/parkingabo/user/self/vehicles`,
        }),
        {},
    );

    return (
        <ParkingaboAsyncLoadedSection
            state={userState}
            render={user => (
                <ParkingaboAsyncLoadedSection
                    state={productState}
                    render={products => (
                        <ParkingaboAsyncLoadedSection
                            state={vehiclesState}
                            render={vehiclesData => {
                                const vehiclesStatus = vehiclesState.status;
                                const vehicles = {
                                    data: vehiclesData,
                                    status: vehiclesStatus,
                                    refetch: refetchVehicles,
                                };
                                const baseProps = {
                                    logout,
                                    user,
                                    refetchUser,
                                    products,
                                    refetchProducts,
                                    vehicles,
                                };

                                return (
                                    <OnboardingProvider
                                        user={user}
                                        vehicles={vehiclesData}
                                    >
                                        <Routes>
                                            <Route
                                                path={''}
                                                element={
                                                    <TenantSelectionRoute
                                                        {...baseProps}
                                                    />
                                                }
                                            />
                                            <Route
                                                element={
                                                    <TenantChecker
                                                        {...baseProps}
                                                    />
                                                }
                                            >
                                                <Route
                                                    element={
                                                        <RedirectRequiredChecker
                                                            {...baseProps}
                                                        />
                                                    }
                                                >
                                                    <Route
                                                        path={':tenantId/'}
                                                        element={
                                                            <HomeRoute
                                                                {...baseProps}
                                                            />
                                                        }
                                                    >
                                                        <Route
                                                            path={
                                                                'onboarding/success'
                                                            }
                                                            element={
                                                                <OnboardingSuccessRoute
                                                                    {...baseProps}
                                                                />
                                                            }
                                                        />
                                                    </Route>
                                                </Route>
                                                <Route path={':tenantId/*'}>
                                                    <Route
                                                        path={'onboarding/*'}
                                                    >
                                                        <Route
                                                            path={'vehicle'}
                                                            element={
                                                                <OnboardingVehicleRoute
                                                                    {...baseProps}
                                                                />
                                                            }
                                                        />
                                                        <Route
                                                            path={'user-data'}
                                                            element={
                                                                <OnboardingUserDataRoute
                                                                    {...baseProps}
                                                                />
                                                            }
                                                        />

                                                        <Route
                                                            element={
                                                                <PaymentChecker
                                                                    paymentMethodType={
                                                                        PaymentMethodType.ONBOARDING
                                                                    }
                                                                    {...baseProps}
                                                                />
                                                            }
                                                        >
                                                            <Route
                                                                path={
                                                                    'payment/*'
                                                                }
                                                                element={
                                                                    <OnboardingPaymentMethodRoute
                                                                        {...baseProps}
                                                                    />
                                                                }
                                                            >
                                                                <Route
                                                                    path={
                                                                        'aborted'
                                                                    }
                                                                    element={
                                                                        <PaymentAbortedRoute />
                                                                    }
                                                                />
                                                            </Route>
                                                        </Route>
                                                    </Route>
                                                    <Route
                                                        path={
                                                            'update-payments/employee/:userId/payment/:transactionId'
                                                        }
                                                        element={
                                                            <PaymentMethodCheckRoute
                                                                paymentMethodType={
                                                                    PaymentMethodType.UPDATE
                                                                }
                                                                {...baseProps}
                                                            />
                                                        }
                                                    />
                                                    <Route
                                                        path={
                                                            'register-payments/employee/:userId/payment/:transactionId'
                                                        }
                                                        element={
                                                            <PaymentMethodCheckRoute
                                                                paymentMethodType={
                                                                    PaymentMethodType.REGISTER
                                                                }
                                                                {...baseProps}
                                                            />
                                                        }
                                                    />
                                                    <Route
                                                        path={
                                                            'onboarding-payments/employee/:userId/payment/:transactionId'
                                                        }
                                                        element={
                                                            <PaymentMethodCheckRoute
                                                                paymentMethodType={
                                                                    PaymentMethodType.ONBOARDING
                                                                }
                                                                {...baseProps}
                                                            />
                                                        }
                                                    />
                                                    <Route
                                                        path={
                                                            'update-twint/employee/:userId/pairing/:twintPairingToken'
                                                        }
                                                        element={
                                                            <TwintPairingRoute
                                                                paymentMethodType={
                                                                    PaymentMethodType.UPDATE
                                                                }
                                                                {...baseProps}
                                                            />
                                                        }
                                                    />
                                                    <Route
                                                        path={
                                                            'register-twint/employee/:userId/pairing/:twintPairingToken'
                                                        }
                                                        element={
                                                            <TwintPairingRoute
                                                                paymentMethodType={
                                                                    PaymentMethodType.REGISTER
                                                                }
                                                                {...baseProps}
                                                            />
                                                        }
                                                    />
                                                    <Route
                                                        path={
                                                            'onboarding-twint/employee/:userId/pairing/:twintPairingToken'
                                                        }
                                                        element={
                                                            <TwintPairingRoute
                                                                paymentMethodType={
                                                                    PaymentMethodType.ONBOARDING
                                                                }
                                                                {...baseProps}
                                                            />
                                                        }
                                                    />
                                                    <Route
                                                        path={
                                                            'twint-checking/*'
                                                        }
                                                    >
                                                        <Route
                                                            path={
                                                                'update-twint'
                                                            }
                                                            element={
                                                                <TwintPairingCheckRoute
                                                                    paymentMethodType={
                                                                        PaymentMethodType.UPDATE
                                                                    }
                                                                    userStatus={
                                                                        userState.status
                                                                    }
                                                                    {...baseProps}
                                                                />
                                                            }
                                                        />
                                                        <Route
                                                            path={
                                                                'register-twint'
                                                            }
                                                            element={
                                                                <TwintPairingCheckRoute
                                                                    paymentMethodType={
                                                                        PaymentMethodType.REGISTER
                                                                    }
                                                                    userStatus={
                                                                        userState.status
                                                                    }
                                                                    {...baseProps}
                                                                />
                                                            }
                                                        />
                                                        <Route
                                                            path={
                                                                'onboarding-twint'
                                                            }
                                                            element={
                                                                <TwintPairingCheckRoute
                                                                    paymentMethodType={
                                                                        PaymentMethodType.ONBOARDING
                                                                    }
                                                                    userStatus={
                                                                        userState.status
                                                                    }
                                                                    {...baseProps}
                                                                />
                                                            }
                                                        />
                                                    </Route>

                                                    <Route path={'payment/*'}>
                                                        <Route
                                                            element={
                                                                <PaymentChecker
                                                                    paymentMethodType={
                                                                        PaymentMethodType.REGISTER
                                                                    }
                                                                    {...baseProps}
                                                                />
                                                            }
                                                        >
                                                            <Route
                                                                path={
                                                                    'register/*'
                                                                }
                                                                element={
                                                                    <AddPaymentMethodRoute
                                                                        paymentMethodType={
                                                                            PaymentMethodType.REGISTER
                                                                        }
                                                                    />
                                                                }
                                                            >
                                                                <Route
                                                                    path={
                                                                        'initial'
                                                                    }
                                                                    element={
                                                                        <PaymentRegisterInitialRoute />
                                                                    }
                                                                />
                                                                <Route
                                                                    path={
                                                                        'aborted'
                                                                    }
                                                                    element={
                                                                        <PaymentAbortedRoute />
                                                                    }
                                                                />
                                                            </Route>
                                                        </Route>
                                                        <Route
                                                            element={
                                                                <PaymentChecker
                                                                    paymentMethodType={
                                                                        PaymentMethodType.UPDATE
                                                                    }
                                                                    {...baseProps}
                                                                />
                                                            }
                                                        >
                                                            <Route
                                                                path={
                                                                    'update/*'
                                                                }
                                                                element={
                                                                    <AddPaymentMethodRoute
                                                                        paymentMethodType={
                                                                            PaymentMethodType.UPDATE
                                                                        }
                                                                    />
                                                                }
                                                            >
                                                                <Route
                                                                    path={
                                                                        'initial'
                                                                    }
                                                                    element={
                                                                        <PaymentUpdateInitialRoute />
                                                                    }
                                                                />
                                                                <Route
                                                                    path={
                                                                        'aborted'
                                                                    }
                                                                    element={
                                                                        <PaymentAbortedRoute />
                                                                    }
                                                                />
                                                            </Route>
                                                        </Route>
                                                    </Route>
                                                    <Route
                                                        element={
                                                            <RedirectRequiredChecker
                                                                {...baseProps}
                                                            />
                                                        }
                                                    >
                                                        <Route
                                                            path={'vehicles/*'}
                                                            element={
                                                                <VehiclesRoute
                                                                    {...baseProps}
                                                                />
                                                            }
                                                        >
                                                            <Route
                                                                path={'add'}
                                                                element={
                                                                    <AddVehicleRoute
                                                                        {...baseProps}
                                                                    />
                                                                }
                                                            />
                                                            <Route
                                                                path={
                                                                    ':vehicleId'
                                                                }
                                                                element={
                                                                    <VehiclesDetailRoute
                                                                        {...baseProps}
                                                                    />
                                                                }
                                                            />
                                                        </Route>

                                                        <Route
                                                            path={'products/*'}
                                                            element={
                                                                <ProductsRoute
                                                                    {...baseProps}
                                                                />
                                                            }
                                                        >
                                                            <Route
                                                                path={
                                                                    ':contractId'
                                                                }
                                                                element={
                                                                    <ProductsDetailOutlet
                                                                        {...baseProps}
                                                                    />
                                                                }
                                                            />
                                                            <Route
                                                                path={'add'}
                                                                element={
                                                                    <ProductsAddOutlet
                                                                        {...baseProps}
                                                                    />
                                                                }
                                                            >
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <ProductsSelectionOutlet />
                                                                    }
                                                                />
                                                                <Route
                                                                    path={
                                                                        ':productTemplateId'
                                                                    }
                                                                    element={
                                                                        <ProductsConfigurationOutlet
                                                                            {...baseProps}
                                                                        />
                                                                    }
                                                                />
                                                            </Route>
                                                        </Route>

                                                        <Route
                                                            path={
                                                                'account-statement'
                                                            }
                                                            element={
                                                                <AccountStatementOutlet />
                                                            }
                                                        >
                                                            <Route
                                                                path={
                                                                    ':bkBookingId'
                                                                }
                                                                element={
                                                                    <AccountStatementItemDetail />
                                                                }
                                                            />
                                                        </Route>

                                                        <Route
                                                            path={'settings/*'}
                                                            element={
                                                                <SettingsRoute
                                                                    {...baseProps}
                                                                />
                                                            }
                                                        />
                                                        <Route
                                                            path={
                                                                'settings/payment/*'
                                                            }
                                                            element={
                                                                <PaymentSettingsRoute
                                                                    {...baseProps}
                                                                />
                                                            }
                                                        >
                                                            <Route
                                                                path={'success'}
                                                                element={
                                                                    <AddPaymentSuccessRoute />
                                                                }
                                                            />
                                                        </Route>
                                                        <Route
                                                            path={
                                                                'settings/user/*'
                                                            }
                                                            element={
                                                                <EditProfileRoute
                                                                    {...baseProps}
                                                                />
                                                            }
                                                        >
                                                            <Route
                                                                path={
                                                                    'email-link-resent'
                                                                }
                                                                element={
                                                                    <EmailResentConfirmationRoute />
                                                                }
                                                            />
                                                            <Route
                                                                path={
                                                                    'password'
                                                                }
                                                                element={
                                                                    <EditPasswordRoute
                                                                        {...baseProps}
                                                                    />
                                                                }
                                                            />
                                                            <Route
                                                                path={'email'}
                                                                element={
                                                                    <EditEmailRoute
                                                                        {...baseProps}
                                                                    />
                                                                }
                                                            />
                                                            <Route
                                                                path={
                                                                    'language'
                                                                }
                                                                element={
                                                                    <EditLanguageRoute
                                                                        {...baseProps}
                                                                    />
                                                                }
                                                            />
                                                            <Route
                                                                path={'name'}
                                                                element={
                                                                    <EditNameRoute
                                                                        {...baseProps}
                                                                    />
                                                                }
                                                            />
                                                        </Route>
                                                    </Route>
                                                </Route>
                                            </Route>
                                            <Route
                                                path="/"
                                                element={
                                                    <NoMatch
                                                        loggedIn={
                                                            login.loggedIn
                                                        }
                                                    />
                                                }
                                            />
                                        </Routes>
                                    </OnboardingProvider>
                                );
                            }}
                        />
                    )}
                />
            )}
        />
    );
}
