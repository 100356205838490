export namespace LocalStorageBase {
    // detect if local storage is disabled by iphone
    // https://github.com/Modernizr/Modernizr/commit/a93625c21694fcda6fc1514a48f54232f703194a
    //
    export function hasLocalStorage(): boolean {
        try {
            localStorage.setItem('test', 'test');
            localStorage.removeItem('test');
            return true;
        } catch (e) {
            return false;
        }
    }

    export function setStringItem(key: string, value: string): void {
        if (!LOCAL_STORAGE_ENABLED) {
            LOCAL_STORAGE_MOCK[key] = value;
        } else {
            try {
                localStorage.setItem(key, value);
            } catch (e) {}
        }
    }

    export function getStringItem(key: string): string | null {
        if (!LOCAL_STORAGE_ENABLED) {
            const value = LOCAL_STORAGE_MOCK[key];
            return value === undefined ? null : value;
        }
        try {
            return localStorage.getItem(key);
        } catch (e) {
            return null;
        }
    }

    export function setSerializableItem<T>(key: string, value: T): void {
        setStringItem(key, JSON.stringify(value));
    }

    export function getSerializableItem<T>(key: string): T | null {
        const strValue = getStringItem(key);

        try {
            return !strValue ? null : JSON.parse(strValue);
        } catch (e) {
            return null;
        }
    }

    export function removeItem(key: string): void {
        if (!LOCAL_STORAGE_ENABLED) {
            delete LOCAL_STORAGE_MOCK[key];
        } else {
            try {
                localStorage.removeItem(key);
            } catch (e) {}
        }
    }
}

const LOCAL_STORAGE_ENABLED = LocalStorageBase.hasLocalStorage();
const LOCAL_STORAGE_MOCK: any = {};
