import * as React from 'react';
import { css } from '@emotion/css';
import { Spinner } from '../../twint-app/src/ui/PendingOrErrorModal';
import { AppChooser } from './AppChooser';
import { Localized } from './Localized';
import { PairingPageDesktop } from './PairingPageDesktop';
import { PairingPageMobile } from './PairingPageMobile';
import { LocalStorageBase } from '../../lib/LocalStorageBase';
import getStringItem = LocalStorageBase.getStringItem;
import setStringItem = LocalStorageBase.setStringItem;
import superagent = require('superagent');
import {
    abortTwintTransaction,
    PairingState,
    PendingTwintPairing,
    TwintPairingState,
    TwintPendingState,
    useTwintState,
} from '../../common/utils/TwintPairing';

require('../css/style.styl');

export enum Platform {
    IOS = 'IOS',
    ANDROID = 'ANDROID',
    DESKTOP = 'DESKTOP',
}

function abortAndGoBack(
    abortRequest: superagent.Request<any, any>,
    url: string,
    token: string,
    mode: PendingTwintPairing.Mode,
) {
    abortTwintTransaction(abortRequest, () => goBack(url, token, mode));
}

function goBack(url: string, token: string, mode: PendingTwintPairing.Mode) {
    const isUof = mode === PendingTwintPairing.Mode.UOF;
    window.location.href = `${url}?twintToken=${token}&uof=${isUof}`;
}

const lastApp = 'LAST_APP';

export function PairingPage({
    token,
    platform,
    isUof,
    url,
    stateState,
    abortRequest,
    configsRequest,
}: {
    token: string;
    platform: Platform;
    isUof: boolean;
    url: string;
    stateState: TwintPairingState;
    abortRequest: superagent.Request<any, any>;
    configsRequest: superagent.Request<any, any>;
}) {
    const [scheme, setScheme] = React.useState(getStringItem(lastApp) || '');
    const mode = isUof
        ? PendingTwintPairing.Mode.UOF
        : PendingTwintPairing.Mode.PAYMENT;
    const pairingData = useTwintState(
        token,
        mode,
        null,
        (action, payload) => null,
        stateState,
        abortRequest,
    );

    React.useEffect(() => {
        if (pairingData.state === PairingState.SUCCESS) {
            goBack(url, token, mode);
        }
        if (pairingData.state === PairingState.ABORTED) {
            abortAndGoBack(abortRequest, url, token, mode);
        }
    }, [pairingData.state]);

    if (pairingData.state === PairingState.UNKNOWN) {
        return <Spinner visible={true} />;
    }

    const pairingToken = (pairingData.token || '') + '';

    const isIos = platform === Platform.IOS;

    return (
        <div className="twintPairingPage">
            <div className="section nav">
                <div className="wrap">
                    <div className="menu-item one-third column text-left">
                        <a
                            className="back-link"
                            href={url}
                            onClick={e => {
                                e.preventDefault();
                                switch (pairingData.state) {
                                    case PairingState.CONFIRMED_BY_USER:
                                    case PairingState.SUCCESS:
                                        goBack(url, pairingToken, mode);
                                        break;
                                    default:
                                        abortAndGoBack(
                                            abortRequest,
                                            url,
                                            pairingToken,
                                            mode,
                                        );
                                        break;
                                }
                            }}
                            target="_self"
                        >
                            &nbsp;&nbsp;
                            <Back pairingData={pairingData} />
                        </a>
                    </div>
                    <div className="menu-item one-third column text-center text-uppercase">
                        <a href="https://www.twint.ch" target="_blank">
                            <div className="twint-logo" />{' '}
                            <div className="icon-info" />
                        </a>
                    </div>
                </div>
            </div>
            <div className="nav-spacer" />
            {platform !== Platform.DESKTOP && (
                <div className="section warning">
                    <div className="icon-warning" />
                    <div className="warning-message">
                        <Localized
                            de="Kehren Sie bitte nach dem Bezahlen mit TWINT zurück zu Parkingpay, damit die Transaktion erfolgreich abgeschlossen werden kann."
                            fr="Veuillez retourner à Parkingpay après avoir payé avec TWINT, afin que la transazione puisse être finalisée."
                            it="Dopo aver pagato con TWINT ritorni a Parkingpay, in modo da concludere correttamente la transazione."
                            en="After paying with TWINT, please return to Parkingpay in order to complete the transaction successfully."
                        />
                    </div>
                </div>
            )}
            <div
                id="logo-container"
                className={[
                    'section',
                    'nav-mobile',
                    'hidden',
                    css({
                        display:
                            platform === Platform.ANDROID
                                ? 'block !important'
                                : 'none !important',
                    }),
                ].join(' ')}
            >
                <div className="info" />
                <div className="wrap">
                    <div className="menu-item one-third column text-left">
                        <a
                            className="logo"
                            href="https://www.twint.ch"
                            target="_blank"
                        >
                            <div className="twint-logo" />
                        </a>
                    </div>
                    <div className="menu-item one-third column text-center" />
                </div>
            </div>
            <Amount pairingData={pairingData} isIos={isIos} />
            <div className="section header-mobile hidden">
                <div className="row text-center">
                    <div className="text-uppercase">
                        <a
                            className="button back-button"
                            href="#"
                            target="_self"
                        >
                            <Localized
                                de="Zurück"
                                fr="Retour"
                                it="Indietro"
                                en="Back"
                            />
                        </a>
                        <a
                            className="button info-button"
                            href="https://www.twint.ch"
                            target="_blank"
                        >
                            Infos
                        </a>
                    </div>
                </div>
                <div className="row text-center text-uppercase">
                    <div>
                        {pairingData.mode ===
                            PendingTwintPairing.Mode.PAYMENT && (
                            <p className="value">CHF {pairingData.amount}</p>
                        )}
                    </div>
                </div>
            </div>
            <div className="section page">
                {isIos ? (
                    <AppChooser
                        scheme={scheme}
                        setScheme={newScheme => {
                            setStringItem(lastApp, newScheme);
                            setScheme(newScheme);
                        }}
                        token={pairingToken}
                        configsRequest={configsRequest}
                    />
                ) : (
                    <div /> // Necessary for twint styles
                )}
                <div className="row text-center pay-btn">
                    <div className="wrap">
                        <div className="copy-cache">
                            <button
                                type="button"
                                className={['btn', css({ width: '100%' })].join(
                                    ' ',
                                )}
                                id="pay-with-twint"
                                disabled={isIos && !scheme}
                                onClick={() =>
                                    openApp(platform, pairingToken, scheme)
                                }
                            >
                                <Localized
                                    de="Jetzt zur App wechseln"
                                    fr="Ouvrez l'appli maintenant"
                                    it="Apri adesso l'app"
                                    en="Switch to the app now"
                                />
                            </button>
                        </div>
                    </div>
                </div>
                {platform === Platform.DESKTOP ? (
                    <PairingPageDesktop token={pairingToken} />
                ) : (
                    <PairingPageMobile token={pairingToken} />
                )}
            </div>
        </div>
    );
}

const Back = (p: { pairingData: TwintPendingState }) => {
    switch (p.pairingData.mode) {
        case PendingTwintPairing.Mode.PAYMENT:
            return (
                <Localized
                    de="Zahlung abbrechen"
                    fr="Annuler le paiement"
                    it="Annulla pagamento"
                    en="Abort payment"
                />
            );
        case PendingTwintPairing.Mode.UOF:
            return (
                <Localized
                    de="Aktivierung abbrechen"
                    fr="Annuler l'activation"
                    it="Annulla attivazione"
                    en="Abort activation"
                />
            );
    }
};

const Amount = (p: { pairingData: TwintPendingState; isIos: boolean }) => {
    if (
        p.pairingData.mode !== PendingTwintPairing.Mode.PAYMENT ||
        p.pairingData.state === PairingState.UNKNOWN
    ) {
        return <div id="amount-container" className="header" />;
    }

    return (
        <div
            id="amount-container"
            className={[
                'section',
                'header',
                css({ marginTop: p.isIos ? '32px !important' : 0 }),
            ].join(' ')}
        >
            <div className="wrap">
                <div className="header-item one-merge column text-center text-uppercase">
                    <span className="value">CHF {p.pairingData.amount}</span>
                </div>
            </div>
        </div>
    );
};

export function PairingTokenDisplay(p: { token: string }) {
    const numbers: React.ReactNode[] = [];
    for (let i = 0; i < p.token.length; i++) {
        numbers.push(
            <p className="credential-number" key={`token-${i}`}>
                {p.token.charAt(i)}
            </p>,
        );
        numbers.push(' ');
    }

    return <div className="credentials-item">{numbers}</div>;
}

export function getPlatform(): Platform {
    var ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf('android') > -1) {
        return Platform.ANDROID;
    }

    if (
        ua.indexOf('ipad') > -1 ||
        ua.indexOf('iphone') > -1 ||
        ua.indexOf('ipd') > -1
    ) {
        return Platform.IOS;
    }

    return Platform.DESKTOP;
}

export function getAppUrl(
    platform: Platform,
    token: string,
    issuer?: string,
): string {
    switch (platform) {
        case Platform.IOS:
            return `${issuer}applinks/?al_applink_data={"app_action_type":"TWINT_PAYMENT","extras":{"code":"${token}"},"referer_app_link":{"app_name":"EXTERNAL_WEB_BROWSER","target_url":"","url":""},"version":"6.0"}`;
        case Platform.ANDROID:
            return `intent://payment#Intent;action=ch.twint.action.TWINT_PAYMENT;scheme=twint;S.code=${token};S.startingOrigin=EXTERNAL_WEB_BROWSER;S.browser_fallback_url=;end`;
        default:
            throw "Can't open non mobile platform app";
    }
}

export function openApp(platform: Platform, token: string, issuer?: string) {
    parent.location = (getAppUrl(
        platform,
        token,
        issuer,
    ) as unknown) as Location;
}

export const customerUrl = () => {
    switch (window.location.host) {
        case 'parkingpay.ch':
            return 'https://parkingpay.ch';
        case 'customer.test.digitalparking.ch':
            return 'https://customer.test.digitalparking.ch';
        case 'customer.dev1.digitalparking.dev':
            return 'https://customer.dev1.digitalparking.dev';
        case 'customer.dev2.digitalparking.dev':
            return 'https://customer.dev2.digitalparking.dev';
        case 'customer.dev3.digitalparking.dev':
            return 'https://customer.dev3.digitalparking.dev';
        case 'customer.dev4.digitalparking.dev':
            return 'https://customer.dev4.digitalparking.dev';
        case 'customer.demo.parkingportal.ch':
            return 'https://demo.parkingpay.ch';
        case 'customer.ebi.digitalparking.dev':
            return 'https://customer.ebi.digitalparking.dev';
        default:
            return `${window.location.protocol}//${window.location.host}`;
    }
};
