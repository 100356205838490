import * as moment from 'moment';
import { isDefined, isUndefined, Maybe } from './MaybeV2';
import { EmDash } from './Punctuation';
import Text = require('./Text');
import { DateTime } from 'luxon';

export class Range {
    start: moment.Moment;
    end: moment.Moment;

    constructor(start: moment.Moment, end: moment.Moment) {
        this.start = start;
        this.end = end;
    }

    contains(ref: moment.Moment): boolean {
        return (
            (ref.isAfter(this.start) || ref.isSame(this.start)) &&
            (ref.isBefore(this.end) || ref.isSame(this.end))
        );
    }

    after(ref: moment.Moment): boolean {
        return this.start.isAfter(ref) && this.end.isAfter(ref);
    }

    before(ref: moment.Moment): boolean {
        return this.start.isBefore(ref) && this.end.isBefore(ref);
    }

    toString(): string {
        return (
            Formatter.dayMonthYear(this.start) +
            ' - ' +
            Formatter.dayMonthYear(this.end)
        );
    }
}

export class OpenRange {
    from: Maybe<moment.Moment>;
    to: Maybe<moment.Moment>;

    constructor(from?: Maybe<moment.Moment>, to?: Maybe<moment.Moment>) {
        this.from = from;
        this.to = to;
    }

    contains(ref: moment.Moment): boolean {
        const from = this.from;
        const to = this.to;
        if (from && to) {
            if (from.isSame(to)) {
                return false;
            }
        }
        const fromInRange =
            !isDefined(from) ||
            (isDefined(from) && (from.isBefore(ref) || from.isSame(ref)));
        const toInRange =
            !isDefined(to) ||
            (isDefined(to) && (to.isAfter(ref) || to.isSame(ref)));
        return fromInRange && toInRange;
    }

    toString(): string {
        return Formatter.openRange(this.from, this.to, Formatter.dayMonthYear);
    }
}

export enum TimeUnit {
    SECOND,
    MINUTE,
    HOUR,
    DAY,
    MONTH,
    YEAR,
}

export namespace Formatter {
    type Formatter = (m: moment.Moment) => string;

    export const DATETIME_LOCAL = 'YYYY-MM-DDTHH:mm';

    export enum Locale {
        de,
        fr,
        it,
        en,
    }

    export function getLocaleFromString(s: string): Locale {
        switch (s) {
            case 'de':
                return Locale.de;
            case 'fr':
                return Locale.fr;
            case 'it':
                return Locale.it;
            case 'en':
                return Locale.en;
            default:
                return Locale.de;
        }
    }

    export function dayOfMonth(m: moment.Moment): string {
        return m.format('D.');
    }

    export function dayMonthAbbrNameYear(
        m: moment.Moment,
        locale: Locale,
    ): string {
        return m.locale(Locale[locale]).format('D. MMM YYYY');
    }

    export function dayMonthNameYear(m: moment.Moment, locale: Locale): string {
        return m.locale(Locale[locale]).format('D. MMMM YYYY');
    }

    export function monthNameAbbrAndYear(
        m: moment.Moment,
        locale: Locale,
    ): string {
        return m.locale(Locale[locale]).format('MMM YYYY');
    }

    export function monthNameAbbr(m: moment.Moment, locale: Locale): string {
        // we do not want to render . anywhere (e.g. German = "SEPT.")
        return m.locale(Locale[locale]).format('MMM').replace('.', '');
    }

    export function monthName(m: moment.Moment, locale: Locale): string {
        return m.locale(Locale[locale]).format('MMMM');
    }

    /**
     * @deprecated Use dateTime instead
     */
    export function monthNameYear(
        dateTime: moment.Moment,
        locale: Locale,
    ): string;
    export function monthNameYear(dateTime: DateTime, locale: Locale): string;
    export function monthNameYear(
        dateTime: DateTime | moment.Moment,
        locale: Locale,
    ): string {
        if (moment.isMoment(dateTime)) {
            return dateTime.locale(Locale[locale]).format('MMMM YYYY');
        }
        return dateTime.setLocale(toLuxonLocale(locale)).toFormat('MMMM yyyy');
    }

    export function monthAbrevYear(m: moment.Moment, locale: Locale): string {
        return m.locale(Locale[locale]).format('MMM YYYY');
    }

    export function dayMonthName(m: moment.Moment, locale: Locale): string {
        return m.locale(Locale[locale]).format('D. MMMM');
    }

    export function dayOfWeekName(m: moment.Moment, locale: Locale): string {
        return m.locale(Locale[locale]).format('dddd');
    }

    export function dayOfWeekAbbr(m: moment.Moment, locale: Locale): string {
        return m.locale(Locale[locale]).format('dd');
    }

    export function dayOfWeekAbbr3(m: moment.Moment, locale: Locale): string {
        return m.locale(Locale[locale]).format('ddd');
    }

    /**
     * @deprecated Use dateTime instead
     */
    export function isoYearMonthDay(dateTime: moment.Moment): string;
    export function isoYearMonthDay(dateTime: DateTime): string;
    export function isoYearMonthDay(
        dateTime: moment.Moment | DateTime,
    ): string {
        if (moment.isMoment(dateTime)) {
            return dateTime.format('YYYY-MM-DD');
        }
        return dateTime.toISODate();
    }

    export function isoYearMonth(m: moment.Moment): string {
        return m.format('YYYY-MM');
    }

    /**
     * @deprecated Use dateTime instead
     */
    export function isoString(dateTime: moment.Moment): string;
    export function isoString(dateTime: DateTime): string;
    export function isoString(dateTime: DateTime | moment.Moment): string {
        if (moment.isMoment(dateTime)) {
            return dateTime.toISOString();
        }
        return dateTime.toISO();
    }

    export function year(m: moment.Moment): string {
        return m.format('YYYY');
    }

    /**
     * @deprecated Use dateTime instead
     */
    export function dayMonthYear(dateTime: moment.Moment): string;
    export function dayMonthYear(dateTime: DateTime): string;
    export function dayMonthYear(dateTime: moment.Moment | DateTime): string {
        if (moment.isMoment(dateTime)) {
            return dateTime.format('DD.MM.YYYY');
        }
        return dateTime.toFormat('dd.MM.yyyy');
    }

    export function formatPermitToDateWithIndefinite(m: moment.Moment): string {
        if (m.isAfter(moment(new Date('2037-12-29')))) {
            return '∞';
        }
        return m.format('DD.MM.YYYY');
    }

    // String has to be MM-DD, manual parsing because leap years explode otherwise
    export function dayMonth(date: String): string {
        return date.substr(3, 2) + '.' + date.substr(0, 2) + '.';
    }

    /**
     * @deprecated Use dateTime instead
     */
    export function dayMonthYearHourMinute(dateTime: moment.Moment): string;
    export function dayMonthYearHourMinute(dateTime: DateTime): string;
    export function dayMonthYearHourMinute(
        dateTime: moment.Moment | DateTime,
    ): string {
        if (moment.isMoment(dateTime)) {
            return dateTime.format(`DD.MM.YYYY ${EmDash} HH:mm`);
        }
        return dateTime.toFormat(`dd.MM.yyyy ${EmDash} HH:mm`);
    }

    /**
     * @deprecated Use dateTime instead
     */
    export function dayMonthYearHourMinuteSecond(
        dateTime: moment.Moment,
    ): string;
    export function dayMonthYearHourMinuteSecond(dateTime: string): string;
    export function dayMonthYearHourMinuteSecond(
        dateTime: moment.Moment | string,
    ): string {
        if (moment.isMoment(dateTime)) {
            return dateTime.format(`DD.MM.YYYY ${EmDash} HH:mm:ss`);
        }
        const dateTimeLuxon = DateTime.fromISO(dateTime).setLocale('de');
        const date = dateTimeLuxon.toLocaleString({
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });
        const time = dateTimeLuxon.toLocaleString({
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
        });
        return `${date} ${EmDash} ${time}`;
    }

    export const isoStringTodayMonthYearhHourMinute = (s: string): string => {
        return dayMonthYearHourMinute(Parser.isoToMoment(s));
    };
    export const isoStringTodayMonthYearhHourMinuteSecond = (
        s: string,
    ): string => {
        return dayMonthYearHourMinuteSecond(Parser.isoToMoment(s));
    };

    export function dayMonthYearHourMinuteUTC(m: moment.Moment): string {
        return m.utc().format('DD.MM.YYYY HH:mm');
    }

    export function datetimeLocal(m: moment.Moment): string {
        return m.format(DATETIME_LOCAL);
    }

    export function dateWithDurationFromNow(
        m: moment.Moment,
        language: string,
    ): string {
        const dateString = dayMonthYearHourMinute(m);
        const durationString = durationFromNow(m, language);

        return `${dateString} (${durationString})`;
    }

    export function durationFromNow(
        m: moment.Moment,
        language: string,
    ): string {
        moment.locale(language);
        const now: moment.Moment = moment();
        const duration: moment.Duration = moment.duration(m.diff(now));

        return duration.humanize(true);
    }

    /**
     * @deprecated Use dateTime instead
     */
    export function hourMinute(dateTime: moment.Moment): string;
    export function hourMinute(dateTime: DateTime): string;
    export function hourMinute(dateTime: moment.Moment | DateTime): string {
        if (moment.isMoment(dateTime)) {
            return dateTime.format('HH:mm');
        }
        return dateTime.toFormat('HH:mm');
    }

    export function getDurationMoment(
        from: moment.Moment,
        to: moment.Moment,
        language: string,
    ): string {
        moment.locale(language);
        return moment.duration(to.diff(from)).humanize();
    }

    export function getDurationDays(
        from: moment.Moment,
        to: moment.Moment,
    ): number {
        return Math.floor(moment(to).diff(moment(from), 'days', true));
    }

    export const openRange = (
        from: Maybe<moment.Moment>,
        to: Maybe<moment.Moment>,
        formatter: Formatter,
        placeholder: string = '',
    ): string => {
        if (isUndefined(from) && isUndefined(to)) {
            return '–';
        }
        return (
            (isDefined(from) ? formatter(from) : placeholder) +
            ' – ' +
            (isDefined(to) ? formatter(to) : placeholder)
        );
    };

    export interface DurationTexts {
        [key: string]: () => string;
        second: Text.Translation;
        minute: Text.Translation;
        hour: Text.Translation;
        day: Text.Translation;
        month: Text.Translation;
        year: Text.Translation;

        minuteFull: Text.Translation;
        hourFull: Text.Translation;
    }

    export const durationTexts: Text.Translations<DurationTexts> = {
        en: {
            second: () => 's',
            minute: () => 'min',
            hour: () => 'h',
            day: () => 'd',
            month: () => 'm',
            year: () => 'y',
            minuteFull: () => 'minutes',
            hourFull: () => 'hours',
        },
        de: {
            second: () => 's',
            minute: () => 'min',
            hour: () => 'h',
            day: () => 'd',
            month: () => 'Mt',
            year: () => 'J',
            minuteFull: () => 'Minuten',
            hourFull: () => 'Stunden',
        },
        fr: {
            second: () => 's',
            minute: () => 'min',
            hour: () => 'h',
            day: () => 'j',
            month: () => 'm',
            year: () => 'a',
            minuteFull: () => 'minutes',
            hourFull: () => 'heures',
        },
        it: {
            second: () => 's',
            minute: () => 'min',
            hour: () => 'h',
            day: () => 'g',
            month: () => 'm',
            year: () => 'a',
            minuteFull: () => 'minuti',
            hourFull: () => 'ore',
        },
    };

    export function getDurationFromMinutes(
        minutes: number,
        texts: DurationTexts,
    ): string {
        const now = moment();
        const end = moment().add(minutes, 'minutes');
        return getDuration(now, end, texts);
    }

    /*
     returns the duration as component objects
     special case: if the time difference is less than 1 minute, 1 minute is returned
     */
    export interface DurationComponent {
        value: number;
        unit: string;
    }
    export const getDurationComponents = (
        from: moment.Moment,
        to: moment.Moment,
        texts: DurationTexts,
    ) => {
        // rounding (floor) of seconds prevents minutes-mismatch
        const momFrom = moment(from).seconds(0);
        const momTo = moment(to).seconds(0);

        // these names are used to index into texts and as units for moment
        const units: moment.unitOfTime.Diff[] = [
            'year',
            'month',
            'day',
            'hour',
            'minute',
        ];

        const components: DurationComponent[] = [];

        units.forEach((unit: moment.unitOfTime.DurationConstructor) => {
            const value = Math.floor(momTo.diff(momFrom, unit, true));
            if (value > 0) {
                momTo.subtract(value, unit);
                components.push({ value: value, unit: texts[unit]() });
            }
        });

        // special case: the minimum returned is 1 minute
        if (components.length === 0) {
            return [{ value: 1, unit: texts.minute() }];
        }

        return components;
    };

    /*
     returns the duration as a formatted string
     */
    export const getDuration = (
        from: string | moment.Moment,
        to: string | moment.Moment,
        texts: DurationTexts,
    ) =>
        getDurationComponents(moment(from), moment(to), texts)
            .map(component => component.value + component.unit)
            .join(' ');
}

export namespace Parser {
    export function momentToDateTime(moment: moment.Moment): DateTime {
        return DateTime.fromISO(moment.toISOString());
    }

    export function isoYearMonth(str: string): moment.Moment {
        return moment(str, 'YYYY-MM');
    }

    export function isoYearMonthDay(str: string): moment.Moment {
        return moment(str, 'YYYY-MM-DD');
    }

    export function isoToMoment(iso: string): moment.Moment {
        return moment(new Date(iso));
    }

    export function isoToMaybeMoment(iso: string): moment.Moment | null {
        if (!iso) {
            return null;
        }

        return moment(new Date(iso));
    }

    export function rangeFromIsoString(str: string): Maybe<Range> {
        if (!str) {
            return null;
        }

        const args = str.split('/');
        const start = moment(args[0]);
        const end = moment(args[1]);
        if (start.isValid() && end.isValid()) {
            return new Range(start, end);
        } else {
            return null;
        }
    }
}

export function compareDateTime(a: DateTime, b: DateTime) {
    return a < b ? -1 : a > b ? 1 : 0;
}

export const unlimitedFromDate = Parser.isoYearMonthDay('1970-01-01').startOf(
    'day',
);
export const isUnlimitedFromDate = (d: moment.Moment) =>
    d.isSame(unlimitedFromDate, 'day');

export const unlimitedToDate = Parser.isoYearMonthDay('2037-12-31').endOf(
    'day',
);
export const isUnlimitedToDate = (d: moment.Moment) =>
    d.isSame(unlimitedToDate, 'day');

export function toLuxonLocale(locale: Formatter.Locale): string {
    switch (locale) {
        case Formatter.Locale.de:
            return 'de-CH';
        case Formatter.Locale.fr:
            return 'fr-CH';
        case Formatter.Locale.it:
            return 'it-CH';
        case Formatter.Locale.en:
            return 'en-CH';
    }
}
