import { useParams } from 'react-router';
import * as React from 'react';
import * as superagent from 'superagent';
import {
    useParkingaboCsrfToken,
    useParkingaboServerFetch,
} from '../../../api/ParkingaboApi';
import {
    getPlatform,
    PairingPage,
} from '../../../../../twint-payment/src/PairingPage';
import {
    AuthedRouteCompProps,
    useParkingaboAuthedPathGeneration,
} from '../../RouteUtils';
import { RequestStatus } from '../../../../../lib/hooks/ServerStateHooks';
import * as ReactDOM from 'react-dom';
import {
    PairingState,
    PendingTwintPairing,
    transactionFromUoFState,
    TwintPendingState,
    TwintUoFStateResponse,
} from '../../../../../common/utils/TwintPairing';
import { PaymentMethodType } from '../../../components/forms/PaymentMethodForm';

function useTwintOufState(
    mode: PendingTwintPairing.Mode,
    requestCounter: number,
    pairingToken: string,
): TwintPendingState {
    const [uofData] = useParkingaboServerFetch<
        TwintUoFStateResponse,
        { token: string; count: number }
    >(
        ({ token }) => ({
            url: `/ui-api/parkingabo/user/self/payment/twint-alias/${token}/state`,
        }),
        { token: pairingToken, count: requestCounter },
    );

    if (uofData.status !== RequestStatus.SUCCESS) {
        return {
            mode: PendingTwintPairing.Mode.UOF,
            state: PairingState.UNKNOWN,
            requestState: uofData.status,
        };
    } else {
        return {
            mode: PendingTwintPairing.Mode.UOF,
            state: transactionFromUoFState(uofData.data.state),
            requestState: uofData.status,
            token: pairingToken,
        };
    }
}

export function getTwintCheckingPath(
    paymentMethodType: PaymentMethodType,
): string {
    switch (paymentMethodType) {
        case PaymentMethodType.REGISTER:
            return 'twint-checking/register-twint';
        case PaymentMethodType.UPDATE:
            return 'twint-checking/update-twint';
        case PaymentMethodType.ONBOARDING:
            return 'twint-checking/onboarding-twint';
    }
}

export function TwintPairingRoute({
    paymentMethodType,
    user,
}: {
    paymentMethodType: PaymentMethodType;
} & AuthedRouteCompProps) {
    const generateAuthedParkingaboPath = useParkingaboAuthedPathGeneration();
    const queryParams = new URLSearchParams(window.location.search);
    const isUof = queryParams.get('uof');
    const { twintPairingToken } = useParams<{ twintPairingToken: string }>();
    const backUrl = `${window.location.protocol}//${
        window.location.host
    }${generateAuthedParkingaboPath(getTwintCheckingPath(paymentMethodType))}`;
    const csrfToken = useParkingaboCsrfToken();

    if (!isUof) {
        return <>operation not supported</>;
    }

    if (!csrfToken || !twintPairingToken) {
        // this can't actually happen as if this route can be rendered the token will be populated
        return null;
    }

    return ReactDOM.createPortal(
        <PairingPage
            token={twintPairingToken}
            platform={getPlatform()}
            isUof={true}
            url={backUrl}
            stateState={useTwintOufState}
            abortRequest={superagent
                .post(
                    `/ui-api/parkingabo/user/self/payment/twint-alias/${twintPairingToken}/abort`,
                )
                .set('X-CSRF-Token', csrfToken)
                .send({})}
            configsRequest={superagent
                .get(`/ui-api/parkingabo/user/self/payment/twint/configs`)
                .set('X-CSRF-Token', csrfToken)
                .send({})}
        />,
        document.getElementById('app-container') as HTMLElement,
    );
}
