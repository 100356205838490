import * as React from 'react';
import { useEffect, useState } from 'react';
import { Localized } from '../../../../../common/hooks/LanguageProvider';
import { Box, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import {
    formatZoneName,
    ParkingaboProductTemplateReservation,
    ReservationPriceConfigType,
} from './ParkingaboProductTemplateModels';
import {
    WizardBody,
    WizardBottomBar,
    WizardFadeInEffect,
    WizardProductHeader,
    WizardStepper,
} from './WizardComponents';
import { useNavigate } from 'react-router-dom';
import { useParkingaboServerWrite } from '../../../api/ParkingaboApi';
import {
    RequestStatus,
    useServerSuccessEffect,
} from '../../../../../lib/hooks/ServerStateHooks';
import { BackendRequestErrorMessage } from '../../../components/error/BackendRequestErrorMessage';
import { compareDateTime, Formatter } from '../../../../../lib/Date';
import { currencyCentsToLocalPrice } from '../../../../../lib/NumberFormatter';
import { FeedbackPopup } from '../../../components/FeedbackPopup';
import { ParkingaboOverlineList } from '../../../components/layout/ParkingaboOverlineList';
import { ParkingaboZoneInfo } from '../../../shared/ParkingaboProductModels';
import { exhaustiveGuard } from '../../../../../lib/ExhaustiveGuard';
import { MultiDateSelectionStep } from './SharedSteps';
import { TestphaseProductSelected } from './ProductsConfigurationGuestReservation';
import { ParkingaboUser } from '../../../shared/ParkingaboModels';

const enum WizardStep {
    DATE,
    OVERVIEW,
}

interface WizardSelection {
    dates: DateTime[];
}

export function ProductsConfigurationReservation({
    user,
    product,
    zones,
    noProductChange,
    refetchProducts,
}: {
    user: ParkingaboUser;
    product: ParkingaboProductTemplateReservation;
    zones: ParkingaboZoneInfo[];
    noProductChange: boolean;
    refetchProducts: () => void;
}) {
    const [selection, setSelection] = useState<WizardSelection>({
        dates: [],
    });
    const totalAmountRappen =
        selection.dates.length > 0
            ? calculateTotalPrice(product, selection.dates)
            : null;

    const [
        purchaseState,
        purchaseProduct,
        resetPurchaseState,
    ] = useParkingaboServerWrite<
        {
            productTemplateId: string;
            dates: string[];
            totalAmountRappen: number;
        },
        never
    >(() => ({
        url: `/ui-api/parkingabo/user/self/product/purchase/cardcenter-reservation-day`,
    }));

    useServerSuccessEffect(purchaseState, refetchProducts);

    const navigate = useNavigate();

    useEffect(() => {
        if (purchaseState.status === RequestStatus.ERROR) {
            resetPurchaseState();
        }
    }, [selection]);

    function handlePurchase() {
        if (
            !selection.dates ||
            totalAmountRappen === null ||
            activeStep !== WizardStep.OVERVIEW
        ) {
            throw new Error(
                'Tried to purchase product without completed selection',
            );
        }
        purchaseProduct({
            productTemplateId: product.productTemplateId,
            dates: selection.dates.map(date => date.toISODate()),
            totalAmountRappen: totalAmountRappen,
        });
    }

    const steps = [
        {
            step: WizardStep.DATE,
            label: <Localized de="Datum" fr="Date" it="Data" en="Date" />,
        },
        {
            step: WizardStep.OVERVIEW,
            label: (
                <Localized
                    de="Übersicht"
                    fr="Aperçu"
                    it="Panoramica"
                    en="Overview"
                />
            ),
        },
    ];

    const firstStep = steps[0].step;
    const [activeStep, setActiveStep] = React.useState<WizardStep>(firstStep);

    const nextStepHasClearance = stepHasClearance(
        activeStep + 1,
        selection,
        totalAmountRappen,
    );

    const [showTestPhaseDialog, setShowTestPhaseDialog] = useState(false);

    return (
        <>
            <BackendRequestErrorMessage requestState={purchaseState} />
            <PurchaseSuccessDialog
                open={purchaseState.status === RequestStatus.SUCCESS}
                onAbort={() => navigate('...')}
            />
            <TestphaseProductSelected
                open={showTestPhaseDialog}
                onAbort={() => setShowTestPhaseDialog(false)}
                onProceed={handlePurchase}
            />
            <WizardProductHeader>
                <Localized {...product.name} />
            </WizardProductHeader>
            <WizardStepper
                steps={steps}
                activeStep={activeStep}
                onStepClick={step => {
                    if (stepHasClearance(step, selection, totalAmountRappen)) {
                        setActiveStep(step);
                    }
                }}
                offset={noProductChange ? 0 : 2}
            />
            <WizardBody>
                {activeStep === WizardStep.DATE && (
                    <MultiDateSelectionStep
                        dates={selection.dates}
                        onChange={dates => {
                            setSelection({
                                ...selection,
                                dates,
                            });
                        }}
                        minValidityDate={
                            product.dayAndPriceConfig.minValidityStart
                                ? DateTime.fromISO(
                                      product.dayAndPriceConfig
                                          .minValidityStart,
                                  )
                                : null
                        }
                        maxSelectableDates={
                            product.dayAndPriceConfig.maxPurchaseQuantity!!
                        }
                        maxDaysInFuture={
                            product.dayAndPriceConfig
                                .maxDaysPurchaseAheadInFuture!!
                        }
                    />
                )}
                {activeStep === WizardStep.OVERVIEW && (
                    <ProductOverview
                        product={product}
                        zones={zones}
                        dates={selection.dates}
                        totalAmountRappen={totalAmountRappen!!}
                    />
                )}
            </WizardBody>
            <WizardBottomBar
                onNextClick={
                    nextStepHasClearance
                        ? () => {
                              if (activeStep === WizardStep.OVERVIEW) {
                                  if (
                                      user.tenantId === 360 &&
                                      product.type === 'RESERVATION' &&
                                      product.dayAndPriceConfig.priceConfig
                                          .noChargeUntil !== null &&
                                      DateTime.fromISO(
                                          product.dayAndPriceConfig.priceConfig
                                              .noChargeUntil,
                                      ) > DateTime.local()
                                  ) {
                                      setShowTestPhaseDialog(true);
                                  } else {
                                      handlePurchase();
                                  }
                              } else {
                                  setActiveStep(activeStep + 1);
                              }
                          }
                        : undefined
                }
                nextLabel={
                    activeStep === WizardStep.OVERVIEW ? (
                        <Localized
                            de="Buchen"
                            fr="Réserver"
                            it="Prenota"
                            en="Book"
                        />
                    ) : undefined
                }
                nextAsSubmitRequestState={
                    activeStep === WizardStep.OVERVIEW
                        ? purchaseState.status
                        : undefined
                }
                onPreviousClick={() => {
                    if (activeStep === firstStep) {
                        navigate('..');
                    } else {
                        setActiveStep(activeStep - 1);
                    }
                }}
                previousLabel={
                    activeStep === firstStep ? (
                        <Localized
                            de="Produkt ändern"
                            fr="Changer de produit"
                            it="Cambia prodotto"
                            en="Change product"
                        />
                    ) : undefined
                }
                hidePrevious={noProductChange && activeStep === firstStep}
            />
        </>
    );
}

function stepHasClearance(
    step: WizardStep,
    selection: WizardSelection,
    totalAmountRappen: number | null,
): boolean {
    switch (step) {
        case WizardStep.DATE:
            return true;
        default:
            return selection.dates.length > 0 && totalAmountRappen !== null;
    }
}

function ProductOverview({
    product,
    zones,
    dates,
    totalAmountRappen,
}: {
    product: ParkingaboProductTemplateReservation;
    zones: ParkingaboZoneInfo[];
    dates: DateTime[];
    totalAmountRappen: number;
}) {
    const productZones = zones.filter(zone =>
        product.zoneIds.includes(zone.zoneId),
    );
    const sortedDates = dates.sort(compareDateTime);

    return (
        <WizardFadeInEffect>
            <ParkingaboOverlineList.Body>
                <ParkingaboOverlineList.Item
                    label={
                        productZones.length > 1 ? (
                            <Localized
                                de="Parkings"
                                fr="Parkings"
                                it="Parcheggi"
                                en="Parkings"
                            />
                        ) : (
                            <Localized
                                de="Parking"
                                fr="Parking"
                                it="Parcheggio"
                                en="Parking"
                            />
                        )
                    }
                >
                    {productZones.map(zone => (
                        <Box
                            sx={{
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                            }}
                            key={zone.zoneId}
                        >
                            {formatZoneName(zone)}
                        </Box>
                    ))}
                </ParkingaboOverlineList.Item>
                <ParkingaboOverlineList.Item
                    label={
                        sortedDates.length === 1 ? (
                            <Localized
                                de="Tag"
                                fr="Jour"
                                it="Giorno"
                                en="Day"
                            />
                        ) : (
                            <Localized
                                de="Tage"
                                fr="Jours"
                                it="Giorni"
                                en="Days"
                            />
                        )
                    }
                >
                    {sortedDates.map(date => (
                        <div key={date.toMillis()}>
                            {Formatter.dayMonthYear(date)}
                            {date.equals(DateTime.now().startOf('day')) ? (
                                <Localized
                                    de=" (ab Kaufzeit)"
                                    fr=" (à partir de l'heure d'achat)"
                                    it=" (dall'ora d'acquisto)"
                                    en=" (as of purchase time)"
                                />
                            ) : (
                                ''
                            )}
                        </div>
                    ))}
                </ParkingaboOverlineList.Item>
                {product.info && (
                    <ParkingaboOverlineList.Item
                        label={
                            <Localized
                                de="Info"
                                fr="Info"
                                it="Info"
                                en="Info"
                            />
                        }
                    >
                        <Localized {...product.info} />
                    </ParkingaboOverlineList.Item>
                )}

                <ParkingaboOverlineList.Item
                    label={
                        <Localized
                            de="Preis"
                            fr="Prix"
                            it="Prezzo"
                            en="Price"
                        />
                    }
                >
                    <Typography fontSize={'1.5rem'} fontWeight="bold">
                        {currencyCentsToLocalPrice('de', totalAmountRappen)}
                    </Typography>
                </ParkingaboOverlineList.Item>
            </ParkingaboOverlineList.Body>
        </WizardFadeInEffect>
    );
}

function PurchaseSuccessDialog({
    open,
    onAbort,
}: {
    open: boolean;
    onAbort: () => void;
}) {
    return (
        <FeedbackPopup
            open={open}
            color="success"
            title={
                <Localized
                    de="Bestätigung"
                    fr="Confirmation"
                    it="Conferma"
                    en="Confirmation"
                />
            }
            abortLabel={'OK'}
            onAbort={onAbort}
        >
            <Localized
                de="Das Produkt wurde erfolgreich erworben."
                fr="Le produit a été acheté avec succès."
                it="Il prodotto è stata acquistato con successo."
                en="The product was successfully purchased."
            />
        </FeedbackPopup>
    );
}

function calculateTotalPrice(
    product: ParkingaboProductTemplateReservation,
    dates: DateTime[],
): number {
    return dates
        .map(date => {
            const priceConfig = product.dayAndPriceConfig.priceConfig;
            if (priceConfig.type === ReservationPriceConfigType.SIMPLE) {
                return priceConfig.noChargeUntil &&
                    date < DateTime.fromISO(priceConfig.noChargeUntil)
                    ? 0
                    : priceConfig.priceRappen;
            }
            if (priceConfig.type === ReservationPriceConfigType.WEEKDAY) {
                if (
                    priceConfig.noChargeUntil &&
                    date < DateTime.fromISO(priceConfig.noChargeUntil)
                ) {
                    return 0;
                }
                switch (date.weekday) {
                    case 1:
                        return priceConfig.mondayRappen;
                    case 2:
                        return priceConfig.tuesdayRappen;
                    case 3:
                        return priceConfig.wednesdayRappen;
                    case 4:
                        return priceConfig.thursdayRappen;
                    case 5:
                        return priceConfig.fridayRappen;
                    case 6:
                        return priceConfig.saturdayRappen;
                    case 7:
                        return priceConfig.sundayRappen;
                    default:
                        throw Error('Unknown weekday');
                }
            }
            exhaustiveGuard(priceConfig);
        })
        .reduce((a, b) => a + b, 0);
}
