/*
 * @deprecated use union types instead e.g. number | null - See: https://www.typescriptlang.org/docs/handbook/unions-and-intersections.html
 */
export type Maybe<T> = T | null | undefined;

/*
 * @deprecated compare directly using truthy values e.g. if(variable) instead of if(isDefined(variable)) - See: https://basarat.gitbook.io/typescript/recap/truthy
 */
export function isDefined<T>(x: Maybe<T>): x is T {
    return x !== undefined && x !== null;
}

/*
 * @deprecated compare directly using truthy values e.g. if(!variable) instead of if(isUndefined(variable)) - See: https://basarat.gitbook.io/typescript/recap/truthy
 */
export function isUndefined<T>(x: Maybe<T>): x is null | undefined {
    return x === undefined || x === null;
}

export function getOrElse<T>(x: Maybe<T>, defaultValue: T): T {
    if (isDefined(x)) {
        return <T>x;
    } else {
        return defaultValue;
    }
}

export function getOrNull<T>(x: Maybe<T>): T | null {
    if (isDefined(x)) {
        return <T>x;
    } else {
        return null;
    }
}

export function forceDef<T>(x: Maybe<T>): T {
    return getOrNull(x) as T;
}

export function ifDefined<T>(x: Maybe<T>, then: (def: T) => void): void {
    if (isDefined(x)) {
        then(<T>x);
    }
}

export function thenElse<T, R>(x: Maybe<T>, then: (def: T) => R, els: R): R {
    if (isDefined(x)) {
        return then(<T>x);
    } else {
        return els;
    }
}

export const thenOrNull = <T, R>(x: Maybe<T>, then: (def: T) => R) =>
    thenElse(x, then, null);

export function isDefinedAndDiffersFrom<T>(x: Maybe<T>, y: T): boolean {
    if (isDefined(x)) {
        return x !== y;
    } else {
        return false;
    }
}
