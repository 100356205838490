import { css } from '@emotion/css';
import * as React from 'react';
import Lottie from 'react-lottie';
import { ServerRequestState } from '../../../lib/hooks/ServerStateHooks';
import { spinnerData } from '../../assets/spinnerData';
import { logAction } from '../LogAction';
import { txt } from '../Texts';
import { Lang } from '../Translate';
import { AlertBox, AlertBoxColor } from './AlertBox';
import { Modal } from './Modal';
import { DisplayPriority } from './DisplayPriority';

export function PendingOrErrorModal(p: {
    sessionToken: string;
    slices: ServerRequestState<any, {}>[];
    pendingOnlyWhenNoData?: boolean;
    showUseMeter?: boolean;
}): JSX.Element | null {
    const errors = p.slices.filter(s => s.status === 'error');

    if (errors.length > 0) {
        return <ErrorModal {...p} errors={errors} />;
    }

    if (
        p.slices.filter(
            s =>
                s.status === 'pending' && (!p.pendingOnlyWhenNoData || !s.data),
        ).length > 0
    ) {
        return <Spinner visible />;
    }

    return null;
}

export function RetryOnErrorModal(props: {
    httpRequest: ServerRequestState<any, {}>;
    doRetry: () => void;
}): JSX.Element | null {
    if (props.httpRequest.status === 'pending') {
        return <Spinner visible />;
    }

    if (props.httpRequest.status === 'error') {
        return (
            <AlertBox
                onConfirm={props.doRetry}
                confirmColor={AlertBoxColor.BLUE}
                confirmLabel={txt.general.tryAgain}
            >
                <p>
                    <Lang {...txt.general.error} />{' '}
                    <Lang {...txt.general.useMeter} />
                </p>

                <p>
                    <Lang {...txt.general.wereSorry} />
                </p>
            </AlertBox>
        );
    }

    return null;
}

export const ErrorModal = (p: {
    showUseMeter?: boolean;
    sessionToken: string;
    errors: ServerRequestState<any, {}>[];
}) => {
    const logPayload = p.errors.map(s => {
        if (s.status === 'error') {
            return {
                statusCode: s.httpStatusCode,
            };
        }
    });

    return (
        <AlertBox
            onCancel={() => {
                logAction(p.sessionToken, 'back-to-twint-on-error', logPayload);
                window.close();
            }}
            onConfirm={() => {
                logAction(p.sessionToken, 'try-again-on-error', logPayload);
                window.location.reload();
            }}
            confirmColor={AlertBoxColor.BLUE}
            confirmLabel={txt.general.tryAgain}
        >
            <p>
                <Lang {...txt.general.error} />{' '}
                {p.showUseMeter ? (
                    <Lang {...txt.general.useMeter} />
                ) : undefined}
            </p>
            <p>
                <Lang {...txt.general.wereSorry} />
            </p>
        </AlertBox>
    );
};

export const InlineSpinner = ({ zIndex }: { zIndex?: number }) => (
    <div
        className={css({
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            opacity: 0.8,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(255,255,255,0.9)',
            zIndex: zIndex || 'auto',
        })}
    >
        <Lottie
            height={50}
            width={50}
            isStopped={false}
            isPaused={false}
            options={{
                loop: true,
                autoplay: true,
                animationData: spinnerData,
                rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice',
                },
            }}
        />
    </div>
);

export const Spinner = (p: { visible: boolean }) => {
    if (!p.visible) {
        return null;
    }
    return (
        <Modal>
            <InlineSpinner zIndex={DisplayPriority.modal} />
        </Modal>
    );
};
