import * as React from 'react';
import { Box } from '@mui/material';
import { Theme } from '@mui/material/styles';

export function ParkingaboLayout({
    children,
    style,
}: {
    children?: React.ReactNode | React.ReactNodeArray;
    style?: React.CSSProperties;
}) {
    return (
        <Box
            sx={theme => ({
                ...layoutStyles(theme),
                padding: theme.spacing(2),
                ...style,
            })}
        >
            {children}
        </Box>
    );
}

export function layoutStyles(theme: Theme): React.CSSProperties {
    return {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        flexGrow: 1,
        overflowY: 'auto',
        height: '100%',
    };
}
