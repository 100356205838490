import { useParkingaboServerWrite } from '../api/ParkingaboApi';

export function useRequestEmailChange() {
    return useParkingaboServerWrite<{ email: string | null }, void>(
        () => ({
            url: '/ui-api/parkingabo/user/self/request-email-change',
        }),
        true,
    );
}
