import * as Flux from '../../../../lib/Flux';
import { languageFromString } from '../../../../lib/Localized';
import { isDefined } from '../../../../lib/MaybeV2';
import { getParameterByName } from '../../../../lib/Url';
import { ALLOWED_LANGUAGE_CODES } from '../i18n/Text';

export interface State {
    language: string;
}

export class StateSlice extends Flux.StateSlice<State> {
    key(): string {
        return 'common-SettingsState';
    }

    sideEffects(_store: Flux.Store): void {
        // nop
    }

    getInitialState(): State {
        // it is possible to switch default language by using a url query parameter ?lang=[language]
        const langFromUrl = getLanguageParameter();
        return {
            language: langFromUrl !== null ? langFromUrl : 'de',
        };
    }

    writeBrowserLanguagePending(): void {
        this.set((s: State) => {
            return s;
        });
    }

    writeLanguage(language: string): void {
        this.set((s: State) => {
            s.language = language;
            return s;
        });
    }

    writeLanguageFromServer(language: string): void {
        const langFromUrl = getLanguageParameter();
        this.set((s: State) => {
            s.language = langFromUrl !== null ? langFromUrl : language;
            return s;
        });
    }
}

function getLanguageParameter() {
    let lngQueryParameter = getParameterByName(window.location.href, 'lang');

    return isDefined(lngQueryParameter) &&
        isValidLanguageCode(lngQueryParameter)
        ? lngQueryParameter
        : null;
}

function isValidLanguageCode(language: string | null): boolean {
    return (
        isDefined(language) && ALLOWED_LANGUAGE_CODES.indexOf(language) !== -1
    );
}

export function useOperatorLanguage() {
    const { storeState: language } = Flux.useStore(
        store => new StateSlice(store).state.language,
    );

    return languageFromString(language);
}
