import { Message } from '../../../../../lib/Localized';
import {
    ParkingaboProductType,
    ParkingaboZoneInfo,
} from '../../../shared/ParkingaboProductModels';
import { VehicleLicensePlateType } from '../../../../../common/models/Vehicle';

export type ParkingaboProductTemplate =
    | ParkingaboProductTemplateReservation
    | ParkingaboProductTemplateSubscription
    | ParkingaboProductTemplateGuestReservation;

interface ParkingaboProductTemplateBase {
    type: ParkingaboProductType;
    productTemplateId: string;
    name: Message;
    vehicleType: VehicleLicensePlateType;
    zoneIds: number[];
    info?: Message;
}

export enum ReservationTimeAndPriceConfigType {
    CALENDAR_DAY = 'CALENDAR_DAY',
}

export enum ReservationPriceConfigType {
    SIMPLE = 'SIMPLE',
    WEEKDAY = 'WEEKDAY',
}

export interface SimplePrice {
    type: ReservationPriceConfigType.SIMPLE;
    priceRappen: number;
    noChargeUntil: string | null;
}

export interface WeekdayPrice {
    type: ReservationPriceConfigType.WEEKDAY;
    mondayRappen: number;
    tuesdayRappen: number;
    wednesdayRappen: number;
    thursdayRappen: number;
    fridayRappen: number;
    saturdayRappen: number;
    sundayRappen: number;
    noChargeUntil: string | null;
}

export interface ReservationTimeACalendarDayReservationTimeAndPrice {
    type: ReservationTimeAndPriceConfigType.CALENDAR_DAY;
    validityStart: string;
    validityEnd: string;
    maxPurchaseQuantity: number;
    maxDaysPurchaseAheadInFuture: number;
    minValidityStart: string | null;
    priceConfig: SimplePrice | WeekdayPrice;
}

export type DayAndPriceConfig = ReservationTimeACalendarDayReservationTimeAndPrice;

export interface ParkingaboProductTemplateReservation
    extends ParkingaboProductTemplateBase {
    type: ParkingaboProductType.RESERVATION;
    dayAndPriceConfig: DayAndPriceConfig;
}

export interface ParkingaboProductTemplateGuestReservation
    extends ParkingaboProductTemplateBase {
    type: ParkingaboProductType.GUEST_RESERVATION;
    honestyCheckboxText: Message;
    customerRemarkFieldText: Message | null;
    maxHours: number;
    maxDaysPurchaseAheadInFuture: number;
    minValidityStart: string | null;
}

export interface ParkingaboProductTemplateSubscription
    extends ParkingaboProductTemplateBase {
    type: ParkingaboProductType.SUBSCRIPTION;
    intervalConfig: SubscriptionIntervalConfig;
}

export function formatZoneName(zone: ParkingaboZoneInfo): string {
    return [zone.name, zone.city].join(' – ');
}

export interface ParkingaboDuration {
    quantity: number;
    rappenPrice: number;
    maxPurchaseQuantity: number;
    maxDaysPurchaseAheadInFuture: number;
}

export interface SubscriptionIntervalConfig {
    howManyIntervalsYouCanStartSubscriptionInTheFuture: number;
    intervalSubscriptionAmountRappen: number;
}
