import * as React from 'react';
import {
    FieldValues,
    SubmitHandler,
    UnpackNestedValue,
    useForm,
    UseFormHandleSubmit,
    useFormState,
} from 'react-hook-form';
import {
    RequestStatus,
    ServerRequestState,
} from '../../lib/hooks/ServerStateHooks';
import { Language } from '../../lib/Localized';
import {
    addValidationErrorsToForm,
    FormGlobalViolationEntry,
    ValidationData,
} from './FormValidationHelpers';

interface GenericServerError {
    isGenericServerError: true;
}

interface GenericValidationError {
    isGenericServerError: false;
    issues: FormGlobalViolationEntry[];
}

export type GenericFormError = GenericValidationError | GenericServerError;

export function useEasyForm<D, ED extends ValidationData>(
    onDirtyStateChange: ((isDirty: boolean) => void) | undefined,
    submitState: ServerRequestState<D, ED> | undefined,
    language: Language,
    ...args: Parameters<typeof useForm>
) {
    const formInfo = useForm(...args);
    const formState = useFormState({ control: formInfo.control });
    const [
        genericSubmitError,
        setGenericSubmitError,
    ] = React.useState<GenericFormError | null>(null);

    React.useEffect(() => {
        return () => {
            if (onDirtyStateChange) {
                // cleanup state in case form is exited when dirty
                onDirtyStateChange(false);
            }
        };
    }, []);

    React.useEffect(() => {
        if (onDirtyStateChange) {
            onDirtyStateChange(formState.isDirty);
        }
    }, [formState.isDirty]);

    React.useEffect(() => {
        if (submitState?.status === RequestStatus.ERROR) {
            if (
                submitState.httpStatusCode === 400 &&
                submitState.data !== null
            ) {
                const globalError = addValidationErrorsToForm(
                    submitState.data,
                    formInfo.setError,
                    language,
                );
                setGenericSubmitError({
                    isGenericServerError: false,
                    issues: globalError,
                });
            } else {
                setGenericSubmitError({
                    isGenericServerError: true,
                });
            }
        }
    }, [submitState]);

    return {
        formInfo: {
            ...formInfo,
            handleSubmit: cookSubmitHandlerToStripEmptyStrings(
                formInfo.handleSubmit,
            ),
        },
        formState,
        genericSubmitError,
    };
}

export function setNullInitialValuesToEmptyStrings<K extends string>(
    initialValues: Partial<Record<K, string | number | null | undefined>>,
) {
    // the partial here is only to make the variable indexable by K
    const deNullified: Partial<Record<K, string>> = {};

    for (const key in initialValues) {
        if (initialValues[key] !== undefined) {
            // cannot map objects in a type safe way apparently..
            var val = (initialValues[key] ? initialValues[key] : '') as string;
            deNullified[key] = val;
        }
    }

    return deNullified;
}

function cookSubmitHandlerToStripEmptyStrings<TFieldValues extends FieldValues>(
    handleSubmit: UseFormHandleSubmit<TFieldValues>,
): UseFormHandleSubmit<TFieldValues> {
    const middleware: typeof handleSubmit = (onSubmit, onInvalid) => {
        const submitHandler = stripEmptyValuesFromFormData(onSubmit);

        return handleSubmit(submitHandler, onInvalid);
    };

    return middleware;
}

function stripEmptyValuesFromFormData<TFieldValues extends FieldValues>(
    onSubmit: SubmitHandler<TFieldValues>,
) {
    return (formData: { [key: string]: any }) => {
        const reducedFormFields = {
            ...formData,
        };

        Object.entries(formData).forEach(([field, value]) => {
            if (value === '') {
                reducedFormFields[field] = null;
            }
        });

        onSubmit(reducedFormFields as UnpackNestedValue<TFieldValues>);
    };
}
