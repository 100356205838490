import {
    Box,
    Checkbox,
    FormControlLabel,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Localized } from '../../../../common/hooks/LanguageProvider';
import { isPWA } from '../../../../lib/utils/PwaUtils';
import { useLoginRequest } from '../../api/ParkingaboLoginApi';
import {
    RequestStatus,
    useNavigateOnSuccess,
    useServerErrorEffect,
    useServerSuccessEffect,
} from '../../../../lib/hooks/ServerStateHooks';
import { ParkingaboLogin } from '../AppRoutes';
import { ParkingaboLogoHeader } from '../../components/layout/ParkingaboLogoHeader';
import { ParkingaboLink } from '../../components/ParkinaboLink';
import { ParkingaboFooter } from '../../components/layout/ParkingaboFooter';
import { useParkingaboServerWrite } from '../../api/ParkingaboApi';
import { FormEvent } from 'react';
import { LoadingButton } from '@mui/lab';
import { FeedbackPopup } from '../../components/FeedbackPopup';
import { ParkingaboButton } from '../../components/layout/ParkingaboButton';
import { isFormGlobalViolationEntry } from '../../../../common/utils/FormValidationHelpers';

export function LoginRoute({ login }: { login: ParkingaboLogin }) {
    const navigate = useNavigate();
    if (login.loggedIn) {
        navigate(`/`, { replace: true });
        return null;
    }
    return <LoginPage login={login} />;
}

function LoginPage({ login }: { login: ParkingaboLogin }) {
    const theme = useTheme();
    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const [userName, setUsername] = React.useState(
        decodeURIComponent(params.get('email') || ''),
    );
    const [userPassword, setPassword] = React.useState('');
    const [rememberMe, setRememberMe] = React.useState(true);
    const [loginState, sendLogin] = useLoginRequest();
    const [
        showVerificationMissing,
        setShowVerificationMissing,
    ] = React.useState(false);
    useServerSuccessEffect(loginState, () => login.setLoggedIn(true));
    useServerErrorEffect(loginState, errStatus => {
        if (errStatus === 403) {
            // use is authenticated but not yet verified
            setShowVerificationMissing(true);
        }
    });
    const [anotherLinkState, requestAnotherLink] = useParkingaboServerWrite<
        { email: string },
        never
    >(() => ({
        url: '/ui-api/parkingabo/resend-verification-link',
    }));

    useNavigateOnSuccess(anotherLinkState, '/register/email-sent');

    const hasError = loginState.status === RequestStatus.ERROR;
    const violation = hasError && loginState.data?.violations[0];
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
                overflowY: 'auto',
            }}
        >
            <ParkingaboLogoHeader />
            <Box
                component="form"
                onSubmit={(e: FormEvent<HTMLElement>) => {
                    e.preventDefault();
                    sendLogin({
                        email: userName,
                        password: userPassword,
                        rememberMe,
                        createRefreshToken: isPWA() && rememberMe,
                    });
                }}
                sx={{
                    padding: 3,
                }}
            >
                <TextField
                    label={
                        <Localized
                            de="E-Mail"
                            fr="E-mail"
                            it="E-mail"
                            en="Email"
                        />
                    }
                    value={userName}
                    onChange={e => setUsername(e.target.value)}
                    fullWidth={true}
                    type="email"
                    margin="normal"
                    autoComplete="email"
                />
                <TextField
                    label={
                        <Localized
                            de="Passwort"
                            fr="Mot de passe"
                            it="Password"
                            en="Password"
                        />
                    }
                    value={userPassword}
                    onChange={e => setPassword(e.target.value)}
                    fullWidth={true}
                    type="password"
                    autoComplete="current-password"
                    error={hasError}
                    helperText={
                        hasError ? (
                            violation &&
                            isFormGlobalViolationEntry(violation) ? (
                                <Localized
                                    {...violation.issue.humanReadableMessage
                                        .byLanguage}
                                />
                            ) : (
                                <Localized
                                    de="E-Mail und/oder Passwort ungültig."
                                    fr="E-mail et/ou mot de passe invalide."
                                    it="E-mail e/o password non validi."
                                    en="Invalid email and/or password."
                                />
                            )
                        ) : (
                            ' '
                        )
                    }
                />
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: 4,
                        alignItems: 'center',
                    }}
                >
                    <ParkingaboLink
                        to={`/request-password-reset${
                            userName.length > 0
                                ? `?email=${encodeURIComponent(userName)}`
                                : ''
                        }`}
                        sx={{
                            marginRight: 1,
                        }}
                    >
                        <Localized
                            de="Passwort vergessen?"
                            fr="Mot de passe oublié?"
                            it="Password dimenticata?"
                            en="Forgot the password?"
                        />
                    </ParkingaboLink>
                    <FormControlLabel
                        labelPlacement="start"
                        control={<Checkbox color="primary" />}
                        sx={{
                            textAlign: 'right',
                        }}
                        label={
                            <Typography
                                component="span"
                                sx={{
                                    fontSize: 14,
                                    color: theme.palette.primary.main,
                                    fontWeight: 700,
                                }}
                            >
                                <Localized
                                    de="Login Speichern"
                                    fr="Rester connecté"
                                    it="Ricordami"
                                    en="Remember me"
                                />
                            </Typography>
                        }
                        checked={rememberMe}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setRememberMe(e.target.checked)
                        }
                    />
                </Box>
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        marginTop: theme.spacing(2),
                        marginBottom: theme.spacing(5),
                    }}
                >
                    <LoadingButton
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={
                            userName.length <= 0 || userPassword.length <= 0
                        }
                        sx={{
                            width: '50%',
                            marginLeft: 'auto',
                        }}
                        loading={loginState.status === RequestStatus.PENDING}
                    >
                        <Localized
                            de="Anmelden"
                            fr="Connexion"
                            it="Accesso"
                            en="Login"
                        />
                    </LoadingButton>
                </Box>
                {login.subdomain !== null && (
                    <Box
                        sx={{
                            width: '80%',
                            textAlign: 'center',
                            margin: '0 auto',
                        }}
                    >
                        <ParkingaboLink
                            to={`/register${
                                userName.length > 0
                                    ? `?email=${encodeURIComponent(userName)}`
                                    : ''
                            }`}
                        >
                            <Localized
                                de="Noch kein Konto?"
                                fr="Pas encore un compte?"
                                it="Non ha ancora un conto?"
                                en="You don't have an account yet?"
                            />
                            <ParkingaboButton
                                variant="outlined"
                                sx={{ marginTop: 1 }}
                            >
                                <Localized
                                    de="Hier registrieren"
                                    fr="Enregistrez-vous ici"
                                    it="Registratevi qui"
                                    en="Sign up here"
                                />
                            </ParkingaboButton>
                        </ParkingaboLink>
                    </Box>
                )}
            </Box>
            <ParkingaboFooter />
            <FeedbackPopup
                open={showVerificationMissing}
                onConfirm={() => requestAnotherLink({ email: userName })}
                onAbort={() => setShowVerificationMissing(false)}
                title={
                    <Localized
                        de="E-Mail nicht bestätigt"
                        fr="E-mail pas confirmée"
                        it="E-mail non confermato"
                        en="Email not confirmed"
                    />
                }
                confirmLabel={
                    <Localized
                        de="Link senden"
                        fr="Envoyer le lien"
                        it="Invia link"
                        en="Send link"
                    />
                }
                abortLabel={
                    <Localized
                        de="Zurück"
                        fr="Retourner"
                        it="Indietro"
                        en="Back"
                    />
                }
            >
                <Typography sx={{ fontSize: 14 }}>
                    <Localized
                        de="Sie müssen Ihre E-Mail-Adresse verifizieren, bevor Sie sich anmelden können."
                        fr="Vous devez vérifier votre adresse e-mail avant de pouvoir vous connecter."
                        it="Prima di poter accedere deve verificare il suo indirizzo e-mail."
                        en="You must verify your e-mail address before you can log in."
                    />
                </Typography>
            </FeedbackPopup>
        </Box>
    );
}
