import { Message } from '../../../lib/Localized';

export interface ParkingaboProductListItem {
    contractId: number;
    name: Message;
    remark: String;
    zones: ParkingaboZoneInfo[];
    validFrom: string;
    validTo: string | null;
    priceRappen: number;
    type: ParkingaboProductType;
    state: ParkingaboProductState;
}

export interface ParkingaboZoneInfo {
    zoneId: number;
    name: string;
    city: string;
}

export const enum ParkingaboProductType {
    RESERVATION = 'RESERVATION',
    GUEST_RESERVATION = 'GUEST_RESERVATION',
    SUBSCRIPTION = 'SUBSCRIPTION',
}

export const enum ParkingaboProductState {
    EXPIRED = 'EXPIRED',
    ACTIVE = 'ACTIVE',
    FUTURE = 'FUTURE',
}
