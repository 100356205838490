export const ALLOWED_LANGUAGE_CODES: string[] = ['de', 'fr', 'it'];

export interface Translations<T> {
    [key: string]: T;
    de: T;
    fr: T;
    it: T;
}

export type Translation = () => string;
export type TranslationWithArgs1<T> = (arg1: T) => string;
export type TranslationWithArgs2<T, S> = (arg1: T, arg2: S) => string;
export type TranslationWithArgs3<T, S, U> = (
    arg1: T,
    arg2: S,
    arg3: U,
) => string;
