import * as numeral from 'numeral';
import * as langDeCh from './NumberFormatterLocales/de-ch';
import * as langDe from './NumberFormatterLocales/de';
import * as langFrCh from './NumberFormatterLocales/fr-ch';
import * as langItCh from './NumberFormatterLocales/it-ch';

const DEFAULT_LANGUAGE = 'de-ch';
numeral.register('locale', 'de-ch', langDeCh.language);
numeral.register('locale', 'de', langDe.language);
numeral.register('locale', 'fr-ch', langFrCh.language);
numeral.register('locale', 'it-ch', langItCh.language);

function getLocalizedNumeral(locale: string, n: number): Numeral {
    locale = locale.toLowerCase();
    const localeMap: { [locale: string]: string } = {
        de: 'de-ch',
        'de-ch': 'de-ch',
        fr: 'fr-ch',
        it: 'it-ch',
    };

    if (localeMap.hasOwnProperty(locale)) {
        numeral.locale(localeMap[locale]);
    } else {
        numeral.locale('de');
    }
    return numeral(n);
}

/**
 * format a currency amount in cents/rappen to a localized string
 * @param locale
 * @param cents
 * @param currency
 * @returns {string}
 */
export function currencyCentsToLocalPrice(
    locale: string,
    cents: number,
    currency: boolean = true,
): string {
    const value = cents / 100; // convert to chf
    const corrected = value >= 0.005 || value <= -0.005 ? value : 0;
    const str = getLocalizedNumeral(locale, corrected).format('0,0.00');

    return currency ? 'CHF ' + str : str;
}

export function numberToLocalPrice(
    locale: string,
    n: number,
    currency: boolean = true,
): string {
    const corrected = n >= 0.005 || n <= -0.005 ? n : 0;
    const str = getLocalizedNumeral(locale, corrected).format('0,0.00');

    return currency ? 'CHF\u00a0' + str : str;
}

export function numberToPrice(n: number, currency: boolean = true): string {
    return numberToLocalPrice(DEFAULT_LANGUAGE, n, currency);
}

export function pad(num: number, size: number): string {
    var s = num + '';
    while (s.length < size) {
        s = '0' + s;
    }
    return s;
}

export function format(
    locale: string,
    n: number,
    formatString?: string,
): string {
    return getLocalizedNumeral(locale, n).format(formatString);
}

export const leftPadZeroIfNotTwoDigits = (n: number): string => {
    if (n < 10) {
        return `0${Math.max(n, 0)}`;
    } else {
        return n.toString();
    }
};
