import { css } from '@emotion/css';
import * as React from 'react';
import { Clickable } from '../../ui/Clickable';
import { LicensePlateState } from './state/SharedState';

export type Translation = {
    de: React.ReactChild;
    fr: React.ReactChild;
    it: React.ReactChild;
    en: React.ReactChild;
};

export const txt: Texts = {
    lp: {
        type: {
            de: 'Fahrzeugtyp',
            fr: 'Type de véhicule',
            it: 'Tipo di veicolo',
            en: 'Vehicle type',
        },
        typeCar: {
            de: 'Motorwagen',
            fr: 'Voiture',
            it: 'Automobile',
            en: 'Car',
        },
        typeMotorcycle: {
            de: 'Motorrad',
            fr: 'Moto',
            it: 'Motocicletta',
            en: 'Motorcycle',
        },
        licensePlate: {
            de: 'Kennzeichen',
            fr: 'Plaque',
            it: 'Numero di targa',
            en: 'License plate',
        },
        licensePlateHint: {
            de: 'z.B. ZH123456',
            fr: 'p.ex. VD123456',
            it: 'p.es. TI123456',
            en: 'e.g. ZH123456',
        },
        mostUsedCountries: {
            de: 'Nachbarländer',
            fr: 'Pays voisins',
            it: 'Nazioni limitrofi',
            en: 'Neighboring countries',
        },
        allCountries: {
            de: 'Andere Länder',
            fr: 'Autres pays',
            it: 'Altre nazioni',
            en: 'Other countries',
        },
        delete: { de: 'Löschen', fr: 'Effacer', it: 'Elimina', en: 'Delete' },
        deleteDescription: {
            de: 'Möchten Sie das Fahrzeug unwiderrufbar löschen?',
            fr: 'Voulez-vous supprimer définitivement ce véhicule?',
            it: 'Vuole eliminare definitivamente questo veicolo?',
            en: 'Would you like to delete this vehicle permanently?',
        },
        addLicensePlate: {
            de: 'Weiteres Fahrzeug hinzufügen',
            fr: 'Ajouter un autre véhicule',
            it: 'Aggiungi un altro veicolo',
            en: 'Add another vehicle',
        },
        cars: {
            de: 'Fahrzeuge',
            fr: 'Véhicules',
            it: 'Veicoli',
            en: 'Vehicles',
        },
        lprNotificationTitle: {
            de: 'KENNZEICHENERKENNUNG',
            fr: 'RECONNAISSANCE DE PLAQUE',
            it: 'RICONOSCIMENTO TARGA',
            en: 'LICENSE PLATE RECOGNITION',
        },
        lprNotificationText: onClick => {
            return {
                de: (
                    <>
                        Bei{' '}
                        <Clickable
                            element={'span'}
                            className={css({ textDecoration: 'underline' })}
                            onClick={onClick}
                        >
                            Parkings mit dieser Funktion
                        </Clickable>{' '}
                        <br />
                        einfach ohne Ticket ein- und ausfahren.
                    </>
                ),
                fr: (
                    <>
                        Entrer et sortir sans ticket
                        <br />
                        dans les{' '}
                        <Clickable
                            element={'span'}
                            className={css({ textDecoration: 'underline' })}
                            onClick={onClick}
                        >
                            parkings dotés de cette fonction
                        </Clickable>
                        .
                    </>
                ),
                it: (
                    <>
                        Entrare e uscire senza prendere il ticket,
                        <br />
                        nei{' '}
                        <Clickable
                            element={'span'}
                            className={css({ textDecoration: 'underline' })}
                            onClick={onClick}
                        >
                            parcheggi provvisti di questa funzione
                        </Clickable>
                        .
                    </>
                ),
                en: (
                    <>
                        Enter and exit without a ticket,
                        <br />
                        in{' '}
                        <Clickable
                            element={'span'}
                            className={css({ textDecoration: 'underline' })}
                            onClick={onClick}
                        >
                            parkings equipped with this feature
                        </Clickable>{' '}
                        .
                    </>
                ),
            };
        },
        lprDeleteLpTitle: {
            de: 'Fahrzeug deaktivieren',
            fr: 'Désactiver le véhicule',
            it: 'Disattiva veicolo',
            en: 'Disable vehicle',
        },
        lprDeleteLpDesc: lp => {
            return {
                de: (
                    <>
                        {`Wollen Sie die Kennzeichenerkennung bei Parkings mit Schranken für das Kennzeichen `}
                        <b>{lp}</b>
                        {` deaktivieren?`}
                    </>
                ),
                fr: (
                    <>
                        {`Vous souhaitez désactiver la reconnaissance de l'immatriculation `}
                        <b>{lp}</b>
                        {` dans les parkings à barrières?`}
                    </>
                ),
                it: (
                    <>
                        {`Vuole disattivare il riconoscimento della targa `}
                        <b>{lp}</b>
                        {` nei parcheggi con barriere?`}
                    </>
                ),
                en: (
                    <>
                        {`Do you want to disable the recognition of the license plate `}
                        <b>{lp}</b>
                        {` in parkings with barriers?`}
                    </>
                ),
            };
        },
        lprActivateLpTitle: {
            de: 'Fahrzeug aktivieren',
            fr: 'Activer le véhicule',
            it: 'Attiva veicolo',
            en: 'Enable vehicle',
        },
        lprActivateLpDesc: lp => {
            return {
                de: (
                    <>
                        {`Wollen Sie die Kennzeichenerkennung bei Parkings mit Schranken für das Kennzeichen `}
                        <b>{lp}</b>
                        {` aktivieren?`}
                    </>
                ),
                fr: (
                    <>
                        {`Vous souhaitez activer la reconnaissance de l'immatriculation `}
                        <b>{lp}</b>
                        {` dans les parkings à barrières?`}
                    </>
                ),
                it: (
                    <>
                        {`Vuole attivare il riconoscimento della targa `}
                        <b>{lp}</b>
                        {` nei parcheggi con barriere?`}
                    </>
                ),
                en: (
                    <>
                        {`Do you want to enable the recognition of the license plate `}
                        <b>{lp}</b>
                        {` in parkings with barriers?`}
                    </>
                ),
            };
        },
        lprActivateLpFees: {
            de: 'Zuschlag: CHF 0.25 pro Transaktion',
            fr: 'Supplément: CHF 0.25 par transaction',
            it: 'Supplemento: CHF 0.25 per transazione',
            en: 'Surcharge: CHF 0.25 per transaction',
        },
        lprErrorTitle: {
            de: 'Fahrzeug nicht aktivierbar',
            fr: 'Véhicule non activable',
            it: 'Veicolo non attivabile',
            en: 'Vehicle not activatable',
        },
        lprErrorReasonOccupied: lp => {
            return {
                de: (
                    <>
                        {`Für das Fahrzeug `}
                        <b>{lp}</b>
                        {` wurde die Kennzeichenerkennung schon in einer anderen TWINT App aktiviert.`}
                    </>
                ),
                fr: (
                    <>
                        {`Pour le véhicule `}
                        <b>{lp}</b>
                        {`, la reconnaissance des plaques d'immatriculation a déjà été activée dans une autre appli TWINT.`}
                    </>
                ),
                it: (
                    <>
                        {`Per il veicolo `}
                        <b>{lp}</b>
                        {`, il riconoscimento della targa è già stato attivato in un'altra app TWINT.`}
                    </>
                ),
                en: (
                    <>
                        {`For vehicle `}
                        <b>{lp}</b>
                        {` the license plate recognition has already been activated in another TWINT app.`}
                    </>
                ),
            };
        },
        lprErrorReasonNotAllowed: lp => {
            return {
                de: (
                    <>
                        {`Das Fahrzeug `}
                        <b>{lp}</b>
                        {` kann nicht aktiviert werden, da es sich um ein Motorrad handelt.`}
                    </>
                ),
                fr: (
                    <>
                        {`Le véhicule `}
                        <b>{lp}</b>
                        {` ne peut pas être activé, puisque c'est une moto.`}
                    </>
                ),
                it: (
                    <>
                        {`Il veicolo `}
                        <b>{lp}</b>
                        {` non può essere attivato, dal momento che è una moto.`}
                    </>
                ),
                en: (
                    <>
                        {`The vehicle `}
                        <b>{lp}</b>
                        {` can not be activated, as it is a motorcycle.`}
                    </>
                ),
            };
        },
    },
    lpValidation: {
        allLettersUppercase: {
            de: 'Nur Grossbuchstaben erlaubt.',
            fr: 'Seulement lettres capitales autorisées.',
            it: 'Sono consentite solo lettere maiuscole.',
            en: 'Only uppercase letters are allowed.',
        },
        countryMustBeFL: {
            de: 'Das Kennzeichen muss  mit FL beginnen',
            fr: "L'immatriculation doit commencer par FL",
            it: 'Il numero di targa deve cominciare con FL',
            en: 'The license plate must start with FL',
        },
        hasMoreThanFiveNumbers: {
            de: 'Maximal 5 Zahlen erlaubt',
            fr: 'Maximum 5 chiffres autorisés',
            it: 'Sono ammessi al max. 5 numeri',
            en: 'Max 5 numbers permitted',
        },
        hasMoreThanSixNumbers: {
            de: 'Maximal 6 Zahlen erlaubt',
            fr: 'Maximum 6 chiffres autorisés',
            it: 'Sono ammessi al max. 6 numeri',
            en: 'Max 6 numbers permitted',
        },
        lettersAfterCanton: {
            de: 'Nur Zahlen nach Kantonskürzel erlaubt',
            fr: 'Seulement des chiffres autorisés après le code canton',
            it: 'Sono consentite solo cifre dopo il codice del cantone',
            en: 'Only numbers after canton abbreviation',
        },
        lettersAfterCountryFL: {
            de: 'Nur Zahlen nach FL-Kürzel erlaubt',
            fr: 'Seule les chiffres sont autorisés après le code FL',
            it: 'Sono consentite solo cifre dopo il codice FL',
            en: 'Only numbers after FL abbreviation',
        },
        minThreeLetters: {
            de: 'Mindestens 3 Zeichen eingeben',
            fr: 'Saisir au moins 3 caractères',
            it: 'Inserire almeno 3 caratteri',
            en: 'Enter at least 3 characters',
        },
        noValidCanton: {
            de: 'Keine gültige Kantonsbezeichnung',
            fr: "Le code du canton n'est pas valable",
            it: 'Il codice del cantone non è valido',
            en: 'No valid canton abbreviation',
        },
        noWhiteSpace: {
            de: 'Keine Leerzeichen erlaubt',
            fr: 'Espaces non autorisés',
            it: 'Non sono consentiti spazi',
            en: 'No spaces allowed',
        },
        mandatory: {
            de: 'Kennzeichen zwingend',
            fr: "L'immatriculation est obligatoire",
            it: 'Numero di targa obbligatorio',
            en: 'License plate mandatory',
        },
    },
    parkingsList: {
        parkingsListTitle: {
            de: 'Parkings mit Kennzeichenerkennung',
            fr: 'Parkings avec reconnaissance de plaque',
            it: 'Parcheggi con riconoscimento targa',
            en: 'Parkings with license plate recognition',
        },
    },
    lprSettings: {
        title: {
            de: 'Kennzeichenerkennung',
            fr: 'Reconnaissance de plaque',
            it: 'Riconoscimento targa',
            en: 'License plate recognition',
        },
        lprFunctionDescription: {
            de:
                'Mit dieser Funktion können Sie bei entsprechend ausgestatteten Parkings direkt ein- und ausfahren, ohne ein Ticket zu ziehen oder die Kasse zu bedienen.',
            fr:
                'Cette fonction vous permet de directement entrer et sortir des parkings convenablement équipés sans avoir à prendre un ticket ou passer à la caisse.',
            it:
                'Questa funzione vi permette di entrare e uscire direttamente dai parcheggi opportunamente attrezzati senza dover prendere un ticket o passare dalla cassa.',
            en:
                'With this function, you can drive directly in and out of appropriately equipped parkings without having to take a ticket or pay at the ticket machines.',
        },
        showParkingslist: {
            de: 'Parkingliste anzeigen',
            fr: 'Afficher liste des parkings',
            it: 'Mostra lista parcheggi',
            en: 'Show parkings list',
        },
        lprFeeDescription: {
            de: (
                <>
                    Zusätzlich zu den im jeweiligen Parking geltenden
                    Parkgebühren wird ein{' '}
                    <b>Zuschlag von CHF 0.25 pro Transaktion</b> erhoben.
                </>
            ),
            fr: (
                <>
                    En plus des frais de stationnement applicables dans le
                    parking concerné,{' '}
                    <b>un supplément de CHF 0.25 par transaction</b> sera
                    prélevé.
                </>
            ),
            it: (
                <>
                    In aggiunta alle tariffe di parcheggio in vigore nel
                    rispettivo parcheggio, viene riscosso{' '}
                    <b>un supplemento di 0.25 CHF per transazione</b>.
                </>
            ),
            en: (
                <>
                    In addition to the parking fees applicable in the given
                    parking, <b>a surcharge of CHF 0.25 per transaction</b> will
                    be charged.
                </>
            ),
        },
        toggleLPR: {
            de: 'Kennzeichenerkennung aktiv',
            fr: "Reconnaissance de l'immatriculation active",
            it: 'Riconoscimento della targa attivo',
            en: 'License plate recognition active',
        },
        deactivateLPRTitle: {
            de: 'KENNZEICHENERKENNUNG DEAKTIVIEREN',
            fr: 'DÉSACTIVER LA RECONNAISSANCE DE PLAQUE',
            it: 'DISATTIVARE RICONOSCIMENTO TARGA',
            en: 'DEACTIVATE LICENSE PLATE RECOGNITION',
        },
        deactivateLPRQuestion: {
            de: 'Möchten Sie die Kennzeichenerkennung wirklich deaktivieren?',
            fr:
                "Souhaitez-vous vraiment désactiver la reconnaissance de l'immatriculation?",
            it: 'Vuoi veramente disattivare il riconoscimento della targa?',
            en:
                'Do you really want to deactivate the license plate recognition?',
        },
    },
    lprActivation: {
        consentTitle: {
            de: 'KENNZEICHENERKENNUNG',
            fr: 'RECONNAISSANCE DE PLAQUE',
            it: 'RICONOSCIMENTO TARGA',
            en: 'LICENSE PLATE RECOGNITION',
        },
        consentTwintPermission: {
            de:
                'Diese Funktion erfordert die Berechtigung, Ihre TWINT App ohne weitere Bestätigung zu belasten.',
            fr:
                'Cette fonction nécessite une autorisation pour débiter votre appli TWINT sans autre confirmation.',
            it:
                'Questa funzione richiede il permesso di addebitare la sua app TWINT senza ulteriori conferme.',
            en:
                'This function requires authorization to debit your TWINT app without further confirmation.',
        },
        consentTwintAcceptNote: {
            de: 'Bitte akzeptieren Sie im nächsten Schritt diese Änderung.',
            fr: 'Veuillez accepter ce changement lors de la prochaine étape.',
            it:
                'Si prega di accettare questo cambiamento nel prossimo passaggio.',
            en: 'Please accept this change in the next step.',
        },
        pairingOngoingTitle: {
            de: 'AUTOMATISCHE ZAHLUNG',
            fr: 'PAIEMENT AUTOMATIQUE',
            it: 'PAGAMENTO AUTOMATICO',
            en: 'AUTOMATIC PAYMENT',
        },
        pairingOngoingDesc: {
            de: 'Die Aktivierung wird bearbeitet...',
            fr: "L'activation sera traité...",
            it: "L'attivazione viene elaborata...",
            en: 'The activation will be processed...',
        },
        pairingOngoingReopenIntent: {
            de: 'Erneut versuchen',
            fr: 'Essayer de nouveau',
            it: 'Riprova',
            en: 'Try again',
        },
        pairingSuccessTitle: {
            de: 'AKTIVIERUNGSBESTÄTIGUNG',
            fr: "CONFIRMATION DE L'ACTIVATION",
            it: "CONFERMA DELL'ATTIVAZIONE",
            en: 'ACTIVATION CONFIRMATION',
        },
        pairingSuccessDesc: {
            de:
                'Die Kennzeichenerkennung wurde erfolgreich aktiviert und kann ab sofort benutzt werden.',
            fr:
                "La reconnaissance des plaques d'immatriculation a été activée avec succès et peut être immédiatement utilisée.",
            it:
                'Il riconoscimento della targa è stato attivato con successo e può essere utilizzato immediatamente.',
            en:
                'License plate recognition has been successfully activated and can be used from now on.',
        },
        pairingSuccessDescShort: {
            de: 'Die Kennzeichenerkennung wurde erfolgreich aktiviert.',
            fr:
                "La reconnaissance des plaques d'immatriculation a été activée avec succès.",
            it: 'Il riconoscimento della targa è stato attivato con successo.',
            en: 'License plate recognition has been successfully activated.',
        },
        pairingSingleLpOccupied: (
            lp: LicensePlateState.LicensePlate,
        ): Translation => {
            return {
                de: `Das Fahrzeug ${lp.licensePlateNr} konnte nicht aktiviert werden, da es bereits in einer anderen TWINT App aktiv ist.`,
                fr: `Le véhicule ${lp.licensePlateNr} n'a pas pu être activé, car il est déjà actif dans une autre appli TWINT.`,
                it: `Il veicolo ${lp.licensePlateNr} non ha potuto essere attivato, in quanto è già attivo in un'altra app TWINT.`,
                en: `The vehicle ${lp.licensePlateNr} could not be activated, as it is already active in another TWINT app.`,
            };
        },
        pairingMultipleLpsOccupied: (
            lps: LicensePlateState.LicensePlate[],
        ): Translation => {
            return {
                de: `Die Fahrzeuge ${lps
                    .map(lp => lp.licensePlateNr)
                    .join(
                        ', ',
                    )} konnten nicht aktiviert werden, da sie bereits in einer anderen TWINT App aktiv sind.`,
                fr: `Les véhicules ${lps
                    .map(lp => lp.licensePlateNr)
                    .join(
                        ', ',
                    )} n'ont pas pu être activés, car ils sont déjà actifs dans une autre application TWINT.`,
                it: `I veicoli ${lps
                    .map(lp => lp.licensePlateNr)
                    .join(
                        ', ',
                    )} non hanno potuto essere attivati, in quanto sono già attivi in un'altra app TWINT.`,
                en: `The vehicles ${lps
                    .map(lp => lp.licensePlateNr)
                    .join(
                        ', ',
                    )} could not be activated, as they are already active in another TWINT app.`,
            };
        },
        pairingSingleLpNotAllowed: (
            lp: LicensePlateState.LicensePlate,
        ): Translation => {
            return {
                de: `Das Fahrzeug ${lp.licensePlateNr} konnte nicht aktiviert werden, da es sich um ein Motorrad handelt.`,
                fr: `Le véhicule ${lp.licensePlateNr} n'a pas pu être activé, puisque c'est une moto.`,
                it: `Il veicolo ${lp.licensePlateNr} non ha potuto essere attivato, dal momento che è una moto.`,
                en: `The vehicle ${lp.licensePlateNr} could not be activated, as it is a motorcycle`,
            };
        },
        pairingMultipleLpsNotAllowed: (
            lps: LicensePlateState.LicensePlate[],
        ): Translation => {
            return {
                de: `Die Fahrzeuge ${lps
                    .map(lp => lp.licensePlateNr)
                    .join(
                        ', ',
                    )} konnten nicht aktiviert werden, da es sich um Motorräder handelt.`,
                fr: `Les véhicules ${lps
                    .map(lp => lp.licensePlateNr)
                    .join(
                        ', ',
                    )} n'ont pas pu être activés, puisque ce sont des motos.`,
                it: `I veicoli ${lps
                    .map(lp => lp.licensePlateNr)
                    .join(
                        ', ',
                    )} non hanno potuto essere attivati, dal momento che sono delle moto`,
                en: `The vehicles ${lps
                    .map(lp => lp.licensePlateNr)
                    .join(
                        ', ',
                    )} could not be activated, as they are motorcycles.`,
            };
        },
        pairingErrorTitle: {
            de: 'FEHLER',
            fr: 'ERREUR',
            it: 'ERRORE',
            en: 'ERROR',
        },
        pairingErrorDesc: {
            de: 'Die Kennzeichenerkennung konnte nicht aktiviert werden.',
            fr:
                "La reconnaissance des plaques d'immatriculation n'a pas pu être activée.",
            it: 'Il riconoscimento della targa non ha potuto essere attivato.',
            en: 'License plate recognition could not be activated.',
        },
        pairingErrorReasonAbort: {
            de:
                'Sie haben die Aktivierung der automatischen Zahlung abgebrochen.',
            fr: "Vous avez annulé l'activation du paiement automatique.",
            it: "Avete annullato l'attivazione del pagamento automatico.",
            en: 'You have cancelled the activation of the automatic payment.',
        },
        pairingErrorReasonIntent: {
            de:
                'Bitte gehen Sie zurück zur TWINT App und wiederholen Sie den Vorgang, indem Sie den Bereich Parking erneut auswählen.',
            fr:
                "Veuillez retourner à l'app TWINT et répétez le processus en sélectionnant à nouveau la section Parking.",
            it:
                "Voglia tornare nell'app TWINT e ripetere il processo selezionando nuovamente la sezione Parking.",
            en:
                'Please return to the TWINT app and repeat the process by selecting the Parking section again.',
        },
        pairingErrorReasonNoCar: {
            de:
                'Zur Aktivierung von TWINT LPR ist mindestens ein Motorwagen erforderlich. Motorräder können nicht aktiviert werden.',
            fr:
                'Au moins une voiture est nécessaire pour activer TWINT LPR. Les motos ne peuvent pas être activées.',
            it:
                "Per attivare TWINT LPR è necessaria almeno un'automobile. Le moto non possono essere attivate.",
            en:
                'At least one car is required to activate TWINT LPR. Motorbikes cannot be activated.',
        },
        pairingAbortTitle: {
            de: 'ABBRUCH',
            fr: 'ANNULATION',
            it: 'ANNULLAMENTO',
            en: 'CANCELLATION',
        },
        pairingAbortDesc: {
            de: 'Die Aktivierung wird abgebrochen...',
            fr: "L'activation sera annulée...",
            it: "L'attivazione verrà interrotta...",
            en: 'The activation will be cancelled...',
        },
    },
    onboarding: {
        lpForEnforcement: {
            de:
                'Ihr Kennzeichen dient zur Kontrolle. Die korrekte Eingabe ist deshalb wichtig.',
            fr:
                'Votre numéro d’immatriculation sert à des fins de contrôle. Il est donc essentiel que la saisie soit correcte.',
            it:
                'La sua targa ha funzioni di controllo. Per questo è importante inserirla correttamente.',
            en:
                'Your registration number is used for control purposes. It is therefore important that you enter the correct number.',
        },
        atLeastOneCar: {
            de: 'Sie müssen mindestens ein Kennzeichen hinzufügen.',
            fr: 'Vous devez ajouter au moins un numéro d’immatriculation.',
            it: 'Deve inserire almeno una targa.',
            en: 'You must enter at least one registration number.',
        },
    },
    checkin: {
        parkDuration: {
            de: 'Gültig bis',
            fr: "Valable jusqu'à",
            it: 'Validità fino a',
            en: 'Valid until',
        },
        until: { de: 'bis', fr: "jusqu'à", it: 'fino a', en: 'until' },
        price: { de: 'Preis', fr: 'Prix', it: 'Prezzo', en: 'Price' },
        payNow: {
            de: 'Parkvorgang starten',
            fr: 'Démarrer le stationnement',
            it: 'Avvia sosta',
            en: 'Start transaction',
        },
        buyParkTicket: {
            de: 'Parkticket kaufen',
            fr: 'Acheter un ticket de stationnement',
            it: 'Acquista ticket di parcheggio',
            en: 'Buy car park ticket',
        },
        enterTime: {
            de: 'Parkdauer einstellen',
            fr: 'Sélectionner la durée',
            it: 'Selezionare la durata',
            en: 'Select parking time',
        },
        operatorName: {
            de: 'Betreiber',
            fr: 'Exploitant',
            it: 'Gestore',
            en: 'Operator',
        },
        cityName: { de: 'Ort', fr: 'Lieu', it: 'Località', en: 'Location' },
        maxParkTime: {
            de: 'Maximale Parkdauer',
            fr: 'Durée maximale',
            it: 'Durata massima',
            en: 'Maximum parking time',
        },
        confirmAmount: {
            de:
                'Bestätigen Sie bitte den zu bezahlenden Betrag für den Parkvorgang in der TWINT-App.',
            fr:
                "Confirmer dans l'appli TWINT le montant à payer pour le stationnement.",
            it: "Confermare nell'app TWINT l'importo da pagare per la sosta.",
            en: 'Confirm the fee for the transaction in the TWINT app.',
        },
        zoneName: { de: 'Zone', fr: 'Zone', it: 'Zona', en: 'Zone' },
        showReceipt: {
            de: 'Quittung anzeigen',
            fr: 'Afficher le reçu',
            it: 'Mostra ricevuta',
            en: 'Show receipt',
        },
        urlUsed: {
            de:
                'Diese Seite darf nur einmal aufgerufen werden. Bitte scannen Sie erneut den QR-Code.',
            fr:
                "Cette page ne peut être appelée qu'une seule fois. Scannez à nouveau le code QR.",
            it:
                'Questa pagina può essere richiamata solo una volta. Scansionare nuovamente il codice QR.',
            en:
                'This page may only be called once. Please scan the QR code again.',
        },
        expired: {
            de:
                'Die Seite war zu lange inaktiv. Bitte gehen Sie zurück zur TWINT-App und wiederholen Sie den Vorgang.',
            fr:
                "Cette page est restée inactive trop longtemps. Veuillez retourner à l'app TWINT et répéter le processus.",
            it:
                "La pagina è rimasta inattiva troppo a lungo. Voglia tornare nell'app TWINT e ripetere il processo.",
            en:
                'The page has been inactive for too long. Please return to the TWINT app and repeat the process.',
        },
        transactionRunning: {
            de:
                'Mit diesem Kennzeichen läuft schon ein Parkvorgang in einem anderen Konto.',
            fr:
                'Avec cette immatriculation un stationnement est déjà actif dans un autre compte.',
            it:
                "Con questo numero di targa è già attiva una sosta in un'altro conto.",
            en:
                'A park transaction is already running with this license plate in another account.',
        },
        unpairedQrCodeError: {
            de:
                'Dieser QR-Code ist leider nicht korrekt eingerichtet. Um das zu beheben, benötigen wir den Standort dieses QR-Codes. Dazu brauchen wir Ihre Hilfe: erteilen Sie eine (einmalige) Freigabe Ihres aktuellen Standorts.',
            fr:
                "Malheureusement, ce code QR n'a pas été configuré correctement. Pour résoudre ce problème, nous avons besoin de la position exacte de ce code QR. À cette fin, nous vous invitons à partager (une seule fois) votre position actuelle.",
            it:
                'Purtroppo questo codice QR non è stato configurato correttamente. Per risolvere il problema, abbiamo bisogno della posizione esatta del codice QR. A questo scopo la invitiamo a condividere (solo una volta) la sua posizione attuale.',
            en:
                'This QR-Code is not correctly configured. To be able to fix this, we need to know the exact location of this QR-Code. For this, we need your help: please share your location (only once).',
        },
        unpairedQrCodeShareLocation: {
            de: 'Standort teilen',
            fr: 'Partager la position',
            it: 'Condividi posizione',
            en: 'Share location',
        },
        unpairedQrCodeGeoSent: {
            de:
                'Standort erfolgreich übermittelt. Vielen Dank für Ihre Hilfe. In den nächsten Tagen wird der Fehler behoben, so dass der QR-Code das nächste Mal funktioniert.',
            fr:
                "Position transmise correctement. Merci pour votre aide. Dans les prochains jours, l'erreur sera corrigée afin que le code QR fonctionne correctement.",
            it:
                "Posizione trasmessa correttamente. Grazie per il suo aiuto. Nei prossimi giorni l'errore verrà corretto, in modo che la prossima volta il codice QR funzioni correttamente.",
            en:
                'Location sent. Thank you for your help. In the next days the error will be corrected, so that the next time the QR code works properly.',
        },
        unpairedQrCodeGeoNotSent: {
            de: 'Standort konnte nicht übermittelt werden.',
            fr: "La position n'a pas pu être transmise.",
            it: 'La posizione non ha potuto essere trasmessa.',
            en: 'Location could not be transmitted.',
        },
        close: { de: 'Weiter', fr: 'Continuer', it: 'Avanti', en: 'Continue' },
        immediateParkForThisLicensePlateAndZone: {
            de:
                'In dieser Zone und mit diesem Kennzeichen haben Sie soeben einen Parkvorgang beendet.',
            fr:
                'Vous venez de terminer une procédure de stationnement dans cette zone avec l’immatriculation sélectionnée.',
            it:
                'Con questo numero di targa avete appena terminato una sosta in questa zona.',
            en:
                'With this license plate you just finished a parking transaction in this zone.',
        },
        subsequentPaymentForbiddenAnyway: {
            de:
                'Nachzahlungsverbot gilt in jedem Fall auch bei der Nutzung von Parkingpay und bei den Gratis-Zeiten.',
            fr:
                "Linterdiction de payer à nouveau s'applique dans tous les cas, même avec l'utilisation de Parkingpay et dans les temps gratuites.",
            it:
                'Il divieto di rinnovare il pagamento vale anche per Parkingpay e i tempi gratuiti.',
            en:
                'The prohibition to renew the payment also applies in case of using the Parkingpay and during the free times.',
        },
        title: {
            de: 'Nachzahlen verboten',
            fr: 'Interdit de payer à nouveau',
            it: 'Proibito ripagare',
            en: 'Payment renewal prohibited',
        },
        whatYouShouldDo: {
            de:
                'Nach dem Ablauf eines Parkvorgangs muss das Fahrzeug zuerst wieder in den Verkehr eingefügt werden; ist dies nicht der Fall und wird ein neuer Parkvorgang gestartet, muss trotz laufendem Parkvorgang mit einer Parkbusse gerechnet werden.',
            fr:
                'Au terme de chaque procédure de stationnement, le véhicule doit être remis en circulation. Si tel n’est pas le cas et qu’une nouvelle procédure de stationnement est démarrée, une amende d’ordre peut être émise par l’autorité de contrôle.',
            it:
                'Dopo la fine di ogni sosta, il veicolo dev’essere rimesso in circolazione; se questo non avviene e una nuova sosta viene avviata, si può andare in contro a una contravvenzione, nonostante la sosta sia attiva.',
            en:
                'After the end of each parking transaction, the vehicle must be re-inserted in the traffic; if this is not the case and a new parking transaction is started, a parking fine must be expected despite active parking transaction.',
        },
        parkingProhibitedTitle: {
            de: 'Parkverbot',
            fr: 'Interdiction de stationnement',
            it: 'Divieto di parcheggio',
            en: 'Parking prohibited',
        },
        parkingProhibitedDescription: {
            de:
                'Zurzeit darf in dieser Zone mit diesem Fahrzeug nicht parkiert werden.',
            fr:
                'Actuellement, il est interdit de se garer dans cette zone avec ce véhicule.',
            it:
                'Attualmente non è permesso parcheggiare questo veicolo in questa zona.',
            en:
                "Currently it's not allowed to park in this zone with this vehicle.",
        },
        closeWindow: {
            de: 'Schliessen',
            fr: 'Fermer',
            it: 'Chiudi',
            en: 'Close',
        },
        hour: {
            de: 'Stunde',
            fr: 'heure',
            it: 'ora',
            en: 'hour',
        },
        hours: {
            de: 'Stunden',
            fr: 'heures',
            it: 'ore',
            en: 'hours',
        },
        day: {
            de: 'Tag',
            fr: 'jour',
            it: 'giorno',
            en: 'day',
        },
        days: {
            de: 'Tage',
            fr: 'jours',
            it: 'giorni',
            en: 'days',
        },
        week: {
            de: 'Woche',
            fr: 'semaine',
            it: 'settimana',
            en: 'week',
        },
        weeks: {
            de: 'Wochen',
            fr: 'semaines',
            it: 'settimane',
            en: 'weeks',
        },
        month: {
            de: 'Monat',
            fr: 'mois',
            it: 'mese',
            en: 'month',
        },
        months: {
            de: 'Monate',
            fr: 'mois',
            it: 'mesi',
            en: 'months',
        },
        purchasePermit: {
            de: 'Bewilligung kaufen',
            fr: 'Acheter autorisation',
            it: 'Acquista autorizzazione',
            en: 'Purchase permit',
        },
        additionalInfos: {
            de: 'Zusätzliche Informationen',
            fr: 'Informations complémentaires',
            it: 'Informazioni supplementari',
            en: 'Additional information',
        },
        zones: {
            de: 'Zonen',
            fr: 'Zones',
            it: 'Zone',
            en: 'Zones',
        },
    },
    receipt: {
        terminate: {
            de: 'Parkvorgang beenden',
            fr: 'Terminer le stationnement',
            it: 'Termina la sosta',
            en: 'End parking time',
        },
        parkingTime: {
            de: 'Parkzeit',
            fr: 'Temps de stationnement',
            it: 'Tempo di parcheggio',
            en: 'Parking time',
        },
        from: { de: 'von', fr: 'du', it: 'dal', en: 'from' },
        to: { de: 'bis', fr: 'au', it: 'al', en: 'to' },
        terminationAlertInfo: {
            de:
                'Möchten Sie wirklich den Parkvorgang im Voraus beenden? Die Preisdifferenz wird Ihnen zurückerstattet.',
            fr:
                "Voulez-vous vraiment arrêter le stationnement à l'avance? La différence de prix vous sera remboursée.",
            it:
                'Vuole veramente terminare la sosta? La differenza di prezzo le verrà rimborsata.',
            en:
                'Do you really want to stop the park transaction in advance? The price difference will be refunded.',
        },
        downloadReceipt: {
            de: 'Beleg anzeigen',
            fr: 'Afficher le reçu',
            it: 'Mostra la ricevuta',
            en: 'Display receipt',
        },
        time: {
            de: 'Uhr',
            fr: '',
            it: '',
            en: '',
        },
        terminated: (amount: number) => {
            return {
                de: `Die Parkzeit ist beendet. ${
                    amount > 0 ? 'Der Betrag wurde erfolgreich abgebucht.' : ''
                }`,
                fr: `Le stationnement est terminé. ${
                    amount > 0 ? 'Le montant dû a été débité.' : ''
                }`,
                it: `La sosta è terminata. ${
                    amount > 0 ? "L'importo dovuto è stato addebitato." : ''
                }`,
                en: `Park transaction terminated. ${
                    amount > 0 ? 'The amount due has been debited.' : ''
                }`,
            };
        },
    },
    outstandingAmount: {
        outstandingAmountTitle: {
            de: 'OFFENER BETRAG',
            fr: 'MONTANT NON ACQUITTÉ',
            it: 'IMPORTO SCOPERTO',
            en: 'OUTSTANDING AMOUNT',
        },
        outstandingAmountText: (outstandingAmount: number) => ({
            de: `Ihre letzten Parkgebühren konnten nicht belastet werden. Der ausstehende Betrag von CHF ${outstandingAmount.toFixed(
                2,
            )} muss gedeckt sein, bevor neue Transaktionen durchgeführt werden können.`,
            fr: `Vos derniers frais de stationnement n'ont pas pu être débités. Le montant impayé de ${outstandingAmount.toFixed(
                2,
            )} CHF doit être payé avant que de nouvelles transactions puissent être effectuées.`,
            it: `Le sue ultime tasse di parcheggio non hanno potuto essere addebitate. Prima di poter effettuare nuove transazioni, l'importo dovuto pari a CHF ${outstandingAmount.toFixed(
                2,
            )} deve venir coperto.`,
            en: `Your last parking fees could not be charged. The outstanding amount of CHF ${outstandingAmount.toFixed(
                2,
            )} must be covered before new transactions can be made.`,
        }),
        retryPayment: {
            de: 'Zahlung erneut versuchen',
            fr: 'Retenter le paiement',
            it: 'Riprova pagamento',
            en: 'Try payment again',
        },
        renewAlias: {
            de: 'Automatische Zahlung erneuern',
            fr: 'Renouveler le paiement automatique',
            it: 'Rinnova pagamento automatico',
            en: 'Renew automatic payment',
        },
        failedAttemptTitle: {
            de: 'VERSUCH GESCHEITERT',
            fr: 'TENTATIVE ÉCHOUÉE',
            it: 'TENTATIVO FALLITO',
            en: 'FAILED ATTEMPT',
        },
        failedAttemptText: {
            de:
                'Der Versuch, den ausstehenden Betrag zu belasten, blieb leider erfolglos.',
            fr:
                "Malheureusement, la tentative de débiter le montant restant n'a pas abouti.",
            it:
                "Purtroppo il tentativo di addebitare l'importo scoperto non è andato a buon fine.",
            en:
                'Unfortunately, the attempt to debit the outstanding amount was unsuccessful.',
        },
        restartSession: {
            de:
                'Bitte gehen Sie zurück zur TWINT App und wiederholen Sie den Vorgang, indem Sie den Bereich Parking erneut auswählen.',
            fr:
                "Veuillez retourner à l'app TWINT et répétez le processus en sélectionnant à nouveau la section Parking.",
            it:
                "Voglia tornare nell'app TWINT e ripetere il processo selezionando nuovamente la sezione Parking.",
            en:
                'Please return to the TWINT app and repeat the process by selecting the Parking section again.',
        },
        contactIssuer: {
            de:
                'Wenn Sie TWINT Prepaid nutzen, prüfen Sie bitte, ob Sie genügend Guthaben haben. In den anderen Fällen wenden Sie sich bitte an Ihr Finanzinstitut, um den Grund für die Ablehnung der Belastung zu klären.',
            fr:
                'Si vous utilisez le service prépayé TWINT, veuillez vérifier que votre solde disponible est suffisant. Dans les autres cas, veuillez contacter votre institution financière pour clarifier la raison du refus de débit.',
            it:
                "Se utilizza TWINT Prepaid verifichi che il saldo disponibile sia sufficiente. Negli altri casi voglia contattare il suo Istituto finanziario per chiarire il motivo del rifiuto dell'addebito.",
            en:
                'If you are using TWINT Prepaid, please verify that you have sufficient available balance. In other cases, please contact your financial institution to clarify the reason for the debit refusal.',
        },
        askToRenewAlias: {
            de:
                'Um dieses Problem zu lösen, bitten wir Sie, die automatische Zahlung zu erneuern, indem Sie auf die Schaltfläche hier unten drücken.',
            fr:
                'Pour résoudre ce problème, nous vous invitons à renouveler le paiement automatique en appuyant sur le bouton ci-dessous.',
            it:
                'Per risolvere il problema, la invitiamo a rinnovare il pagamento automatico, premendo il tasto qui sotto.',
            en:
                'To solve this problem, we invite you to renew your automatic payment by pressing the button below.',
        },
        reasonInsufficientFunds: {
            de:
                'Der Versuch, den ausstehenden Betrag zu belasten, war leider nicht erfolgreich, da die notwendigen Mittel nicht vorhanden sind.',
            fr:
                "Malheureusement, la tentative de débiter le montant restant n'a pas abouti, car les fonds nécessaires ne sont pas disponibles.",
            it:
                "Purtroppo il tentativo di addebitare l'importo scoperto non è andato a buon fine, in quanto i fondi necessari non sono disponibili.",
            en:
                'Unfortunately, the attempt to debit the outstanding amount was unsuccessful, as the necessary funds are not available.',
        },
        topUpInstructions: {
            de:
                'Wenn Sie TWINT Prepaid nutzen, müssen Sie Ihr Guthaben zunächst in der TWINT-App aufladen. In den anderen Fällen prüfen Sie das Guthaben des mit der TWINT-App Ihres Finanzinstituts verbundenen Kontos (oder der Karte).',
            fr:
                "Si vous utilisez TWINT Prepaid, vous devez d'abord recharger votre solde dans l'application TWINT. Dans les autres cas, vérifiez la disponibilité du compte (ou de la carte) lié à l'application TWINT de votre institution financière.",
            it:
                "Se utilizza TWINT Prepaid deve prima ricaricare il saldo nell'app TWINT. Negli altri casi verifichi la disponibilità del conto (risp. della carta) collegato all'app TWINT del suo istituto finanziario.",
            en:
                'If you use TWINT Prepaid, you must first recharge your balance in the TWINT app. In other cases, check the balance of the account (or card) linked to the TWINT app of your financial institution.',
        },
        paymentSuccessfulTitle: {
            de: 'ZAHLUNG ERFOLGREICH',
            fr: 'PAIEMENT EFFECTUÉ',
            it: 'PAGAMENTO ESEGUITO',
            en: 'PAYMENT COMPLETED',
        },
        paymentSuccessfullText: {
            de: 'Der fällige Betrag wurde erfolgreich bezahlt.',
            fr: 'Le montant dû a été payé avec succès.',
            it: "L'importo dovuto è stato pagato con successo. ",
            en: 'The amount due has been successfully paid.',
        },
        collectAmount: {
            de: 'Einziehung des ausstehenden Betrags',
            fr: 'Recouvrement du montant en souffrance',
            it: "Riscossione dell'importo dovuto",
            en: 'Collecting outstanding amount',
        },
    },
    general: {
        dayShort: { de: 'Tag', fr: 'j', it: 'g', en: 'day' },
        daysShort: { de: 'Tage', fr: 'j', it: 'g', en: 'days' },
        hoursShort: { de: 'Std.', fr: 'h', it: 'h', en: 'h' },
        minutesShort: { de: 'Min.', fr: 'min.', it: 'min.', en: 'm' },
        secondsShort: { de: 'S.', fr: 's', it: 's', en: 's' },
        description: {
            de: 'Bezeichnung',
            fr: 'Désignation',
            it: 'Descrizione',
            en: 'Description',
        },
        details: {
            de: 'Details',
            fr: 'Détails',
            it: 'Dettagli',
            en: 'Details',
        },
        activate: {
            de: 'Aktivieren',
            fr: 'Activer',
            it: 'Attiva',
            en: 'Activate',
        },
        permitValidity: {
            de: 'Gültigkeit',
            fr: 'Validité',
            it: 'Validità',
            en: 'Validity',
        },
        back: { de: 'Zurück', fr: 'Retour', it: 'Indietro', en: 'Back' },
        close: { de: 'Schliessen', fr: 'Fermer', it: 'Chiudi', en: 'Close' },
        ok: { de: 'OK', fr: 'OK', it: 'OK', en: 'OK' },
        confirm: {
            de: 'Bestätigen',
            fr: 'Confirmer',
            it: 'Conferma',
            en: 'Confirm',
        },
        confirmAmount: {
            de: 'Bestätigen Sie den zu bezahlenden Betrag in der TWINT-App.',
            fr: "Confirmer dans l'appli TWINT le montant à payer.",
            it: "Confermare nell'app TWINT l'importo da pagare.",
            en: 'Confirm the fee for the transaction in the TWINT app.',
        },
        continue: {
            de: 'Weiter',
            fr: 'Continuer',
            it: 'Continua',
            en: 'Continue',
        },
        cancel: { de: 'Abbrechen', fr: 'Annuler', it: 'Annulla', en: 'Cancel' },
        deactivate: {
            de: 'Deaktivieren',
            fr: 'Désactiver',
            it: 'Disattiva',
            en: 'Deactivate',
        },
        hide: {
            de: 'Ausblenden',
            fr: 'Cacher',
            it: 'Nascondi',
            en: 'Hide',
        },
        tryAgain: {
            de: 'Erneut versuchen',
            fr: 'Essayer de nouveau',
            it: 'Riprova',
            en: 'Try again',
        },
        useMeter: {
            de: 'Leider müssen Sie zur Zeit an der Parkuhr / Kasse bezahlen.',
            fr: 'Malheureusement, vous devez payer au parcmètre en ce moment.',
            it: 'Purtroppo al momento deve pagare al parchimetro.',
            en:
                'Unfortunately, at the moment you have to pay at the parking meter.',
        },
        error: {
            de: 'Es ist ein Problem aufgetreten.',
            fr: 'Il y avait un problème.',
            it: 'Si è verificato un problema.',
            en: 'A problem occured.',
        },
        errorNonActivePermit: {
            de: 'Es ist nicht mehr möglich, diese Bewilligung zu erwerben.',
            fr: "Il n'est plus possible d'acheter cette autorisation.",
            it: "Non è più possibile acquistare quest'autorizzazione.",
            en: 'It is no longer possible to purchase this permit.',
        },
        errorNonActiveZone: {
            de:
                'Es ist nicht möglich, in dieser Zone einen Parkvorgang zu starten',
            fr:
                "Il n'est pas possible de lancer une procédure de stationnement dans cette zone",
            it: 'Non è possibile avviare una sosta in questa zona',
            en: 'It is not possible to start a procedure in this zone',
        },
        contactOperatorCallToAction: {
            de:
                'Bitte kontaktieren Sie den Betreiber für weitere Informationen:',
            fr: "Veuillez contacter l'exploitant pour plus d'informations:",
            it: 'Per ulteriori informazioni si rivolga al gestore:',
            en: 'Please contact the operator for more information:',
        },
        wereSorry: {
            de: 'Wir bitten Sie um Ihr Verständnis.',
            fr: 'Nous nous excusons pour la gêne occasionnée.',
            it: "Ci scusiamo per l'inconveniente.",
            en: 'We apologize for any inconvenience.',
        },
        backToPortraitMode: {
            de: 'Bitte wechseln Sie zurück zum Hochformatmodus.',
            fr: 'Veuillez revenir en mode portrait.',
            it: 'Voglia tornare alla modalità verticale.',
            en: 'Please rotate back to portrait mode.',
        },
        lprAdvertisement: {
            de: 'Mit TWINT ticketlos ins Parkhaus ein-/ausfahren?',
            fr: 'Entrer/sortir du parking sans ticket avec TWINT?',
            it: "Entrare/uscire senza ticket dall'autosilo con TWINT?",
            en: 'Enter/exit the car park ticket-free with TWINT?',
        },
        lprAdvertisementWithParking: {
            de: 'mit TWINT ticketlos ein-/ausfahren?',
            fr: 'entrer/sortir sans ticket avec TWINT?',
            it: 'entrare/uscire senza ticket con TWINT?',
            en: 'enter/exit ticket-free with TWINT?',
        },
        barrierGateZoneInfoNoLpr: {
            de: 'Möchten Sie hier bequem und ticketlos ein- und ausfahren?',
            fr:
                'Vous souhaitez entrer et sortir ici confortablement sans ticket?',
            it: 'Vuole entrare e uscire qui comodamente senza ticket?',
            en:
                'Would you like to enter and exit here comfortably without a ticket?',
        },
        barrierGateZoneInfoWithLpr: {
            de:
                'Hier können Sie bequem ticketlos per Kenn\u00ADzeichen\u00ADerkennung ein- und ausfahren.',
            fr:
                "Ici, vous pouvez entrer et sortir confortablement sans ticket grâce à la reconnaissance de l'immatriculation.",
            it:
                'Qui può entrare e uscire comodamente senza ticket, tramite riconoscimento della targa.',
            en:
                'Here you can enter and exit the car park comfortably without a ticket thanks to number plate recognition.',
        },
        transactionsTitle: {
            de: 'Abgeschlossene Parkvorgänge',
            fr: 'Stationnements terminés',
            it: 'Soste concluse',
            en: 'Completed transactions',
        },
        twintLimitationTitle: {
            de: 'Einschränkung',
            fr: 'Limitation',
            it: 'Limitazione',
            en: 'Limitation',
        },
        twintLimitationText1: {
            de:
                'Aus technischen Gründen können TWINT-Transaktionen maximal 6 Tage und 16 Stunden lang offen bleiben.',
            fr:
                'Pour des raisons techniques, les transactions TWINT peuvent rester ouvertes pendant un maximum de 6 jours et 16 heures.',
            it:
                'Per motivi tecnici le transazioni TWINT possono rimanere aperte al massimo 6 giorni e 16 ore.',
            en:
                'For technical reasons, TWINT transactions can remain open for a maximum of 6 days and 16 hours.',
        },
        twintLimitationText2: {
            de:
                'Die Höchstparkzeit, die für diese Zone zur Verfügung steht, wurde daher entsprechend angepasst.',
            fr:
                'La durée maximale disponible pour cette zone a donc été ajustée en conséquence.',
            it:
                'La durata massima disponibile per questa zona à stata quindi adattata di conseguenza.',
            en:
                'The maximum duration available for this zone has therefore been adjusted accordingly.',
        },
        noTransactions: {
            de:
                'Sie haben noch keinen Parkvorgang mit dieser TWINT App bezahlt.',
            fr:
                "Vous n'avez encore payé aucun stationnement avec cette appli TWINT.",
            it: 'Non hai ancora pagato alcuna sosta con questa app TWINT.',
            en:
                'You have not yet paid for a parking transaction with this TWINT app.',
        },
    },
};

interface FaqQuestion {
    question: Translation;
    answer: Translation;
}

const HELPDESK = (
    <div>
        <div className={css({ marginBottom: '8px' })}>Helpdesk</div>
        <a
            className={css({ marginBottom: '4px' })}
            href="mailto:info@parkingpay.ch"
        >
            info@parkingpay.ch
        </a>
        <div className={css({ marginBottom: '4px' })}>0848 330 555</div>
    </div>
);

export const FAQTexts: FaqQuestion[] = [
    {
        question: {
            de:
                'Ich habe eine ungerechtfertigte Parkbusse erhalten, was kann ich tun?',
            fr:
                'J’ai reçu une amende de parking injustifiée. Que puis-je faire?',
            it: 'Ho ricevuto una multa ingiusta, cosa posso fare?',
            en: 'I have received an unwarranted parking fine. What can I do?',
        },
        answer: {
            de:
                'In der TWINT App findest du im Menü «Bewegungen» die Zahlungsbestätigung und Quittung. Lade die Quittung herunter und melde dich bitte direkt an die auf dem Bussenbeleg aufgeführte Stelle. Diese wird den bezahlten Parkvorgang überprüfen und über eine allfällige Aufhebung der Busse entscheiden. TWINT oder Parkingpay können keine Busse aufheben.',
            fr:
                "Dans l'app TWINT, vous trouverez la confirmation de paiement et votre reçu dans le menu «Transactions». Téléchargez le reçu et adressez-vous directement à l’instance figurant sur l’amende. Cet office vérifiera le stationnement payé et décidera d’une éventuelle annulation de l’amende. TWINT ou Parkingpay ne sont pas en mesure d’annuler des amendes.",
            it:
                "Nell’app TWINT al menu «Movimenti» sono disponibili ricevute e conferme di pagamento. Scarica la ricevuta e contatta direttamente l'ufficio indicato sulla multa. Sarà compito di quest'ultimo verificare l’avvenuto pagamento del parcheggio e decidere l’eventuale annullamento della multa. Né TWINT né Parkingpay hanno la facoltà di annullare una multa.",
            en:
                'In the TWINT app, you will find the payment confirmation and your receipt in the menu «Statement entries». Download the receipt and contact the office listed on the fine. This office will check the paid parking transaction and, where applicable, decide whether to rescind the fine. TWINT and Parkingpay are unable to cancel fines.',
        },
    },
    {
        question: {
            de: 'Woher weiss der Parkplatzkontrolleur, dass ich bezahlt habe?',
            fr:
                'Comment la personne en charge du contrôle peut-elle savoir si j’ai payé mon stationnement?',
            it: 'Il controllore come fa a sapere che ho pagato il parcheggio?',
            en: 'How does the enforcement agent know whether I have paid?',
        },
        answer: {
            de:
                'Der Kontrolleur verfügt über ein Kontrollgerät, welches online mit unserem System verbunden ist. Mit diesem Gerät wird das Kennzeichen des Fahrzeuges gescannt. So wird festgestellt, ob die Parkgebühr bezahlt wurde.',
            fr:
                'La personne en charge du contrôle dispose d’un appareil connecté en ligne avec notre système. La plaque d’immatriculation du véhicule est scannée à l’aide de cet appareil. Ceci permet de vérifier si le stationnement a été payée.',
            it:
                'Il controllore è dotato di un dispositivo di controllo connesso online con il nostro sistema. Questo dispositivo permette di scannerizzare le targhe dei veicoli e quindi di verificare l’avvenuto pagamento della tassa di parcheggio.',
            en:
                'The enforcement agent has a control device, that is linked online to our system. This device is used to scan your vehicle’s license plate number, meaning the enforcement agent can establish whether the parking fee has been paid.',
        },
    },
    {
        question: {
            de:
                'Darf ich nach dem Ende eines Parkvorganges sofort einen neuen starten?',
            fr:
                'Est-ce que je peux démarrer une nouvelle procédure de stationnement immédiatement après la fin de la précédente?',
            it:
                'Posso avviare una nuova sosta, subito dopo la fine di quella precedente?',
            en:
                'Following the end of a parking transaction, can I immediately start a new one?',
        },
        answer: {
            de:
                'Nein. Nach dem Ende eines Parkvorganges muss das Fahrzeug wieder in Verkehr gesetzt werden.',
            fr:
                'Non. Après la fin de la procédure de stationnement, le véhicule doit être engagé de nouveau dans la circulation.',
            it:
                'No. Dopo la fine della sosta, il veicolo deve essere rimesso in circolazione.',
            en:
                'No. Following the end of a parking transaction, the vehicle must be driven away.',
        },
    },
    {
        question: {
            de: 'Wie kann ich einen laufenden Parkvorgang frühzeitig beenden?',
            fr: 'Comment puis-je abréger la durée de stationnement en cours?',
            it:
                'Come faccio a interrompere anticipatamente una sosta in corso?',
            en: 'How can I prematurely end a current parking transaction?',
        },
        answer: {
            de:
                'Öffne in der TWINT App das Menü «Bewegungen» und tippe auf die entsprechende Zahlung oder scanne erneut den QR-Code. Tippe dann auf «Parkvorgang beenden». Solltest du den Parkvorgang nicht frühzeitig beenden können, weil z.B. dein Smartphone-Akku leer war, kann dir die restliche Parkzeit nicht zurückerstattet werden.',
            fr:
                'Dans l’app TWINT, ouvrez le menu «Transactions» et cliquez sur le paiement concerné ou scannez à nouveau le code QR. Sélectionnez ensuite «Terminer stationnement». Si vous n’êtes pas en mesure d’abréger la durée de stationnement, par exemple parce que la batterie de votre smartphone est vide, le temps de stationnement restant ne pourra pas vous être remboursé.',
            it:
                "Apri il menu «Movimenti» nell'app TWINT e clicca sul pagamento corrispondente oppure scansiona di nuovo il codice QR. Clicca quindi su «Termina sosta». Se non è stato possibile interrompere la sosta, ad esempio perché la batteria dello smartphone è scarica, non sarà possibile ottenere il rimborso del tempo rimanente.",
            en:
                'In the TWINT app, open the «Statement entries» menu and tap the relevant payment or scan the QR code again. Now, tap «End parking time». If you are unable to end the parking transaction early, for example because your smartphone battery was empty, it will not be possible to refund the remaining parking time.',
        },
    },
    {
        question: {
            de: 'Wie lange darf ich parkieren?',
            fr: 'Combien de temps suis-je autorisé à stationner?',
            it: 'Qual è la durata massima di un parcheggio?',
            en: 'For how am I allowed to park?',
        },
        answer: {
            de:
                'Je nach Parkplatz ist die maximale Parkdauer unterschiedlich und wird sowohl auf der Parkuhr wie auch in der App ausgewiesen. Es ist nicht gestattet, einen laufenden Parkvorgang zu verlängern. Tipp: Du hast die Möglichkeit, die maximale Parkzeit zu wählen und bei frühzeitiger Beendigung des Parkvorgangs den Restbetrag zurück zu erhalten, indem du den laufenden Parkvorgang frühzeitig beendest.',
            fr:
                'La durée maximale varie selon la place de stationnement et est indiquée sur le parcomètre et dans l’app. Il n’est pas autorisé de prolonger la durée de stationnement en cours. Conseil: vous avez la possibilité de sélectionner la durée maximale de stationnement; la différence vous sera restituée si vous abrégez la durée de stationnement.',
            it:
                'Può variare da parcheggio a parcheggio, ed è indicata sia sul parchimetro sia nell’app. Non è possibile prolungare una sosta già in corso. Suggerimento: puoi selezionare la durata massima e farti rimborsare l’importo residuo in caso di interruzione anticipata della sosta. Ti basterà interrompere la sosta in corso.',
            en:
                'Depending on the car park, the maximum parking duration can vary and is displayed both on the parking meter and in the app. It is not permitted to extend an ongoing parking transaction. Tip: you can choose the maximum parking time and then receive the remaining amount back if you end a parking transaction early.',
        },
    },
    {
        question: {
            de:
                'Entstehen zusätzliche Kosten, wenn ich die Parkgebühr mit TWINT bezahle?',
            fr:
                'Payer les frais de stationnement avec TWINT entraîne-t-il des coûts supplémentaires?',
            it:
                'Pagare le tasse di parcheggio con TWINT comporta dei costi aggiuntivi?',
            en:
                'Are there any additional costs if I pay for the parking fee with TWINT?',
        },
        answer: {
            de:
                'In der Regel gelten dieselben Tarife wie bei der Parkuhr oder an der Kasse. Bei entsprechendem und ausdrücklichem Hinweis erheben einzelne Betreiber für die Benutzung der Parkingpay-Funktionen Zuschläge. Beachte, dass für die Funktion «Ticketloses Parkieren» (Kennzeichenerkennung bei Parkanlagen mit Schranken) pro konstenpflichtigem Parkvorgang zusätzliche Gebühren von CHF 0.25 anfallen.',
            fr:
                "En général, les tarifs sont identiques à ceux perçus au parcomètre ou à la caisse. Certains exploitants prélèvent un supplément pour l’utilisation des fonctions Parkingpay. Dans ce cas, ceci est explicitement indiqué. Veuillez noter qu’avec la fonction «Stationner sans ticket» (reconnaissance de la plaque d'immatriculation dans les parkings à barrières), des taxes supplémentaires de CHF 0.25 sont perçues par procédure de stationnement payant.",
            it:
                'Di regola valgono le stesse tariffe del parchimetro o della cassa. Può capitare che, previa esplicita avvertenza in tal senso, singoli gestori richiedano un supplemento per l’uso delle funzioni Parkingpay. Ricorda che la funzione «Parcheggiare senza ticket» (riconoscimento della targa nei parcheggi con barriere) comporta una commissione di CHF 0.25 per ogni sosta a pagamento.',
            en:
                'Generally speaking, the same fees apply as those shown on the parking meter or on the cash desk. Where corresponding and express reference is made, individual operators may levy surcharges for the use of the Parkingpay functions. Please note that the «Ticketless parking» function (number plate recognition in parkings with barriers) incurs a fee of CHF 0.25 per each paid parking transaction.',
        },
    },
    {
        question: {
            de: 'Wie kann ich mein hinterlegtes Fahrzeugkennzeichen anpassen?',
            fr:
                'Comment puis-je modifier la plaque d’immatriculation enregistré?',
            it:
                'Come faccio a modificare la mia targa del veicolo, che avevo registrato in precedenza?',
            en: 'How do I change my saved vehicle license plate number?',
        },
        answer: {
            de:
                'Tippe oben rechts auf das Fahrzeug-Symbol und im nächsten Screen auf «Weiteres Fahrzeug hinzufügen». Gib das vollständige Kennzeichen an und tippe auf «Bestätigen». Du siehst nun eine Übersicht all deiner hinterlegten Kennzeichen.',
            fr:
                'Cliquez sur le symbole véhicule en haut à droite, puis sur «Ajouter véhicule» sur le prochain écran. Saisissez l’intégralité du numéro d’immatriculation et cliquez sur «Confirmer». Vous verrez alors un aperçu de tous les plaques d’immatriculation enregistrés.',
            it:
                "Clicca in alto a destra sul simbolo del veicolo e nella schermata successiva su «Aggiungi veicolo». Inserisci la targa completa e clicca su «Confermare». Verrà quindi visualizzato l'elenco di tutte le targhe da te registrate.",
            en:
                'Tap the vehicle symbol in the top-right corner and then «Add additional vehicle» on the next screen. Enter the complete license plate number and tap «Confirm». You will now see an overview of all your saved license plate numbers.',
        },
    },
    {
        question: {
            de:
                'Wo finde ich die Quittung einer mit TWINT bezahlten Parkgebühr?',
            fr:
                "Où puis-je trouver le reçu d'une taxe de stationnement payée avec TWINT?",
            it:
                'Dove posso trovare la ricevuta di una tassa di parcheggio pagata con TWINT?',
            en:
                'Where can I find the receipt for a parking fee paid with TWINT?',
        },
        answer: {
            de:
                'Öffne TWINT+ Parking. Tippe auf das Symbol mit den drei Strichen oben rechts auf der Karte. Du siehst nun eine Übersicht all deiner abgeschlossenen Parkvorgänge. Tippe auf den Betrag neben dem Parkvorgang, für welchen du die Quittung herunterladen möchtest. Die Quittung wird dir nun als PDF-Dokument angezeigt und du kannst diese auf deinem Smartphone speichern.',
            fr:
                'Ouvrez TWINT+ Parking. Cliquez sur les trois tirets en haut à droite. Vous verrez alors un aperçu de toutes tes procédures de stationnement terminées. Cliquez sur le montant à côté de la procédure pour laquel vous souhaitez télécharger le reçu. Le reçu s’affiche alors au format PDF, et vous pouvez le sauvegarder sur votre smartphone.',
            it:
                'Apri TWINT+ Parking. Clicca sul simbolo con le tre linee in alto a destra nella mappa. Verrà quindi visualizzato l’elenco di tutte le soste già concluse. Clicca sull’importo accanto alla sosta per cui desideri scaricare la ricevuta. La ricevuta verrà quindi visualizzata in formato PDF e potrai scaricarla sullo tuo smartphone.',
            en:
                'Open TWINT+ Parking. Tap the symbol with the three lines in the top-right corner of the screen. You will now see an overview of all your past parking transactions. Tap the amount next to the parking transaction for which you would like to download the receipt. The receipt will now be displayed to you as a PDF document that you can save on your smartphone.',
        },
    },
    {
        question: {
            de: 'Welche zusätzlichen Möglichkeiten bietet Parking in TWINT+?',
            fr:
                'Quelles fonctions supplémentaires sont-elles proposées dans TWINT+?',
            it: 'Che ulteriori possibilità offre Parking in TWINT+?',
            en: 'What additional options does Parking in TWINT+ offer?',
        },
        answer: {
            de:
                'In TWINT+ kannst du deine Parkzeit bezahlen, ohne den QR-Code an der Parkuhr scannen zu müssen. Wähle dazu auf der Karte einfach die gewünschte Parkzone in deiner Nähe aus. Zudem kannst du in bestimmten Parkhäusern auch ticketlos Parkieren.',
            fr:
                'Dans TWINT+, vous pouvez payer votre temps de stationnement sans devoir scanner le code QR apposé sur le parcomètre. Pour ce faire, sélectionnez simplement la zone de stationnement souhaitée sur la carte. Vous pouvez en plus stationner sans ticket dans certains parkings.',
            it:
                'TWINT+ permette di pagare la sosta senza scannerizzare il codice QR al parchimetro. Per fare questo puoi semplicemente selezionare sulla mappa la zona di parcheggio desiderata nelle tue vicinanze. Inoltre, certi parcheggi offrono la possibilità di parcheggiare anche senza ticket.',
            en:
                'In TWINT+, you can pay for your parking time without having to scan the QR code on the parking meter. On the map, simply select the parking zone near you in which you would like to park. You can also park in certain car parks without having to buy a ticket.',
        },
    },
    {
        question: {
            de: 'Was ist «Ticketloses Parkieren» und wie funktioniert es?',
            fr:
                'Qu’est-ce que le «Stationner sans ticket» et comment fonctionne-t-il?',
            it: 'Che cos’è «Parcheggiare senza ticket» e come funziona?',
            en: 'What is “Ticketless parking” and how does it work?',
        },
        answer: {
            de:
                'Bei der neuen Funktion «Ticketloses Parkieren» bieten wir unseren Nutzern neu die Möglichkeit, bei Parkanlagen mit Schranken (z.B. Parkhäusern) und Kennzeichenerkennung, von einer automatisierten Abrechnung der Parkgebühr zu profitieren. Für dich fallen dabei für diese Dienstleistung pro kostenpflichtigem Parkvorgang zusätzliche Gebühren von CHF 0.25 an. Weitere Informationen dazu findest du im FAQ-Bereich auf www.twint.ch.',
            fr:
                'Avec la nouvelle fonction «Stationner sans ticket», nous offrons à nos clients la possibilité de profiter d’une facturation automatisée dans les parkings dotés de systèmes de barrières (p. ex. parkings souterrains) et d’un système de reconnaissance des plaques d’immatriculation. Pour cette prestation, des frais supplémentaires de CHF 0.25 sont facturés au client pour chaque procédure de stationnement payant. De plus amples informations à ce propos figurent dans la rubrique correspondante de la FAQ sous www.twint.ch.',
            it:
                '«Parcheggiare senza ticket» è una nuova funzione che offre ai nostri utenti la possibilità di saldare automaticamente le tasse di parcheggio negli impianti dotati di barriere (ad es. autosili) e del riconoscimento targa. Questo servizio prevede una commissione supplementare di CHF 0.25 per ogni sosta a pagamento. Ulteriori informazioni sono disponibili nelle FAQ su www.twint.ch.',
            en:
                'With the new «Ticketless parking» function, we are now offering our users the opportunity to benefit from an automated system for paying parking fees in parking facilities with barrier systems (e.g. multi-storey car parks) and a licence plate recognition system. This service will incur an additional fee for you of CHF 0.25 per paid parking transaction. Further information on this topic is available in the FAQ section at www.twint.ch.',
        },
    },
    {
        question: {
            de:
                'Bei welchen Parkings ist das ticketlose Ein- und Ausfahren bereits verfügbar?',
            fr: 'Quels parkings proposent le stationnement sans ticket?',
            it:
                'Quali sono i parcheggi che consentono di entrare e uscire senza ticket?',
            en:
                'In which car parks is it already possible to enter and exit without a ticket?',
        },
        answer: {
            de:
                'Öffne TWINT+ Parking. Tippe auf das Fahrzeug-Symbol oben rechts auf der Karte. Tippe nun auf «Kennzeichenerkennung» und im nächsten Screen dann auf «Parkingliste anzeigen». Jetzt werden dir alle Parkings angezeigt, bei denen das ticketlose Ein- und Ausfahren möglich ist.',
            fr:
                'Ouvrez TWINT+ Parking. Cliquez sur le symbole du véhicule en haut à droite de la carte. Cliquez ensuite sur «Reconnaissance des plaques d’immatriculation» puis sur «Afficher la liste des parkings» sur le prochain écran. Tous les parkings proposant le stationnement sans ticket s’afficheront alors.',
            it:
                'Apri TWINT+ Parking. Clicca sul simbolo del veicolo in alto a destra nella mappa. Clicca quindi su «Riconoscimento targa», e nella schermata successiva su «Visualizza elenco parcheggi». Verrà quindi visualizzata la lista di tutti i parcheggi dov’è possibile entrare e uscire senza ticket.',
            en:
                'Open TWINT+ Parking. Tap the vehicle symbol in the top-right corner of the map. Now, tap «Licence plate recognition» and then «Show parking list» on the next screen. You will now see all of the car parks where you can enter and exit without a ticket.',
        },
    },
    {
        question: {
            de:
                'Wie kann ich die Funktion «Ticketloses Parkieren» wieder deaktivieren?',
            fr:
                'Comment puis-je désactiver la fonction «Stationner sans ticket»?',
            it:
                'Come faccio a disattivare la funzione «Parcheggiare senza ticket»?',
            en: 'How do I deactivate the service «Ticketless parking»?',
        },
        answer: {
            de:
                'Um ein bestimmtes Fahrzeugkennzeichen abzumelden, öffne TWINT+ Parking und tippe auf das Fahrzeug-Symbol oben rechts auf der Karte. Tippe nun rechts neben dem Fahrzeugkennzeichen, welches du deaktivieren möchtest, auf das Kamera-Symbol und bestätige die Deaktivierung. Um dich vollständig abzumelden, tippe auf «Kennzeichenerkennung», im nächsten Screen dann auf «Kennzeichenerkennung aktiv» und bestätige die Deaktivierung.',
            fr:
                "Pour révoquer l'enregistrement d'une certaine plaque d'immatriculation, ouvrez TWINT+ Parking et cliquez sur le symbole du véhicule en haut à droite de la carte. À droite du numéro d’immatriculation que vous souhaitez désactiver, cliquez sur le symbole de caméra et confirmez la désactivation. Pour te désinscrire complètement, appuie sur «Reconnaissance des plaques», puis sur «Reconnaissance des plaques active» sur le prochain écran et confirmer la désactivation.",
            it:
                'Per revocare la registrazione di una determinata targa clicca sul simbolo del veicolo in alto a destra nella mappa. Clicca quindi sul simbolo della telecamera accanto alla targa che vorresti disattivare e conferma la disattivazione. Per revocare completamente la registrazione, clicca su «Riconoscimento targa», quindi nella schermata successiva su «Riconoscimento targa attivo» e conferma la disattivazione.',
            en:
                'To cancel the registration for a specific licence plate number, open TWINT+ Parking and tap the vehicle symbol in the top-right corner of the screen. Now tap the camera symbol to the right of the licence plate number that you would like to deactivate and then confirm the deactivation. To deregister completely, tap «Licence plate recognition», then on the next screen «License plate recognition active» and confirm the deactivation.',
        },
    },
];

export const HelpdeskTexts = {
    question: {
        de: 'Weitere Fragen?',
        fr: 'Plus de questions?',
        it: 'Altre domande?',
        en: 'Further questions?',
    },
    answer: {
        de: HELPDESK,
        fr: HELPDESK,
        it: HELPDESK,
        en: HELPDESK,
    },
};

export const FAQDataProtectionTexts = {
    delete: {
        question: {
            de: 'Daten löschen',
            fr: 'Supprimer les données',
            it: 'Elimina dati',
            en: 'Delete data',
        },
        answer: (ref: string) => ({
            de: `Die Parkingpay-Daten werden zwar von der TWINT-App gelöscht, aber bleiben für den Betreiber für max. 24 Monate abrufbar. Wenn Sie Ihre Daten wirklich löschen möchten, wenden Sie sich bitte an unseren Helpdesk unter 0848 330 555 und teilen Sie uns die Referenz ${ref} mit.`,
            fr: `Les données Parkingpay sont supprimées de l'application TWINT, mais restent disponibles pour l'exploitant pour max. 24 mois. Si vous souhaitez vraiment supprimer vos données, veuillez contacter notre Helpdesk au 0848 330 555 et communiquer la référence ${ref}.`,
            it: `I dati Parkingpay vengono cancellati dall'app TWINT, ma rimangono disponibili per il gestore al max. 24 mesi. Se volete veramente cancellare i vostri dati contattate il nostro Helpdesk al numero 0848 330 555 e comunicate il riferimento ${ref}.`,
            en: `The Parkingpay data is deleted from the TWINT app, but remain available for the operator for max. 24 months. If you really want to delete your data please contact our Helpdesk on 0848 330 555 and communicate the reference ${ref}.`,
        }),
    },
    export: {
        question: {
            de: 'Daten exportieren',
            fr: 'Exporter les données',
            it: 'Esporta dati',
            en: 'Export data',
        },
        answer: (link: string) => ({
            de: (
                <a href={link} target="_blank">
                    Download
                </a>
            ),
            fr: (
                <a href={link} target="_blank">
                    Download
                </a>
            ),
            it: (
                <a href={link} target="_blank">
                    Download
                </a>
            ),
            en: (
                <a href={link} target="_blank">
                    Download
                </a>
            ),
        }),
    },
};

interface Texts {
    lp: LicensePlate;
    lpValidation: LpValidation;
    parkingsList: ParkingsList;
    lprActivation: LprActivation;
    lprSettings: LprSettings;
    checkin: Checkin;
    onboarding: Onboarding;
    receipt: Receipt;
    outstandingAmount: OutstandingAmount;
    general: General;
}

interface LicensePlate {
    type: Translation;
    typeCar: Translation;
    typeMotorcycle: Translation;
    licensePlate: Translation;
    licensePlateHint: Translation;
    mostUsedCountries: Translation;
    allCountries: Translation;
    delete: Translation;
    deleteDescription: Translation;
    addLicensePlate: Translation;
    cars: Translation;
    lprNotificationTitle: Translation;
    lprNotificationText: (onClick: () => void) => Translation;
    lprDeleteLpTitle: Translation;
    lprDeleteLpDesc: (lp: string) => Translation;
    lprActivateLpTitle: Translation;
    lprActivateLpDesc: (lp: string) => Translation;
    lprActivateLpFees: Translation;
    lprErrorTitle: Translation;
    lprErrorReasonOccupied: (lp: string) => Translation;
    lprErrorReasonNotAllowed: (lp: string) => Translation;
}

interface General {
    activate: Translation;
    back: Translation;
    close: Translation;
    confirm: Translation;
    confirmAmount: Translation;
    continue: Translation;
    cancel: Translation;
    ok: Translation;
    dayShort: Translation;
    daysShort: Translation;
    deactivate: Translation;
    hide: Translation;
    hoursShort: Translation;
    secondsShort: Translation;
    minutesShort: Translation;
    description: Translation;
    details: Translation;
    permitValidity: Translation;
    tryAgain: Translation;
    useMeter: Translation;
    error: Translation;
    errorNonActivePermit: Translation;
    errorNonActiveZone: Translation;
    contactOperatorCallToAction: Translation;
    wereSorry: Translation;
    backToPortraitMode: Translation;
    lprAdvertisement: Translation;
    lprAdvertisementWithParking: Translation;
    barrierGateZoneInfoNoLpr: Translation;
    barrierGateZoneInfoWithLpr: Translation;
    transactionsTitle: Translation;
    twintLimitationTitle: Translation;
    twintLimitationText1: Translation;
    twintLimitationText2: Translation;
    noTransactions: Translation;
}

interface Checkin {
    parkDuration: Translation;
    until: Translation;
    price: Translation;
    payNow: Translation;
    buyParkTicket: Translation;
    enterTime: Translation;
    confirmAmount: Translation;
    operatorName: Translation;
    zoneName: Translation;
    cityName: Translation;
    maxParkTime: Translation;
    urlUsed: Translation;
    expired: Translation;
    showReceipt: Translation;
    transactionRunning: Translation;
    unpairedQrCodeShareLocation: Translation;
    unpairedQrCodeError: Translation;
    unpairedQrCodeGeoSent: Translation;
    unpairedQrCodeGeoNotSent: Translation;
    close: Translation;
    immediateParkForThisLicensePlateAndZone: Translation;
    subsequentPaymentForbiddenAnyway: Translation;
    title: Translation;
    whatYouShouldDo: Translation;
    parkingProhibitedTitle: Translation;
    parkingProhibitedDescription: Translation;
    closeWindow: Translation;
    hour: Translation;
    hours: Translation;
    day: Translation;
    days: Translation;
    week: Translation;
    weeks: Translation;
    month: Translation;
    months: Translation;
    purchasePermit: Translation;
    additionalInfos: Translation;
    zones: Translation;
}

interface Onboarding {
    lpForEnforcement: Translation;
    atLeastOneCar: Translation;
}

interface Receipt {
    terminate: Translation;
    parkingTime: Translation;
    from: Translation;
    to: Translation;
    time: Translation;
    terminationAlertInfo: Translation;
    terminated: (amount: number) => Translation;
    downloadReceipt: Translation;
}

interface LpValidation {
    allLettersUppercase: Translation;
    countryMustBeFL: Translation;
    hasMoreThanFiveNumbers: Translation;
    hasMoreThanSixNumbers: Translation;
    lettersAfterCanton: Translation;
    lettersAfterCountryFL: Translation;
    minThreeLetters: Translation;
    noValidCanton: Translation;
    noWhiteSpace: Translation;
    mandatory: Translation;
}

interface ParkingsList {
    parkingsListTitle: Translation;
}

interface LprSettings {
    title: Translation;
    lprFunctionDescription: Translation;
    showParkingslist: Translation;
    lprFeeDescription: Translation;
    toggleLPR: Translation;
    deactivateLPRTitle: Translation;
    deactivateLPRQuestion: Translation;
}

interface LprActivation {
    consentTitle: Translation;
    consentTwintPermission: Translation;
    consentTwintAcceptNote: Translation;
    pairingOngoingTitle: Translation;
    pairingOngoingDesc: Translation;
    pairingOngoingReopenIntent: Translation;
    pairingSuccessTitle: Translation;
    pairingSuccessDesc: Translation;
    pairingSuccessDescShort: Translation;
    pairingSingleLpOccupied: (
        lp: LicensePlateState.LicensePlate,
    ) => Translation;
    pairingMultipleLpsOccupied: (
        lps: LicensePlateState.LicensePlate[],
    ) => Translation;
    pairingSingleLpNotAllowed: (
        lp: LicensePlateState.LicensePlate,
    ) => Translation;
    pairingMultipleLpsNotAllowed: (
        lps: LicensePlateState.LicensePlate[],
    ) => Translation;
    pairingErrorTitle: Translation;
    pairingErrorDesc: Translation;
    pairingErrorReasonAbort: Translation;
    pairingErrorReasonIntent: Translation;
    pairingErrorReasonNoCar: Translation;
    pairingAbortTitle: Translation;
    pairingAbortDesc: Translation;
}

interface OutstandingAmount {
    outstandingAmountTitle: Translation;
    outstandingAmountText: (outstandingAmount: number) => Translation;
    retryPayment: Translation;
    renewAlias: Translation;
    failedAttemptTitle: Translation;
    failedAttemptText: Translation;
    restartSession: Translation;
    contactIssuer: Translation;
    askToRenewAlias: Translation;
    reasonInsufficientFunds: Translation;
    topUpInstructions: Translation;
    paymentSuccessfulTitle: Translation;
    paymentSuccessfullText: Translation;
    collectAmount: Translation;
}

export const AGBTexts_202207: Translation = {
    de: `
# ALLGEMEINE GESCHÄFTSBEDINGUNGEN

## Für Benutzer via TWINT
## Version 07.2022

### 1. Präambel

Digitalparking AG (nachfolgend „Digitalparking“ genannt) bietet unter dem Namen „Parkingpay“ Dienstleistungen an, welche TWINT-Benutzern (natürliche oder juristischen Personen, nachfolgend „Benutzer“ genannt) ermöglicht, die Nutzung von entsprechend ausgerüsteten Parkplätzen abzuwickeln.

Im Namen und mit Ermächtigung der am Parkingpay-System angeschlossenen Betreiber von Parkplätzen (Gemeinden, Städte, Betreiber von Parkhäusern usw., nachfolgend „Betreiber“ genannt) übernimmt Digitalparking die Erhebung und bargeldlose Abrechnung der anfallenden Gebühren gegenüber dem Benutzer.

Die Abrechnung der Parkinggebühr für die Nutzung von Parkplätzen mit dem Parkingpay-System zu den jeweils geltenden Tarifen erfolgt über das TWINT-Konto des Benutzers.

Die Parkingpay-Funktionen können nur im Rahmen der verfügbaren Limite vom TWINT-Konto genutzt werden.

### 2. Aktivierung

Der Benutzer muss einmalig diesen Allgemeinen Geschäftsbedingungen (nachfolgend „AGB“ genannt) und der separaten Datenschutzerklärung zustimmen. Die Aktivierung ist kostenlos. Ein Anspruch auf Aktivierung besteht nicht.

Sowohl der Benutzer als auch Digitalparking können die Parkingpay-Funktionen jederzeit ohne Einhaltung einer Kündigungsfrist deaktivieren.

Digitalparking ist zudem berechtigt, die Funktionen jederzeit zu sperren, wenn

* der Benutzer gegen die AGB oder gegen geltendes Recht verstösst,
* der Benutzer falsche Angaben gemacht hat oder
* eine missbräuchliche Nutzung vermutet wird.

### 3. Nutzungsvertrag

Mit der Zustimmung zu den AGB bei der Aktivierung kommt zwischen Digitalparking und dem Benutzer ein darauf basierender Vertrag zustande. Der Benutzer hat weder ein Rücktritts- noch ein Rückgaberecht der bezogenen Leistung.

### 4. Informationspflicht des Benutzers

Der Benutzer verpflichtet sich, Änderungen seiner vertragswesentlichen Daten unverzüglich selber vorzunehmen.

Kommt der Benutzer seiner Informationspflicht nicht nach, so ist Digitalparking berechtigt, dem Benutzer die ihr entstehenden Mehraufwendungen in Rechnung zu stellen.

### 5. Sorgfaltspflicht des Benutzers

Stellt der Benutzer missbräuchliche Parkgebühren-Belastungen über sein TWINT-Konto fest, ist er verpflichtet, Digitalparking oder den TWINT-Issuer darüber unverzüglich zu unterrichten, damit seine Parkingpay-Funktionen gesperrt werden können. Das gleiche gilt bei Verlust oder Diebstahl des Mobiltelefons.

Bei missbräuchlicher Benutzung durch Dritte entfällt die Haftung des Benutzers für Schäden, die nach dem Eingehen der Mitteilung an Digitalparking belastet werden.

### 6. Gebühren

#### Parkgebühren

Für die Nutzung der gebührenpflichtigen Parkplätze werden dem Benutzer die Gebühren gemäss den Tarifen des entsprechenden Parkingbetreibers belastet. Bei entsprechendem ausdrücklichem Hinweis, können für die Benutzung einzelner Parkingpay-Funktionen Zuschläge erhoben werden.

#### Kommunikationsgebühren

Fallen für die Nutzung der Parkingpay-Funktionen Kommunikationskosten (Internet- und Mobiltelefongebühren) an, sind diese durch den Benutzer zu tragen.

### 7. Fehlerfall oder inkorrekte Nutzung des Systems

Wenn es dem Benutzer nicht gelingen sollte, über Parkingpay zu bezahlen, ist er in jedem Falle dazu verpflichtet, die Parkgebühr auf konventionellem Weg zu bezahlen. Falls es keine solche Möglichkeit gibt, darf der Benutzer nicht parkieren. Der Parkvorgang bei Parkplätzen ohne Zugangskontrolle gilt als gestartet, wenn die dazugehörige Zahlung ausdrücklich vom TWINT bestätigt wurde.

### 8. Parkbussen

Erhält der Benutzer eine Parkbusse und bestreitet er diese, so hat er sich ausschliesslich an die auf dem entsprechenden Bussenbeleg aufgeführte Stelle zu wenden.

Wenn nachweislich ein korrekter Parkvorgang mit dem Parkingpay-System stattgefunden hat, findet der Benutzer den entsprechenden Beleg als URL in der Transaktionshistorie der TWINT-App. Über eine Aufhebung einer Parkbusse entscheidet in jedem Falle die zuständige Stelle. Digitalparking haftet in keinem Fall für zu bezahlende Parkbussen.

Höchstparkzeiten und Nachzahlungsverbote gelten in jedem Fall auch bei Nutzung des Parkingpay-Systems. Beim Erreichen der geltenden Höchstparkzeit wird der Parkvorgang automatisch beendet.

Nach dem Ablauf jedes Parkvorgangs muss das Fahrzeug wieder in den Verkehr eingefügt werden. Ist dies nicht der Fall und es wird ein neuer Parkvorgang gestartet, muss trotz laufendem Parkvorgang mit einer Parkbusse gerechnet werden. Ein blosses Verschieben des Fahrzeuges auf einen anderen, in der Nähe liegenden Parkplatz ist unzulässig. Davon ausgenommen sind Parkplätze, bei welchen das Nachzahlen gemäss den geltenden Bestimmungen zulässig ist.

### 9. Haftung

Digitalparking haftet nach den gesetzlichen Bestimmungen für Schäden, sofern diese auf Vorsatz, grobe Fahrlässigkeit oder die schuldhafte Verletzung wesentlicher Vertragspflichten von Digitalparking zurückzuführen sind.

Digitalparking übernimmt keine Verantwortung oder Haftung für die ständige Verfügbarkeit der Parkingpay-Funktionen, insbesondere nicht bezüglich der Verfügbarkeit der Mobilfunknetze und der Funktionsfähigkeit von mobilen Endgeräten.

Bei Nichtverfügbarkeit des Parkingpay-Systems kann Digitalparking nicht für die entstehenden Folgekosten wie Parkbussen, Abschleppgebühren etc. verantwortlich gemacht werden.

Für fehlerhafte oder nicht erfolgte Beendigung von Parkvorgängen übernimmt Digitalparking keine Haftung.

### 10. Änderungen oder Ergänzungen dieser Geschäftsbedingungen

Digitalparking behält sich vor, diese AGB jederzeit und ohne Nennung von Gründen zu ändern.

Änderungen oder Ergänzungen werden dem Benutzer bei der nächsten Nutzung der Parkingpay-Funktionen eingeblendet.

Sofern eine Bestimmung dieser AGB unwirksam ist, bleiben die übrigen Bestimmungen davon unberührt. Die unwirksame Bestimmung gilt als durch eine solche ersetzt, die dem Sinn und Zweck der unwirksamen Bestimmung in rechtswirksamer Weise wirtschaftlich am nächsten kommt. Gleiches gilt für eventuelle Regelungslücken.

Mündliche Nebenabreden haben keine Gültigkeit.

### 11. Anwendbares Recht und Gerichtsstand

Das Vertragsverhältnis unterliegt Schweizer Recht. Als Gerichtsstand gilt Schlieren.
                        `,
    fr: `
# CONDITIONS GÉNÉRALES DE VENTE

## Pour les usagers via TWINT
## Version 07.2022

### 1. Préambule

Digitalparking SA (ci-après: «Digitalparking») propose sous le nom «Parkingpay» des prestations permettant à un utilisateur TWINT (personne physique ou morale, ci-après «l’usager») de s’acquitter de sa taxe de stationnement pour les parkings équipés en conséquence.

Au nom et avec l’habilitation des exploitants de parkings affiliés au système Parkingpay (communes, villes, exploitants de parkings à étages etc., ci-après: «l’exploitant»), Digitalparking se charge de la perception des éventuelles taxes dues par les usagers et de leur règlement dématérialisé à l’exploitant.

Les frais de stationnement pour l'utilisation des espaces de stationnement avec le système Parkingpay aux tarifs applicables sont imputés au compte TWINT de l'utilisateur.

Les fonctions Parkingpay ne peuvent être utilisées que dans les limites du compte TWINT.

### 2. Activation

L’usager est tenu d’accepter ces conditions générales (ci-après: «CGV»), ainsi que la déclaration sur la protection des données. L’activation est gratuite, mais ne constitue pas un droit en soi.

L’usager aussi bien que Digitalparking peuvent désactiver les fonctions Parkingpay, à tout moment et sans préavis.

Digitalparking est en outre habilité à bloquer les fonctions en tout temps dès lors que

* l’usager contrevient aux CGV ou au droit en vigueur,
* si l'usager a fait de fausses déclarations ou encore,
* s’il y a suspicion d’usage frauduleux ou abusif.

### 3. Contrat d'utilisation

L'acceptation des CGV au moment de l’activation lie l'usager à Digitalparking par un contrat basé sur lesdites CGV.

L'usager n'a aucun droit de rétractation ni droit de restitution sur les prestations acquises.

### 4. Devoir d’information de la part de l’usager

L’usager s’engage à corriger, sur son compte et sans délai, toute modification eventuelle des données contractuelles.

Si l’usager ne satisfait pas à ce devoir d’information, Digitalparking est habilité à facturer à l’usager les coûts supplémentaires que ce manquement aura générés.

### 5. Devoir de diligence de l’usager

Si l’usager constate des charges abusives sur son compte TWINT concernant les taxes de stationnemment, il est tenu d’informer sans délai Digitalparking ou l’émetteur TWINT afin que son compte Parkingpay puisse être bloqué. Ceci vaut également en cas de perte ou de vol du téléphone mobile.

En cas d’usage frauduleux par des tiers, l’usager est dégagé de toute responsabilité quant aux dommages survenant après réception de l’information par Digitalparking/émetteur TWINT.

### 6. Coûts

Taxes de stationnement

Pour l’utilisation des parkings payants, l’usager est débité des taxes correspondantes aux tarifs de l’exploitant du parking. À condition que ceux-ci soient mentionnés expressément, des suppléments peuvent être appliqués pour l'utilisation de certaines fonctions Parkingpay.

Frais de communication

Les éventuels frais de communication générés par l’utilisation de Parkingpay (Internet et téléphone mobile) seront à la charge de l’usager.

### 7. Erreur ou usage incorrect du système

Si l’usager ne parvient pas à payer via Parkingpay, il est tenu de s’acquitter de la taxe par les moyens conventionnels. Si une telle possibilité n'existe pas, l'utilisateur n'a pas le droit de stationner. La procédure de stationnement pour les parkings sans contrôle d'accès est considérée comme démarrée si le paiement correspondant a été expressément confirmé par TWINT.

### 8. Amende de stationnement

Pour toute contestation d’amende de stationnement, l’usager doit s’adresser exclusivement à l’instance indiquée sur l’amende.

S’il est avéré que le stationnement s’est déroulé dans les règles, par le biais du système Parkingpay, l’usager trouve le justificatif correspondant dans l'historique des transactions de l’appli TWINT. L’annulation de l’amende demeure, dans tous les cas, l’affaire de l’instance émettrice. Digitalparking n’est en aucun cas responsable du paiement d’amendes de stationnement.

Les durées maximales de stationnement et les interdictions de prolongation s’appliquent dans tous les cas, même dans le cadre de l’utilisation du système Parkingpay. En cas d'atteinte de la durée maximale de stationnement, le système arrête automatiquement la procédure.

Après la fin de chaque procédure de stationnement, le véhicule doit être remis en circulation. Si cela ne se produit pas et qu'une nouvelle procédure de stationnement est démarrée, l’usager peut recevoir une amende, même si la procédure de stationnement a été démarrée correctement. Il n'est pas permis de simplement déplacer le véhicule vers une autre place de stationnement adjacente. Ceci ne s'applique pas aux zones où, conformément à la réglementation en vigueur, il est permis de payer plusieurs fois de suite.

### 9. Responsabilité civile

Aux termes de la loi, Digitalparking est responsable des dommages causés intentionnellement, par négligence grave ou infraction fautive à des devoirs contractuels majeurs de la part de Digitalparking.

La responsabilité de Digitalparking n’est pas engagée quant à la permanence de la disponibilité des fonctions Parkingpay, en particulier concernant la disponibilité des réseaux de téléphonie mobile et le bon fonctionnement des terminaux de téléphonie mobile.

En cas d’inaccessibilité au système Parkingpay, Digitalparking ne saurait être tenu pour responsable des coûts qui en résultent, tels qu’amendes de stationnement, frais d’enlèvement etc.

La responsabilité de Digitalparking n’est pas engagée en cas d’erreur ou d’absence de clôture de la procédure de stationnement.

### 10. Modifications et ajouts aux présentes conditions générales

Digitalparking se réserve le droit de modifier à tout moment et sans justification les présentes CGV.

Les modifications ou ajouts seront affichés à l'utilisateur la prochaine fois que les fonctions Parkingpay seront utilisées.

Les nouvelles CGV sont considérées comme acceptées si l'utilisateur les confirme et continue avec l’utilisation des fonctions Parkingpay.

Si une disposition des présentes CGV devait être invalide, les autres dispositions n'en seraient aucunement affectées. La disposition invalide serait alors remplacée par une disposition qui se rapprocherait le plus possible, sur le plan économique et légal, du sens et de l'objectif de la disposition invalide. Il en va de même pour les éventuelles lacunes réglementaires.

Tout accord oral annexe est nul et non-avenu.

### 11. Droit applicable et for

La relation contractuelle est soumise au droit suisse et le for juridique est Schlieren.

                        `,
    it: `
# CONDIZIONI GENERALI

## Per gli utenti via TWINT
## Versione 07.2022

### 1. Premessa

Digitalparking SA (di seguito «Digitalparking») offre sotto il nome “Parkingpay” dei servizi che permettono agli utenti TWINT (persona fisica o giuridica, di seguito «utente») di utilizzare i parcheggi adeguatamente attrezzati.

Digitalparking si occupa, in nome e per conto del gestore del parcheggio aderente al sistema Parkingpay (comuni, città, gestori di autosili ecc., di seguito «gestore»), della riscossione senza contanti e del conteggio delle eventuali tasse di parcheggio a carico dell’utente.

Le tasse per l’utilizzo dei parcheggi con il sistema Parkingpay vengono addebitate, secondo le tariffe in vigore, sul conto TWINT dell'utente.

Le funzioni Parkingpay possono essere utilizzate solo entro i limiti del conto TWINT.

### 2. Attivazione

L’utente deve accettare queste Condizioni generali (di seguito «CG»), come pure l’informativa sulla protezione dei dati. L’attivazione è gratuita. Non sussiste alcun diritto all’attivazione.

Le funzioni Parkingpay possono essere disattivate in qualsiasi momento, sia dall’utente che da Digitalparking, senza alcun termine di preavviso.

Digitalparking può bloccare in qualsiasi momento le funzioni se:

* l’utente contravviene alle disposizioni delle CG o viola le leggi vigenti;
* l’utente ha fornito dati personali errati;
* si sospetta un utilizzo improprio.

### 3. Contratto di utilizzo

Con l’accettazione delle CG al momento dell’attivazione, l’utente stipula un contratto con Digitalparking basato sulle suddette condizioni.

Per le prestazioni percepite non è possibile avvalersi del diritto di recesso o di restituzione.

### 4. Obbligo di informazione dell’utente

L’utente si impegna ad aggiornare tempestivamente le informazioni fondamentali nel quadro del contratto.

In caso di mancato adempimento di tali obblighi informativi, Digitalparking è autorizzata ad addebitare all’utente le eventuali spese supplementari che ha dovuto sostenere.

### 5. Obbligo di diligenza dell’utente

Se l’utente constata degli addebiti irregolari sul proprio conto TWINT, riguardanti le tasse di parcheggio, è tenuto a informare tempestivamente Digitalparking oppure l’emittente TWINT per fare in modo che le funzioni Parkingpay vengano bloccate. Occorre procedere analogamente in caso di smarrimento o furto del cellulare.

In caso di utilizzo improprio da parte di terzi, decade la responsabilità dell’utente per qualsiasi danno che si sia verificato successivamente al ricevimento della comunicazione da parte di Digitalparking o dell’emittente TWINT.

### 6. Tasse e costi

#### Tasse di parcheggio

Per la sosta nei parcheggi a pagamento, all’utente vengono addebitate le tasse di parcheggio in base alle tariffe stabilite dal gestore. Laddove espressamente indicato, può essere richiesto il pagamento di un supplemento per l’utilizzo di alcune funzioni Parkingpay.

#### Costi per le comunicazioni

Se l’utilizzo delle funzioni Parkingpay comporta dei costi per le comunicazioni (via Internet o cellulare), questi sono a carico dell’utente.

### 7. Errore o utilizzo non corretto del sistema

Se l’utente non dovesse riuscire a pagare tramite Parkingpay, è comunque tenuto a pagare la tassa di parcheggio in modo convenzionale. Nel caso non ci fosse tale possibilità, l’utente non ha diritto a parcheggiare. La procedura di parcheggio nei parcheggi senza controllo degli accessi si considera avviata solo se il pagamento corrispondente è stato espressamente confermato da TWINT.

### 8. Contravvenzioni di parcheggio

Se l’utente riceve una contravvenzione di parcheggio, per contestarla deve rivolgersi esclusivamente all’ufficio riportato sulla contravvenzione stessa.

Se la procedura di parcheggio con il sistema Parkingpay è avvenuta correttamente, l'utente trova il documento corrispondente nella cronologia delle transazioni dell'app TWINT. Riguardo all’annullamento di una contravvenzione di parcheggio decide esclusivamente l’ufficio competente e Digitalparking non è in nessun caso responsabile per eventuali contravvenzioni che devono essere pagate.

I limiti di durata del parcheggio e i divieti di prolungamento del parcheggio vengono applicati anche in caso di utilizzo del sistema Parkingpay. Quando viene raggiunto il tempo massimo di parcheggio, la procedura di parcheggio viene terminata automaticamente.

Dopo la fine di ogni procedura di parcheggio, il veicolo dev’essere rimesso in circolazione; se questo non avviene e una nuova procedura di parcheggio viene avviata, si può andare in contro a una contravvenzione, nonostante la procedura di parcheggio valida. Non è consentito spostare semplicemente il veicolo in un altro parcheggio adiacente. Questo non si applica ai parcheggi in cui, in conformità con le normative applicabili, è consentito pagare più volte di seguito.

### 9. Responsabilità

Conformemente alle disposizioni di legge, Digitalparking risponde dei danni imputabili a dolo, colpa grave o violazione intenzionale di obblighi contrattuali fondamentali da parte di Digitalparking.

Digitalparking non si assume nessuna responsabilità generale o civile per la costante disponibilità delle funzioni Parkingpay, con particolare riferimento alla disponibilità delle reti di telefonia mobile e al funzionamento dei terminali mobili.

In caso di mancata disponibilità del sistema Parkingpay, Digitalparking non può essere chiamata in causa come responsabile dei costi conseguenti a carico dell’utente, quali multe nel parcheggio, spese di traino ecc.

Digitalparking non si assume alcuna responsabilità per procedure di parcheggio non correttamente terminate o non eseguite.

### 10. Variazioni o aggiunte alle presenti condizioni

Digitalparking si riserva il diritto di modificare in qualsiasi momento le presenti CG senza necessità di motivare le ragioni. Le eventuali variazioni o aggiunte verranno comunicate all’utente al successivo utilizzo delle funzioni Parkingpay.

Le nuove CG vengono considerate accettate se l’utente le conferma e prosegue con l’utilizzo delle funzioni Parkingpay.

L’inefficacia di una qualsiasi delle disposizioni contenute nelle presenti CG non comporta l’inefficacia delle altre disposizioni. La disposizione inefficace si considera sostituita da un’altra che più si avvicina al senso e all’obiettivo della disposizione originale da un punto di vista sia giuridico che commerciale. Si procede analogamente in caso di eventuali lacune normative.

Eventuali accordi accessori verbali sono privi di validità giuridica.

### 11. Diritto applicabile e foro competente

Il rapporto contrattuale soggiace al diritto svizzero. Foro competente è Schlieren.
                        `,
    en: `
# ALLGEMEINE GESCHÄFTSBEDINGUNGEN

## Für Benutzer via TWINT
## Version 07.2022

### 1. Präambel

Digitalparking AG (nachfolgend „Digitalparking“ genannt) bietet unter dem Namen „Parkingpay“ Dienstleistungen an, welche TWINT-Benutzern (natürliche oder juristischen Personen, nachfolgend „Benutzer“ genannt) ermöglicht, die Nutzung von entsprechend ausgerüsteten Parkplätzen abzuwickeln.

Im Namen und mit Ermächtigung der am Parkingpay-System angeschlossenen Betreiber von Parkplätzen (Gemeinden, Städte, Betreiber von Parkhäusern usw., nachfolgend „Betreiber“ genannt) übernimmt Digitalparking die Erhebung und bargeldlose Abrechnung der anfallenden Gebühren gegenüber dem Benutzer.

Die Abrechnung der Parkinggebühr für die Nutzung von Parkplätzen mit dem Parkingpay-System zu den jeweils geltenden Tarifen erfolgt über das TWINT-Konto des Benutzers.

Die Parkingpay-Funktionen können nur im Rahmen der verfügbaren Limite vom TWINT-Konto genutzt werden.

### 2. Aktivierung

Der Benutzer muss einmalig diesen Allgemeinen Geschäftsbedingungen (nachfolgend „AGB“ genannt) und der separaten Datenschutzerklärung zustimmen. Die Aktivierung ist kostenlos. Ein Anspruch auf Aktivierung besteht nicht.

Sowohl der Benutzer als auch Digitalparking können die Parkingpay-Funktionen jederzeit ohne Einhaltung einer Kündigungsfrist deaktivieren.

Digitalparking ist zudem berechtigt, die Funktionen jederzeit zu sperren, wenn

* der Benutzer gegen die AGB oder gegen geltendes Recht verstösst,
* der Benutzer falsche Angaben gemacht hat oder
* eine missbräuchliche Nutzung vermutet wird.

### 3. Nutzungsvertrag

Mit der Zustimmung zu den AGB bei der Aktivierung kommt zwischen Digitalparking und dem Benutzer ein darauf basierender Vertrag zustande. Der Benutzer hat weder ein Rücktritts- noch ein Rückgaberecht der bezogenen Leistung.

### 4. Informationspflicht des Benutzers

Der Benutzer verpflichtet sich, Änderungen seiner vertragswesentlichen Daten unverzüglich selber vorzunehmen.

Kommt der Benutzer seiner Informationspflicht nicht nach, so ist Digitalparking berechtigt, dem Benutzer die ihr entstehenden Mehraufwendungen in Rechnung zu stellen.

### 5. Sorgfaltspflicht des Benutzers

Stellt der Benutzer missbräuchliche Parkgebühren-Belastungen über sein TWINT-Konto fest, ist er verpflichtet, Digitalparking oder den TWINT-Issuer darüber unverzüglich zu unterrichten, damit seine Parkingpay-Funktionen gesperrt werden können. Das gleiche gilt bei Verlust oder Diebstahl des Mobiltelefons.

Bei missbräuchlicher Benutzung durch Dritte entfällt die Haftung des Benutzers für Schäden, die nach dem Eingehen der Mitteilung an Digitalparking belastet werden.

### 6. Gebühren

#### Parkgebühren

Für die Nutzung der gebührenpflichtigen Parkplätze werden dem Benutzer die Gebühren gemäss den Tarifen des entsprechenden Parkingbetreibers belastet. Bei entsprechendem ausdrücklichem Hinweis, können für die Benutzung einzelner Parkingpay-Funktionen Zuschläge erhoben werden.

#### Kommunikationsgebühren

Fallen für die Nutzung der Parkingpay-Funktionen Kommunikationskosten (Internet- und Mobiltelefongebühren) an, sind diese durch den Benutzer zu tragen.

### 7. Fehlerfall oder inkorrekte Nutzung des Systems

Wenn es dem Benutzer nicht gelingen sollte, über Parkingpay zu bezahlen, ist er in jedem Falle dazu verpflichtet, die Parkgebühr auf konventionellem Weg zu bezahlen. Falls es keine solche Möglichkeit gibt, darf der Benutzer nicht parkieren. Der Parkvorgang bei Parkplätzen ohne Zugangskontrolle gilt als gestartet, wenn die dazugehörige Zahlung ausdrücklich vom TWINT bestätigt wurde.

### 8. Parkbussen

Erhält der Benutzer eine Parkbusse und bestreitet er diese, so hat er sich ausschliesslich an die auf dem entsprechenden Bussenbeleg aufgeführte Stelle zu wenden.

Wenn nachweislich ein korrekter Parkvorgang mit dem Parkingpay-System stattgefunden hat, findet der Benutzer den entsprechenden Beleg als URL in der Transaktionshistorie der TWINT-App. Über eine Aufhebung einer Parkbusse entscheidet in jedem Falle die zuständige Stelle. Digitalparking haftet in keinem Fall für zu bezahlende Parkbussen.

Höchstparkzeiten und Nachzahlungsverbote gelten in jedem Fall auch bei Nutzung des Parkingpay-Systems. Beim Erreichen der geltenden Höchstparkzeit wird der Parkvorgang automatisch beendet.

Nach dem Ablauf jedes Parkvorgangs muss das Fahrzeug wieder in den Verkehr eingefügt werden. Ist dies nicht der Fall und es wird ein neuer Parkvorgang gestartet, muss trotz laufendem Parkvorgang mit einer Parkbusse gerechnet werden. Ein blosses Verschieben des Fahrzeuges auf einen anderen, in der Nähe liegenden Parkplatz ist unzulässig. Davon ausgenommen sind Parkplätze, bei welchen das Nachzahlen gemäss den geltenden Bestimmungen zulässig ist.

### 9. Haftung

Digitalparking haftet nach den gesetzlichen Bestimmungen für Schäden, sofern diese auf Vorsatz, grobe Fahrlässigkeit oder die schuldhafte Verletzung wesentlicher Vertragspflichten von Digitalparking zurückzuführen sind.

Digitalparking übernimmt keine Verantwortung oder Haftung für die ständige Verfügbarkeit der Parkingpay-Funktionen, insbesondere nicht bezüglich der Verfügbarkeit der Mobilfunknetze und der Funktionsfähigkeit von mobilen Endgeräten.

Bei Nichtverfügbarkeit des Parkingpay-Systems kann Digitalparking nicht für die entstehenden Folgekosten wie Parkbussen, Abschleppgebühren etc. verantwortlich gemacht werden.

Für fehlerhafte oder nicht erfolgte Beendigung von Parkvorgängen übernimmt Digitalparking keine Haftung.

### 10. Änderungen oder Ergänzungen dieser Geschäftsbedingungen

Digitalparking behält sich vor, diese AGB jederzeit und ohne Nennung von Gründen zu ändern.

Änderungen oder Ergänzungen werden dem Benutzer bei der nächsten Nutzung der Parkingpay-Funktionen eingeblendet.

Sofern eine Bestimmung dieser AGB unwirksam ist, bleiben die übrigen Bestimmungen davon unberührt. Die unwirksame Bestimmung gilt als durch eine solche ersetzt, die dem Sinn und Zweck der unwirksamen Bestimmung in rechtswirksamer Weise wirtschaftlich am nächsten kommt. Gleiches gilt für eventuelle Regelungslücken.

Mündliche Nebenabreden haben keine Gültigkeit.

### 11. Anwendbares Recht und Gerichtsstand

Das Vertragsverhältnis unterliegt Schweizer Recht. Als Gerichtsstand gilt Schlieren.
                        `,
};

export const DataProtectionTexts_201811: Translation = {
    de: `
# DATENSCHUTZERKLÄRUNG

## FÜR BENUTZER
## Version 11.2018

### 1. Allgemeines

Datenschutz und Datensicherheit haben für uns eine sehr hohe Priorität. Wir haben deshalb umfangreiche technische und organisatorische Massnahmen im Rahmen der datenschutzrechtlichen Vorschriften getroffen, die den Schutz ihrer personenbezogenen Daten sicherstellen. Wir stellen Ihnen Angebote über verschiedene Zugangswege (z.B. Website und Smartphone Applikation) bereit (zusammen die „Plattform“ genannt).

In dieser Datenschutzerklärung informieren wir Sie über die Verwendung Ihrer persönlichen Daten sowie über die Verwendung von Cookies oder ähnliche Technologien auf unserer Plattform.

Die Erhebung und Verarbeitung Ihrer personenbezogenen Daten geschieht unter Beachtung der geltenden datenschutzrechtlichen Vorschriften, insbesondere des Schweizer Datenschutzgesetzes (DSG) und der Europäischen Datenschutzgrundverordnung (DSGVO), insoweit diese im konkreten Fall auf die Datenverarbeitung Anwendung findet.

### 2. Verantwortlicher Anbieter

Verantwortlicher Anbieter der Plattform und der im Zusammenhang mit Ihrer Plattformnutzung erhobenen und verarbeiteten personenbezogenen Daten ist die Digitalparking AG, Rütistrasse 13, CH-8952 Schlieren.

### 3. Erhebung, Verarbeitung und Nutzung Ihrer personenbezogenen Daten

Der Umfang und die Art der Erhebung, Verarbeitung und Nutzung Ihrer personenbezogenen Daten unterscheidet sich danach, ob Sie unsere Plattform nur besuchen und informatorisch nutzen oder auch über unsere Plattform mit uns korrespondieren, bzw. diese aktiv als Benutzer der Parkingpay Web- oder Smartphone-Applikation (nachfolgend „Parkingpay“), der TWINT-App (nachfolgend „TWINT“) oder eines Smart-Device verwenden.

#### a) Lediglich informatorische Nutzung

Für die nur informatorische Nutzung unserer Plattform ist es grundsätzlich nicht erforderlich, dass Sie personenbezogene Daten angeben. Vielmehr erheben und verwenden wir in diesem Fall nur diejenigen Ihrer Daten, die uns Ihr Internetbrowser automatisch übermittelt (insbesondere Ihre IP-Adresse). Diese Informationen werden – in nicht anonymisierter Form – lediglich für die Dauer Ihres Plattform-Besuchs gespeichert und eine Auswertung erfolgt im Nachhinein in anonymisierter Form lediglich zu statistischen Zwecken. Es werden daher keine personenbezogenen Daten über Sie erhoben.

#### b) Aktive Nutzung der Plattform / Parkuhren mit Kennzeicheneingaben

Bei der Nutzung einer solchen Parkuhr müssen Sie zwingend Ihr Kennzeichen eingeben.

Die Betreiber der von Ihnen benutzten Parkplätze erhalten Zugriffe auf folgende Daten von Ihnen:

* Kennzeichen
* Parkuhrnummer
* Parkzeit und Parkgebühr

#### c) Aktive Nutzung der Plattform / Parkingpay

Sofern Sie die Plattform aktiv nutzen und sich als Benutzer registrieren, werden wir die von Ihnen im Rahmen der Registrierung zwingend anzugebenden Daten speichern und verarbeiten, um Ihnen die Buchung und Bezahlung von kostenpflichtigen Parkplätzen über Parkingpay zu ermöglichen.

Es handelt sich hierbei um folgende Daten:

* E-Mail Adresse
* Name und Vorname
* Firmenname (nur Firmenkunden)
* Adresse
* Kennzeichen

Wir erheben, verarbeiten und nutzen die von Ihnen zur Verfügung gestellten personenbezogenen Daten soweit es für die Begründung, Durchführung oder Beendigung eines solchen rechtsgeschäftlichen oder rechtsgeschäftsähnlichen Vertrages mit Ihnen oder zwischen Ihnen und dem Betreiber der von Ihnen genutzten Parkplätze erforderlich ist. Wir dürfen Ihre personenbezogenen Daten zum Zwecke der Begründung, Durchführung und Abwicklung des Vertragsverhältnisses mit uns und zwischen Ihnen und dem Betreiber der von Ihnen genutzten Parkplätze erheben, verarbeiten und nutzen.

Die Betreiber der von Ihnen genutzten Parkplätze erhalten Zugriffe auf folgende Daten von Ihnen:

* Kennzeichen oder Badgenummer
* Kontoinhaber (Vorname Nachname oder Firmenname)
* Zone (evt. Parkuhr und Parkplatznummer) oder Parking
* Parkzeit und Parkgebühr

Die Betreiber haben keine Einsicht in oder Zugriff auf Ihr Benutzerkonto; sie dürfen lediglich Inhaber, Zahlungsweise und Saldo (nur bei Vorauszahlung) in einem dedizierten Betreiberportal abfragen.

Ausserdem speichern wir als Teil Ihres Benutzerkontos bei jeder Aktion folgende Daten:

* Datum / Zeit
* Ereignistyp
* Erhalten bzw. übermittelte Daten

Die Daten werden nur bei Support-Fällen verwendet und werden nicht systematisch ausgewertet. Die Betreiber haben keine Einsicht in diesen Daten. Diese Daten werden 3 Monate aufbewahrt und dann automatisch gelöscht.

#### d) Aktive Nutzung der Plattform / TWINT

Sofern Sie die Plattform über TWINT aktiv nutzen, werden wir die von Ihnen im Rahmen der ersten Nutzung zwingend anzugebenden Daten in einem anonymen Benutzerkonto speichern und verarbeiten, um Ihnen die Buchung und Bezahlung von kostenpflichtigen Parkplätzen über Parkingpay zu ermöglichen.

Es handelt sich hierbei um folgende Daten:

* Kennzeichen
* Referenz zum TWINT-Konto (pseudonymisierte IDs)

Wir erheben, verarbeiten und nutzen die von Ihnen zur Verfügung gestellten personenbezogenen Daten soweit es für die Begründung, Durchführung oder Beendigung eines solchen rechtsgeschäftlichen oder rechtsgeschäftsähnlichen Vertrages mit Ihnen oder zwischen Ihnen und dem Betreiber der von Ihnen genutzten Parkplätze erforderlich ist. Wir dürfen Ihre personenbezogenen Daten zum Zwecke der Begründung, Durchführung und Abwicklung des Vertragsverhältnisses mit uns und zwischen Ihnen und dem Betreiber der von Ihnen genutzten Parkplätze erheben, verarbeiten und nutzen.

Die Betreiber der von Ihnen genutzten Parkplätze erhalten Zugriffe auf folgende Daten von Ihnen:

* Kennzeichen
* Zone (evt. Parkuhr und Parkplatznummer)
* Parkzeit und Parkgebühr

Die Betreiber haben keine Einsicht in oder Zugriff auf Ihr Benutzerkonto.

Ausserdem speichern wir als Teil Ihres Benutzerkontos bei jeder Aktion auf unsere Platform folgende Daten:

* Datum / Zeit
* Ereignistyp
* Erhalten bzw. übermittelte Daten

Die Daten werden nur bei Support-Fällen verwendet und werden nicht systematisch ausgewertet. Die Betreiber haben keine Einsicht in diesen Daten. Diese Daten werden 3 Monate aufbewahrt und dann automatisch gelöscht.

Unpersönliche Zugriffsdaten der Plattform

Wir sammeln Informationen über Sie, wenn Sie die Plattform nutzen. Wir erfassen automatisch Informationen über Ihr Nutzungsverhalten und Ihre Interaktion mit uns und registrieren Daten zu Ihrem Computer oder Mobilgerät. Wir erheben, speichern und nutzen Daten über jeden Zugriff auf unsere Plattform (sogenannte Serverlogfiles). Zu den Zugriffsdaten gehören:

* Name und URL der abgerufenen Datei
* Datum und Uhrzeit des Abrufs
* übertragene Datenmenge
* Meldung über erfolgreichen Abruf (HTTP response code)
* Browsertyp und Browserversion
* Betriebssystem
* Referer URL (d.h. die zuvor besuchte Seite)
* Websites, die vom System des Nutzers über unsere Website aufgerufen werden
* Internet-Service-Provider des Nutzers
* IP-Adresse und der anfragende Provider

Wir nutzen diese Protokolldaten ohne Zuordnung zu Ihrer Person oder sonstiger Profilerstellung für statistische Auswertungen zum Zweck des Betriebs, der Sicherheit und der Optimierung unserer Website, aber auch zur anonymen Erfassung der Anzahl der Besucher auf unserer Website (traffic) sowie zum Umfang und zur Art der Nutzung unserer Website und Dienste. Aufgrund dieser Informationen können wir den Datenverkehr analysieren, Fehler suchen und beheben und unsere Dienste verbessern. Im Anwendungsbereich der DSGVO liegt hierin auch unser berechtigtes Interesse gemäss Art 6 Abs. 1 S. 1 f) DSGVO.

Wir behalten uns vor, die Protokolldaten nachträglich zu überprüfen, wenn aufgrund konkreter Anhaltspunkte der berechtigte Verdacht einer rechtswidrigen Nutzung besteht. Nach einem Monat löschen wir die Logfiles, wenn diese für Sicherheitszwecke nicht mehr erforderlich ist und wenn wir keinen konkreten Verdacht einer Straftat im Zusammenhang mit der Nutzung unserer Website haben.

### 4. Ihre Rechte

Nach den anwendbaren Gesetzen haben Sie verschiedene Rechte bezüglich Ihrer personenbezogenen Daten. Möchten Sie diese Rechte geltend machen, so richten Sie Ihre Anfrage bitte per E-Mail oder per Post unter eindeutiger Identifizierung Ihrer Person an die in Ziffer9 genannte Adresse.

Nachfolgend finden Sie eine Übersicht über Ihre Rechte.

#### 4.1. Recht auf Bestätigung und Auskunft

Sie haben das Recht auf eine übersichtliche Auskunft über die Verarbeitung Ihrer personenbezogenen Daten. Entsprechend haben Sie jederzeit das Recht, von uns eine Bestätigung darüber zu erhalten, ob Sie betreffende personenbezogene Daten verarbeitet werden. Ist dies der Fall, so haben Sie das Recht, von uns eine unentgeltliche Auskunft über die zu Ihnen gespeicherten personenbezogenen Daten nebst einer Kopie dieser Daten zu verlangen. Des Weiteren besteht ein Recht auf folgende Informationen:

* die Verarbeitungszwecke;
* die Kategorien personenbezogener Daten, die verarbeitet werden;
* die Empfänger oder Kategorien von Empfängern, gegenüber denen die personenbezogenen Daten offengelegt worden sind oder noch offengelegt werden, insbesondere bei Empfängern in Drittländern oder bei internationalen Organisationen;
* falls möglich, die geplante Dauer, für die die personenbezogenen Daten gespeichert werden, oder, falls dies nicht möglich ist, die Kriterien für die Festlegung dieser Dauer;
* das Bestehen eines Rechts auf Berichtigung oder Löschung der Sie betreffenden personenbezogenen Daten oder auf Einschränkung der Verarbeitung durch den Verantwortlichen oder eines Widerspruchsrechts gegen diese Verarbeitung;
* das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde;
* wenn die personenbezogenen Daten nicht bei Ihnen erhoben werden, alle verfügbaren Informationen über die Herkunft der Daten;
* das Bestehen einer automatisierten Entscheidungsfindung einschliesslich Profiling gemäss Art. 22 Abs. 1 und 4 DSGVO und – zumindest in diesen Fällen – aussagekräftige Informationen über die involvierte Logik sowie die Tragweite und die angestrebten Auswirkungen einer derartigen Verarbeitung für Sie.
* Werden personenbezogene Daten an ein Drittland oder an eine internationale Organisation übermittelt, so haben Sie das Recht, über die geeigneten Garantien gemäss Art. 46 DSGVO im Zusammenhang mit der Übermittlung unterrichtet zu werden.

#### 4.2. Recht auf Berichtigung

Sie haben das Recht, von uns die Berichtigung und ggf. auch Vervollständigung Sie betreffender personenbezogener Daten zu verlangen. Entsprechend haben Sie das Recht, von uns unverzüglich die Berichtigung Sie betreffender unrichtiger personenbezogener Daten zu verlangen. Unter Berücksichtigung der Zwecke der Verarbeitung haben Sie das Recht, die Vervollständigung unvollständiger personenbezogener Daten – auch mittels einer ergänzenden Erklärung – zu verlangen.

Sie können die in Ihrem Benutzerkonto gespeicherten personenbezogenen Daten zudem jederzeit direkt bearbeiten.

#### 4.3. Recht auf Löschung ("Recht auf Vergessenwerden")

In einer Reihe von Fällen sind wir verpflichtet, Sie betreffende personenbezogene Daten zu löschen. Entsprechend haben Sie insbesondere im Anwendungsbereich gemäss Art. 17 Abs. 1 DSGVO das Recht, von uns zu verlangen, dass Sie betreffende personenbezogene Daten unverzüglich gelöscht werden, und wir sind verpflichtet, personenbezogene Daten unverzüglich zu löschen, sofern einer der folgenden Gründe zutrifft:

* Die personenbezogenen Daten sind für die Zwecke, für die sie erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr notwendig.
* Sie widerrufen Ihre Einwilligung, auf die sich die Verarbeitung stützte, und es fehlt an einer anderweitigen Rechtsgrundlage für die Verarbeitung.
* Sie legen gemäss Art. 21 Abs. 1 DSGVO Widerspruch gegen die Verarbeitung ein und es liegen keine vorrangigen berechtigten Gründe für die Verarbeitung vor, oder Sie legen gemäss Art. 21 Abs. 2 DSGVO Widerspruch gegen die Verarbeitung ein.
* Die personenbezogenen Daten wurden unrechtmässig verarbeitet.
* Die Löschung der personenbezogenen Daten ist zur Erfüllung einer rechtlichen Verpflichtung der wir unterliegen.
* Die personenbezogenen Daten wurden in Bezug auf angebotene Dienste der Informationsgesellschaft gemäss Art. 8 Abs. 1 DSGVO erhoben.

Sie können die in Ihrem Benutzerkonto gespeicherten personenbezogenen Daten sowie das Konto als Ganzes jederzeit löschen.

#### 4.4. Recht auf Einschränkung der Verarbeitung

In einer Reihe von Fällen sind Sie berechtigt, von uns eine Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Entsprechend haben Sie das Recht, von uns die Einschränkung der Verarbeitung zu verlangen, wenn eine der folgenden Voraussetzungen gegeben ist:

* die Richtigkeit der personenbezogenen Daten wird von Ihnen bestritten, und zwar für eine Dauer, die es uns ermöglicht, die Richtigkeit der personenbezogenen Daten zu überprüfen,
* die Verarbeitung unrechtmässig ist und Sie die Löschung der personenbezogenen Daten ablehnten und stattdessen die Einschränkung der Nutzung der personenbezogenen Daten verlangt haben;
* wir die personenbezogenen Daten für die Zwecke der Verarbeitung nicht länger benötigen, Sie die Daten jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen, oder
* Sie Widerspruch gegen die Verarbeitung gemäss Art. 21 Abs. 1 DSGVO eingelegt haben, solange noch nicht feststeht, ob die berechtigten Gründe unseres Unternehmens gegenüber den Ihren überwiegen.

#### 4.5. Recht auf Datenübertragbarkeit im Anwendungsbereich der DSGVO

Sie haben im Anwendungsbereich der DSGVO das Recht, Sie betreffende personenbezogene Daten maschinenlesbar zu erhalten, zu übermitteln, oder von uns übermitteln zu lassen. Entsprechend haben Sie das Recht, die Sie betreffenden personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten, und Sie haben das Recht, diese Daten einem anderen Verantwortlichen ohne Behinderung durch uns zu übermitteln, sofern die Verarbeitung auf einer Einwilligung gemäss Art. 6 Abs. 1 S. 1 a) DSGVO oder Art. 9 Abs. 2 a) DSGVO oder auf einem Vertrag gemäss Art. 6 Abs. 1 S. 1 b) DSGVO beruht und die Verarbeitung mithilfe automatisierter Verfahren erfolgt.

Bei der Ausübung Ihres Rechts auf Datenübertragbarkeit gemäss Absatz 1 haben Sie das Recht, zu erwirken, dass die personenbezogenen Daten direkt von uns einem anderen Verantwortlichen übermittelt werden, soweit dies technisch machbar ist.

Sie können die in Ihrem Benutzerkonto gespeicherten Daten einschliesslich Transaktionsdaten jederzeit in Form einer CSV-Datei exportieren.

#### 4.6. Widerspruchsrecht im Anwendungsbereich der DSGVO

Sie haben das Recht, aus einer rechtmässigen Verarbeitung Ihrer personenbezogenen Daten durch uns zu widersprechen, wenn sich dies aus Ihrer besonderen Situation begründet und unsere Interessen an der Verarbeitung nicht überwiegen. Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung Sie betreffender personenbezogener Daten, die aufgrund von Art. 6 Abs. 1 S. 1 e) oder f) DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling. Wir verarbeiten die personenbezogenen Daten nicht mehr, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.

Werden personenbezogene Daten von uns verarbeitet, um Direktwerbung zu betreiben, so haben Sie das Recht, jederzeit Widerspruch gegen die Verarbeitung Sie betreffender personenbezogener Daten zum Zwecke derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht.

#### 4.7. Recht auf Widerruf einer datenschutzrechtlichen Einwilligung

Sie haben das Recht, eine von Ihnen erteilte Einwilligung zur Verarbeitung personenbezogener Daten jederzeit zu widerrufen.

#### 4.8. Recht auf Beschwerde bei einer Aufsichtsbehörde

Sie haben das Recht auf Beschwerde bei einer Aufsichtsbehörde, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten rechtswidrig ist. In der Schweiz können Sie sich an den Eidgenössischen Datenschutz- und Öffentlichkeitsbeauftragten wenden (www.edoeb.admin.ch) und ausserhalb der Schweiz an die für Ihre Sie aufgrund Ihres Wohnsitzes oder Aufenthaltsortes zuständige Aufsichtsbehörde.

### 5. Externe Dienstleister / Datentransfer ins Ausland

Grundsätzlich verwenden wir Ihre personenbezogenen Daten nur innerhalb unseres Unternehmens. Wenn und soweit wir Dritte im Rahmen der Erfüllung von Verträgen einschalten (etwa Logistik-Dienstleister), erhalten diese personenbezogene Daten nur in dem Umfang, in welchem die Übermittlung für die entsprechende Leistung erforderlich ist.

Für den Fall, dass wir bestimmte Teile der Datenverarbeitung auslagern („Auftragsverarbeitung“), verpflichten wir Auftragsverarbeiter vertraglich dazu, personenbezogene Daten nur im Einklang mit den Anforderungen der Datenschutzgesetze zu verwenden und den Schutz der Rechte der betroffenen Person zu gewährleisten. Eine Datenübertragung an Stellen oder Personen ausserhalb der Schweiz bzw. EU ausserhalb der in dieser Erklärung genannten Fälle findet nicht statt.

#### 5.1. Hosting

Die von uns in Anspruch genommenen Hosting-Leistungen dienen der Zurverfügungstellung der folgenden Leistungen: Infrastruktur- und Plattformdienstleistungen, Rechenkapazität, Speicherplatz und Datenbankdienste, Sicherheitsleistungen sowie technische Wartungsleistungen, die wir zum Zweck des Betriebs der Website einsetzen.

Hierbei verarbeiten wir, bzw. unser Hostinganbieter Bestandsdaten, Kontaktdaten, Inhaltsdaten, Vertragsdaten, Nutzungsdaten, Meta- und Kommunikationsdaten von Kunden, Interessenten und Besuchern dieser Website auf Grundlage unserer berechtigten Interessen an einer effizienten und sicheren Zurverfügungstellung unserer Website (im Anwendungsbereich der DSGVO massgebliche Bestimmungen sind die Art. 6 Abs. 1 S. 1 f) DSGVO i.V.m. Art. 28 DSGVO).

#### 5.2. Payment – Dienstleister

Zur Realisierung der Bezahlvorgänge setzen wir den Payment-Dienstleister Datatrans AG und TWINT AG ein. Wir haben mit Datatrans einen Auftragsverarbeitervertrag nach Art. 28 DSGVO.

### 6. Cookies und Local Storage

Ein Cookie ist eine kleine Textdatei, die verschiedene Informationen beinhaltet und auf Ihrem Endgerät durch dem Web-Browser abgelegt wird.

Den Local Storage (HTML5 Local Storage) ist eine kleine Datenbank, die verschiedene Informationen beinhaltet und auf Ihrem Endgerät im Web-Browser integriert ist.

#### a) Lediglich informatorische Nutzung

Wir verwenden weder die Cookies noch den Local Storage.

#### b) Aktive Nutzung der Plattform / Parkingpay

Wir verwenden die Cookies und die Local Storage wenn Sie sich in unsere Webapplikationen einloggen.

Die Cookies werden benutzt, um die verschiedenen Anfragen Ihres Browsers der gemeinsamen authentifizierten Sitzung zuzuordnen und somit Ihr Konto vor unbefugtem Zugriff zu schützen. Diese Cookies werden auf Ihrer Festplatte gespeichert und löschen sich nach der vorgegebenen Zeit automatisch. Ihre Lebensdauer beträgt entweder 1 Tag („Login speichern“ inaktiv) oder 90 Tage („Login speichern“ aktiv).

In den Cookies werden etwa folgende Daten und Informationen gespeichert:

* Session-Identifikation (pseudonymisierte IDs)

Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies vorab informiert werden und im Einzelfall entscheiden können, ob Sie die Annahme von Cookies für bestimmte Fälle oder generell ausschliessen, oder dass Cookies komplett verhindert werden. Dadurch kann die Funktionalität der Webapplikation eingeschränkt werden.

Der Local Storage wird benutzt um die Benutzerfreundlichkeit der Applikation zu erhöhen. Die Informationen im Local Storage werden nie automatisch gelöscht.

Im Local Storage werden folgende Daten und Informationen gespeichert:

* Sprachcode
* Letzte ausgewähltes Optionen (pseudonymisierte IDs)
* Letzte ausgewählte PLZ

Sie können Ihren Browser so einstellen, dass es Local Storage Informationen komplett verhindert werden. Dadurch kann die Funktionalität der Webapplikation eingeschränkt werden.

Unser berechtigtes Interesse an der Nutzung der Cookies und des Local Storage gemäss Art 6 Abs. 1 S. 1 f) DSGVO liegt darin, unsere Webapplikationen nutzerfreundlicher, effektiver und sicherer zu machen.

### 7. Speicherdauer

Sofern nicht spezifisch angegeben speichern wir personenbezogene Daten nur so lange, wie dies zur Erfüllung der verfolgten Zwecke notwendig ist.

In einigen Fällen sieht der Gesetzgeber die Aufbewahrung von personenbezogenen Daten vor, etwa im Steuer- oder Handelsrecht. In diesen Fällen werden die Daten von uns lediglich für diese gesetzlichen Zwecke weiter gespeichert, aber nicht anderweitig verarbeitet und nach Ablauf der gesetzlichen Aufbewahrungsfrist gelöscht.

### 8. Datensicherheit

Wir sind um die Sicherheit Ihrer Daten im Rahmen der geltenden Datenschutzgesetze und technischen Möglichkeiten maximal bemüht.

Ihre persönlichen Daten werden bei uns verschlüsselt mit dem Codierungssystem SSL (Secure Socket Layer) übertragen. Dies gilt für Ihre Stammdaten, Ihre Transaktionen und auch für das Kundenlogin. Wir weisen jedoch darauf hin, dass die Datenübertragung im Internet Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.

Zur Sicherung Ihrer Daten unterhalten wir technische und organisatorische Sicherungsmassnahmen entsprechend Art. 32 DSGVO, die wir immer wieder dem Stand der Technik anpassen.

Wir gewährleisten ausserdem nicht, dass unser Angebot zu bestimmten Zeiten zur Verfügung steht; Störungen, Unterbrechungen oder Ausfälle können nicht ausgeschlossen werden. Die von uns verwendeten Server werden regelmässig sorgfältig gesichert.

### 9. Ansprechpartner

Bei Fragen zur Erhebung, Verarbeitung oder Nutzung Ihrer personenbezogenen Daten, bei Auskünften, Berichtigung, Sperrung oder Löschung von personenbezogenen Daten sowie Widerruf von Einwilligungen oder Widerspruch der Bearbeitung wenden Sie sich an:

Postadresse: Digitalparking AG, Rütistrasse 13, 8952 Schlieren

E-Mail Adresse: privacy@digitalparking.ch
                        `,
    fr: `
# DÉCLARATION SUR LA PROTECTION DES DONNÉES

## POUR LES USAGERS
## Version 11.2018

### 1. Généralités

La protection des données et leur sécurité sont pour nous une priorité. C’est pourquoi nous avons pris, dans le cadre des prescriptions légales sur la protection des données, de vastes mesures techniques et organisationnelles qui garantissent la protection de vos données à caractère personnel. Nous vous soumettons des offres par différents canaux (p. ex. site internet et application smartphone, ci-après collectivement: la «plateforme»).

Dans la présente déclaration sur la protection des données, nous vous informons sur l’utilisation de vos données personnelles ainsi que sur les cookies et les technologies similaires qui sont utilisés sur notre plateforme.

La collecte et le traitement de vos données à caractère personnel ont lieu dans le respect des prescriptions légales en matière de protection des données, en particulier de la loi suisse sur la protection des données (LPD) et du Règlement général européen sur la protection des données (RGPD), dans la mesure où ils s’appliquent respectivement aux différents cas concrets.

### 2. Fournisseur responsable

Le fournisseur responsable de la plateforme et des données à caractère personnel collectées et traitées dans le cadre de votre utilisation de la plateforme est la société Digitalparking SA, Rütistrasse 13, CH-8952 Schlieren.

### 3. Collecte, traitement et utilisation de vos données à caractère personnel

Les données à caractère personnel vous concernant que nous collectons, traitons et utilisons diffèrent dans leur quantité et leur genre selon que vous ne faites que visiter notre plateforme et l’utiliser à titre informatif ou que vous correspondez avec nous via notre plateforme, c'est-à-dire l’utilisez de manière active, en tant qu’utilisateur de l’application web ou smartphone Parkingpay (ci-après: «Parkingpay»), de l’appli TWINT (ci-après: „TWINT“) ou d’un appareil connecté.

#### a) Utilisation à titre purement informatif

Pour une utilisation de notre plateforme à titre purement informatif, il n’est pas nécessaire que vous fournissiez des données à caractère personnel. Dans ce cas, plus exactement, nous collectons uniquement celles de vos données que votre navigateur Internet nous transmet automatiquement (en particulier votre adresse IP). Ces informations sont enregistrées – sous forme non anonymisée – uniquement pendant la durée de votre visite sur la plateforme, et elles ne sont exploitées ensuite sous forme anonymisée qu’à des fins de statistiques. Aucune donnée à caractère personnel n’est donc collectée à votre sujet.

#### b) Utilisation active de la plateforme: parcomètres avec saisie de la plaque

Lorsque vous utilisez un tel parcomètre, vous devez obligatoirement saisir votre numéro d’immatriculation.

Les exploitants des places de parc que vous utilisez reçoivent l’accès aux données suivantes vous concernant:

* Plaque d’immatriculation
* Numéro du parcomètre
* Temps de stationnement et taxe de stationnement

#### c) Utilisation active de la plateforme: Parkingpay

Si vous utilisez la plateforme de manière active et vous enregistrez en tant qu’utilisateur, nous sauvegardons les données que vous devez fournir dans le cadre de l’enregistrement et les traiterons pour vous permettre d’utiliser et de payer des places de parc via Parkingpay.

Il s’agit des données suivantes:

* Adresse e-mail
* Nom et prénom
* Nom de l’entreprise (seulement si le client est une entreprise)
* Adresse
* Plaque d’immatriculation
    Nous collectons, traitons et utilisons les données à caractère personnel que vous avez mises à notre disposition dans la mesure nécessaire pour justifier, exécuter ou résilier un contrat passé avec vous ou entre vous et l’exploitant des places de parc que vous utilisez. Nous avons le droit de collecter, de traiter et d’utiliser vos données à caractère personnel pour justifier, exécuter et organiser la relation contractuelle avec nous et entre vous et l’exploitant des places de parc que vous utilisez.

Les exploitants des places de parc que vous utilisez reçoivent l’accès aux données suivantes vous concernant:

* Plaque d’immatriculation ou numéro de badge
* Titulaire du compte (prénom et nom, ou nom de l’entreprise)
* Zone (év. parcomètre et numéro de place) ou parking
* Temps de stationnement et taxe de stationnement

Les exploitants n’ont pas accès à votre compte d’utilisateur et ne peuvent pas le consulter; ils peuvent seulement appeler les données suivantes: titulaire, mode de paiement et solde (seulement en cas de paiement d’avance), sur un portail d’exploitant dédié.

En outre, lors de chaque action, nous enregistrons les données suivantes qui font partie de votre compte utilisateur:

* Date / heure
* Type d’événement
* Données reçues ou transmises

Ces données ne sont utilisées que dans des cas de support et ne sont pas systématiquement évaluées. Les exploitants n’ont pas accès à ces données. Elles sont conservées pendant 3 mois, puis sont automatiquement effacées.

#### d) Utilisation active de la plateforme: TWINT

Si vous utilisez la plateforme de manière active via l’appli TWINT, nous sauvegardons, dans un compte utilisateur anonyme, les données que vous devez fournir dans le cadre de la première utilisation et les traiterons pour vous permettre d’utiliser et de payer des places de stationnement via Parkingpay.

Il s’agit des données suivantes:

* Plaque d’immatriculation
* Référence au compte TWINT (ID pseudonymisées)

Nous collectons, traitons et utilisons les données à caractère personnel que vous avez mises à notre disposition dans la mesure nécessaire pour justifier, exécuter ou résilier un contrat passé avec vous ou entre vous et l’exploitant des places de parc que vous utilisez. Nous avons le droit de collecter, de traiter et d’utiliser vos données à caractère personnel pour justifier, exécuter et organiser la relation contractuelle avec nous et entre vous et l’exploitant des places de parc que vous utilisez.

Les exploitants des places de parc que vous utilisez reçoivent l’accès aux données suivantes vous concernant:

* Plaque d’immatriculation
* Zone (év. parcomètre et numéro de place) ou parking
* Temps de stationnement et taxe de stationnement

Les exploitants n’ont pas accès à votre compte d’utilisateur et ne peuvent pas le consulter.

En outre, lors de chaque action, nous enregistrons les données suivantes qui font partie de votre compte utilisateur:

* Date / heure
* Type d’événement
* Données reçues ou transmises

Ces données ne sont utilisées que dans des cas de support et ne sont pas systématiquement évaluées. Les exploitants n’ont pas accès à ces données. Elles sont conservées pendant 3 mois, puis sont automatiquement effacées.

Données d’accès à la plateforme qui n’ont pas de caractère personnel

Lorsque vous utilisez la plateforme, nous collectons des informations à votre sujet. Nous saisissons automatiquement des informations sur votre comportement d’utilisation et votre interaction avec nous, et nous enregistrons des données relatives à votre ordinateur ou appareil portable. Nous collectons, enregistrons et utilisons des données sur chaque accès à notre plateforme (ce qu’on appelle des fichiers journaux de serveur). Ces données d’accès comprennent:

* Nom et URL du fichier appelé
* Date et heure de l’appel
* Volume des données transmises
* Signal d'aboutissement de l’appel (HTTP response code)
* Type et version du navigateur
* Système d’exploitation
* URL du referrer (c’est-à-dire de la page précédemment consultée)
* Sites Internet qui sont appelés par le système de l’utilisateur, via notre site Internet
* Fournisseur d’accès internet de l’utilisateur
* Adresse IP et le fournisseur d’accès requérant

Nous utilisons ces données de protocole sans les rattacher à votre personne ni établir d’autre profil, dans un but statistique lié à l’exploitation, à la sécurité et à l’optimisation de notre site internet, mais aussi pour la saisie anonyme du nombre des visiteurs de notre site (trafic) ainsi que concernant la quantité et le genre d’utilisations qui sont faites de notre site Internet et de nos services. Sur la base de ces informations, nous pouvons analyser le trafic des données, rechercher et réparer des erreurs et améliorer nos services. À cet égard, nos intérêts légitimes prévus à l’art. 6, par. 1, point 1 f) RGPD sont également compris dans le champ d’application du RGPD.

Nous nous réservons le droit de vérifier après coup les données de protocole si des éléments concrets éveillent le soupçon d’une utilisation contraire à la loi. Au bout d’un mois, nous effaçons les fichiers journaux lorsqu’ils ne nous sont plus nécessaires à des fins de sécurité et lorsqu’il n’y a pas de soupçon concret d’une infraction pénale liée à l’utilisation de notre site Internet.

### 4. Vos droits

Selon les lois applicables, vous disposez de différents droits concernant vos données à caractère personnel. Si vous souhaitez exercer ces droits, veuillez adresser votre demande à l’adresse figurant au chiffre 8, par e-mail ou par la poste, en vous identifiant sans équivoque.

Vous trouvez ci-dessous un récapitulatif de vos droits.

#### 4.1. Droit à la confirmation et à la communication

Vous avez le droit d’être clairement renseigné sur le traitement de vos données à caractère personnel. En conséquence, si des données à caractère personnel vous concernant sont traitées, vous avez à tout moment le droit d’en obtenir de notre part la confirmation. Si c’est le cas, vous avez le droit d’exiger de notre part une communication gratuite concernant les données à caractère personnel enregistrées à votre sujet, ainsi qu’une copie de ces données. Il existe également un droit aux informations suivantes:

* la finalité du traitement;
* les catégories de données à caractère personnel qui sont traitées;
* les destinataires ou catégories de destinataires à qui les données à caractère personnel ont été ou seront communiquées, notamment quand il y a des destinataires dans des pays tiers ou des organisations internationales;
* si possible, la durée prévue pendant laquelle les données à caractère personnel seront enregistrées, ou, si ce n'est pas possible, les critères selon lesquels cette durée est fixée;
* l’existence d’un droit à la rectification ou à l’effacement des données à caractère personnel ou à ce que le traitement par le responsable soit limité; ou l’existence d’un droit de s’opposer à ce traitement;
* l’existence d’un droit de réclamation auprès d’une autorité de contrôle;
* si les données à caractère personnel n’ont pas été collectées auprès de vous, toutes les informations disponibles sur la provenance des données;
* l’existence d’un processus de décision automatisé, y compris profilage, au sens de l’art. 22, par. 1 et 4 RGPD et – du moins dans ces cas – des informations compréhensibles sur la logique impliquée ainsi que sur la portée et les effets escomptés pour vous d’un tel traitement.
* Lorsque des données à caractère personnel sont transférées vers un pays tiers ou une organisation internationale, vous avez le droit d’être informé des garanties appropriées qui doivent accompagner ce transfert, au sens de l’art. 46 RGPD.

#### 4.2. Droit de rectification

Vous avez le droit d'exiger que nous rectifiions et, le cas échéant, complétions des données à caractère personnel qui vous concernent. Dans ce sens, vous avez le droit d’obtenir de nous, dans les meilleurs délais, la rectification de données à caractère personnel inexactes vous concernant. En tenant compte des finalités du traitement, vous avez le droit d’exiger que des données à caractère personnel incomplètes soient complétées, y compris en fournissant une déclaration complémentaire.

Vous pouvez en outre à tout moment modifier directement les données à caractère personnel qui sont enregistrées sur votre compte utilisateur.

#### 4.3. Droit à l’effacement («droit à l’oubli»)

Dans une série de cas, nous avons l’obligation d’effacer des données à caractère personnel vous concernant. Ainsi, vous avez le droit d’obtenir de notre part, plus particulièrement dans le champ d’application de l’art. 17, par. 1 RGPD, dans les meilleurs délais, l’effacement de données à caractère personnel vous concernant, et nous avons l’obligation d’effacer dans les meilleurs délais des données à caractère personnel lorsque l’un des motifs suivants s’applique:

* Les données à caractère personnel ne sont plus nécessaires au regard des finalités pour lesquelles elles ont été collectées ou traitées d'une autre manière.
* Vous retirez le consentement sur lequel est fondé le traitement, et il n'existe pas d'autre fondement juridique au traitement.
* Vous vous opposez au traitement en vertu de l’art. 21, par. 1 RGPD et il n'existe pas de motif légitime impérieux pour le traitement, ou vous vous opposez au traitement en vertu de l’art. 21, par. 2 RGPD.
* Les données à caractère personnel ont fait l'objet d'un traitement illicite.
* Les données à caractère personnel doivent être effacées pour respecter une obligation légale à laquelle nous sommes soumis.
* Les données à caractère personnel ont été collectées dans le cadre de l'offre de services de la société de l'information visée à l’art. 8, par. 1 RGPD.

Vous pouvez à tout moment effacer les données à caractère personnel enregistrées sur votre compte utilisateur ou effacer entièrement ce compte lui-même.

#### 4.4. Droit à la limitation du traitement

Dans une série de cas, vous avez le droit d’obtenir de notre part une limitation du traitement de vos données à caractère personnel. Ainsi, vous avez le droit d'obtenir que nous limitions le traitement lorsqu'un des éléments suivants s'applique:

* Vous contestez l'exactitude des données à caractère personnel, pendant une durée qui nous permet de vérifier l'exactitude des données à caractère personnel.
* Le traitement est illicite et vous vous êtes opposé à l’effacement des données à caractère personnel et avez exigé à la place la limitation de leur utilisation.
* Nous n'avons plus besoin des données à caractère personnel aux fins du traitement mais celles-ci vous sont encore nécessaires pour la constatation, l'exercice ou la défense de vos droits.
* Vous êtes opposé au traitement en vertu de l’art. 21, par. 1 RGPD, pendant la vérification portant sur le point de savoir si les motifs légitimes poursuivis par notre entreprise prévalent sur les vôtres.

#### 4.5. Droit à la portabilité des données relevant du champ d’application du RGPD

Dans le champ d’application du RGPD, vous avez le droit de recevoir, de transmettre ou de vous faire transmettre de notre part, dans un format lisible par machine, des données à caractère personnel vous concernant. Ainsi, vous avez le droit de recevoir, dans un format structuré, couramment utilisé et lisible par machine, les données à caractère personnel vous concernant que vous nous avez fournies et vous avez le droit de transmettre ces données à un autre responsable de traitement sans que nous y fassions obstacle, lorsque le traitement est fondé sur le consentement en application de l'art. 6, par. 1, point 1 a), ou de l'art. 9, par. 2 a) RGPD, ou sur un contrat en application de l'art. 6, par. 1, point 1 b) RGPD, et lorsque le traitement est effectué à l'aide de procédés automatisés.

Dans l’exercice de votre droit à la portabilité des données prévu au paragraphe 1 de l’article précité, vous avez le droit d'obtenir que nous transmettions directement les données à caractère personnel à un autre responsable de traitement, lorsque cela est techniquement possible.

Vous pouvez à tout moment exporter sous forme d’un fichier CSV les données qui sont enregistrées sur votre compte utilisateur, y compris les données de transaction.

#### 4.6. Droit d’opposition dans le champ d’application du RGPD

Vous avez le droit de vous opposer, pour des raisons tenant à votre situation particulière, à ce que nous traitions vos données à caractère personnel, à moins que nous n’ayons un intérêt prépondérant à ce traitement. Vous avez le droit de vous opposer à tout moment, pour des raisons tenant à votre situation particulière, à un traitement des données à caractère personnel vous concernant fondé sur l'art. 6, par. 1, points 1 e) ou f) RGPD, y compris de vous opposer à un profilage fondé sur ces dispositions. Nous ne traitons plus vos données à caractère personnel, à moins que nous ne démontrions qu'il existe des motifs de traitement légitimes et impérieux, qui prévalent sur vos intérêts, droits et libertés, ou à moins que le traitement serve à la constatation et à l'exercice de vos droits ou à leur défense en justice.

Lorsque nous traitons des données à caractère personnel à des fins de prospection, vous avez le droit de vous opposer à tout moment à ce traitement des données à caractère personnel vous concernant, y compris au profilage dans la mesure où il est lié à une telle prospection.

#### 4.7. Droit de retirer son consentement en matière de protection des données

Vous avez le droit de retirer à tout moment un consentement donné pour le traitement de données à caractère personnel.

#### 4.8. Droit de réclamation auprès d’une autorité de contrôle

Vous avez le droit d’introduire une réclamation auprès d’une autorité de contrôle si vous estimez que le traitement des données à caractère personnel vous concernant est contraire à la loi. En Suisse, vous pouvez vous adresser au préposé fédéral à la protection des données et à la transparence (www.edoeb.admin.ch), et hors de Suisse, à l’autorité de contrôle compétente pour vous en raison de votre domicile ou de votre lieu de résidence.

### 5. Prestataires externes / transfert de données à l’étranger

En principe, nous n’utilisons vos données à caractère personnel qu’au sein de notre entreprise. Si nous faisons intervenir des tiers (par exemple pour des services de logistique) dans le cadre de l’exécution de contrats, ces personnes peuvent recevoir des données à caractère personnel, mais uniquement dans la mesure où cette transmission est nécessaire à la prestation correspondante.
Si nous déléguons certaines parties du traitement de données («sous-traitance»), nous soumettons nos sous-traitants, par contrat, à la double obligation d’utiliser des données à caractère personnel uniquement conformément aux exigences des lois sur la protection des données et de garantir la protection des droits de la personne concernée. Aucun transfert de données à des organismes ou à des personnes hors de Suisse ou hors de l’UE n’a lieu dans d’autres cas que ceux évoqués dans la présente déclaration.

#### 5.1. Hébergement

Les prestations d’hébergement auxquelles nous recourons servent à mettre à disposition les prestations suivantes: services d’infrastructure et de plateforme, capacité de calcul, emplacements mémoire et services de banque de données, prestations de sécurité ainsi que prestations techniques de maintenance que nous mettons en œuvre pour le bon fonctionnement du site Internet.
Nous ou notre hébergeur traitons alors des données de base (données d’inventaire), des données de contact, de contenu, de contrats, d’utilisation, des métadonnées et données de communication de clients, de personnes intéressées et de visiteurs de ce site Internet, sur la base de notre intérêt légitime à mettre à disposition notre site Internet de manière efficace et sûre (les dispositions déterminantes dans le champ d’application du RGPD sont les art. 6, par. 1, point 1 f) RGPD en combinaison avec l’art. 28 RGPD).

#### 5.2. Prestataire pour les paiements

Pour la réalisation des procédures de paiement, nous recourons au prestataire Datatrans SA et TWINT SA. Nous avons avec Datatrans SA un contrat de sous-traitance au sens de l’art. 28 RGPD.

### 6. Cookies et local storage

Un cookie est un petit fichier texte qui contient différentes informations et est enregistré par le navigateur web sur votre appareil terminal.

Local storage (HTML5 Local Storage) est une petite base de données qui contient différentes informations et est intégrée dans le navigateur web sur votre appareil terminal.

#### a) Utilisation à titre purement informatif

Nous n’utilisons ni les cookies, ni le local storage.

#### b) Utilisation active de la plateforme: Parkingpay

Nous utilisons les cookies et le local storage quand vous ouvrez une session dans nos applications web.

Les cookies servent à assigner à la session commune authentifiée les différentes requêtes de votre navigateur, et donc à protéger votre compte contre un accès non autorisé. Ces cookies sont enregistrés sur votre disque dur et s’effacent automatiquement après le temps prescrit. Leur durée de vie est soit d’une journée («Rester connecté» désactivé), soit de 90 jours («Rester connecté» activé).

Les données et informations suivantes, par exemple, sont enregistrées dans les cookies:

* Identification de session (ID pseudonymisées)

Vous pouvez configurer votre navigateur pour être informé à l’avance quand des cookies sont mis en place et décider dans chaque cas d’exclure l'acceptation de cookies dans certains cas ou de manière générale, voire de les bloquer complètement. Cela peut restreindre les fonctionnalités de l’application web.

Le local storage est utilisé pour améliorer la convivialité de l’application. Les informations du local storage ne sont jamais effacées automatiquement.

Les données et informations suivantes sont enregistrées dans le local storage:

* Code de langue
* Dernières options sélectionnées (ID pseudonymisées)
* Dernier NPA sélectionné

Vous pouvez configurer votre navigateur pour qu’il bloque entièrement les informations local storage. Cela peut restreindre les fonctionnalités de l’application web.

Au sens de l’art. 6, par. 1, point 1 f) RGPD, l’intérêt légitime que présente pour nous l’utilisation des cookies et du local storage est de nous permettre de rendre nos applications web plus conviviales, plus efficaces et plus sûres.

### 7. Durée de l’enregistrement

Sauf indication contraire spécifique, nous n’enregistrons les données à caractère personnel qu’aussi longtemps que c’est nécessaire à la réalisation des buts poursuivis.

Dans certains cas, par exemple en droit fiscal ou commercial, le législateur prévoit que des données à caractère personnel doivent être conservées. Dans ces cas, nous continuons à mémoriser les données, mais uniquement dans ces buts légaux; nous ne les traitons pas autrement et elles sont effacées à l’expiration du délai légal de conservation.

### 8. Sécurité des données

Dans les limites des possibilités techniques et de la législation en vigueur sur la protection des données, nous faisons le maximum pour que vos données soient en sécurité.

Chez nous, vos données personnelles sont transmises sous forme chiffrée, au moyen du système de codage SSL (Secure Socket Layer). C’est le cas pour vos données de base, vos transactions et aussi pour le login de client. Nous vous signalons toutefois que la transmission de données sur Internet peut présenter des failles de sécurité. Une protection totalement infaillible des données contre l’accès de tiers n'est pas possible.

Pour sécuriser vos données, nous maintenons des mesures techniques et organisationnelles correspondant à l’art. 32 RGPD et nous les adaptons régulièrement à l’état de la technique.

Pour le surplus, nous ne garantissons pas que notre offre soit disponible à certains moments; des perturbations, des interruptions ou des pannes ne peuvent pas être exclues. Les serveurs que nous utilisons sont régulièrement sécurisés avec soin.

### 9. Contact

Pour des questions sur la collecte, le traitement ou l’utilisation de vos données à caractère personnel, pour des renseignements, une rectification, un blocage ou l’effacement de données à caractère personnel ainsi que pour une opposition au traitement des données, veuillez-vous adresser à:

Adresse postale: Digitalparking SA, Rütistrasse 13, 8952 Schlieren

Adresse e-mail: privacy@digitalparking.ch
                        `,
    it: `
# INFORMATIVA SULLA PROTEZIONE DEI DATI

## PER GLI UTENTI
## Versione 11.2018

### 1. Aspetti generali

La protezione e la sicurezza dei dati hanno per noi assoluta priorità. Per questo motivo abbiamo intrapreso misure tecniche e organizzative complete, nell’ambito delle disposizioni in materia di protezione dei dati, al fine di garantire la protezione dei vostri dati personali. Noi predisponiamo per voi le offerte attraverso diversi canali (p.es. sito web e applicazione smartphone), denominati in generale “piattaforma”.

In questa informativa sulla protezione dei dati vi informiamo sull’utilizzo dei vostri dati personali e sull’utilizzo dei cookie e delle tecnologie simili nella nostra piattaforma.

La raccolta e il trattamento dei vostri dati personali avviene nel rispetto delle disposizioni vigenti in materia di protezione dei dati, soprattutto della Legge sulla protezione dei dati svizzera (LPD) e del Regolamento generale sulla protezione dei dati (GDPR), qualora questo trovi applicazione nel caso concreto del trattamento dei dati.

### 2. Fornitore responsabile

Il fornitore responsabile della piattaforma e dei dati personali raccolti e trattati nell’ambito del vostro utilizzo della piattaforma è Digitalparking SA, Rütistrasse 13, CH-8952 Schlieren.

### 3. Raccolta, trattamento e utilizzo dei vostri dati personali

L’entità e il tipo di raccolta, trattamento e utilizzo dei vostri dati personali varia a seconda che voi vi limitiate a visitare la nostra piattaforma e a utilizzarla per scopi informativi o se attraverso la piattaforma vi mettete in contatto con noi, ovvero se la usate attivamente come utente dell’applicazione web o smartphone Parkingpay (di seguito denominata «Parkingpay»), l’app TWINT (di seguito denominata „TWINT“) o di un dispositivo smart.

#### a) Utilizzo a scopo unicamente informativo

Per l’utilizzo della nostra piattaforma a scopo unicamente informativo, in linea generale non è necessario che voi comunichiate i vostri dati personali. In questo caso noi raccogliamo e utilizziamo solo i dati sul vostro conto che ci vengono trasmessi automaticamente dal vostro browser Internet (soprattutto il vostro indirizzo IP). Queste informazioni vengono salvate – in forma non anonimizzata – solo per la durata della vostra visita alla piattaforma, mentre successivamente viene effettuata una valutazione in forma anonimizzata a scopi unicamente statistici. Pertanto non vengono raccolti dati personali sul vostro conto.

#### b) Utilizzo attivo della piattaforma: parchimetri con inserimento del numero di targa

Quando utilizzate un parchimetro di questo tipo, voi dovete necessariamente inserire il numero di targa.

I gestori dei parcheggi da voi utilizzati hanno accesso ai seguenti dati:

* numero di targa
* numero del parchimetro
* tempo e tassa di parcheggio

#### c) Utilizzo attivo della piattaforma: Parkingpay

Se utilizzate la piattaforma attivamente e vi registrate come utente, noi salveremo e tratteremo i dati da voi inseriti nell’ambito della registrazione, al fine di consentirvi di utilizzare e pagare i parcheggi a pagamento attraverso Parkingpay.

Si tratta in questo caso dei seguenti dati:

* indirizzo e-mail
* nome e cognome
* nome dell’azienda (solo clienti aziendali)
* indirizzo
* numero di targa

Noi registriamo, trattiamo e utilizziamo i dati personali da voi messi a disposizione, nella misura in cui questi sono necessari per l’impostazione, l’esecuzione o la cessazione di un contratto di natura commerciale o simile con voi o tra voi e il gestore dei parcheggi da voi utilizzati. Noi siamo autorizzati a raccogliere, trattare e utilizzare i dati personali sul vostro conto allo scopo di impostare, eseguire ed espletare il rapporto contrattuale con noi e tra voi e il gestore dei parcheggi da voi utilizzati.

I gestori dei parcheggi da voi utilizzati hanno accesso ai seguenti dati:

* numero di targa
* zona (ev. parchimetro o numero del posto)
* tempo e tassa di parcheggio

I gestori non possono prendere visione né hanno accesso al vostro conto utente.

Inoltre, durante ogni azione all’interno del vostro conto utente, noi salviamo i seguenti dati:

* data/ora
* tipo di evento
* dati ricevuti o trasmessi

I dati vengono utilizzati solo nei casi di supporto e non vengono valutati sistematicamente. I gestori non possono prendere visione di tali dati. Questi dati vengono conservati per 3 mesi e quindi cancellati automaticamente.

#### d) Utilizzo attivo della piattaforma: TWINT

Se utilizzate la piattaforma attivamente tramite l’app TWINT, noi salveremo e tratteremo i dati da voi inseriti nell’ambito del primo utilizzo, al fine di consentirvi di utilizzare e pagare i parcheggi a pagamento attraverso Parkingpay.

Si tratta in questo caso dei seguenti dati:

* numero di targa
* Referenza al conto TWINT (ID pseudonimizzato)

Noi registriamo, trattiamo e utilizziamo i dati personali da voi messi a disposizione, nella misura in cui questi sono necessari per l’impostazione, l’esecuzione o la cessazione di un contratto di natura commerciale o simile con voi o tra voi e il gestore dei parcheggi da voi utilizzati. Noi siamo autorizzati a raccogliere, trattare e utilizzare i dati personali sul vostro conto allo scopo di impostare, eseguire ed espletare il rapporto contrattuale con noi e tra voi e il gestore dei parcheggi da voi utilizzati.

I gestori dei parcheggi da voi utilizzati hanno accesso ai seguenti dati:

* numero di targa o di badge
* titolare del conto (nome, cognome o nome dell’azienda)
* zona (ev. parchimetro o numero del posto) o parcheggio
* tempo e tassa di parcheggio

I gestori non possono prendere visione né hanno accesso al vostro conto utente; questi possono effettuare unicamente delle ricerche su titolare, modalità di pagamento e saldo (solo in caso di pagamento anticipato) in un portale gestori dedicato.

Inoltre, durante ogni azione all’interno del vostro conto utente, noi salviamo i seguenti dati:

* data/ora
* tipo di evento
* dati ricevuti o trasmessi

I dati vengono utilizzati solo nei casi di supporto e non vengono valutati sistematicamente. I gestori non possono prendere visione di tali dati. Questi dati vengono conservati per 3 mesi e quindi cancellati automaticamente.

Dati di accesso alla piattaforma che non hanno carattere personale

Noi raccogliamo informazioni su di voi ogni qualvolta utilizzate la piattaforma. Registriamo automaticamente informazioni sul vostro comportamento di utilizzo e sulla vostra interazione con noi e registriamo i dati relativi al vostro computer o dispositivo mobile. Noi registriamo, salviamo e utilizziamo dati su ogni accesso alla nostra piattaforma (cosiddetti file log del server). Fra i dati di accesso vi sono:

* nome e URL del file richiamato
* data e ora della richiesta
* quantità di dati trasferita
* notifica sulla richiesta con esito positivo (HTTP response code)
* tipo e versione del browser
* sistema operativo
* referer URL (ovvero la pagina precedentemente visitata)
* siti web che vengono richiamati dal sistema dell’utente attraverso il nostro sito web
* Internet Service Provider dell’utente
* indirizzo IP e provider richiedente

Noi utilizziamo questi dati di protocollo - senza associarli alla vostra persona né per creare profili di qualsiasi tipo - per valutazioni statistiche finalizzate alla gestione, alla sicurezza e all’ottimizzazione del nostro sito web, ma anche per registrare in forma anonima il numero dei visitatori del nostro sito web (traffic) e per valutare l’entità e il tipo di utilizzo del nostro sito web e dei nostri servizi. In base a queste informazioni noi possiamo analizzare il traffico dati, cercare e rimuovere gli errori e migliorare i nostri servizi. Nell’ambito di applicazione della GDPR sussiste anche il nostro interesse legittimo come da art. 6 par. 1 f. 1 f) GDPR.

Ci riserviamo il diritto di controllare a posteriori i dati di protocollo, quando a causa di circostanze concrete sussista il legittimo sospetto di un utilizzo non conforme alle regole. Dopo un mese cancelliamo i file log, qualora questi non siano più necessari per scopi di sicurezza o se non vi sono sospetti concreti di un reato relativamente all’utilizzo del nostro sito web.

### 4. I vostri diritti

Secondo le leggi vigenti, voi avete diversi diritti in materia di dati personali. Se desiderate rivendicare questi diritti, inviate la vostra richiesta via e-mail o per posta, identificando in modo univoco la vostra persona, all’indirizzo riportato al punto 8.

Di seguito trovate una panoramica dei vostri diritti.

#### 4.1. Diritto alla conferma e all’informazione

Avete il diritto di ottenere informazioni chiare sul trattamento dei vostri dati personali. A questo proposito voi avete il diritto, in ogni momento, di ricevere da noi una conferma se presso di noi vengono trattati dati personali su di voi. In caso positivo, voi avete il diritto di ottenere da noi informazioni a titolo gratuito sui dati personali salvati, oltre che una copia di questi dati. Sussiste altresì il diritto alle seguenti informazioni:

* finalità del trattamento;
* categorie di dati personali trattati;
* destinatari o categorie di destinatari a cui i dati personali sono stati o saranno comunicati, in particolare se destinatari di paesi terzi o organizzazioni internazionali;
* ove possibile, il periodo di conservazione dei dati personali previsto oppure, se non è possibile, i criteri utilizzati per determinare tale periodo;
* esistenza del diritto di chiedere al titolare del trattamento la rettifica o la cancellazione dei dati personali o la limitazione del trattamento dei dati personali che vi riguardano o di opporsi al loro trattamento;
* diritto di proporre reclamo a un'autorità di vigilanza;
* qualora i dati non siano raccolti presso di voi, tutte le informazioni disponibili sulla loro origine;
* esistenza di un processo decisionale automatizzato, compresa la profilazione di cui all'art. 22, par. 1 e 4 GDPR e, almeno in tali casi, informazioni significative sulla logica utilizzata, nonché l'importanza e le conseguenze previste di tale trattamento per voi;
* qualora i dati personali siano trasferiti a un paese terzo o a un'organizzazione internazionale, voi avete il diritto di essere informati dell'esistenza di garanzie adeguate ai sensi dell'art. 46 GDPR relative al trasferimento.

#### 4.2. Diritto di rettifica

Avete il diritto di richiederci la rettifica ed eventualmente anche il completamento dei dati personali che vi riguardano. Di conseguenza, avete il diritto di ottenere da noi la rettifica immediata dei dati personali inesatti che vi riguardano. Tenuto conto delle finalità del trattamento, avete il diritto di ottenere l'integrazione dei dati personali incompleti, anche fornendo una dichiarazione integrativa.

Potete inoltre elaborare i dati personali salvati nel vostro conto utenti in qualsiasi momento.

#### 4.3. Diritto alla cancellazione («diritto all'oblio»)

In una serie di casi siamo tenuti a cancellare i dati personali che vi riguardano. In tali circostanze voi avete in particolare nell’ambito di applicazione di cui all’art. 17 par. 1 GDPR il diritto di chiedere la cancellazione immediata dei dati personali che vi riguardano e noi siamo tenuti a cancellarli immediatamente in presenza di uno dei seguenti motivi:

* i dati personali non sono più necessari rispetto alle finalità per le quali sono stati raccolti o altrimenti trattati;
* voi revocate l’autorizzazione su cui si basava il trattamento e non vi sono altre basi giuridiche che consentano il trattamento,
* ai sensi dell’ art. 21 par. 1 GDPR voi vi opponete al trattamento e non vi sono altri motivi legittimi prevalenti che lo consentano, oppure ai sensi dell’art. 21 par. 2 GDPR voi vi opponete al trattamento,
* i dati personali sono stati trattati illecitamente,
* la cancellazione dei dati personali serve a ottemperare a un obbligo di legge cui siamo soggetti,
* i dati personali sono stati raccolti relativamente all'offerta di servizi della società dell'informazione di cui all'art. 8, par. 1 GDPR.

Potete cancellare in qualsiasi momento i dati personali salvati nel vostro conto utente nonché l’intero conto.

#### 4.4. Diritto di limitazione del trattamento

In una serie di casi avete il diritto di richiederci una limitazione del trattamento dei vostri dati personali. Nella fattispecie avete il diritto di ottenere da noi la limitazione del trattamento, quando ricorre una delle seguenti situazioni:

* voi contestate l'esattezza dei dati personali, per un periodo tale che permetta a noi di verificare l'esattezza di tali dati personali;
* il trattamento è illecito e voi vi siete opposti alla cancellazione dei dati personali e avete chiesto invece che ne fosse limitato l'utilizzo;
* benché noi non abbiamo più bisogno dei dati personali ai fini del trattamento, questi sono necessari a voi per la rivendicazione, l'esercizio o la difesa di pretese giuridiche oppure
* voi vi siete opposti al trattamento ai sensi dell’art. 21 par. 1 GDPR, sebbene non sia ancora chiaro se i motivi legittimi della nostra azienda prevalgano sui vostri.

#### 4.5. Diritto alla portabilità dei dati nell’ambito di applicazione del GDPR

Nell’ambito di applicazione del GDPR voi avete il diritto di ricevere, trasmettere o di far trasmettere da noi i dati personali che vi riguardano in un formato leggibile automaticamente da una macchina. Voi avete altresì il diritto di ricevere questi dati personali che ci avete fornito in un formato strutturato, di uso comune e leggibile automaticamente da una macchina e avete il diritto di trasmettere tali dati a un altro responsabile per il trattamento senza impedimenti da parte nostra, qualora il trattamento si basi su un consenso ai sensi dell’art. 6 par. 1 f. 1 a) GDPR o art. 9 par. 2 a) GDPR o su un contratto di cui all’art. 6 par. 1 f. 1 b) GDPR e se il trattamento avviene mediante procedure automatizzate.

Nell'esercitare i vostri diritti relativamente alla portabilità dei dati a norma del paragrafo 1, voi avete il diritto di ottenere la trasmissione dei dati personali direttamente da noi a un altro responsabile per il trattamento, se tecnicamente fattibile.

Potete esportare in ogni momento i dati salvati sul vostro conto utente compresi i dati delle transazioni sotto forma di file CSV.

#### 4.6. Diritto di opposizione nell’ambito di applicazione del GDPR

Avete il diritto di opporvi a un trattamento lecito dei vostri dati personali da parte nostra, qualora ciò sia motivato in virtù della vostra particolare situazione e se i nostri interessi al trattamento non siano prevalenti. Avete il diritto di opporvi in ogni momento, per motivi connessi alla vostra situazione particolare, al trattamento dei dati personali che vi riguardano ai sensi dell'art. 6, par. 1, f. 1 lettere e) o f) GDPR, compresa la profilazione sulla base di tali disposizioni. Noi ci asteniamo dal trattare ulteriormente i dati personali salvo che non dimostriamo l'esistenza di motivi preminenti e legittimi per procedere al trattamento che prevalgono sui vostri interessi, diritti e libertà oppure per la rivendicazione, l'esercizio o la difesa di pretese giuridiche.

Qualora i dati personali siano da noi trattati per finalità di marketing diretto, voi avete il diritto di opporvi in qualsiasi momento al trattamento dei dati personali che vi riguardano per scopi pubblicitari di tale natura, compresa la profilazione nella misura in cui sia connessa a tale marketing diretto.

#### 4.7. Diritto alla revoca di un consenso in materia di protezione dei dati

Voi avete il diritto di revocare in qualsiasi momento un consenso fornito ai fini del trattamento dei vostri dati personali.

#### 4.8. Diritto di proporre reclamo a un’autorità di vigilanza

Voi avete il diritto di proporre reclamo a un’autorità di vigilanza, se ritenete che il trattamento dei dati personali che vi riguardano sia illecito. In Svizzera potete rivolgervi all’Incaricato federale della protezione dei dati e della trasparenza (www.edoeb.admin.ch), mentre al di fuori della Svizzera all’autorità di vigilanza di competenza in base al vostro domicilio o luogo di dimora.

### 5. Fornitori di servizi esterni / Trasmissione di dati all’estero

In linea generale utilizziamo i vostri dati personali solo all’interno della nostra azienda. Se dovessimo attivare terzi relativamente all’esecuzione dei contratti (per esempio fornitori di servizi logistici), questi ricevono i dati personali solo nella misura in cui la trasmissione è necessaria per lo svolgimento delle relative prestazioni.

Nel caso in cui esternalizziamo determinate parti di trattamento dati («Elaborazione dell’ordine di trattamento»), noi vincoliamo contrattualmente i responsabili del trattamento a utilizzare i dati personali solo in linea con i requisiti delle leggi sulla protezione dei dati e a garantire la protezione dei diritti dell’interessato. La trasmissione dati a uffici o persone esternamente alla Svizzera risp. all’UE e al di fuori dei casi riportati nella presente informativa non ha luogo.

#### 5.1. Hosting

I servizi di hosting di cui ci serviamo servono per erogare i seguenti servizi: servizi relativi all’infrastruttura e alla piattaforma, capacità di calcolo, spazio di memoria e servizi di banche dati, servizi di sicurezza e di manutenzione tecnica che utilizziamo al fine di gestire la piattaforma.

Noi, ovvero il fornitore dei servizi di hosting, trattiamo nello specifico dati di base, di contatto, di contenuto, dati contrattuali, dati di utilizzo, metadati e dati di comunicazione di clienti, interessati e visitatori del presente sito web in base ai nostri legittimi interessi di un approntamento efficiente e sicuro della nostra piattaforma (nell’ambito di applicazione delle disposizioni del GDPR sono rilevanti le disposizioni di cui all’art. 6 par. 1 f. 1 f) GDPR unitamente all’art. 28 GDPR).

#### 5.2. Pagamento – Fornitore di servizi

Per realizzare le procedure di pagamento ci avvaliamo dei fornitori di servizi di pagamento Datatrans SA e TWINT SA. Con Datatrans SA abbiamo un contratto di responsabilità del trattamento di cui all’art. 28 GDPR.

### 6. Cookie e local storage

Un cookie è un piccolo file di testo contenente diverse informazioni che viene salvato sul vostro terminale dal web browser.

Il local storage (HTML5 Local Storage) è una piccola banca dati contenente diverse informazioni, che è integrata nel web browser del vostro terminale.

#### a) Utilizzo a scopo unicamente informativo

Noi non utilizziamo né i cookie né il local storage.

#### b) Utilizzo attivo della piattaforma: Parkingpay

Noi utilizziamo i cookie e il local storage se voi effettuate il login nelle nostre applicazioni web.

I cookie vengono utilizzati per assegnare le diverse richieste del vostro browser alla sessione autenticata comune e quindi per proteggere il vostro conto dall’accesso non autorizzato. Questi cookie vengono salvati sul vostri disco fisso e si cancellano automaticamente dopo un periodo definito. La loro durata di vita è di 1 giorno («Rimanere connessi» inattivo) o 90 giorni («Rimanere connessi» attivo).

Nei cookie vengono salvati i seguenti dati e le seguenti informazioni:

* identificazione della sessione (ID pseudonimizzati)

Potete impostare il vostro browser in modo che siate informati in anticipo sul salvataggio dei cookie e possiate decidere nei singoli casi se rifiutare i cookie in determinati casi o in generale o se bloccare completamente il salvataggio dei cookie. In questo caso la funzionalità dell’applicazione web può essere limitata.

Il local storage viene utilizzato per aumentare la facilità di utilizzo dell’applicazione. Le informazioni nel local storage non vengono mai cancellate automaticamente.

Nel local storage vengono salvati i seguenti dati e le seguenti informazioni:

* lingua
* ultime opzioni selezionate (ID pseudonimizzati)
* ultimi NPA utilizzati

Potete impostare il browser in modo che le informazioni del local storage vengano completamente bloccate. In questo caso la funzionalità dell’applicazione web può essere limitata.

Il nostro interesse legittimo all’utilizzo dei cookie e del local storage ai sensi dell'art. 6 par. 1 f. 1 f) GDPR consiste nel rendere più fruibili, efficaci e sicure le nostre applicazioni web.

### 7. Durata di conservazione dei dati

Qualora non sia specificato diversamente, noi conserviamo i dati personali solo per il periodo necessario per il soddisfacimento degli scopi perseguiti.

In alcuni casi il legislatore impone la conservazione dei dati personali, per esempio nel diritto fiscale o commerciale. In questi casi i dati continuano a essere da noi conservati unicamente per questi scopi giuridici, ma non vengono utilizzati in modi diversi e dopo la scadenza dei termini di conservazione i dati vengono cancellati.

### 8. Sicurezza dei dati

Ci adoperiamo al meglio per la sicurezza dei vostri dati nell'ambito delle leggi vigenti sulla protezione dei dati e delle possibilità tecniche.

I vostri dati vengono da noi trasmessi cifrati attraverso il sistema di codifica SSL (Secure Socket Layer). Questo vale per i vostri dati anagrafici, le vostre transazioni e anche per il login del cliente. Vi ricordiamo che la trasmissione dei dati in Internet può presentare delle lacune nella sicurezza. Non è possibile garantire una protezione dei dati perfetta dall’accesso da parte di terzi.

Per garantire la sicurezza dei vostri dati noi intraprendiamo misure di sicurezza tecniche e organizzative ai sensi dell’art. 32 GDPR, che adeguiamo sempre in funzione dello stato della tecnica.

Non assicuriamo inoltre che la nostra offerta sia disponibile in determinati periodi; guasti, interruzioni o malfunzionamenti non possono essere esclusi. I server da noi utilizzati sono messi regolarmente e accuratamente in sicurezza.

### 9. Interlocutore

In caso di domande sulla raccolta, il trattamento o l’utilizzo dei vostri dati personali e in caso di informazioni, rettifica, blocco o cancellazione dei dati personali o di revoca di consenso od opposizione al trattamento, rivolgetevi a:

Indirizzo postale: Digitalparking SA, Rütistrasse 13, 8952 Schlieren

Indirizzo e-mail: privacy@digitalparking.ch
                        `,
    en: `
# DATENSCHUTZERKLÄRUNG

## FÜR BENUTZER
## Version 11.2018

### 1. Allgemeines

Datenschutz und Datensicherheit haben für uns eine sehr hohe Priorität. Wir haben deshalb umfangreiche technische und organisatorische Massnahmen im Rahmen der datenschutzrechtlichen Vorschriften getroffen, die den Schutz ihrer personenbezogenen Daten sicherstellen. Wir stellen Ihnen Angebote über verschiedene Zugangswege (z.B. Website und Smartphone Applikation) bereit (zusammen die „Plattform“ genannt).

In dieser Datenschutzerklärung informieren wir Sie über die Verwendung Ihrer persönlichen Daten sowie über die Verwendung von Cookies oder ähnliche Technologien auf unserer Plattform.

Die Erhebung und Verarbeitung Ihrer personenbezogenen Daten geschieht unter Beachtung der geltenden datenschutzrechtlichen Vorschriften, insbesondere des Schweizer Datenschutzgesetzes (DSG) und der Europäischen Datenschutzgrundverordnung (DSGVO), insoweit diese im konkreten Fall auf die Datenverarbeitung Anwendung findet.

### 2. Verantwortlicher Anbieter

Verantwortlicher Anbieter der Plattform und der im Zusammenhang mit Ihrer Plattformnutzung erhobenen und verarbeiteten personenbezogenen Daten ist die Digitalparking AG, Rütistrasse 13, CH-8952 Schlieren.

### 3. Erhebung, Verarbeitung und Nutzung Ihrer personenbezogenen Daten

Der Umfang und die Art der Erhebung, Verarbeitung und Nutzung Ihrer personenbezogenen Daten unterscheidet sich danach, ob Sie unsere Plattform nur besuchen und informatorisch nutzen oder auch über unsere Plattform mit uns korrespondieren, bzw. diese aktiv als Benutzer der Parkingpay Web- oder Smartphone-Applikation (nachfolgend „Parkingpay“), der TWINT-App (nachfolgend „TWINT“) oder eines Smart-Device verwenden.

#### a) Lediglich informatorische Nutzung

Für die nur informatorische Nutzung unserer Plattform ist es grundsätzlich nicht erforderlich, dass Sie personenbezogene Daten angeben. Vielmehr erheben und verwenden wir in diesem Fall nur diejenigen Ihrer Daten, die uns Ihr Internetbrowser automatisch übermittelt (insbesondere Ihre IP-Adresse). Diese Informationen werden – in nicht anonymisierter Form – lediglich für die Dauer Ihres Plattform-Besuchs gespeichert und eine Auswertung erfolgt im Nachhinein in anonymisierter Form lediglich zu statistischen Zwecken. Es werden daher keine personenbezogenen Daten über Sie erhoben.

#### b) Aktive Nutzung der Plattform / Parkuhren mit Kennzeicheneingaben

Bei der Nutzung einer solchen Parkuhr müssen Sie zwingend Ihr Kennzeichen eingeben.

Die Betreiber der von Ihnen benutzten Parkplätze erhalten Zugriffe auf folgende Daten von Ihnen:

* Kennzeichen
* Parkuhrnummer
* Parkzeit und Parkgebühr

#### c) Aktive Nutzung der Plattform / Parkingpay

Sofern Sie die Plattform aktiv nutzen und sich als Benutzer registrieren, werden wir die von Ihnen im Rahmen der Registrierung zwingend anzugebenden Daten speichern und verarbeiten, um Ihnen die Buchung und Bezahlung von kostenpflichtigen Parkplätzen über Parkingpay zu ermöglichen.

Es handelt sich hierbei um folgende Daten:

* E-Mail Adresse
* Name und Vorname
* Firmenname (nur Firmenkunden)
* Adresse
* Kennzeichen

Wir erheben, verarbeiten und nutzen die von Ihnen zur Verfügung gestellten personenbezogenen Daten soweit es für die Begründung, Durchführung oder Beendigung eines solchen rechtsgeschäftlichen oder rechtsgeschäftsähnlichen Vertrages mit Ihnen oder zwischen Ihnen und dem Betreiber der von Ihnen genutzten Parkplätze erforderlich ist. Wir dürfen Ihre personenbezogenen Daten zum Zwecke der Begründung, Durchführung und Abwicklung des Vertragsverhältnisses mit uns und zwischen Ihnen und dem Betreiber der von Ihnen genutzten Parkplätze erheben, verarbeiten und nutzen.

Die Betreiber der von Ihnen genutzten Parkplätze erhalten Zugriffe auf folgende Daten von Ihnen:

* Kennzeichen oder Badgenummer
* Kontoinhaber (Vorname Nachname oder Firmenname)
* Zone (evt. Parkuhr und Parkplatznummer) oder Parking
* Parkzeit und Parkgebühr

Die Betreiber haben keine Einsicht in oder Zugriff auf Ihr Benutzerkonto; sie dürfen lediglich Inhaber, Zahlungsweise und Saldo (nur bei Vorauszahlung) in einem dedizierten Betreiberportal abfragen.

Ausserdem speichern wir als Teil Ihres Benutzerkontos bei jeder Aktion folgende Daten:

* Datum / Zeit
* Ereignistyp
* Erhalten bzw. übermittelte Daten

Die Daten werden nur bei Support-Fällen verwendet und werden nicht systematisch ausgewertet. Die Betreiber haben keine Einsicht in diesen Daten. Diese Daten werden 3 Monate aufbewahrt und dann automatisch gelöscht.

#### d) Aktive Nutzung der Plattform / TWINT

Sofern Sie die Plattform über TWINT aktiv nutzen, werden wir die von Ihnen im Rahmen der ersten Nutzung zwingend anzugebenden Daten in einem anonymen Benutzerkonto speichern und verarbeiten, um Ihnen die Buchung und Bezahlung von kostenpflichtigen Parkplätzen über Parkingpay zu ermöglichen.

Es handelt sich hierbei um folgende Daten:

* Kennzeichen
* Referenz zum TWINT-Konto (pseudonymisierte IDs)

Wir erheben, verarbeiten und nutzen die von Ihnen zur Verfügung gestellten personenbezogenen Daten soweit es für die Begründung, Durchführung oder Beendigung eines solchen rechtsgeschäftlichen oder rechtsgeschäftsähnlichen Vertrages mit Ihnen oder zwischen Ihnen und dem Betreiber der von Ihnen genutzten Parkplätze erforderlich ist. Wir dürfen Ihre personenbezogenen Daten zum Zwecke der Begründung, Durchführung und Abwicklung des Vertragsverhältnisses mit uns und zwischen Ihnen und dem Betreiber der von Ihnen genutzten Parkplätze erheben, verarbeiten und nutzen.

Die Betreiber der von Ihnen genutzten Parkplätze erhalten Zugriffe auf folgende Daten von Ihnen:

* Kennzeichen
* Zone (evt. Parkuhr und Parkplatznummer)
* Parkzeit und Parkgebühr

Die Betreiber haben keine Einsicht in oder Zugriff auf Ihr Benutzerkonto.

Ausserdem speichern wir als Teil Ihres Benutzerkontos bei jeder Aktion auf unsere Platform folgende Daten:

* Datum / Zeit
* Ereignistyp
* Erhalten bzw. übermittelte Daten

Die Daten werden nur bei Support-Fällen verwendet und werden nicht systematisch ausgewertet. Die Betreiber haben keine Einsicht in diesen Daten. Diese Daten werden 3 Monate aufbewahrt und dann automatisch gelöscht.

Unpersönliche Zugriffsdaten der Plattform

Wir sammeln Informationen über Sie, wenn Sie die Plattform nutzen. Wir erfassen automatisch Informationen über Ihr Nutzungsverhalten und Ihre Interaktion mit uns und registrieren Daten zu Ihrem Computer oder Mobilgerät. Wir erheben, speichern und nutzen Daten über jeden Zugriff auf unsere Plattform (sogenannte Serverlogfiles). Zu den Zugriffsdaten gehören:

* Name und URL der abgerufenen Datei
* Datum und Uhrzeit des Abrufs
* übertragene Datenmenge
* Meldung über erfolgreichen Abruf (HTTP response code)
* Browsertyp und Browserversion
* Betriebssystem
* Referer URL (d.h. die zuvor besuchte Seite)
* Websites, die vom System des Nutzers über unsere Website aufgerufen werden
* Internet-Service-Provider des Nutzers
* IP-Adresse und der anfragende Provider

Wir nutzen diese Protokolldaten ohne Zuordnung zu Ihrer Person oder sonstiger Profilerstellung für statistische Auswertungen zum Zweck des Betriebs, der Sicherheit und der Optimierung unserer Website, aber auch zur anonymen Erfassung der Anzahl der Besucher auf unserer Website (traffic) sowie zum Umfang und zur Art der Nutzung unserer Website und Dienste. Aufgrund dieser Informationen können wir den Datenverkehr analysieren, Fehler suchen und beheben und unsere Dienste verbessern. Im Anwendungsbereich der DSGVO liegt hierin auch unser berechtigtes Interesse gemäss Art 6 Abs. 1 S. 1 f) DSGVO.

Wir behalten uns vor, die Protokolldaten nachträglich zu überprüfen, wenn aufgrund konkreter Anhaltspunkte der berechtigte Verdacht einer rechtswidrigen Nutzung besteht. Nach einem Monat löschen wir die Logfiles, wenn diese für Sicherheitszwecke nicht mehr erforderlich ist und wenn wir keinen konkreten Verdacht einer Straftat im Zusammenhang mit der Nutzung unserer Website haben.

### 4. Ihre Rechte

Nach den anwendbaren Gesetzen haben Sie verschiedene Rechte bezüglich Ihrer personenbezogenen Daten. Möchten Sie diese Rechte geltend machen, so richten Sie Ihre Anfrage bitte per E-Mail oder per Post unter eindeutiger Identifizierung Ihrer Person an die in Ziffer9 genannte Adresse.

Nachfolgend finden Sie eine Übersicht über Ihre Rechte.

#### 4.1. Recht auf Bestätigung und Auskunft

Sie haben das Recht auf eine übersichtliche Auskunft über die Verarbeitung Ihrer personenbezogenen Daten. Entsprechend haben Sie jederzeit das Recht, von uns eine Bestätigung darüber zu erhalten, ob Sie betreffende personenbezogene Daten verarbeitet werden. Ist dies der Fall, so haben Sie das Recht, von uns eine unentgeltliche Auskunft über die zu Ihnen gespeicherten personenbezogenen Daten nebst einer Kopie dieser Daten zu verlangen. Des Weiteren besteht ein Recht auf folgende Informationen:

* die Verarbeitungszwecke;
* die Kategorien personenbezogener Daten, die verarbeitet werden;
* die Empfänger oder Kategorien von Empfängern, gegenüber denen die personenbezogenen Daten offengelegt worden sind oder noch offengelegt werden, insbesondere bei Empfängern in Drittländern oder bei internationalen Organisationen;
* falls möglich, die geplante Dauer, für die die personenbezogenen Daten gespeichert werden, oder, falls dies nicht möglich ist, die Kriterien für die Festlegung dieser Dauer;
* das Bestehen eines Rechts auf Berichtigung oder Löschung der Sie betreffenden personenbezogenen Daten oder auf Einschränkung der Verarbeitung durch den Verantwortlichen oder eines Widerspruchsrechts gegen diese Verarbeitung;
* das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde;
* wenn die personenbezogenen Daten nicht bei Ihnen erhoben werden, alle verfügbaren Informationen über die Herkunft der Daten;
* das Bestehen einer automatisierten Entscheidungsfindung einschliesslich Profiling gemäss Art. 22 Abs. 1 und 4 DSGVO und – zumindest in diesen Fällen – aussagekräftige Informationen über die involvierte Logik sowie die Tragweite und die angestrebten Auswirkungen einer derartigen Verarbeitung für Sie.
* Werden personenbezogene Daten an ein Drittland oder an eine internationale Organisation übermittelt, so haben Sie das Recht, über die geeigneten Garantien gemäss Art. 46 DSGVO im Zusammenhang mit der Übermittlung unterrichtet zu werden.

#### 4.2. Recht auf Berichtigung

Sie haben das Recht, von uns die Berichtigung und ggf. auch Vervollständigung Sie betreffender personenbezogener Daten zu verlangen. Entsprechend haben Sie das Recht, von uns unverzüglich die Berichtigung Sie betreffender unrichtiger personenbezogener Daten zu verlangen. Unter Berücksichtigung der Zwecke der Verarbeitung haben Sie das Recht, die Vervollständigung unvollständiger personenbezogener Daten – auch mittels einer ergänzenden Erklärung – zu verlangen.

Sie können die in Ihrem Benutzerkonto gespeicherten personenbezogenen Daten zudem jederzeit direkt bearbeiten.

#### 4.3. Recht auf Löschung ("Recht auf Vergessenwerden")

In einer Reihe von Fällen sind wir verpflichtet, Sie betreffende personenbezogene Daten zu löschen. Entsprechend haben Sie insbesondere im Anwendungsbereich gemäss Art. 17 Abs. 1 DSGVO das Recht, von uns zu verlangen, dass Sie betreffende personenbezogene Daten unverzüglich gelöscht werden, und wir sind verpflichtet, personenbezogene Daten unverzüglich zu löschen, sofern einer der folgenden Gründe zutrifft:

* Die personenbezogenen Daten sind für die Zwecke, für die sie erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr notwendig.
* Sie widerrufen Ihre Einwilligung, auf die sich die Verarbeitung stützte, und es fehlt an einer anderweitigen Rechtsgrundlage für die Verarbeitung.
* Sie legen gemäss Art. 21 Abs. 1 DSGVO Widerspruch gegen die Verarbeitung ein und es liegen keine vorrangigen berechtigten Gründe für die Verarbeitung vor, oder Sie legen gemäss Art. 21 Abs. 2 DSGVO Widerspruch gegen die Verarbeitung ein.
* Die personenbezogenen Daten wurden unrechtmässig verarbeitet.
* Die Löschung der personenbezogenen Daten ist zur Erfüllung einer rechtlichen Verpflichtung der wir unterliegen.
* Die personenbezogenen Daten wurden in Bezug auf angebotene Dienste der Informationsgesellschaft gemäss Art. 8 Abs. 1 DSGVO erhoben.

Sie können die in Ihrem Benutzerkonto gespeicherten personenbezogenen Daten sowie das Konto als Ganzes jederzeit löschen.

#### 4.4. Recht auf Einschränkung der Verarbeitung

In einer Reihe von Fällen sind Sie berechtigt, von uns eine Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Entsprechend haben Sie das Recht, von uns die Einschränkung der Verarbeitung zu verlangen, wenn eine der folgenden Voraussetzungen gegeben ist:

* die Richtigkeit der personenbezogenen Daten wird von Ihnen bestritten, und zwar für eine Dauer, die es uns ermöglicht, die Richtigkeit der personenbezogenen Daten zu überprüfen,
* die Verarbeitung unrechtmässig ist und Sie die Löschung der personenbezogenen Daten ablehnten und stattdessen die Einschränkung der Nutzung der personenbezogenen Daten verlangt haben;
* wir die personenbezogenen Daten für die Zwecke der Verarbeitung nicht länger benötigen, Sie die Daten jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen, oder
* Sie Widerspruch gegen die Verarbeitung gemäss Art. 21 Abs. 1 DSGVO eingelegt haben, solange noch nicht feststeht, ob die berechtigten Gründe unseres Unternehmens gegenüber den Ihren überwiegen.

#### 4.5. Recht auf Datenübertragbarkeit im Anwendungsbereich der DSGVO

Sie haben im Anwendungsbereich der DSGVO das Recht, Sie betreffende personenbezogene Daten maschinenlesbar zu erhalten, zu übermitteln, oder von uns übermitteln zu lassen. Entsprechend haben Sie das Recht, die Sie betreffenden personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten, und Sie haben das Recht, diese Daten einem anderen Verantwortlichen ohne Behinderung durch uns zu übermitteln, sofern die Verarbeitung auf einer Einwilligung gemäss Art. 6 Abs. 1 S. 1 a) DSGVO oder Art. 9 Abs. 2 a) DSGVO oder auf einem Vertrag gemäss Art. 6 Abs. 1 S. 1 b) DSGVO beruht und die Verarbeitung mithilfe automatisierter Verfahren erfolgt.

Bei der Ausübung Ihres Rechts auf Datenübertragbarkeit gemäss Absatz 1 haben Sie das Recht, zu erwirken, dass die personenbezogenen Daten direkt von uns einem anderen Verantwortlichen übermittelt werden, soweit dies technisch machbar ist.

Sie können die in Ihrem Benutzerkonto gespeicherten Daten einschliesslich Transaktionsdaten jederzeit in Form einer CSV-Datei exportieren.

#### 4.6. Widerspruchsrecht im Anwendungsbereich der DSGVO

Sie haben das Recht, aus einer rechtmässigen Verarbeitung Ihrer personenbezogenen Daten durch uns zu widersprechen, wenn sich dies aus Ihrer besonderen Situation begründet und unsere Interessen an der Verarbeitung nicht überwiegen. Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung Sie betreffender personenbezogener Daten, die aufgrund von Art. 6 Abs. 1 S. 1 e) oder f) DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling. Wir verarbeiten die personenbezogenen Daten nicht mehr, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.

Werden personenbezogene Daten von uns verarbeitet, um Direktwerbung zu betreiben, so haben Sie das Recht, jederzeit Widerspruch gegen die Verarbeitung Sie betreffender personenbezogener Daten zum Zwecke derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht.

#### 4.7. Recht auf Widerruf einer datenschutzrechtlichen Einwilligung

Sie haben das Recht, eine von Ihnen erteilte Einwilligung zur Verarbeitung personenbezogener Daten jederzeit zu widerrufen.

#### 4.8. Recht auf Beschwerde bei einer Aufsichtsbehörde

Sie haben das Recht auf Beschwerde bei einer Aufsichtsbehörde, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten rechtswidrig ist. In der Schweiz können Sie sich an den Eidgenössischen Datenschutz- und Öffentlichkeitsbeauftragten wenden (www.edoeb.admin.ch) und ausserhalb der Schweiz an die für Ihre Sie aufgrund Ihres Wohnsitzes oder Aufenthaltsortes zuständige Aufsichtsbehörde.

### 5. Externe Dienstleister / Datentransfer ins Ausland

Grundsätzlich verwenden wir Ihre personenbezogenen Daten nur innerhalb unseres Unternehmens. Wenn und soweit wir Dritte im Rahmen der Erfüllung von Verträgen einschalten (etwa Logistik-Dienstleister), erhalten diese personenbezogene Daten nur in dem Umfang, in welchem die Übermittlung für die entsprechende Leistung erforderlich ist.

Für den Fall, dass wir bestimmte Teile der Datenverarbeitung auslagern („Auftragsverarbeitung“), verpflichten wir Auftragsverarbeiter vertraglich dazu, personenbezogene Daten nur im Einklang mit den Anforderungen der Datenschutzgesetze zu verwenden und den Schutz der Rechte der betroffenen Person zu gewährleisten. Eine Datenübertragung an Stellen oder Personen ausserhalb der Schweiz bzw. EU ausserhalb der in dieser Erklärung genannten Fälle findet nicht statt.

#### 5.1. Hosting

Die von uns in Anspruch genommenen Hosting-Leistungen dienen der Zurverfügungstellung der folgenden Leistungen: Infrastruktur- und Plattformdienstleistungen, Rechenkapazität, Speicherplatz und Datenbankdienste, Sicherheitsleistungen sowie technische Wartungsleistungen, die wir zum Zweck des Betriebs der Website einsetzen.

Hierbei verarbeiten wir, bzw. unser Hostinganbieter Bestandsdaten, Kontaktdaten, Inhaltsdaten, Vertragsdaten, Nutzungsdaten, Meta- und Kommunikationsdaten von Kunden, Interessenten und Besuchern dieser Website auf Grundlage unserer berechtigten Interessen an einer effizienten und sicheren Zurverfügungstellung unserer Website (im Anwendungsbereich der DSGVO massgebliche Bestimmungen sind die Art. 6 Abs. 1 S. 1 f) DSGVO i.V.m. Art. 28 DSGVO).

#### 5.2. Payment – Dienstleister

Zur Realisierung der Bezahlvorgänge setzen wir den Payment-Dienstleister Datatrans AG und TWINT AG ein. Wir haben mit Datatrans einen Auftragsverarbeitervertrag nach Art. 28 DSGVO.

### 6. Cookies und Local Storage

Ein Cookie ist eine kleine Textdatei, die verschiedene Informationen beinhaltet und auf Ihrem Endgerät durch dem Web-Browser abgelegt wird.

Den Local Storage (HTML5 Local Storage) ist eine kleine Datenbank, die verschiedene Informationen beinhaltet und auf Ihrem Endgerät im Web-Browser integriert ist.

#### a) Lediglich informatorische Nutzung

Wir verwenden weder die Cookies noch den Local Storage.

#### b) Aktive Nutzung der Plattform / Parkingpay

Wir verwenden die Cookies und die Local Storage wenn Sie sich in unsere Webapplikationen einloggen.

Die Cookies werden benutzt, um die verschiedenen Anfragen Ihres Browsers der gemeinsamen authentifizierten Sitzung zuzuordnen und somit Ihr Konto vor unbefugtem Zugriff zu schützen. Diese Cookies werden auf Ihrer Festplatte gespeichert und löschen sich nach der vorgegebenen Zeit automatisch. Ihre Lebensdauer beträgt entweder 1 Tag („Login speichern“ inaktiv) oder 90 Tage („Login speichern“ aktiv).

In den Cookies werden etwa folgende Daten und Informationen gespeichert:

* Session-Identifikation (pseudonymisierte IDs)

Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies vorab informiert werden und im Einzelfall entscheiden können, ob Sie die Annahme von Cookies für bestimmte Fälle oder generell ausschliessen, oder dass Cookies komplett verhindert werden. Dadurch kann die Funktionalität der Webapplikation eingeschränkt werden.

Der Local Storage wird benutzt um die Benutzerfreundlichkeit der Applikation zu erhöhen. Die Informationen im Local Storage werden nie automatisch gelöscht.

Im Local Storage werden folgende Daten und Informationen gespeichert:

* Sprachcode
* Letzte ausgewähltes Optionen (pseudonymisierte IDs)
* Letzte ausgewählte PLZ

Sie können Ihren Browser so einstellen, dass es Local Storage Informationen komplett verhindert werden. Dadurch kann die Funktionalität der Webapplikation eingeschränkt werden.

Unser berechtigtes Interesse an der Nutzung der Cookies und des Local Storage gemäss Art 6 Abs. 1 S. 1 f) DSGVO liegt darin, unsere Webapplikationen nutzerfreundlicher, effektiver und sicherer zu machen.

### 7. Speicherdauer

Sofern nicht spezifisch angegeben speichern wir personenbezogene Daten nur so lange, wie dies zur Erfüllung der verfolgten Zwecke notwendig ist.

In einigen Fällen sieht der Gesetzgeber die Aufbewahrung von personenbezogenen Daten vor, etwa im Steuer- oder Handelsrecht. In diesen Fällen werden die Daten von uns lediglich für diese gesetzlichen Zwecke weiter gespeichert, aber nicht anderweitig verarbeitet und nach Ablauf der gesetzlichen Aufbewahrungsfrist gelöscht.

### 8. Datensicherheit

Wir sind um die Sicherheit Ihrer Daten im Rahmen der geltenden Datenschutzgesetze und technischen Möglichkeiten maximal bemüht.

Ihre persönlichen Daten werden bei uns verschlüsselt mit dem Codierungssystem SSL (Secure Socket Layer) übertragen. Dies gilt für Ihre Stammdaten, Ihre Transaktionen und auch für das Kundenlogin. Wir weisen jedoch darauf hin, dass die Datenübertragung im Internet Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.

Zur Sicherung Ihrer Daten unterhalten wir technische und organisatorische Sicherungsmassnahmen entsprechend Art. 32 DSGVO, die wir immer wieder dem Stand der Technik anpassen.

Wir gewährleisten ausserdem nicht, dass unser Angebot zu bestimmten Zeiten zur Verfügung steht; Störungen, Unterbrechungen oder Ausfälle können nicht ausgeschlossen werden. Die von uns verwendeten Server werden regelmässig sorgfältig gesichert.

### 9. Ansprechpartner

Bei Fragen zur Erhebung, Verarbeitung oder Nutzung Ihrer personenbezogenen Daten, bei Auskünften, Berichtigung, Sperrung oder Löschung von personenbezogenen Daten sowie Widerruf von Einwilligungen oder Widerspruch der Bearbeitung wenden Sie sich an:

Postadresse: Digitalparking AG, Rütistrasse 13, 8952 Schlieren

E-Mail Adresse: privacy@digitalparking.ch
                        `,
};

export const DataProtectionTexts_202207: Translation = {
    de: `
# DATENSCHUTZERKLÄRUNG

## FÜR BENUTZER
## Version 07.2022

### 1. Allgemeines

Datenschutz und Datensicherheit haben für uns eine sehr hohe Priorität. Wir haben deshalb umfangreiche technische und organisatorische Massnahmen im Rahmen der datenschutzrechtlichen Vorschriften getroffen, die den Schutz ihrer personenbezogenen Daten sicherstellen. Wir stellen Ihnen Angebote über verschiedene Zugangswege (z.B. Website und Smartphone Applikation) bereit (zusammen die „Plattform“ genannt).

In dieser Datenschutzerklärung informieren wir Sie über die Verwendung Ihrer persönlichen Daten sowie über die Verwendung von Cookies oder ähnliche Technologien auf unserer Plattform.

Die Erhebung und Verarbeitung Ihrer personenbezogenen Daten geschieht unter Beachtung der geltenden datenschutzrechtlichen Vorschriften, insbesondere des Schweizer Datenschutzgesetzes (DSG) und der Europäischen Datenschutzgrundverordnung (DSGVO), insoweit diese im konkreten Fall auf die Datenverarbeitung Anwendung findet.

### 2. Verantwortlicher Anbieter

Verantwortlicher Anbieter der Plattform und der im Zusammenhang mit Ihrer Plattformnutzung erhobenen und verarbeiteten personenbezogenen Daten ist die Digitalparking AG, Rütistrasse 13, CH-8952 Schlieren.

### 3. Erhebung, Verarbeitung und Nutzung Ihrer personenbezogenen Daten

Der Umfang und die Art der Erhebung, Verarbeitung und Nutzung Ihrer personenbezogenen Daten unterscheidet sich danach, ob Sie unsere Plattform nur besuchen und informatorisch nutzen oder auch über unsere Plattform mit uns korrespondieren, bzw. diese aktiv als Benutzer der Parkingpay Web- oder Smartphone-Applikation (nachfolgend „Parkingpay“), der Parkingabo Web- oder Smartphone-Applikation (nachfolgend „Parkingabo“), der TWINT-App (nachfolgend „TWINT“) oder eines Smart-Device verwenden.

#### a) Lediglich informatorische Nutzung

Für die nur informatorische Nutzung unserer Plattform ist es grundsätzlich nicht erforderlich, dass Sie personenbezogene Daten angeben. Vielmehr erheben und verwenden wir in diesem Fall nur diejenigen Ihrer Daten, die uns Ihr Internetbrowser automatisch übermittelt (insbesondere Ihre IP-Adresse). Diese Informationen werden – in nicht anonymisierter Form – lediglich für die Dauer Ihres Plattform-Besuchs gespeichert und eine Auswertung erfolgt im Nachhinein in anonymisierter Form lediglich zu statistischen Zwecken. Es werden daher keine personenbezogenen Daten über Sie erhoben.

#### b) Aktive Nutzung der Plattform / Parkuhren mit Kennzeicheneingaben

Bei der Nutzung einer solchen Parkuhr müssen Sie zwingend Ihr Kennzeichen eingeben.

Der Betreiber der von Ihnen genutzten Parkplätze erhält Zugriff auf ihre Transaktionen; letztere enthalten die folgenden Daten:

* Kennzeichen
* Parkuhrnummer
* Parkzeit und Parkgebühr

#### c) Aktive Nutzung der Plattform / Parkingpay

Sofern Sie die Plattform aktiv nutzen und sich als Benutzer registrieren, werden wir die von Ihnen im Rahmen der Registrierung zwingend anzugebenden Daten speichern und verarbeiten, um Ihnen die Buchung und Bezahlung von kostenpflichtigen Parkplätzen über Parkingpay zu ermöglichen.

Es handelt sich hierbei um folgende Daten:

* E-Mail Adresse
* Name und Vorname
* Firmenname (nur Firmenkunden)
* Adresse
* Kennzeichen

Wir erheben, verarbeiten und nutzen die von Ihnen zur Verfügung gestellten personenbezogenen Daten soweit es für die Begründung, Durchführung oder Beendigung eines solchen rechtsgeschäftlichen oder rechtsgeschäftsähnlichen Vertrages mit Ihnen oder zwischen Ihnen und dem Betreiber der von Ihnen genutzten Parkplätze erforderlich ist. Wir dürfen Ihre personenbezogenen Daten zum Zwecke der Begründung, Durchführung und Abwicklung des Vertragsverhältnisses mit uns und zwischen Ihnen und dem Betreiber der von Ihnen genutzten Parkplätze erheben, verarbeiten und nutzen.

Der Betreiber der von Ihnen genutzten Parkplätze erhält Zugriff auf ihre Transaktionen; letztere enthalten die folgenden Daten:

* Kennzeichen, Badgenummer, QR-Code-Referenz oder Ticketnummer
* Kontoinhaber (Vorname Nachname oder Firmenname)
* Zone (evt. Parkuhr und Parkplatznummer) oder Parking
* Parkzeit und Parkgebühr

Die Betreiber haben keine Einsicht in oder Zugriff auf Ihr Benutzerkonto; sie dürfen lediglich Inhaber, Zahlungsweise und Saldo (nur bei Vorauszahlung) in einem dedizierten Betreiberportal abfragen.
Ausserdem speichern wir als Teil Ihres Benutzerkontos bei jeder Aktion folgende Daten:

* Datum / Zeit
* Ereignistyp
* Erhalten bzw. übermittelte Daten

Die Daten werden nur bei Support-Fällen verwendet und werden nicht systematisch ausgewertet. Die Betreiber haben keine Einsicht in diesen Daten. Diese Daten werden 3 Monate aufbewahrt und dann automatisch gelöscht.

#### d) Aktive Nutzung der Plattform / TWINT

Sofern Sie die Plattform via die TWINT-App aktiv nutzen, werden wir die von Ihnen im Rahmen der ersten Nutzung angebenden Daten in einem anonymen Benutzerkonto speichern und verarbeiten, um Ihnen die Buchung und Bezahlung von kostenpflichtigen Parkplätzen über Parkingpay zu ermöglichen.

Es handelt sich hierbei um folgende Daten:

* Kennzeichen
* Referenz zum TWINT-Konto (pseudonymisierte IDs)

Wir erheben, verarbeiten und nutzen die von Ihnen zur Verfügung gestellten personenbezogenen Daten soweit es für die Begründung, Durchführung oder Beendigung eines solchen rechtsgeschäftlichen oder rechtsgeschäftsähnlichen Vertrages mit Ihnen oder zwischen Ihnen und dem Betreiber der von Ihnen genutzten Parkplätze erforderlich ist. Wir dürfen Ihre personenbezogenen Daten zum Zwecke der Begründung, Durchführung und Abwicklung des Vertragsverhältnisses mit uns und zwischen Ihnen und dem Betreiber der von Ihnen genutzten Parkplätze erheben, verarbeiten und nutzen.

Der Betreiber der von Ihnen genutzten Parkplätze erhält Zugriff auf ihre Transaktionen; letztere enthalten die folgenden Daten:

* Kennzeichen, QR-Code-Referenz oder Ticketnummer
* Zone (evt. Parkuhr und Parkplatznummer) oder Parking
* Parkzeit und Parkgebühr

Die Betreiber haben keine Einsicht in oder Zugriff auf Ihr Benutzerkonto.
Ausserdem speichern wir als Teil Ihres Benutzerkontos bei jeder Aktion auf unsere Platform folgende Daten:

* Datum / Zeit
* Ereignistyp
* Erhalten bzw. übermittelte Daten

Die Daten werden nur bei Support-Fällen verwendet und werden nicht systematisch ausgewertet. Die Betreiber haben keine Einsicht in diesen Daten. Diese Daten werden 3 Monate aufbewahrt und dann automatisch gelöscht.

#### e) Aktive Nutzung der Plattform / Parkingabo

Sofern Sie die Plattform aktiv nutzen und sich als Benutzer registrieren, werden wir die von Ihnen im Rahmen der Registrierung zwingend anzugebenden Daten speichern und verarbeiten, um Ihnen die Buchung und ggf. Bezahlung von den Parkplätzen über Parkingabo zu ermöglichen.

Es handelt sich hierbei um folgende Daten:

* E-Mail Adresse
* Name und Vorname
* Firmenname (nur Firmenkunden)
* Adresse
* Fahrzeuge (Kennzeichen, Badge und QR-Code)
* Referenz zum Zahlungsmittel (pseudonymisierte IDs)
* betreiberspezifische Referenzen

Wir erheben, verarbeiten und nutzen die von Ihnen zur Verfügung gestellten personenbezogenen Daten soweit es für die Begründung, Durchführung oder Beendigung eines solchen rechtsgeschäftlichen oder rechtsgeschäftsähnlichen Vertrages mit Ihnen oder zwischen Ihnen und dem Betreiber der von Ihnen genutzten Parkplätze erforderlich ist. Wir dürfen Ihre personenbezogenen Daten zum Zwecke der Begründung, Durchführung und Abwicklung des Vertragsverhältnisses mit uns und zwischen Ihnen und dem Betreiber der von Ihnen genutzten Parkplätze erheben, verarbeiten und nutzen.

Der Betreiber der von Ihnen genutzten Parkplätze erhält Zugriff auf ihre Transaktionen; letztere enthalten die folgenden Daten:

* Kennzeichen, Badgenummer oder QR-Code-Referenz
* Kontoinhaber (Vorname Nachname oder Firmenname)
* Zone oder Parking
* Parkzeit und Parkgebühr

Der Betreiber hat volle Einsicht auf Ihr Benutzerkonto und darf die Daten anpassen, mit Ausnahme der Zugangsdaten und Zahlungsmittel; beim Zahlungsmittel kann er lediglich sehen, ob es vorhanden und gültig ist, aber nicht die Details einsehen oder ändern.

Alle Daten im Benutzerkonto, mit der einzigen Ausnahme von Zugangsdaten, sind betriebsspezifisch und daher nur für den jeweiligen Betreiber zugänglich.
Ausserdem speichern wir als Teil Ihres Benutzerkontos bei jeder Aktion folgende Daten:

* Datum / Zeit
* Ereignistyp
* erhaltene bzw. übermittelte Daten

Die Daten werden nur bei Support-Fällen verwendet und werden nicht systematisch ausgewertet. Die Betreiber haben keine Einsicht in diesen Daten. Diese Daten werden 3 Monate aufbewahrt und dann automatisch gelöscht.

#### f) Aktive Nutzung der Plattform / Online-Shop ohne Registrierung

Sofern Sie die Plattform via den Online-Shop aktiv nutzen, werden wir die von Ihnen im Rahmen der Nutzung angegebenen Daten in einem anonymen Konto speichern und verarbeiten, um Ihnen die Buchung und Bezahlung von kostenpflichtigen Parkplätzen über Parkingpay zu ermöglichen.

Es handelt sich hierbei um folgende Daten:

* Referenz zum Gerät (pseudonymisierte IDs)
* Referenz zum Zahlungsmittel (pseudonymisierte IDs)
* E-Mail-Adresse (optional)

Nach der erfolgreichen Ausführung der Zahlung wird das Zahlungsmittel sofort gelöscht.
Bei nicht ausgeführten Zahlungen wird das Zahlungsmittel spätestens nach 7 Tagen gelöscht.

Wir erheben, verarbeiten und nutzen die von Ihnen zur Verfügung gestellten personenbezogenen Daten soweit es für die Begründung, Durchführung oder Beendigung eines solchen rechtsgeschäftlichen oder rechtsgeschäftsähnlichen Vertrages mit Ihnen oder zwischen Ihnen und dem Betreiber der von Ihnen genutzten Parkplätze erforderlich ist. Wir dürfen Ihre personenbezogenen Daten zum Zwecke der Begründung, Durchführung und Abwicklung des Vertragsverhältnisses mit uns und zwischen Ihnen und dem Betreiber der von Ihnen genutzten Parkplätze erheben, verarbeiten und nutzen.

Der Betreiber der von Ihnen benutzten Parkplätze erhält Zugriff auf Ihre Transaktionen; letztere enthalten die folgenden Daten:

* Ticketnummer, Kennzeichen oder QR-Code-Referenz
* Zone (evt. Parkuhr und Parkplatznummer) oder Parking
* Parkzeit und Parkgebühr

Der Betreiber hat keine Einsicht in oder Zugriff auf das anonyme Konto.

Ausserdem speichern wir als Teil des anonymen Kontos bei jeder Aktion folgende Daten:

* Datum / Zeit
* Ereignistyp
* erhaltene bzw. übermittelte Daten

Die Daten werden nur bei Support-Fällen verwendet und werden nicht systematisch ausgewertet. Die Betreiber haben keine Einsicht in diesen Daten. Diese Daten werden 3 Monate aufbewahrt und dann automatisch gelöscht.

#### Unpersönliche Zugriffsdaten der Plattform

Wir sammeln Informationen über Sie, wenn Sie die Plattform nutzen. Wir erfassen automatisch Informationen über Ihr Nutzungsverhalten und Ihre Interaktion mit uns und registrieren Daten zu Ihrem Computer oder Mobilgerät. Wir erheben, speichern und nutzen Daten über jeden Zugriff auf unsere Plattform (sogenannte Serverlogfiles). Zu den Zugriffsdaten gehören:

* Name und URL der abgerufenen Datei
* Datum und Uhrzeit des Abrufs
* übertragene Datenmenge
* Meldung über erfolgreichen Abruf (HTTP response code)
* Browsertyp und Browserversion
* Betriebssystem
* Referer URL (d.h. die zuvor besuchte Seite)
* Websites, die vom System des Nutzers über unsere Website aufgerufen werden
* Internet-Service-Provider des Nutzers
* IP-Adresse und der anfragende Provider

Wir nutzen diese Protokolldaten ohne Zuordnung zu Ihrer Person oder sonstiger Profilerstellung für statistische Auswertungen zum Zweck des Betriebs, der Sicherheit und der Optimierung unserer Website, aber auch zur anonymen Erfassung der Anzahl der Besucher auf unserer Website (traffic) sowie zum Umfang und zur Art der Nutzung unserer Website und Dienste. Aufgrund dieser Informationen können wir den Datenverkehr analysieren, Fehler suchen und beheben und unsere Dienste verbessern. Im Anwendungsbereich der DSGVO liegt hierin auch unser berechtigtes Interesse gemäss Art 6 Abs. 1 S. 1 f) DSGVO.

Wir behalten uns vor, die Protokolldaten nachträglich zu überprüfen, wenn aufgrund konkreter Anhaltspunkte der berechtigte Verdacht einer rechtswidrigen Nutzung besteht. Nach einem Monat löschen wir die Logfiles, wenn diese für Sicherheitszwecke nicht mehr erforderlich ist und wenn wir keinen konkreten Verdacht einer Straftat im Zusammenhang mit der Nutzung unserer Website haben.

### 4. Ihre Rechte

Nach den anwendbaren Gesetzen haben Sie verschiedene Rechte bezüglich Ihrer personenbezogenen Daten. Möchten Sie diese Rechte geltend machen, so richten Sie Ihre Anfrage bitte per E-Mail oder per Post unter eindeutiger Identifizierung Ihrer Person an die in Ziffer 8 genannte Adresse.

Nachfolgend finden Sie eine Übersicht über Ihre Rechte.

### 4.1. Recht auf Bestätigung und Auskunft

Sie haben das Recht auf eine übersichtliche Auskunft über die Verarbeitung Ihrer personenbezogenen Daten. Entsprechend haben Sie jederzeit das Recht, von uns eine Bestätigung darüber zu erhalten, ob Sie betreffende personenbezogene Daten verarbeitet werden. Ist dies der Fall, so haben Sie das Recht, von uns eine unentgeltliche Auskunft über die zu Ihnen gespeicherten personenbezogenen Daten nebst einer Kopie dieser Daten zu verlangen. Des Weiteren besteht ein Recht auf folgende Informationen:

* die Verarbeitungszwecke;
* die Kategorien personenbezogener Daten, die verarbeitet werden;
* die Empfänger oder Kategorien von Empfängern, gegenüber denen die personenbezogenen Daten offengelegt worden sind oder noch offengelegt werden, insbesondere bei Empfängern in Drittländern oder bei internationalen Organisationen;
* falls möglich, die geplante Dauer, für die die personenbezogenen Daten gespeichert werden, oder, falls dies nicht möglich ist, die Kriterien für die Festlegung dieser Dauer;
* das Bestehen eines Rechts auf Berichtigung oder Löschung der Sie betreffenden personenbezogenen Daten oder auf Einschränkung der Verarbeitung durch den Verantwortlichen oder eines Widerspruchsrechts gegen diese Verarbeitung;
* das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde;
* wenn die personenbezogenen Daten nicht bei Ihnen erhoben werden, alle verfügbaren Informationen über die Herkunft der Daten;
* das Bestehen einer automatisierten Entscheidungsfindung einschliesslich Profiling gemäss Art. 22 Abs. 1 und 4 DSGVO und – zumindest in diesen Fällen – aussagekräftige Informationen über die involvierte Logik sowie die Tragweite und die angestrebten Auswirkungen einer derartigen Verarbeitung für Sie.
* Werden personenbezogene Daten an ein Drittland oder an eine internationale Organisation übermittelt, so haben Sie das Recht, über die geeigneten Garantien gemäss Art. 46 DSGVO im Zusammenhang mit der Übermittlung unterrichtet zu werden.

### 4.2. Recht auf Berichtigung

Sie haben das Recht, von uns die Berichtigung und ggf. auch Vervollständigung Sie betreffender personenbezogener Daten zu verlangen. Entsprechend haben Sie das Recht, von uns unverzüglich die Berichtigung Sie betreffender unrichtiger personenbezogener Daten zu verlangen. Unter Berücksichtigung der Zwecke der Verarbeitung haben Sie das Recht, die Vervollständigung unvollständiger personenbezogener Daten – auch mittels einer ergänzenden Erklärung – zu verlangen.

Sie können die in Ihrem Benutzerkonto gespeicherten personenbezogenen Daten zudem jederzeit direkt bearbeiten.

### 4.3. Recht auf Löschung ("Recht auf Vergessenwerden")

In einer Reihe von Fällen sind wir verpflichtet, Sie betreffende personenbezogene Daten zu löschen. Entsprechend haben Sie insbesondere im Anwendungsbereich gemäss Art. 17 Abs. 1 DSGVO das Recht, von uns zu verlangen, dass Sie betreffende personenbezogene Daten unverzüglich gelöscht werden, und wir sind verpflichtet, personenbezogene Daten unverzüglich zu löschen, sofern einer der folgenden Gründe zutrifft:

* Die personenbezogenen Daten sind für die Zwecke, für die sie erhoben oder auf sonstige Weise verarbeitet wurden, nicht mehr notwendig.
* Sie widerrufen Ihre Einwilligung, auf die sich die Verarbeitung stützte, und es fehlt an einer anderweitigen Rechtsgrundlage für die Verarbeitung.
* Sie legen gemäss Art. 21 Abs. 1 DSGVO Widerspruch gegen die Verarbeitung ein und es liegen keine vorrangigen berechtigten Gründe für die Verarbeitung vor, oder Sie legen gemäss Art. 21 Abs. 2 DSGVO Widerspruch gegen die Verarbeitung ein.
* Die personenbezogenen Daten wurden unrechtmässig verarbeitet.
* Die Löschung der personenbezogenen Daten ist zur Erfüllung einer rechtlichen Verpflichtung der wir unterliegen.
* Die personenbezogenen Daten wurden in Bezug auf angebotene Dienste der Informationsgesellschaft gemäss Art. 8 Abs. 1 DSGVO erhoben.

Sie können die in Ihrem Benutzerkonto gespeicherten personenbezogenen Daten sowie das Konto als Ganzes jederzeit löschen.

### 4.4. Recht auf Einschränkung der Verarbeitung

In einer Reihe von Fällen sind Sie berechtigt, von uns eine Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Entsprechend haben Sie das Recht, von uns die Einschränkung der Verarbeitung zu verlangen, wenn eine der folgenden Voraussetzungen gegeben ist:

* die Richtigkeit der personenbezogenen Daten wird von Ihnen bestritten, und zwar für eine Dauer, die es uns ermöglicht, die Richtigkeit der personenbezogenen Daten zu überprüfen,
* die Verarbeitung unrechtmässig ist und Sie die Löschung der personenbezogenen Daten ablehnten und stattdessen die Einschränkung der Nutzung der personenbezogenen Daten verlangt haben;
* wir die personenbezogenen Daten für die Zwecke der Verarbeitung nicht länger benötigen, Sie die Daten jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen benötigen, oder
* Sie Widerspruch gegen die Verarbeitung gemäss Art. 21 Abs. 1 DSGVO eingelegt haben, solange noch nicht feststeht, ob die berechtigten Gründe unseres Unternehmens gegenüber den Ihren überwiegen.

### 4.5. Recht auf Datenübertragbarkeit im Anwendungsbereich der DSGVO

Sie haben im Anwendungsbereich der DSGVO das Recht, Sie betreffende personenbezogene Daten maschinenlesbar zu erhalten, zu übermitteln, oder von uns übermitteln zu lassen. Entsprechend haben Sie das Recht, die Sie betreffenden personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten, und Sie haben das Recht, diese Daten einem anderen Verantwortlichen ohne Behinderung durch uns zu übermitteln, sofern die Verarbeitung auf einer Einwilligung gemäss Art. 6 Abs. 1 S. 1 a) DSGVO oder Art. 9 Abs. 2 a) DSGVO oder auf einem Vertrag gemäss Art. 6 Abs. 1 S. 1 b) DSGVO beruht und die Verarbeitung mithilfe automatisierter Verfahren erfolgt.

Bei der Ausübung Ihres Rechts auf Datenübertragbarkeit gemäss Absatz 1 haben Sie das Recht, zu erwirken, dass die personenbezogenen Daten direkt von uns einem anderen Verantwortlichen übermittelt werden, soweit dies technisch machbar ist.

Sie können die in Ihrem Benutzerkonto gespeicherten Daten einschliesslich Transaktionsdaten jederzeit in Form einer CSV-Datei exportieren.

### 4.6. Widerspruchsrecht im Anwendungsbereich der DSGVO

Sie haben das Recht, aus einer rechtmässigen Verarbeitung Ihrer personenbezogenen Daten durch uns zu widersprechen, wenn sich dies aus Ihrer besonderen Situation begründet und unsere Interessen an der Verarbeitung nicht überwiegen. Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung Sie betreffender personenbezogener Daten, die aufgrund von Art. 6 Abs. 1 S. 1 e) oder f) DSGVO erfolgt, Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling. Wir verarbeiten die personenbezogenen Daten nicht mehr, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.

Werden personenbezogene Daten von uns verarbeitet, um Direktwerbung zu betreiben, so haben Sie das Recht, jederzeit Widerspruch gegen die Verarbeitung Sie betreffender personenbezogener Daten zum Zwecke derartiger Werbung einzulegen; dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in Verbindung steht.

### 4.7. Recht auf Widerruf einer datenschutzrechtlichen Einwilligung

Sie haben das Recht, eine von Ihnen erteilte Einwilligung zur Verarbeitung personenbezogener Daten jederzeit zu widerrufen.

###  4.8. Recht auf Beschwerde bei einer Aufsichtsbehörde

Sie haben das Recht auf Beschwerde bei einer Aufsichtsbehörde, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten rechtswidrig ist. In der Schweiz können Sie sich an den Eidgenössischen Datenschutz- und Öffentlichkeitsbeauftragten wenden (www.edoeb.admin.ch) und ausserhalb der Schweiz an die für Ihre Sie aufgrund Ihres Wohnsitzes oder Aufenthaltsortes zuständige Aufsichtsbehörde.

### 5. Externe Dienstleister / Datentransfer ins Ausland

Grundsätzlich verwenden wir Ihre personenbezogenen Daten nur innerhalb unseres Unternehmens. Wenn und soweit wir Dritte im Rahmen der Erfüllung von Verträgen einschalten (etwa Logistik-Dienstleister), erhalten diese personenbezogene Daten nur in dem Umfang, in welchem die Übermittlung für die entsprechende Leistung erforderlich ist.

Für den Fall, dass wir bestimmte Teile der Datenverarbeitung auslagern („Auftragsverarbeitung“), verpflichten wir Auftragsverarbeiter vertraglich dazu, personenbezogene Daten nur im Einklang mit den Anforderungen der Datenschutzgesetze zu verwenden und den Schutz der Rechte der betroffenen Person zu gewährleisten. Eine Datenübertragung an Stellen oder Personen ausserhalb der Schweiz bzw. EU ausserhalb der in dieser Erklärung genannten Fälle findet nicht statt.

### 5.1. Hosting

Die von uns in Anspruch genommenen Hosting-Leistungen dienen der Zurverfügungstellung der folgenden Leistungen: Infrastruktur- und Plattformdienstleistungen, Rechenkapazität, Speicherplatz und Datenbankdienste, Sicherheitsleistungen sowie technische Wartungsleistungen, die wir zum Zweck des Betriebs der Website einsetzen.

Hierbei verarbeiten wir, bzw. unser Hostinganbieter Bestandsdaten, Kontaktdaten, Inhaltsdaten, Vertragsdaten, Nutzungsdaten, Meta- und Kommunikationsdaten von Kunden, Interessenten und Besuchern dieser Website auf Grundlage unserer berechtigten Interessen an einer effizienten und sicheren Zurverfügungstellung unserer Website (im Anwendungsbereich der DSGVO massgebliche Bestimmungen sind die Art. 6 Abs. 1 S. 1 f) DSGVO i.V.m. Art. 28 DSGVO).

### 5.2. Payment – Dienstleister

Zur Realisierung der Bezahlvorgänge setzen wir den Payment-Dienstleister Datatrans AG und TWINT AG ein. Wir haben mit Datatrans AG einen Auftragsverarbeitervertrag nach Art. 28 DSGVO.

### 6. Cookies und Local Storage

Ein Cookie ist eine kleine Textdatei, die verschiedene Informationen beinhaltet und auf Ihrem Endgerät durch dem Web-Browser abgelegt wird.

Den Local Storage (HTML5 Local Storage) ist eine kleine Datenbank, die verschiedene Informationen beinhaltet und auf Ihrem Endgerät im Web-Browser integriert ist.

#### a) Lediglich informatorische Nutzung

Wir verwenden weder die Cookies noch den Local Storage.

#### b) Aktive Nutzung der Plattform / Parkingpay

Wir verwenden die Cookies und die Local Storage wenn Sie sich in unsere Webapplikationen einloggen.

Die Cookies werden benutzt, um die verschiedenen Anfragen Ihres Browsers der gemeinsamen authentifizierten Sitzung zuzuordnen und somit Ihr Konto vor unbefugtem Zugriff zu schützen. Diese Cookies werden auf Ihrer Festplatte gespeichert und löschen sich nach der vorgegebenen Zeit automatisch. Ihre Lebensdauer beträgt entweder 1 Tag („Login speichern“ inaktiv) oder 90 Tage („Login speichern“ aktiv).

In den Cookies werden etwa folgende Daten und Informationen gespeichert:

* Session-Identifikation (pseudonymisierte IDs)

Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies vorab informiert werden und im Einzelfall entscheiden können, ob Sie die Annahme von Cookies für bestimmte Fälle oder generell ausschliessen, oder dass Cookies komplett verhindert werden. Dadurch kann die Funktionalität der Webapplikation eingeschränkt werden.

Der Local Storage wird benutzt um die Benutzerfreundlichkeit der Applikation zu erhöhen. Die Informationen im Local Storage werden nie automatisch gelöscht.

Im Local Storage werden folgende Daten und Informationen gespeichert:

* Sprachcode
* Letzte ausgewähltes Optionen (pseudonymisierte IDs)
* Letzte ausgewählte PLZ

Sie können Ihren Browser so einstellen, dass es Local Storage Informationen komplett verhindert werden. Dadurch kann die Funktionalität der Webapplikation eingeschränkt werden.

Unser berechtigtes Interesse an der Nutzung der Cookies und des Local Storage gemäss Art 6 Abs. 1 S. 1 f) DSGVO liegt darin, unsere Webapplikationen nutzerfreundlicher, effektiver und sicherer zu machen.

### 7. Speicherdauer

Sofern nicht spezifisch angegeben speichern wir personenbezogene Daten nur so lange, wie dies zur Erfüllung der verfolgten Zwecke notwendig ist.

In einigen Fällen sieht der Gesetzgeber die Aufbewahrung von personenbezogenen Daten vor, etwa im Steuer- oder Handelsrecht. In diesen Fällen werden die Daten von uns lediglich für diese gesetzlichen Zwecke weiter gespeichert, aber nicht anderweitig verarbeitet und nach Ablauf der gesetzlichen Aufbewahrungsfrist gelöscht.

### 8. Datensicherheit

Wir sind um die Sicherheit Ihrer Daten im Rahmen der geltenden Datenschutzgesetze und technischen Möglichkeiten maximal bemüht.

Ihre persönlichen Daten werden bei uns verschlüsselt mit dem Codierungssystem SSL (Secure Socket Layer) übertragen. Dies gilt für Ihre Stammdaten, Ihre Transaktionen und auch für das Kundenlogin. Wir weisen jedoch darauf hin, dass die Datenübertragung im Internet Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.

Zur Sicherung Ihrer Daten unterhalten wir technische und organisatorische Sicherungsmassnahmen entsprechend Art. 32 DSGVO, die wir immer wieder dem Stand der Technik anpassen.

Wir gewährleisten ausserdem nicht, dass unser Angebot zu bestimmten Zeiten zur Verfügung steht; Störungen, Unterbrechungen oder Ausfälle können nicht ausgeschlossen werden. Die von uns verwendeten Server werden regelmässig sorgfältig gesichert.

### 9. Ansprechpartner

Bei Fragen zur Erhebung, Verarbeitung oder Nutzung Ihrer personenbezogenen Daten, bei Auskünften, Berichtigung, Sperrung oder Löschung von personenbezogenen Daten sowie Widerruf von Einwilligungen oder Widerspruch der Bearbeitung wenden Sie sich an:

Postadresse: Digitalparking AG, Rütistrasse 13, 8952 Schlieren

E-Mail Adresse: <privacy@digitalparking.ch>

`,
    fr: `
# DÉCLARATION SUR LA PROTECTION DES DONNÉES

## POUR LES USAGERS
## Version 07.2022

### 1. Généralités

La protection des données et leur sécurité sont pour nous une priorité. C’est pourquoi nous avons pris, dans le cadre des prescriptions légales sur la protection des données, de vastes mesures techniques et organisationnelles qui garantissent la protection de vos données à caractère personnel. Nous vous soumettons des offres par différents canaux (p. ex. site internet et application smartphone, ci-après collectivement: la «plateforme»).

Dans la présente déclaration sur la protection des données, nous vous informons sur l’utilisation de vos données personnelles ainsi que sur les cookies et les technologies similaires qui sont utilisés sur notre plateforme.

La collecte et le traitement de vos données à caractère personnel ont lieu dans le respect des prescriptions légales en matière de protection des données, en particulier de la loi suisse sur la protection des données (LPD) et du Règlement général européen sur la protection des données (RGPD), dans la mesure où ils s’appliquent respectivement aux différents cas concrets.

### 2. Fournisseur responsable

Le fournisseur responsable de la plateforme et des données à caractère personnel collectées et traitées dans le cadre de votre utilisation de la plateforme est la société Digitalparking SA, Rütistrasse 13, CH-8952 Schlieren.

### 3. Collecte, traitement et utilisation de vos données à caractère personnel

Les données à caractère personnel vous concernant que nous collectons, traitons et utilisons diffèrent dans leur quantité et leur genre selon que vous ne faites que visiter notre plateforme et l’utiliser à titre informatif ou que vous correspondez avec nous via notre plateforme, c'est-à-dire l’utilisez de manière active, en tant qu’utilisateur de l’application web ou smartphone Parkingpay (ci-après: «Parkingpay»), de l’application web ou smartphone Parkingabo (ci-après: «Parkingabo»), de l’appli TWINT (ci-après: „TWINT“) ou d’un appareil connecté.

#### a) Utilisation à titre purement informatif

Pour une utilisation de notre plateforme à titre purement informatif, il n’est pas nécessaire que vous fournissiez des données à caractère personnel. Dans ce cas, plus exactement, nous collectons uniquement celles de vos données que votre navigateur Internet nous transmet automatiquement (en particulier votre adresse IP). Ces informations sont enregistrées – sous forme non anonymisée – uniquement pendant la durée de votre visite sur la plateforme, et elles ne sont exploitées ensuite sous forme anonymisée qu’à des fins de statistiques. Aucune donnée à caractère personnel n’est donc collectée à votre sujet.

#### b) Utilisation active de la plateforme: parcomètres avec saisie de la plaque

Lorsque vous utilisez un tel parcomètre, vous devez obligatoirement saisir votre numéro d’immatriculation.

L'exploitant du parking que vous utilisez a accès à ses transactions; ces dernières contiennent les données suivantes:

* Plaque d’immatriculation
* Numéro du parcomètre
* Temps de stationnement et taxe de stationnement

#### c) Utilisation active de la plateforme: Parkingpay

Si vous utilisez la plateforme de manière active et vous enregistrez en tant qu’utilisateur, nous sauvegardons les données que vous devez fournir dans le cadre de l’enregistrement et les traiterons pour vous permettre d’utiliser et de payer des places de parc via Parkingpay.

Il s’agit des données suivantes:

* Adresse e-mail
* Nom et prénom
* Nom de l’entreprise (seulement si le client est une entreprise)
* Adresse
* Plaque d’immatriculation

Nous collectons, traitons et utilisons les données à caractère personnel que vous avez mises à notre disposition dans la mesure nécessaire pour justifier, exécuter ou résilier un contrat passé avec vous ou entre vous et l’exploitant des places de parc que vous utilisez. Nous avons le droit de collecter, de traiter et d’utiliser vos données à caractère personnel pour justifier, exécuter et organiser la relation contractuelle avec nous et entre vous et l’exploitant des places de parc que vous utilisez.

L'exploitant du parking que vous utilisez a accès à ses transactions; ces dernières contiennent les données suivantes:

* Plaque d’immatriculation, numéro de badge, référence du code QR ou numéro de ticket
* Titulaire du compte (prénom et nom, ou nom de l’entreprise)
* Zone (év. parcomètre et numéro de place) ou parking
* Temps de stationnement et taxe de stationnement

Les exploitants n’ont pas accès à votre compte d’utilisateur et ne peuvent pas le consulter; ils peuvent seulement appeler les données suivantes: titulaire, mode de paiement et solde (seulement en cas de paiement d’avance), sur un portail d’exploitant dédié.

En outre, lors de chaque action, nous enregistrons les données suivantes qui font partie de votre compte utilisateur:

* Date / heure
* Type d’événement
* Données reçues ou transmises

Ces données ne sont utilisées que dans des cas de support et ne sont pas systématiquement évaluées. Les exploitants n’ont pas accès à ces données. Elles sont conservées pendant 3 mois, puis sont automatiquement effacées.

#### d) Utilisation active de la plateforme: TWINT

Si vous utilisez la plateforme de manière active via l’appli TWINT, nous sauvegardons, dans un compte utilisateur anonyme, les données que vous devez fournir dans le cadre de la première utilisation et les traiterons pour vous permettre d’utiliser et de payer des places de stationnement via Parkingpay.

Il s’agit des données suivantes:

* Plaque d’immatriculation
* Référence au compte TWINT (ID pseudonymisées)

Nous collectons, traitons et utilisons les données à caractère personnel que vous avez mises à notre disposition dans la mesure nécessaire pour justifier, exécuter ou résilier un contrat passé avec vous ou entre vous et l’exploitant des places de parc que vous utilisez. Nous avons le droit de collecter, de traiter et d’utiliser vos données à caractère personnel pour justifier, exécuter et organiser la relation contractuelle avec nous et entre vous et l’exploitant des places de parc que vous utilisez.

L'exploitant du parking que vous utilisez a accès à ses transactions; ces dernières contiennent les données suivantes:

* Plaque d’immatriculation, référence du code QR ou numéro de ticket
* Zone (év. parcomètre et numéro de place) ou parking
* Temps de stationnement et taxe de stationnement

Les exploitants n’ont pas accès à votre compte d’utilisateur et ne peuvent pas le consulter.

En outre, lors de chaque action, nous enregistrons les données suivantes qui font partie de votre compte utilisateur:

* Date / heure
* Type d’événement
* Données reçues ou transmises

Ces données ne sont utilisées que dans des cas de support et ne sont pas systématiquement évaluées. Les exploitants n’ont pas accès à ces données. Elles sont conservées pendant 3 mois, puis sont automatiquement effacées.

#### e) Utilisation active de la plateforme: Parkingabo

Si vous utilisez la plateforme de manière active et vous enregistrez en tant qu’utilisateur, nous sauvegardons les données que vous devez fournir dans le cadre de l’enregistrement et les traiterons pour vous permettre d’utiliser et evt. payer des places de parc via Parkingabo.

Il s’agit des données suivantes:

* adresse e-mail
* nom et prénom
* nom de l’entreprise (seulement si le client est une entreprise)
* adresse
* véhicules (plaque d’immatriculation, badge et code QR)
* référence au moyen de paiement (ID pseudonymisées)
* références spécifiques à l'exploitant

Nous collectons, traitons et utilisons les données à caractère personnel que vous avez mises à notre disposition dans la mesure nécessaire pour justifier, exécuter ou résilier un contrat passé avec vous ou entre vous et l’exploitant des places de parc que vous utilisez. Nous avons le droit de collecter, de traiter et d’utiliser vos données à caractère personnel pour justifier, exécuter et organiser la relation contractuelle avec nous et entre vous et l’exploitant des places de parc que vous utilisez.

L'exploitant du parking que vous utilisez a accès à ses transactions; ces dernières contiennent les données suivantes:

* plaque d’immatriculation, numéro de badge ou référence du code QR
* titulaire du compte (prénom et nom, ou nom de l’entreprise)
* zone ou parking
* temps de stationnement et taxe de stationnement

L’exploitation a un droit de regard total sur votre compte d'utilisateur et peut adapter les données, à l'exception des données d'accès et du moyen de paiement; pour le moyen de paiement, il peut seulement voir s'il existe et s'il est valable, mais il ne peut pas voir ou modifier les détails.

Toutes les données du compte d'utilisateur, à la seule exception des données d'accès, sont spécifiques à l’exploitation et ne sont donc accessibles qu'à l'exploitant concerné.

En outre, lors de chaque action, nous enregistrons les données suivantes qui font partie de votre compte utilisateur:

* date / heure
* type d’événement
* données reçues ou transmises

Ces données ne sont utilisées que dans des cas de support et ne sont pas systématiquement évaluées. Les exploitants n’ont pas accès à ces données. Elles sont conservées pendant 3 mois, puis sont automatiquement effacées.

#### f) Utilisation active de la plateforme: Shop en ligne sans inscription

Si vous utilisez la plateforme de manière active en tant qu’utilisateur du shop en ligne, nous sauvegardons dans un compte anonyme les données que vous devez fournir dans le cadre de l’utilisation et les traiterons pour vous permettre d’utiliser et payer des places de parc via Parkingpay.

Il s’agit des données suivantes:

* référence de l'appareil (ID pseudonymisées)
* référence au moyen de paiement (ID pseudonymisées)
* adresse e-mail (facultatif)

Après l'exécution réussie du paiement, le moyen de paiement est immédiatement supprimé.
Si le paiement n'est pas effectué, le moyen de paiement est supprimé au plus tard après 7 jours.

Nous collectons, traitons et utilisons les données à caractère personnel que vous avez mises à notre disposition dans la mesure nécessaire pour justifier, exécuter ou résilier un contrat passé avec vous ou entre vous et l’exploitant des places de parc que vous utilisez. Nous avons le droit de collecter, de traiter et d’utiliser vos données à caractère personnel pour justifier, exécuter et organiser la relation contractuelle avec nous et entre vous et l’exploitant des places de parc que vous utilisez.

L'exploitant du parking que vous utilisez a accès à ses transactions; ces dernières contiennent les données suivantes:

* numéro de ticket, plaque d’immatriculation ou référence du code QR
* zone ou parking
* temps de stationnement et taxe de stationnement

L’exploitant n’a pas accès au compte anonyme et ne peut pas le consulter.

En outre, lors de chaque action, nous enregistrons les données suivantes qui font partie du compte anonyme:

* date / heure
* type d’événement
* données reçues ou transmises

Ces données ne sont utilisées que dans des cas de support et ne sont pas systématiquement évaluées. Les exploitants n’ont pas accès à ces données. Elles sont conservées pendant 3 mois, puis sont automatiquement effacées.

#### Données d’accès à la plateforme qui n’ont pas de caractère personnel

Lorsque vous utilisez la plateforme, nous collectons des informations à votre sujet. Nous saisissons automatiquement des informations sur votre comportement d’utilisation et votre interaction avec nous, et nous enregistrons des données relatives à votre ordinateur ou appareil portable. Nous collectons, enregistrons et utilisons des données sur chaque accès à notre plateforme (ce qu’on appelle des fichiers journaux de serveur). Ces données d’accès comprennent:

* Nom et URL du fichier appelé
* Date et heure de l’appel
* Volume des données transmises
* Signal d'aboutissement de l’appel (HTTP response code)
* Type et version du navigateur
* Système d’exploitation
* URL du referrer (c’est-à-dire de la page précédemment consultée)
* Sites Internet qui sont appelés par le système de l’utilisateur, via notre site Internet
* Fournisseur d’accès internet de l’utilisateur
* Adresse IP et le fournisseur d’accès requérant

Nous utilisons ces données de protocole sans les rattacher à votre personne ni établir d’autre profil, dans un but statistique lié à l’exploitation, à la sécurité et à l’optimisation de notre site internet, mais aussi pour la saisie anonyme du nombre des visiteurs de notre site (trafic) ainsi que concernant la quantité et le genre d’utilisations qui sont faites de notre site Internet et de nos services. Sur la base de ces informations, nous pouvons analyser le trafic des données, rechercher et réparer des erreurs et améliorer nos services. À cet égard, nos intérêts légitimes prévus à l’art. 6, par. 1, point 1 f) RGPD sont également compris dans le champ d’application du RGPD.

Nous nous réservons le droit de vérifier après coup les données de protocole si des éléments concrets éveillent le soupçon d’une utilisation contraire à la loi. Au bout d’un mois, nous effaçons les fichiers journaux lorsqu’ils ne nous sont plus nécessaires à des fins de sécurité et lorsqu’il n’y a pas de soupçon concret d’une infraction pénale liée à l’utilisation de notre site Internet.

### 4. Vos droits

Selon les lois applicables, vous disposez de différents droits concernant vos données à caractère personnel. Si vous souhaitez exercer ces droits, veuillez adresser votre demande à l’adresse figurant au chiffre 8, par e-mail ou par la poste, en vous identifiant sans équivoque.

Vous trouvez ci-dessous un récapitulatif de vos droits.

### 4.1. Droit à la confirmation et à la communication

Vous avez le droit d’être clairement renseigné sur le traitement de vos données à caractère personnel. En conséquence, si des données à caractère personnel vous concernant sont traitées, vous avez à tout moment le droit d’en obtenir de notre part la confirmation. Si c’est le cas, vous avez le droit d’exiger de notre part une communication gratuite concernant les données à caractère personnel enregistrées à votre sujet, ainsi qu’une copie de ces données. Il existe également un droit aux informations suivantes:

* la finalité du traitement;
* les catégories de données à caractère personnel qui sont traitées;
* les destinataires ou catégories de destinataires à qui les données à caractère personnel ont été ou seront communiquées, notamment quand il y a des destinataires dans des pays tiers ou des organisations internationales;
* si possible, la durée prévue pendant laquelle les données à caractère personnel seront enregistrées, ou, si ce n'est pas possible, les critères selon lesquels cette durée est fixée;
* l’existence d’un droit à la rectification ou à l’effacement des données à caractère personnel ou à ce que le traitement par le responsable soit limité; ou l’existence d’un droit de s’opposer à ce traitement;
* l’existence d’un droit de réclamation auprès d’une autorité de contrôle;
* si les données à caractère personnel n’ont pas été collectées auprès de vous, toutes les informations disponibles sur la provenance des données;
* l’existence d’un processus de décision automatisé, y compris profilage, au sens de l’art. 22, par. 1 et 4 RGPD et – du moins dans ces cas – des informations compréhensibles sur la logique impliquée ainsi que sur la portée et les effets escomptés pour vous d’un tel traitement.
* Lorsque des données à caractère personnel sont transférées vers un pays tiers ou une organisation internationale, vous avez le droit d’être informé des garanties appropriées qui doivent accompagner ce transfert, au sens de l’art. 46 RGPD.

### 4.2. Droit de rectification

Vous avez le droit d'exiger que nous rectifiions et, le cas échéant, complétions des données à caractère personnel qui vous concernent. Dans ce sens, vous avez le droit d’obtenir de nous, dans les meilleurs délais, la rectification de données à caractère personnel inexactes vous concernant. En tenant compte des finalités du traitement, vous avez le droit d’exiger que des données à caractère personnel incomplètes soient complétées, y compris en fournissant une déclaration complémentaire.

Vous pouvez en outre à tout moment modifier directement les données à caractère personnel qui sont enregistrées sur votre compte utilisateur.

### 4.3. Droit à l’effacement («droit à l’oubli»)

Dans une série de cas, nous avons l’obligation d’effacer des données à caractère personnel vous concernant. Ainsi, vous avez le droit d’obtenir de notre part, plus particulièrement dans le champ d’application de l’art. 17, par. 1 RGPD, dans les meilleurs délais, l’effacement de données à caractère personnel vous concernant, et nous avons l’obligation d’effacer dans les meilleurs délais des données à caractère personnel lorsque l’un des motifs suivants s’applique:

* Les données à caractère personnel ne sont plus nécessaires au regard des finalités pour lesquelles elles ont été collectées ou traitées d'une autre manière.
* Vous retirez le consentement sur lequel est fondé le traitement, et il n'existe pas d'autre fondement juridique au traitement.
* Vous vous opposez au traitement en vertu de l’art. 21, par. 1 RGPD et il n'existe pas de motif légitime impérieux pour le traitement, ou vous vous opposez au traitement en vertu de l’art. 21, par. 2 RGPD.
* Les données à caractère personnel ont fait l'objet d'un traitement illicite.
* Les données à caractère personnel doivent être effacées pour respecter une obligation légale à laquelle nous sommes soumis.
* Les données à caractère personnel ont été collectées dans le cadre de l'offre de services de la société de l'information visée à l’art. 8, par. 1 RGPD.
Vous pouvez à tout moment effacer les données à caractère personnel enregistrées sur votre compte utilisateur ou effacer entièrement ce compte lui-même.

### 4.4. Droit à la limitation du traitement

Dans une série de cas, vous avez le droit d’obtenir de notre part une limitation du traitement de vos données à caractère personnel. Ainsi, vous avez le droit d'obtenir que nous limitions le traitement lorsqu'un des éléments suivants s'applique:

* Vous contestez l'exactitude des données à caractère personnel, pendant une durée qui nous permet de vérifier l'exactitude des données à caractère personnel.
* Le traitement est illicite et vous vous êtes opposé à l’effacement des données à caractère personnel et avez exigé à la place la limitation de leur utilisation.
* Nous n'avons plus besoin des données à caractère personnel aux fins du traitement mais celles-ci vous sont encore nécessaires pour la constatation, l'exercice ou la défense de vos droits.
* Vous êtes opposé au traitement en vertu de l’art. 21, par. 1 RGPD, pendant la vérification portant sur le point de savoir si les motifs légitimes poursuivis par notre entreprise prévalent sur les vôtres.

### 4.5. Droit à la portabilité des données relevant du champ d’application du RGPD

Dans le champ d’application du RGPD, vous avez le droit de recevoir, de transmettre ou de vous faire transmettre de notre part, dans un format lisible par machine, des données à caractère personnel vous concernant. Ainsi, vous avez le droit de recevoir, dans un format structuré, couramment utilisé et lisible par machine, les données à caractère personnel vous concernant que vous nous avez fournies et vous avez le droit de transmettre ces données à un autre responsable de traitement sans que nous y fassions obstacle, lorsque le traitement est fondé sur le consentement en application de l'art. 6, par. 1, point 1 a), ou de l'art. 9, par. 2 a) RGPD, ou sur un contrat en application de l'art. 6, par. 1, point 1 b) RGPD, et lorsque le traitement est effectué à l'aide de procédés automatisés.

Dans l’exercice de votre droit à la portabilité des données prévu au paragraphe 1 de l’article précité, vous avez le droit d'obtenir que nous transmettions directement les données à caractère personnel à un autre responsable de traitement, lorsque cela est techniquement possible.

Vous pouvez à tout moment exporter sous forme d’un fichier CSV les données qui sont enregistrées sur votre compte utilisateur, y compris les données de transaction.

### 4.6. Droit d’opposition dans le champ d’application du RGPD

Vous avez le droit de vous opposer, pour des raisons tenant à votre situation particulière, à ce que nous traitions vos données à caractère personnel, à moins que nous n’ayons un intérêt prépondérant à ce traitement. Vous avez le droit de vous opposer à tout moment, pour des raisons tenant à votre situation particulière, à un traitement des données à caractère personnel vous concernant fondé sur l'art. 6, par. 1, points 1 e) ou f) RGPD, y compris de vous opposer à un profilage fondé sur ces dispositions. Nous ne traitons plus vos données à caractère personnel, à moins que nous ne démontrions qu'il existe des motifs de traitement légitimes et impérieux, qui prévalent sur vos intérêts, droits et libertés, ou à moins que le traitement serve à la constatation et à l'exercice de vos droits ou à leur défense en justice.

Lorsque nous traitons des données à caractère personnel à des fins de prospection, vous avez le droit de vous opposer à tout moment à ce traitement des données à caractère personnel vous concernant, y compris au profilage dans la mesure où il est lié à une telle prospection.

### 4.7. Droit de retirer son consentement en matière de protection des données

Vous avez le droit de retirer à tout moment un consentement donné pour le traitement de données à caractère personnel.

### 4.8. Droit de réclamation auprès d’une autorité de contrôle

Vous avez le droit d’introduire une réclamation auprès d’une autorité de contrôle si vous estimez que le traitement des données à caractère personnel vous concernant est contraire à la loi. En Suisse, vous pouvez vous adresser au préposé fédéral à la protection des données et à la transparence (www.edoeb.admin.ch), et hors de Suisse, à l’autorité de contrôle compétente pour vous en raison de votre domicile ou de votre lieu de résidence.

### 5. Prestataires externes / transfert de données à l’étranger

En principe, nous n’utilisons vos données à caractère personnel qu’au sein de notre entreprise. Si nous faisons intervenir des tiers (par exemple pour des services de logistique) dans le cadre de l’exécution de contrats, ces personnes peuvent recevoir des données à caractère personnel, mais uniquement dans la mesure où cette transmission est nécessaire à la prestation correspondante.

Si nous déléguons certaines parties du traitement de données («sous-traitance»), nous soumettons nos sous-traitants, par contrat, à la double obligation d’utiliser des données à caractère personnel uniquement conformément aux exigences des lois sur la protection des données et de garantir la protection des droits de la personne concernée. Aucun transfert de données à des organismes ou à des personnes hors de Suisse ou hors de l’UE n’a lieu dans d’autres cas que ceux évoqués dans la présente déclaration.

### 5.1. Hébergement

Les prestations d’hébergement auxquelles nous recourons servent à mettre à disposition les prestations suivantes: services d’infrastructure et de plateforme, capacité de calcul, emplacements mémoire et services de banque de données, prestations de sécurité ainsi que prestations techniques de maintenance que nous mettons en œuvre pour le bon fonctionnement du site Internet.

Nous ou notre hébergeur traitons alors des données de base (données d’inventaire), des données de contact, de contenu, de contrats, d’utilisation, des métadonnées et données de communication de clients, de personnes intéressées et de visiteurs de ce site Internet, sur la base de notre intérêt légitime à mettre à disposition notre site Internet de manière efficace et sûre (les dispositions déterminantes dans le champ d’application du RGPD sont les art. 6, par. 1, point 1 f) RGPD en combinaison avec l’art. 28 RGPD).

### 5.2. Prestataire pour les paiements

Pour la réalisation des procédures de paiement, nous recourons au prestataire Datatrans SA et TWINT SA. Nous avons avec Datatrans SA un contrat de sous-traitance au sens de l’art. 28 RGPD.

### 6. Cookies et local storage

Un cookie est un petit fichier texte qui contient différentes informations et est enregistré par le navigateur web sur votre appareil terminal.

Local storage (HTML5 Local Storage) est une petite base de données qui contient différentes informations et est intégrée dans le navigateur web sur votre appareil terminal.

#### a) Utilisation à titre purement informatif

Nous n’utilisons ni les cookies, ni le local storage.

#### b) Utilisation active de la plateforme: Parkingpay

Nous utilisons les cookies et le local storage quand vous ouvrez une session dans nos applications web.

Les cookies servent à assigner à la session commune authentifiée les différentes requêtes de votre navigateur, et donc à protéger votre compte contre un accès non autorisé. Ces cookies sont enregistrés sur votre disque dur et s’effacent automatiquement après le temps prescrit. Leur durée de vie est soit d’une journée («Rester connecté» désactivé), soit de 90 jours («Rester connecté» activé).

Les données et informations suivantes, par exemple, sont enregistrées dans les cookies:

* Identification de session (ID pseudonymisées)

Vous pouvez configurer votre navigateur pour être informé à l’avance quand des cookies sont mis en place et décider dans chaque cas d’exclure l'acceptation de cookies dans certains cas ou de manière générale, voire de les bloquer complètement. Cela peut restreindre les fonctionnalités de l’application web.

Le local storage est utilisé pour améliorer la convivialité de l’application. Les informations du local storage ne sont jamais effacées automatiquement.

Les données et informations suivantes sont enregistrées dans le local storage:

* Code de langue
* Dernières options sélectionnées (ID pseudonymisées)
* Dernier NPA sélectionné

Vous pouvez configurer votre navigateur pour qu’il bloque entièrement les informations local storage. Cela peut restreindre les fonctionnalités de l’application web.

Au sens de l’art. 6, par. 1, point 1 f) RGPD, l’intérêt légitime que présente pour nous l’utilisation des cookies et du local storage est de nous permettre de rendre nos applications web plus conviviales, plus efficaces et plus sûres.

### 7. Durée de l’enregistrement

Sauf indication contraire spécifique, nous n’enregistrons les données à caractère personnel qu’aussi longtemps que c’est nécessaire à la réalisation des buts poursuivis.

Dans certains cas, par exemple en droit fiscal ou commercial, le législateur prévoit que des données à caractère personnel doivent être conservées. Dans ces cas, nous continuons à mémoriser les données, mais uniquement dans ces buts légaux; nous ne les traitons pas autrement et elles sont effacées à l’expiration du délai légal de conservation.

### 8. Sécurité des données

Dans les limites des possibilités techniques et de la législation en vigueur sur la protection des données, nous faisons le maximum pour que vos données soient en sécurité.

Chez nous, vos données personnelles sont transmises sous forme chiffrée, au moyen du système de codage SSL (Secure Socket Layer). C’est le cas pour vos données de base, vos transactions et aussi pour le login de client. Nous vous signalons toutefois que la transmission de données sur Internet peut présenter des failles de sécurité. Une protection totalement infaillible des données contre l’accès de tiers n'est pas possible.

Pour sécuriser vos données, nous maintenons des mesures techniques et organisationnelles correspondant à l’art. 32 RGPD et nous les adaptons régulièrement à l’état de la technique.

Pour le surplus, nous ne garantissons pas que notre offre soit disponible à certains moments; des perturbations, des interruptions ou des pannes ne peuvent pas être exclues. Les serveurs que nous utilisons sont régulièrement sécurisés avec soin.

### 9. Contact

Pour des questions sur la collecte, le traitement ou l’utilisation de vos données à caractère personnel, pour des renseignements, une rectification, un blocage ou l’effacement de données à caractère personnel ainsi que pour une opposition au traitement des données, veuillez-vous adresser à:

Adresse postale: Digitalparking AG, Rütistrasse 13, 8952 Schlieren

Adresse e-mail: <privacy@digitalparking.ch>

`,
    it: `
# INFORMATIVA SULLA PROTEZIONE DEI DATI

## PER GLI UTENTI
## Versione 07.2022

### 1. Aspetti generali

La protezione e la sicurezza dei dati hanno per noi assoluta priorità. Per questo motivo abbiamo intrapreso misure tecniche e organizzative complete, nell’ambito delle disposizioni in materia di protezione dei dati, al fine di garantire la protezione dei vostri dati personali. Noi predisponiamo per voi le offerte attraverso diversi canali (p.es. sito web e applicazione smartphone), denominati in generale “piattaforma”.

In questa informativa sulla protezione dei dati vi informiamo sull’utilizzo dei vostri dati personali e sull’utilizzo dei cookie e delle tecnologie simili nella nostra piattaforma.

La raccolta e il trattamento dei vostri dati personali avviene nel rispetto delle disposizioni vigenti in materia di protezione dei dati, soprattutto della Legge sulla protezione dei dati svizzera (LPD) e del Regolamento generale sulla protezione dei dati (GDPR), qualora questo trovi applicazione nel caso concreto del trattamento dei dati.

### 2. Fornitore responsabile

Il fornitore responsabile della piattaforma e dei dati personali raccolti e trattati nell’ambito del vostro utilizzo della piattaforma è Digitalparking SA, Rütistrasse 13, CH-8952 Schlieren.

### 3. Raccolta, trattamento e utilizzo dei vostri dati personali

L’entità e il tipo di raccolta, trattamento e utilizzo dei vostri dati personali varia a seconda che voi vi limitiate a visitare la nostra piattaforma e a utilizzarla per scopi informativi o se attraverso la piattaforma vi mettete in contatto con noi, ovvero se la usate attivamente come utente dell’applicazione web o smartphone Parkingpay (di seguito denominata «Parkingpay»), dell’applicazione web o smartphone Parkingabo (di seguito denominata «Parkingabo»), l’app TWINT (di seguito denominata „TWINT“) o di un dispositivo smart.

#### a) Utilizzo a scopo unicamente informativo

Per l’utilizzo della nostra piattaforma a scopo unicamente informativo, in linea generale non è necessario che voi comunichiate i vostri dati personali. In questo caso noi raccogliamo e utilizziamo solo i dati sul vostro conto che ci vengono trasmessi automaticamente dal vostro browser Internet (soprattutto il vostro indirizzo IP). Queste informazioni vengono salvate – in forma non anonimizzata – solo per la durata della vostra visita alla piattaforma, mentre successivamente viene effettuata una valutazione in forma anonimizzata a scopi unicamente statistici. Pertanto non vengono raccolti dati personali sul vostro conto.

#### b) Utilizzo attivo della piattaforma: parchimetri con inserimento del numero di targa

Quando utilizzate un parchimetro di questo tipo, voi dovete necessariamente inserire il numero di targa.

Il gestore dei parcheggi da voi utilizzati ha accesso alle proprie transazioni; queste ultime contengono i seguenti dat:

* numero di targa
* numero del parchimetro
* tempo e tassa di parcheggio

#### c) Utilizzo attivo della piattaforma: Parkingpay

Se utilizzate la piattaforma attivamente e vi registrate come utente, noi salveremo e tratteremo i dati da voi inseriti nell’ambito della registrazione, al fine di consentirvi di utilizzare e pagare i parcheggi a pagamento attraverso Parkingpay.

Si tratta in questo caso dei seguenti dati:

* indirizzo e-mail
* nome e cognome
* nome dell’azienda (solo clienti aziendali)
* indirizzo
* numero di targa

Noi registriamo, trattiamo e utilizziamo i dati personali da voi messi a disposizione, nella misura in cui questi sono necessari per l’impostazione, l’esecuzione o la cessazione di un contratto di natura commerciale o simile con voi o tra voi e il gestore dei parcheggi da voi utilizzati. Noi siamo autorizzati a raccogliere, trattare e utilizzare i dati personali sul vostro conto allo scopo di impostare, eseguire ed espletare il rapporto contrattuale con noi e tra voi e il gestore dei parcheggi da voi utilizzati.

Il gestore dei parcheggi da voi utilizzati ha accesso alle proprie transazioni; queste ultime contengono i seguenti dat:

* numero di targa, badge, referenza codice QR o numero di ticket
* titolare del conto (nome, cognome o nome dell’azienda)
* zona (ev. parchimetro o numero del posto) o parcheggio
* tempo e tassa di parcheggio

Il gestore non può prendere visione né ha accesso al vostro conto utente; può effettuare unicamente delle ricerche su titolare, modalità di pagamento e saldo (solo in caso di pagamento anticipato) in un portale gestori dedicato.

Inoltre, durante ogni azione all’interno del vostro conto utente, noi salviamo i seguenti dati:

* data/ora
* tipo di evento
* dati ricevuti o trasmessi

I dati vengono utilizzati solo nei casi di supporto e non vengono valutati sistematicamente. I gestori non possono prendere visione di tali dati. Questi dati vengono conservati per 3 mesi e quindi cancellati automaticamente.

#### d) Utilizzo attivo della piattaforma: TWINT

Se utilizzate la piattaforma attivamente tramite l’app TWINT, noi salveremo e tratteremo i dati da voi inseriti nell’ambito del primo utilizzo, al fine di consentirvi di utilizzare e pagare i parcheggi a pagamento attraverso Parkingpay.

Si tratta in questo caso dei seguenti dati:

* numero di targa
* Referenza al conto TWINT (ID pseudonimizzato)

Noi registriamo, trattiamo e utilizziamo i dati personali da voi messi a disposizione, nella misura in cui questi sono necessari per l’impostazione, l’esecuzione o la cessazione di un contratto di natura commerciale o simile con voi o tra voi e il gestore dei parcheggi da voi utilizzati. Noi siamo autorizzati a raccogliere, trattare e utilizzare i dati personali sul vostro conto allo scopo di impostare, eseguire ed espletare il rapporto contrattuale con noi e tra voi e il gestore dei parcheggi da voi utilizzati.

Il gestore dei parcheggi da voi utilizzati ha accesso alle proprie transazioni; queste ultime contengono i seguenti dat:

* numero di targa, referenza codice QR o numero di ticket
* zona (ev. parchimetro o numero del posto) o parcheggio
* tempo e tassa di parcheggio

Il gestore non può prendere visione né ha accesso al vostro conto utente

Inoltre, durante ogni azione all’interno del vostro conto utente, noi salviamo i seguenti dati:

* data/ora
* tipo di evento
* dati ricevuti o trasmessi

I dati vengono utilizzati solo nei casi di supporto e non vengono valutati sistematicamente. I gestori non possono prendere visione di tali dati. Questi dati vengono conservati per 3 mesi e quindi cancellati automaticamente.

#### e) Utilizzo attivo della piattaforma: Parkingabo

Se utilizzate la piattaforma attivamente e vi registrate come utente, noi salveremo e tratteremo i dati da voi inseriti nell’ambito della registrazione, al fine di consentirvi di utilizzare ed ev. pagare i parcheggi attraverso Parkingabo.

Si tratta in questo caso dei seguenti dati:

* indirizzo e-mail
* nome e cognome
* nome dell’azienda (solo clienti aziendali)
* indirizzo
* veicolo (numero di targa, badge e codice QR)
* referenza al mezzo di pagamento (ID pseudonimizzato)
* referenze specifiche al gestore

Noi registriamo, trattiamo e utilizziamo i dati personali da voi messi a disposizione, nella misura in cui questi sono necessari per l’impostazione, l’esecuzione o la cessazione di un contratto di natura commerciale o simile con voi o tra voi e il gestore dei parcheggi da voi utilizzati. Noi siamo autorizzati a raccogliere, trattare e utilizzare i dati personali sul vostro conto allo scopo di impostare, eseguire ed espletare il rapporto contrattuale con noi e tra voi e il gestore dei parcheggi da voi utilizzati.

Il gestore dei parcheggi da voi utilizzati ha accesso alle proprie transazioni; queste ultime contengono i seguenti dati:

* numero di targa, badge o referenza del codice QR
* titolare del conto (nome, cognome o nome dell’azienda)
* zona o parcheggio
* tempo e tassa di parcheggio

Il gestore ha pieno accesso al vostro conto utente e può modificarne i dati, ad eccezione dei dati di accesso e del mezzo di pagamento; nel caso del mezzo di pagamento, il gestore può solo vedere se esiste ed è valido, ma non può visualizzare o modificare i dettagli.

Tutti i dati del conto utente, con la sola eccezione dei dati di accesso, sono specifici al gestore e quindi accessibili solo al rispettivo gestore.

Inoltre, durante ogni azione all’interno del vostro conto utente, noi salviamo i seguenti dati:

* data/ora
* tipo di evento
* dati ricevuti o trasmessi

I dati vengono utilizzati solo nei casi di supporto e non vengono valutati sistematicamente. I gestori non possono prendere visione di tali dati. Questi dati vengono conservati per 3 mesi e quindi cancellati automaticamente.

#### f) Utilizzo attivo della piattaforma: shop online senza registrazione

Se utilizzate la piattaforma attivamente tramite lo shop online, noi salveremo e tratteremo in un conto anonimo i dati da voi inseriti nell’ambito dell’utilizzo, al fine di consentirvi di utilizzare e pagare i parcheggi a pagamento.

Si tratta in questo caso dei seguenti dati:

* referenza all’apparecchio (ID pseudonimizzato)
* referenza al mezzo di pagamento (ID pseudonimizzato)
* indirizzo e-mail (facoltativo)

Dopo l'esecuzione con successo del pagamento, il mezzo di pagamento viene cancellato immediatamente.
In caso di pagamenti non eseguiti, il mezzo di pagamento viene cancellato al più tardi dopo 7 giorni.

Noi registriamo, trattiamo e utilizziamo i dati personali da voi messi a disposizione, nella misura in cui questi sono necessari per l’impostazione, l’esecuzione o la cessazione di un contratto di natura commerciale o simile con voi o tra voi e il gestore dei parcheggi da voi utilizzati. Noi siamo autorizzati a raccogliere, trattare e utilizzare i dati personali sul vostro conto allo scopo di impostare, eseguire ed espletare il rapporto contrattuale con noi e tra voi e il gestore dei parcheggi da voi utilizzati.

Il gestore dei parcheggi da voi utilizzati ha accesso alle proprie transazioni; queste ultime contengono i seguenti dati:

* numero di ticket, numero di targa o referenza codice QR
* zona (ev. parchimetro o numero del posto) o parcheggio
* tempo e tassa di parcheggio

Il gestore non può prendere visione né ha accesso al conto anonimo.

Inoltre, durante ogni azione all’interno del conto anonimo, noi salviamo i seguenti dati:

* data/ora
* tipo di evento
* dati ricevuti o trasmessi

I dati vengono utilizzati solo nei casi di supporto e non vengono valutati sistematicamente. I gestori non possono prendere visione di tali dati. Questi dati vengono conservati per 3 mesi e quindi cancellati automaticamente.

#### Dati di accesso alla piattaforma che non hanno carattere personale

Noi raccogliamo informazioni su di voi ogni qualvolta utilizzate la piattaforma. Registriamo automaticamente informazioni sul vostro comportamento di utilizzo e sulla vostra interazione con noi e registriamo i dati relativi al vostro computer o dispositivo mobile. Noi registriamo, salviamo e utilizziamo dati su ogni accesso alla nostra piattaforma (cosiddetti file log del server). Fra i dati di accesso vi sono:

* nome e URL del file richiamato
* data e ora della richiesta
* quantità di dati trasferita
* notifica sulla richiesta con esito positivo (HTTP response code)
* tipo e versione del browser
* sistema operativo
* referer URL (ovvero la pagina precedentemente visitata)
* siti web che vengono richiamati dal sistema dell’utente attraverso il nostro sito web
* Internet Service Provider dell’utente
* indirizzo IP e provider richiedente

Noi utilizziamo questi dati di protocollo - senza associarli alla vostra persona né per creare profili di qualsiasi tipo - per valutazioni statistiche finalizzate alla gestione, alla sicurezza e all’ottimizzazione del nostro sito web, ma anche per registrare in forma anonima il numero dei visitatori del nostro sito web (traffic) e per valutare l’entità e il tipo di utilizzo del nostro sito web e dei nostri servizi. In base a queste informazioni noi possiamo analizzare il traffico dati, cercare e rimuovere gli errori e migliorare i nostri servizi. Nell’ambito di applicazione della GDPR sussiste anche il nostro interesse legittimo come da art. 6 par. 1 f. 1 f) GDPR.

Ci riserviamo il diritto di controllare a posteriori i dati di protocollo, quando a causa di circostanze concrete sussista il legittimo sospetto di un utilizzo non conforme alle regole. Dopo un mese cancelliamo i file log, qualora questi non siano più necessari per scopi di sicurezza o se non vi sono sospetti concreti di un reato relativamente all’utilizzo del nostro sito web.

#### 4. I vostri diritti

Secondo le leggi vigenti, voi avete diversi diritti in materia di dati personali. Se desiderate rivendicare questi diritti, inviate la vostra richiesta via e-mail o per posta, identificando in modo univoco la vostra persona, all’indirizzo riportato al punto 8.

Di seguito trovate una panoramica dei vostri diritti.

### 4.1. Diritto alla conferma e all’informazione

Avete il diritto di ottenere informazioni chiare sul trattamento dei vostri dati personali. A questo proposito voi avete il diritto, in ogni momento, di ricevere da noi una conferma se presso di noi vengono trattati dati personali su di voi. In caso positivo, voi avete il diritto di ottenere da noi informazioni a titolo gratuito sui dati personali salvati, oltre che una copia di questi dati. Sussiste altresì il diritto alle seguenti informazioni:

* finalità del trattamento;
* categorie di dati personali trattati;
* destinatari o categorie di destinatari a cui i dati personali sono stati o saranno comunicati, in particolare se destinatari di paesi terzi o organizzazioni internazionali;
* ove possibile, il periodo di conservazione dei dati personali previsto oppure, se non è possibile, i criteri utilizzati per determinare tale periodo;
* esistenza del diritto di chiedere al titolare del trattamento la rettifica o la cancellazione dei dati personali o la limitazione del trattamento dei dati personali che vi riguardano o di opporsi al loro trattamento;
* diritto di proporre reclamo a un'autorità di vigilanza;
* qualora i dati non siano raccolti presso di voi, tutte le informazioni disponibili sulla loro origine;
* esistenza di un processo decisionale automatizzato, compresa la profilazione di cui all'art. 22, par. 1 e 4 GDPR e, almeno in tali casi, informazioni significative sulla logica utilizzata, nonché l'importanza e le conseguenze previste di tale trattamento per voi;
* qualora i dati personali siano trasferiti a un paese terzo o a un'organizzazione internazionale, voi avete il diritto di essere informati dell'esistenza di garanzie adeguate ai sensi dell'art. 46 GDPR relative al trasferimento.

### 4.2. Diritto di rettifica

Avete il diritto di richiederci la rettifica ed eventualmente anche il completamento dei dati personali che vi riguardano. Di conseguenza, avete il diritto di ottenere da noi la rettifica immediata dei dati personali inesatti che vi riguardano. Tenuto conto delle finalità del trattamento, avete il diritto di ottenere l'integrazione dei dati personali incompleti, anche fornendo una dichiarazione integrativa.

Potete inoltre elaborare i dati personali salvati nel vostro conto utenti in qualsiasi momento.

### 4.3. Diritto alla cancellazione («diritto all'oblio»)

In una serie di casi siamo tenuti a cancellare i dati personali che vi riguardano. In tali circostanze voi avete in particolare nell’ambito di applicazione di cui all’art. 17 par. 1 GDPR il diritto di chiedere la cancellazione immediata dei dati personali che vi riguardano e noi siamo tenuti a cancellarli immediatamente in presenza di uno dei seguenti motivi:

* i dati personali non sono più necessari rispetto alle finalità per le quali sono stati raccolti o altrimenti trattati;
* voi revocate l’autorizzazione su cui si basava il trattamento e non vi sono altre basi giuridiche che consentano il trattamento,
* ai sensi dell’ art. 21 par. 1 GDPR voi vi opponete al trattamento e non vi sono altri motivi legittimi prevalenti che lo consentano, oppure ai sensi dell’art. 21 par. 2 GDPR voi vi opponete al trattamento,
* i dati personali sono stati trattati illecitamente,
* la cancellazione dei dati personali serve a ottemperare a un obbligo di legge cui siamo soggetti,
* i dati personali sono stati raccolti relativamente all'offerta di servizi della società dell'informazione di cui all'art. 8, par. 1 GDPR.
Potete cancellare in qualsiasi momento i dati personali salvati nel vostro conto utente nonché l’intero conto.

### 4.4. Diritto di limitazione del trattamento

In una serie di casi avete il diritto di richiederci una limitazione del trattamento dei vostri dati personali. Nella fattispecie avete il diritto di ottenere da noi la limitazione del trattamento, quando ricorre una delle seguenti situazioni:

* voi contestate l'esattezza dei dati personali, per un periodo tale che permetta a noi di verificare l'esattezza di tali dati personali;
* il trattamento è illecito e voi vi siete opposti alla cancellazione dei dati personali e avete chiesto invece che ne fosse limitato l'utilizzo;
* benché noi non abbiamo più bisogno dei dati personali ai fini del trattamento, questi sono necessari a voi per la rivendicazione, l'esercizio o la difesa di pretese giuridiche oppure
* voi vi siete opposti al trattamento ai sensi dell’art. 21 par. 1 GDPR, sebbene non sia ancora chiaro se i motivi legittimi della nostra azienda prevalgano sui vostri.

### 4.5. Diritto alla portabilità dei dati nell’ambito di applicazione del GDPR

Nell’ambito di applicazione del GDPR voi avete il diritto di ricevere, trasmettere o di far trasmettere da noi i dati personali che vi riguardano in un formato leggibile automaticamente da una macchina. Voi avete altresì il diritto di ricevere questi dati personali che ci avete fornito in un formato strutturato, di uso comune e leggibile automaticamente da una macchina e avete il diritto di trasmettere tali dati a un altro responsabile per il trattamento senza impedimenti da parte nostra, qualora il trattamento si basi su un consenso ai sensi dell’art. 6 par. 1 f. 1 a) GDPR o art. 9 par. 2 a) GDPR o su un contratto di cui all’art. 6 par. 1 f. 1 b) GDPR e se il trattamento avviene mediante procedure automatizzate.

Nell'esercitare i vostri diritti relativamente alla portabilità dei dati a norma del paragrafo 1, voi avete il diritto di ottenere la trasmissione dei dati personali direttamente da noi a un altro responsabile per il trattamento, se tecnicamente fattibile.

Potete esportare in ogni momento i dati salvati sul vostro conto utente compresi i dati delle transazioni sotto forma di file CSV.

### 4.6. Diritto di opposizione nell’ambito di applicazione del GDPR

Avete il diritto di opporvi a un trattamento lecito dei vostri dati personali da parte nostra, qualora ciò sia motivato in virtù della vostra particolare situazione e se i nostri interessi al trattamento non siano prevalenti. Avete il diritto di opporvi in ogni momento, per motivi connessi alla vostra situazione particolare, al trattamento dei dati personali che vi riguardano ai sensi dell'art. 6, par. 1, f. 1 lettere e) o f) GDPR, compresa la profilazione sulla base di tali disposizioni. Noi ci asteniamo dal trattare ulteriormente i dati personali salvo che non dimostriamo l'esistenza di motivi preminenti e legittimi per procedere al trattamento che prevalgono sui vostri interessi, diritti e libertà oppure per la rivendicazione, l'esercizio o la difesa di pretese giuridiche.

Qualora i dati personali siano da noi trattati per finalità di marketing diretto, voi avete il diritto di opporvi in qualsiasi momento al trattamento dei dati personali che vi riguardano per scopi pubblicitari di tale natura, compresa la profilazione nella misura in cui sia connessa a tale marketing diretto.

### 4.7. Diritto alla revoca di un consenso in materia di protezione dei dati

Voi avete il diritto di revocare in qualsiasi momento un consenso fornito ai fini del trattamento dei vostri dati personali.

### 4.8. Diritto di proporre reclamo a un’autorità di vigilanza

Voi avete il diritto di proporre reclamo a un’autorità di vigilanza, se ritenete che il trattamento dei dati personali che vi riguardano sia illecito. In Svizzera potete rivolgervi all’Incaricato federale della protezione dei dati e della trasparenza (www.edoeb.admin.ch), mentre al di fuori della Svizzera all’autorità di vigilanza di competenza in base al vostro domicilio o luogo di dimora.

### 5. Fornitori di servizi esterni / Trasmissione di dati all’estero

In linea generale utilizziamo i vostri dati personali solo all’interno della nostra azienda. Se dovessimo attivare terzi relativamente all’esecuzione dei contratti (per esempio fornitori di servizi logistici), questi ricevono i dati personali solo nella misura in cui la trasmissione è necessaria per lo svolgimento delle relative prestazioni.

Nel caso in cui esternalizziamo determinate parti di trattamento dati («Elaborazione dell’ordine di trattamento»), noi vincoliamo contrattualmente i responsabili del trattamento a utilizzare i dati personali solo in linea con i requisiti delle leggi sulla protezione dei dati e a garantire la protezione dei diritti dell’interessato. La trasmissione dati a uffici o persone esternamente alla Svizzera risp. all’UE e al di fuori dei casi riportati nella presente informativa non ha luogo.

### 5.1. Hosting

I servizi di hosting di cui ci serviamo servono per erogare i seguenti servizi: servizi relativi all’infrastruttura e alla piattaforma, capacità di calcolo, spazio di memoria e servizi di banche dati, servizi di sicurezza e di manutenzione tecnica che utilizziamo al fine di gestire la piattaforma.

Noi, ovvero il fornitore dei servizi di hosting, trattiamo nello specifico dati di base, di contatto, di contenuto, dati contrattuali, dati di utilizzo, metadati e dati di comunicazione di clienti, interessati e visitatori del presente sito web in base ai nostri legittimi interessi di un approntamento efficiente e sicuro della nostra piattaforma (nell’ambito di applicazione delle disposizioni del GDPR sono rilevanti le disposizioni di cui all’art. 6 par. 1 f. 1 f) GDPR unitamente all’art. 28 GDPR).

### 5.2. Pagamento – Fornitore di servizi

Per realizzare le procedure di pagamento ci avvaliamo dei fornitori di servizi di pagamento Datatrans SA e TWINT SA. Con Datatrans SA abbiamo un contratto di responsabilità del trattamento di cui all’art. 28 GDPR.

### 6. Cookie e local storage

Un cookie è un piccolo file di testo contenente diverse informazioni che viene salvato sul vostro terminale dal web browser.

Il local storage (HTML5 Local Storage) è una piccola banca dati contenente diverse informazioni, che è integrata nel web browser del vostro terminale.

#### a) Utilizzo a scopo unicamente informativo

Noi non utilizziamo né i cookie né il local storage.

#### b) Utilizzo attivo della piattaforma: Parkingpay

Noi utilizziamo i cookie e il local storage se voi effettuate il login nelle nostre applicazioni web.

I cookie vengono utilizzati per assegnare le diverse richieste del vostro browser alla sessione autenticata comune e quindi per proteggere il vostro conto dall’accesso non autorizzato. Questi cookie vengono salvati sul vostri disco fisso e si cancellano automaticamente dopo un periodo definito. La loro durata di vita è di 1 giorno («Rimanere connessi» inattivo) o 90 giorni («Rimanere connessi» attivo).

Nei cookie vengono salvati i seguenti dati e le seguenti informazioni:

* identificazione della sessione (ID pseudonimizzati)

Potete impostare il vostro browser in modo che siate informati in anticipo sul salvataggio dei cookie e possiate decidere nei singoli casi se rifiutare i cookie in determinati casi o in generale o se bloccare completamente il salvataggio dei cookie. In questo caso la funzionalità dell’applicazione web può essere limitata.

Il local storage viene utilizzato per aumentare la facilità di utilizzo dell’applicazione. Le informazioni nel local storage non vengono mai cancellate automaticamente.

Nel local storage vengono salvati i seguenti dati e le seguenti informazioni:

* lingua
* ultime opzioni selezionate (ID pseudonimizzati)
* ultimi NPA utilizzati

Potete impostare il browser in modo che le informazioni del local storage vengano completamente bloccate. In questo caso la funzionalità dell’applicazione web può essere limitata.

Il nostro interesse legittimo all’utilizzo dei cookie e del local storage ai sensi dell'art. 6 par. 1 f. 1 f) GDPR consiste nel rendere più fruibili, efficaci e sicure le nostre applicazioni web.

### 7. Durata di conservazione dei dati

Qualora non sia specificato diversamente, noi conserviamo i dati personali solo per il periodo necessario per il soddisfacimento degli scopi perseguiti.

In alcuni casi il legislatore impone la conservazione dei dati personali, per esempio nel diritto fiscale o commerciale. In questi casi i dati continuano a essere da noi conservati unicamente per questi scopi giuridici, ma non vengono utilizzati in modi diversi e dopo la scadenza dei termini di conservazione i dati vengono cancellati.

### 8. Sicurezza dei dati

Ci adoperiamo al meglio per la sicurezza dei vostri dati nell'ambito delle leggi vigenti sulla protezione dei dati e delle possibilità tecniche.

I vostri dati vengono da noi trasmessi cifrati attraverso il sistema di codifica SSL (Secure Socket Layer). Questo vale per i vostri dati anagrafici, le vostre transazioni e anche per il login del cliente. Vi ricordiamo che la trasmissione dei dati in Internet può presentare delle lacune nella sicurezza. Non è possibile garantire una protezione dei dati perfetta dall’accesso da parte di terzi.

Per garantire la sicurezza dei vostri dati noi intraprendiamo misure di sicurezza tecniche e organizzative ai sensi dell’art. 32 GDPR, che adeguiamo sempre in funzione dello stato della tecnica.

Non assicuriamo inoltre che la nostra offerta sia disponibile in determinati periodi; guasti, interruzioni o malfunzionamenti non possono essere esclusi. I server da noi utilizzati sono messi regolarmente e accuratamente in sicurezza.

### 9. Interlocutore

In caso di domande sulla raccolta, il trattamento o l’utilizzo dei vostri dati personali e in caso di informazioni, rettifica, blocco o cancellazione dei dati personali o di revoca di consenso od opposizione al trattamento, rivolgetevi a:

Indirizzo postale: Digitalparking SA, Rütistrasse 13, 8952 Schlieren

Indirizzo e-mail: <privacy@digitalparking.ch>

`,
    en: `
# DATA PRIVACY STATEMENT

## FOR USERS
## Version 07.2022

### 1. General

As we attach great importance to data privacy and data protection, we have implemented wide- ranging technical and organisational measures in line with the provisions on data privacy in order to protect your personal data. We make offers available to you via a number of different access channels (e.g. website and smartphone application) (hereinafter referred together as the "platform").

With this data privacy statement, we inform you about the processing of your personal data and the use of cookies or similar technologies on our platform.

Your personal data are collected and processed in compliance with the applicable data protection provisions, in particular Swiss Data Protection Act (DSG) and the EU's General Data Protection Regulation (GDPR) where this applies to data processing in a specific case.

### 2. Responsible provider

Digitalparking AG, Rütistrasse 13, CH-8952 Schlieren is the responsible provider of the platform and the personal data collected and processed in connection with your use of the platform.

### 3. Collection, processing and use of your personal data

The scope and type of collection, processing and use of your personal data differ according to whether you just visit our platform to gather information or whether you also correspond with us via our platform and actively use the platform as a user of the Parkingpay web or app (hereinafter referred to as "Parkingpay"), the Parkingabo web or app (hereinafter referred to as "Parkingabo"), the TWINT app (hereinafter referred to as "TWINT") or a smart device.

#### a) Used for information purposes only

It is generally not necessary to provide any personal data if you just use our platform to gather information. In this case, we only collect and process the data that are automatically sent to us by your Internet browser (in particular your IP address). This information is stored in non- anonymised form for the duration of your visit to our platform, and are subsequently analysed in anonymised form for statistical purposes only. No personal data about you are collected.

#### b) Active use of the platform / parking meters with license plate

When using such a parking meter, you always have to enter your licence plate number.

The operator of the parking spaces you use will have access to their transactions; the latter will contain the following data:

* Licence plate number
* Parking meter number
* Parking time and fee

#### c) Active use of the platform / Parkingpay

If you use the platform actively and register as a user, the data you are required to provide upon registration are stored and processed in order to allow you to use Parkingpay to book and pay for parking places.

The following data are collected:

* E-mail address
* Last and first name
* Company name (only for corporate customers)
* Address
* Licence plate number

We collect, process and use the personal data provided by you to the extent required for establishing, performing or terminating such a legal or similar contract with you or between you and the operator of the parking places used by you. We may collect, process and use your personal data for the purpose of establishing, performing and processing the contractual relationship with us and between you and the operator of the parking places used by you.

The operator of the parking spaces you use will have access to their transactions; the latter will contain the following data:

* Licence plate number, badge number, QR code reference or ticket number
* Account owner (first and last name / company name)
* Zone (parking meter and parking lot number, if applicable) or parking
* Parking time and fee

Operators cannot view or access your user account; they can only view the owner, method of payment and balance (only for prepaid) on a dedicated operator portal.

As part of your user account we also store the following data for each individual action:

* Date / time
* Type of event
* Data received or transmitted

The data are only used if support is needed and are not systematically analysed. Operators do not have access to these data. These data are stored for three months before they are automatically deleted.

#### d) Active use of the platform / TWINT

If you use the platform actively via TWINT app, the data you are required to provide upon the first use are stored in an anonymous user account and processed in order to allow you to use Parkingpay to book and pay for parking places.

The following data are collected:

* License plate
* Reference to TWINT account (pseudonymized ID)

We collect, process and use the personal data provided by you to the extent required for establishing, performing or terminating such a legal or similar contract with you or between you and the operator of the parking places used by you. We may collect, process and use your personal data for the purpose of establishing, performing and processing the contractual relationship with us and between you and the operator of the parking places used by you.

The operator of the parking spaces you use will have access to their transactions; the latter will contain the following data:

* License plate, QR code reference or ticket number
* Zone (parking meter and parking lot number, if applicable) or parking
* Parking time and fee

Operators cannot view or access your user account.

As part of your user account we also store the following data for each individual action:

* Date / time
* Type of event
* Data received or transmitted

The data are only used if support is needed and are not systematically analysed. Operators do not have access to these data. These data are stored for three months before they are automatically deleted.

#### e) Active use of the platform / Parkingabo

If you use the platform actively and register as a user, the data you are required to provide upon registration are stored and processed in order to allow you to use Parkingabo to book and pay for parking places.

The following data are collected:

* E-mail address
* Last and first name
* Company name (only for corporate customers)
* Address
* Vehicles (licence plate, badge and QR code)
* Reference to the mean of payment (pseudonymised ID)
* Operator specific references

We collect, process and use the personal data provided by you to the extent required for establishing, performing or terminating such a legal or similar contract with you or between you and the operator of the parking places used by you. We may collect, process and use your personal data for the purpose of establishing, performing and processing the contractual relationship with us and between you and the operator of the parking places used by you.

The operator of the parking spaces you use will have access to their transactions; the latter will contain the following data:

* Licence plate number, badge number, QR code reference
* Account owner (first and last name / company name)
* Zone or parking
* Parking time and fee

The operator has full access to your user account and may adjust the data, with the exception of the access data and means of payment; in the case of the means of payment, he can only see whether it exists and is valid, but cannot view or change the details.
All data in the user account, with the sole exception of access data, is operator-specific and therefore only accessible to the corresponding operator.

As part of your user account we also store the following data for each individual action:

* Date / time
* Type of event
* Data received or transmitted

The data are only used if support is needed and are not systematically analysed. Operators do not have access to these data. These data are stored for three months before they are automatically deleted.

#### f) Active use of the platform / Online shop without registration

If you use the platform actively via the online shop, the data you are required to provide upon the use are stored in an anonymous user account and processed in order to allow you to use Parkingpay to book and pay for parking places.

The following data are collected:

* Reference to the device (pseudonymised IDs)
* Reference to the means of payment (pseudonymised IDs)
* E-mail address (optional)

After successful execution of the payment, the means of payment is deleted immediately.
In the case of non-executed payments, the means of payment is deleted after 7 days at the latest.

We collect, process and use the personal data provided by you to the extent required for establishing, performing or terminating such a legal or similar contract with you or between you and the operator of the parking places used by you. We may collect, process and use your personal data for the purpose of establishing, performing and processing the contractual relationship with us and between you and the operator of the parking places used by you.

The operator of the parking spaces you use will have access to their transactions; the latter will contain the following data:

* Ticket number, license plate or QR code reference
* Zone (parking meter and parking lot number, if applicable) or parking
* Parking time and fee

Operators cannot view or access the anonymous account.

As part of your user account we also store the following data for each individual action:

* Date / time
* Type of event
* Data received or transmitted

The data are only used if support is needed and are not systematically analysed. Operators do not have access to these data. These data are stored for three months before they are automatically deleted.

#### Impersonal data relating to platform access

We collect information about you when you use the platform. We automatically capture information on your user behaviour and your interaction with us and register data pertaining to your computer or mobile device. We collect, store and use data every time you access our platform (in server log files). These access data include:

* Name and URL of the requested file
* Date and time of access
* Volume of data transmitted
* Confirmation that access was successful (HTTP response code)
* Browser type and version
* Operating system
* Referrer's URL (i.e. the previous site visited by you)
* Websites that are called up by the user's system via our website
* User's Internet service provider
* IP address and requesting provider

We use these data without allocating them to your person or creating any profiles for preparing statistical analyses concerning the operation, security and optimisation of our website as well as to anonymously capture information about the number of visitors to our website (traffic) and the scope and type of use of our website and services. We can use this information to analyse the data traffic, search for and rectify errors, and improve our services. Where the GDPR applies, this also establishes our legitimate interest pursuant to point 1 (f) of Art. 6 para. 1 GDPR.

We reserve the right to subsequently check the protocol data if there is specific evidence to legitimately suspect the unlawful use of the website. We delete the log files after one month if they are no longer needed for security purposes and there is no specific suspicion of a criminal offence related to the use of our website.

### 4. Your rights

You have various rights pertaining to your personal data under the applicable laws. If you wish to assert these rights, please address your enquiry by e-mail or post to the address provided in section 8, making sure that you clearly identify yourself.

The following is an overview of your rights.

### 4.1. Right to confirmation and information

You have the right to receive well-structured information regarding the processing of your personal data. This means that you have the right at any time to request confirmation from us on whether any of your personal data are being processed. If this is the case, you have the right to request information from us free of charge regarding your stored personal data as well as a copy of these data. You also have a right to the following information:

* Purposes of processing
* Categories of personal data that are processed;
* recipients or categories of recipients to whom the personal data were or will be disclosed, in particular regarding recipients in foreign countries or with international organisations;
* if possible, the planned duration of storage of the personal data or, if this is impossible, the criteria for determining this duration;
* the existence of a right to rectification or erasure of personal data concerning you or to restriction of processing by the Controller or a right to object to such processing;
* the existence of a right of appeal to a supervisory authority;
* if the personal data are not collected from you, all available information concerning the origin of the data;
* the existence of automated decision-making, including profiling in accordance with Art. 22 paras. 1 and 4 GDPR and - at least in these cases - meaningful information on the logic involved and the scope and intended effects of such processing for you;
* if personal data are transmitted to a foreign country or an international organisation, you have the right to be instructed about the appropriate safeguards to protect the transmission pursuant to Art. 46 GDPR.

### 4.2. Right to rectification

You have the right to require us to rectify and possibly complete your personal data. This means that you have the right to require us to immediately rectify any incorrect personal data about you. Taking into account the purposes of processing, you have the right to have incomplete personal data completed, including by means of providing a supplementary statement.

You can also directly process your personal data stored in your user account.

### 4.3. Right to erasure ("right to be forgotten")

In many cases we are obliged to erase your personal data. You therefore have the right, in particular where Art. 17 para. 1 GDPR applies, to require us to erase your personal data without undue delay and we will be obliged to erase your personal data without undue delay where one of the following grounds applies:

* the personal data are no longer necessary in relation to the purposes for which they were collected or otherwise processed;
* you withdraw your consent on which the processing is based and there are no other legal grounds for the processing;
* you object to the processing pursuant to Art. 21 para. 1 GDPR and there are no overriding legitimate grounds for the processing, or you object to the processing pursuant to Art. 21 para. 2 GDPR;
* the personal data have been unlawfully processed;
* the personal data have to be erased for compliance with a legal obligation to which we are subject;
* the personal data have been collected in relation to the offer of information society services referred to in Art. 8 para. 1 GDPR.

You can erase the personal data stored in your user account as well as the account itself at any time.

### 4.4. Right to restriction of processing

In many cases you are entitled to ask us to restrict the processing of your personal data. You therefore have the right to require us to restrict the processing where one of the following applies:

* the accuracy of the personal data is contested by you, for a period enabling us to verify the accuracy of the personal data;
* the processing is unlawful and you opposed the erasure of the personal data and requested the restriction of their use instead;
* we no longer need the personal data for the purposes of the processing, but they are required by you for the establishment, exercise or defence of legal claims;
* you objected to processing pursuant to Art. 21 para. 1 GDPR pending verification whether our legitimate grounds override yours.

### 4.5. Right to data portability in the area of application of the GDPR

In the area of application of the GDPR you have the right to receive, transmit or require us to transmit your personal data in a machine-readable format. You therefore have the right to receive your personal data which you have provided to us in a structured, commonly used and machine-readable format and have the right to transmit these data to another controller without hindrance from us, where the processing is based on consent pursuant to point 1 (a) of Art. 6 para. 1 GDPR or point 2 (a) of Art. 9 para. 2 GDPR or on a contract pursuant to point 1(b) of Art. 6 para. 1 GDPR and the processing is carried out by automated means.

In exercising this right to data portability pursuant to paragraph 1, you have the right to have the personal data transmitted directly from us to another controller, where technically feasible.

You can export the data stored in your user account as well as the transaction data at any time in the form of a CSV file.

### 4.6. Right to object in the area of application of the GDPR

You have the right to object to the lawful processing of your personal data by us on grounds relating to your particular situation, unless our grounds for processing override yours. You have the right to object on grounds relating to your particular situation at any time to the processing of your personal data based on point (e) or (f) of Art. 6 para. 1 of the GDPR, including profiling based on those provisions. We will no longer process your personal data unless we can demonstrate compelling legitimate grounds for the processing which override your interests, rights and freedoms or the processing serves the establishment, exercise or defence of legal claims.

Where personal data are processed by us for direct marketing purposes, you have the right to object at any time to the processing of your personal data for such marketing, which includes profiling to the extent that it is related to such direct marketing.

### 4.7. Right to withdraw the declaration of consent for data processing

Right to withdraw the declaration of consent for data processing

###  4.8. Right to lodge a complaint with a supervisory authority

You have the right to lodge a complaint with a supervisory authority if you believe that the processing of your personal data is unlawful. In Switzerland, complaints can be lodged with the Federal Data Protection and Information Commissioner (www.edoeb.admin.ch), and outside of Switzerland with the supervisory authority at your habitual residence or place of domicile.

### 5. External service providers / data transfers abroad

We generally only use your personal data within our company. When and to the extent that we involve third parties in the performance of contracts (e.g. providers of logistics services), these third parties are only sent the personal data they need to perform the respective service.

Where we outsource specific components of data processing (to a processor), we contractually oblige the processor to only use personal data in compliance with the provisions of data protection legislation and to warrant the protection of the rights of the affected parties. Except for the cases mentioned in this statement, data are not transmitted to organisations or persons outside Switzerland or the EU.

### 5.1. Hosting

We use hosting services in order to provide the following services: infrastructure and platform services, computing capacity, storage capacity and database services, security services and technical maintenance services that we use for the operation of the website.

We and our hosting provider process portfolio data, contact data, content data, contractual data, usage data, meta data and communication data of customers, interested parties and visitors to this website based on our legitimate interest in providing an efficient and secure website (the applicable provisions in the area of application of the GDPR are point 1 (f) of Art. 6 para. 1 GDPR in conjunction with Art. 28 GDPR).

### 5.2. Providers of payment services

We use Datatrans Ltd., a provider of payment services to execute the payment transactions. We have concluded a processor contract pursuant to Art. 28 GDPR with Datatrans Ltd.

### 6. Cookies and Local Storage

A cookie is a small text file containing all sorts of information which is filed on your computer by the web browser.

Local storage (HTML5 Local Storage) is a small database containing all sorts of information which is integrated in the web browser on your computer.

#### a) Used for information purposes only

We use neither cookies nor local storage.

#### b) Active use of platform / Parkingpay

We use cookies and local storage when you log on to our web applications.

Cookies are used to link the different requests by your browser to the shared authenticated visit in order to protect your account against unauthorised access. These cookies are stored on your hard disk and are automatically deleted again after the specified period. Their lifetime is either one day («Stay signed in» is inactive) or 90 days («Stay signed in» is active).

The following data and information are stored in the cookies:

* Session identification (pseudonymised IDs)

You can change the settings on your browser to notify you when a cookie should be set, which will allow you to approve or reject cookies on an ad hoc basis or to generally block all cookies. This can restrict the functionality of the web application.

Local storage is used to make the application more user-friendly. The information kept in local storage is never deleted automatically.

The following data and information are saved in local storage:

* Language code
* Last selected options (pseudonymised IDs)
* Last selected postcode

You can change your browser settings to completely prevent the local storage of information. This can restrict the functionality of the web application.

Our legitimate interest in the use of cookies and local storage pursuant to point (f) of Art. 6 para. 1 GDPR lies in our ambition to make our web applications more user-friendly, more effective and more secure.

### 7. Storage period

Unless specifically indicated otherwise, we store personal data only for as long as required to meet the purposes pursued by us.

In some cases the legislator requires us to store personal data, e.g. under tax or commercial law. In these cases we store the data for longer to meet these legal requirements without any further processing, and we delete the data after expiry of the statutory safekeeping period.

### 8. Data security

We do our utmost to ensure the security of your data in compliance with the applicable data protection laws and the technical possibilities.

We transmit your personal data in encrypted form using the SSL (Secure Socket Layer) system. This applies to your master data, your transactions and also to customer log ins. Please note, however, that the transmission of data via the Internet can never be completely secure. It is impossible to completely protect data from access by third parties.

We implement and constantly update technical and organisational security measures pursuant to Art. 32 GDPR to secure your data.

We also cannot guarantee that our services will be available at certain times, as malfunctions, interruptions or breakdowns cannot be excluded. The servers used by us are regularly and carefully secured.

### 9. Contact person

If you have any questions on the collection, processing or use of your personal data, information, amendments, blocking or erasure of personal data and the withdrawal of consent for processing or the lodging of a complaint, please contact:

Postal address: Digitalparking AG, Rütistrasse 13, 8952 Schlieren

E-mail address: privacy@digitalparking.ch

`,
};
