import * as React from 'react';
import { useEffect, useState } from 'react';
import { Box, Card, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { WizardFadeInEffect } from './WizardComponents';
import { CalendarPickerView, ClockPicker, PickersDay } from '@mui/lab';
import { CalendarPicker } from '@mui/x-date-pickers';
import { Localized } from '../../../../../common/hooks/LanguageProvider';
import { Formatter } from '../../../../../lib/Date';
import { css } from '@emotion/css';
import { Message } from '../../../../../lib/Localized';

export function HourSelectionStep({
    instruction,
    value,
    onChange,
    minHour,
    maxHour,
}: {
    instruction: Message;
    value: DateTime | null;
    onChange: (value: DateTime | null) => void;
    minHour: number | null;
    maxHour: number | null;
}) {
    return (
        <WizardFadeInEffect>
            <PickerHeader>
                {value ? (
                    value.toFormat('HH:00')
                ) : (
                    <Localized {...instruction} />
                )}
            </PickerHeader>

            <PickerContainer>
                <ClockPicker
                    classes={{
                        root: css({
                            '& .Mui-disabled': { color: 'rgba(0, 0, 0, 0.1)' },
                        }),
                    }}
                    autoFocus={true}
                    minTime={DateTime.now().set({ hour: minHour || 0 })}
                    maxTime={DateTime.now().set({ hour: maxHour || 23 })}
                    date={
                        /*
                        the clock picker doesn't render min and max if the value is null. we hack this in be passing a date lower
                        than the minHour if the value is null.
                        */
                        value ??
                        ((minHour ?? 0) > 0
                            ? DateTime.now().set({ hour: 0 })
                            : null)
                    }
                    onChange={onChange}
                    views={['hours']}
                />
            </PickerContainer>
        </WizardFadeInEffect>
    );
}

export function MultiDateSelectionStep({
    dates,
    onChange,
    minValidityDate,
    maxSelectableDates,
    maxDaysInFuture,
}: {
    dates: DateTime[];
    minValidityDate: DateTime | null;
    onChange: (dates: DateTime[]) => void;
    maxSelectableDates: number;
    maxDaysInFuture: number;
}) {
    const now = DateTime.now().startOf('day');
    const minDate =
        minValidityDate !== null && now.toMillis() < minValidityDate.toMillis()
            ? minValidityDate
            : now;
    const [lastDate, setLatestDate] = useState(minDate);
    const maxSelected = maxSelectableDates <= dates.length;
    const [view, setView] = useState<CalendarPickerView>('day');

    useEffect(() => {
        // for some reason calendar picker triggers an on change event for now, which we need to reset with minDate
        setLatestDate(minDate);
    }, []);

    return (
        <WizardFadeInEffect>
            <PickerHeader>
                <MultiDatePickerInfoText
                    dates={dates}
                    maxSelectableDates={maxSelectableDates}
                />
            </PickerHeader>

            <PickerContainer>
                <CalendarPicker
                    date={lastDate}
                    onChange={date => {
                        if (date) {
                            const dateTime = date.startOf('day');
                            setLatestDate(dateTime);
                            switch (view) {
                                case 'day':
                                    const index = findIndexOfDate(
                                        dates,
                                        dateTime,
                                    );
                                    if (index === -1) {
                                        onChange([...dates, dateTime]);
                                    } else {
                                        onChange(
                                            dates.filter(
                                                item => !item.equals(dateTime),
                                            ),
                                        );
                                    }
                                    break;
                                case 'month':
                                    setView('day');
                                    break;
                                case 'year':
                                    setView('month');
                                    break;
                            }
                        }
                    }}
                    view={view}
                    onViewChange={setView}
                    allowSameDateSelection
                    disablePast
                    minDate={minDate}
                    maxDate={now.plus({ days: maxDaysInFuture })}
                    renderDay={(date, selectedDates, pickersDayProps) => {
                        const selected = findDate(dates, date);
                        return (
                            <PickersDay
                                {...pickersDayProps}
                                selected={selected}
                                disabled={
                                    pickersDayProps.disabled ||
                                    (maxSelected && !selected)
                                }
                                sx={{
                                    '&&:focus': {
                                        backgroundColor: theme =>
                                            selected
                                                ? theme.palette.primary.main
                                                : theme.palette.background
                                                      .default,
                                    },
                                }}
                            />
                        );
                    }}
                />
            </PickerContainer>
        </WizardFadeInEffect>
    );
}

function PickerHeader({ children }: { children: React.ReactNode }) {
    return (
        <Box
            sx={theme => ({
                display: 'flex',
                justifyContent: 'center',
                marginBottom: theme.spacing(2),
            })}
        >
            <Typography fontWeight="medium">{children}</Typography>
        </Box>
    );
}

function PickerContainer({ children }: { children: React.ReactNode }) {
    return (
        <Card
            elevation={0}
            sx={theme => ({
                backgroundColor: theme.palette.primary.light,
                color: theme.palette.primary.main,
                minHeight: '358px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            })}
        >
            {children}
        </Card>
    );
}

function MultiDatePickerInfoText({
    dates,
    maxSelectableDates,
}: {
    dates: DateTime[];
    maxSelectableDates: number;
}) {
    switch (dates.length) {
        case 0:
            return maxSelectableDates > 1 ? (
                <Localized
                    de="Wählen Sie die gewünschten Tage"
                    fr="Sélectionnez les jours souhaités"
                    it="Selezionare i gironi desiderati"
                    en="Select the desired days"
                />
            ) : (
                <Localized
                    de="Wählen Sie das gewünschte Datum"
                    fr="Sélectionner une date"
                    it="Selezionare la data desiderata"
                    en="Select the desired date"
                />
            );
        case 1:
            return <>{Formatter.dayMonthYear(dates[0])}</>;
        default:
            return (
                <>
                    {dates.length}{' '}
                    <Localized de="Tage" fr="jours" it="giorni" en="days" />
                </>
            );
    }
}

function findDate(dates: DateTime[], date: DateTime): boolean {
    return dates.some(item => item.equals(date));
}

function findIndexOfDate(dates: DateTime[], date: DateTime): number {
    return dates.findIndex(item => item.equals(date));
}
