import * as React from 'react';
import {
    LoadingSpinnerPresets,
    PresetLoadingSpinner,
} from '../../../common/components/material-ui/PresetLoadingSpinner';
import {
    RequestStatus,
    useServerSuccessEffect,
} from '../../../lib/hooks/ServerStateHooks';
import { useLogout } from '../api/ParkingaboLoginApi';
import { Localized } from '../../../common/hooks/LanguageProvider';
import { ParkingaboVehicle } from '../../../common/models/Vehicle';
import { ParkingaboUser } from '../shared/ParkingaboModels';
import { useParams } from 'react-router';
import { ParkingaboButton } from '../components/layout/ParkingaboButton';
import { ParkingaboProductListItem } from '../shared/ParkingaboProductModels';

export interface Vehicles {
    data: ParkingaboVehicle[];
    status: RequestStatus;
    refetch: () => void;
}

export interface AuthedRouteCompProps {
    user: ParkingaboUser;
    refetchUser: () => void;
    products: ParkingaboProductListItem[];
    refetchProducts: () => void;
    logout: () => void;
    vehicles: Vehicles;
}

export function Logout({ logout }: { logout: () => void }) {
    const [logoutState, sendLogout] = useLogout();
    useServerSuccessEffect(logoutState, logout);

    const pending = logoutState.status === RequestStatus.PENDING;
    return (
        <div>
            <ParkingaboButton
                variant="contained"
                onClick={() => sendLogout()}
                disabled={pending}
            >
                {pending ? (
                    <PresetLoadingSpinner
                        preset={LoadingSpinnerPresets.EmbedInButton}
                    />
                ) : (
                    <Localized
                        de="Abmelden"
                        fr="Quitter"
                        it="Esci"
                        en="Logout"
                    />
                )}
            </ParkingaboButton>
        </div>
    );
}

export function useParkingaboAuthedPathGeneration() {
    const { tenantId } = useParams<{ tenantId?: string }>();

    function generateAuthedParkingaboPath(path: string): string {
        return `/${tenantId}/` + path;
    }
    return generateAuthedParkingaboPath;
}
