import {
    useParkingaboServerFetch,
    useParkingaboServerWrite,
    useUpdateParkingaboCsrfToken,
} from './ParkingaboApi';
import {
    RequestMethod,
    RequestStatus,
    ServerWriteState,
    useServerSuccessEffect,
} from '../../../lib/hooks/ServerStateHooks';
import { useEffect } from 'react';
import { isPWA } from '../../../lib/utils/PwaUtils';
import { useNavigate } from 'react-router-dom';
import { ValidationData } from '../../../common/utils/FormValidationHelpers';

export interface SessionValidity {
    expiryDate: string;
    csrfToken: string;
    loginId: string | null;
    subdomain: string | null;
    refreshToken?: string;
}

export const REFRESH_TOKEN_LOCALSTORAGE_KEY = 'parkingabo_login_token';

export function useLogout(): ServerWriteState<SessionValidity, ValidationData> {
    const setGlobalCsrfToken = useUpdateParkingaboCsrfToken();

    const [state, write, reset] = useParkingaboServerWrite<{}, SessionValidity>(
        () => ({
            url: '/ui-api/parkingabo/session/logout',
        }),
        true,
    );

    const navigate = useNavigate();
    useEffect(() => {
        if (state.status === RequestStatus.SUCCESS) {
            navigate('/login', {
                replace: true,
            });
            setGlobalCsrfToken(null);
            localStorage.removeItem(REFRESH_TOKEN_LOCALSTORAGE_KEY);
        }
    }, [state.status]);

    return [state, write, reset];
}

export function useCheckAndRefreshSession() {
    return useParkingaboServerFetch<SessionValidity, {}>(
        () => ({
            url: '/ui-api/parkingabo/session/check-and-refresh',
            method: RequestMethod.POST,
        }),
        {},
        true,
    );
}

interface LoginPayload {
    email: string;
    password: string;
    createRefreshToken: boolean;
    rememberMe: boolean;
}

export function useLoginRequest(): ServerWriteState<
    SessionValidity,
    ValidationData,
    LoginPayload
> {
    const [loginState, sendLogin, reset] = useParkingaboServerWrite<
        LoginPayload,
        SessionValidity
    >(
        () => ({
            url: '/ui-api/parkingabo/session/login',
        }),
        true,
    );

    useServerSuccessEffect(loginState, data => {
        if (isPWA() && data.refreshToken) {
            localStorage.setItem(
                REFRESH_TOKEN_LOCALSTORAGE_KEY,
                data.refreshToken,
            );
        }

        // we're out of the router
        history.replaceState({}, document.title, '/');
    });

    return [loginState, sendLogin, reset];
}

export function useSession() {
    return useParkingaboServerWrite<
        {
            refreshToken?: string;
        },
        SessionValidity
    >(() => ({
        url: '/ui-api/parkingabo/session',
    }));
}
